const json = [
    {
        title: 'Step 1',
        subtitle: 'Let us know your environment',
        desc: 'Supercharge your Account Management with actionable insights ',
        img: require("../../assets/selec.svg")
    },

    {
        title: 'Step 2',
        subtitle: 'Get AppEQ Extension',
        desc: 'Our lightweight extension augments your Inbox and existing Apps with Account Insights',
        img: require("../../assets/download.svg")
    },

    {
        title: 'Step 3',
        subtitle: 'Experience AppEQ',
        desc: 'Visualise how AppEQ brings Account Insights directly to your Inbox',
        img: require("../../assets/demo.svg")
    },
    {
        title: 'Step 4',
        subtitle: 'Invite your team member',
        desc: 'You can invite your team members to AppEQ and we will provied them all support',
        img: require("../../assets/invite.svg")
    },
];
export default json;