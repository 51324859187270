export const fields = [

  {
    label: "Renewal Date",
    key: "renewalDate",
    remarks: "Renewal Due Date",
  },
  { label: "ACV", key: "acv", remarks: "Annual Contract Value" },
  { label: "ARR", key: "arr", remarks: "Annual Recurring Revenue" },
  { label: "TCV", key: "tcv", remarks: "Total Contract Value" },
  {
    label: "Sponsor email",
    key: "sponsorEmail",
    remarks: "For tracking engagment",
  },
  {
    label: "Champion email",
    key: "championEmail",
    remarks: "For tracking engagment",
  },
];

export const fields2 = [
  {
    label: "* Timestamp",
    key: "timestamp",
    remarks: "Mandatory Field",
  },
  {
    label: "* Email",
    key: "account_domain",
    remarks: "Mandatory Field",
  },
  {
    label: "* Score",
    key: "score",
    remarks: "Mandatory Field",
  },
  {
    label: "Reason for Score",
    key: "score_reason",
    remarks: "Not Mandatory",
  },
];


export const Pulse = [
  {
    label: "* Health",
    key: "health",
    remarks: "Mandatory Field",
  },
  {
    label: "* Reason",
    key: "reason",
    remarks: "Mandatory Field",
  },
  {
    label: "* Notes",
    key: "reason",
    remarks: "Mandatory Field",
  },

];

export const Contacts = [
  {
      label: "First Name",
      key: "firstName",
      remarks: "First Name",
  },
  {
      label: "Last Name",
      key: "lastName",
      remarks: "Last Name",
  },
  {
      label: "Email",
      key: "email",
      remarks: "Email",
  },
  {
      label: "Linkedin URL",
      key: "linkedin",
      remarks: "Linkedin",
  },
  {
      label: "Last Activity",
      key: "lastEmailSentDate",
      remarks: "Last Activity",
  },
  {
      label: "Title",
      key: "title",
      remarks: "Title",
  },
  {
      label: "Key Contact",
      key: "contactType",
      remarks: "Key Contact",
  },

  {
      label: "Twitter URL",
      key: "twitter",
      remarks: "Twitter URL",
  },

];