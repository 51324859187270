<template>
    <div style="padding: 15px;display: flex;align-items: center;background: rgb(77, 73, 66);color: white !important;">
        <img style="height: 50px;margin-right: 10px;" src="../assets/dashboard.png" />
        <div class="">
            <div>
                <h2 style="margin-bottom: 5px;color: white !important;text-align: center;font-size: 16px;">{{viewType[index]}} View</h2>
                <p style="color: white !important;font-size: 10px;">
                    {{ desc[index] }}
                    <!-- This is {{viewType[index]}} view with customizations. -->
                </p>
            </div>
            <div style="display: flex;align-items: center;justify-content: flex-end">
                <button style="color: white; background-color: blue; border: none; outline: none; padding: 7px; width: 80px;border-radius: 8px;height: 30px; cursor: pointer;" @click="handleNext">
                    {{ index==1?"Finish":"Next" }}    
                
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted(){
        Intercom('update', {
            "hide_default_launcher": true
        });
    },
    methods: {
        handleNext(){
            console.log('code here now')
            this.index = this.index + 1;
            if(this.index==2){
                window.parent.postMessage({ action: "__appeq_open_demo_popup_close", data: this.index+1 }, "*");

            }else{
                window.parent.postMessage({ action: "__appeq_open_demo_popup_next", data: this.index+1 }, "*");

            }
        }
    },
    data(){
        return {
            viewType:['Dashboard', 'Feeds'],
            desc:['Design and activate dashboards anywhere on your existing enterprise applications'
            ,
            'The Feeds Widget in Appeq serves as a centralized hub for real-time updates, notifications, and interactions related to customer accounts.'],
            index: 0
        }
    },
    created(){
        window.addEventListener("message", (ev) => {
            console.log(ev, 'data here');
        })
    }
}
</script>
<style scoped>
html{
    background: white;
}
</style>