<template>
    <div v-if="!showClosingModal" style="padding: 20px;">
        <div style="display: flex;justify-content: space-between;">
            <h2>Widget Settings</h2>
            <img style="cursor: pointer; height: 18px;" @click="handleClose" src="../icons/Close.svg" alt="">
        </div>
        <div style="display: flex; height: 75vh;">
            <div style="width: 35%;">
                <div style="width: 96%; margin: 10px;">
                    <label>Widget icon and headline *</label>
                    <div style="display: flex; justify-content: space-between; align-items: center;width: 100%;">
                        <!-- <Multiselect v-model="selectedIcon" style="width: 100% !important;" :options="icons"/> -->
                        <div style="width: 100%;">
                            <div style="margin-bottom: 10px; display: flex; justify-content: space-around;">
                                <div style="display: flex; align-items: center;">
                                    <span><img v-bind:src="(selectedIcon)" style="height: 30px;" alt=""></span>
                                </div>
                                <!-- <button style="height: 40px;width: 111px;border-radius: 5px;" @click="openSelectIconModal=true;"> -->
                                    <!-- </button> -->
                                    <span style="color: blue;font-weight: 600;font-size: 16px;cursor: pointer;margin-left: 10px;" @click="handleOpenIconModal">
                                        Select icon
                                    </span>
                            </div>
                            <input style="padding: 8px; outline: none; border: 1px solid #d1d1d1; border-radius: 5px;width: 100%;" type="text" placeholder="Widget headline" v-model="widget.content.title">
                        </div>
                    </div>
                    <div v-if="openDropDownForWidgetIcon" style="position: fixed; z-index: 9;">
                        <div v-if="openDropDownForWidgetIcon" style="position: relative; top: 0.25rem; border-radius: 6px; background: #c6c6ff; height: 12vh; width: 4vw;">
                            <div v-if="!loading" v-for="(icon, idx) in widgetIconOptions" style="cursor: pointer;" :key="idx" @click="handleSelectWidgetIcon(icon)">
                                <img v-bind:src="icon" style="height: 19px;" alt="">
                            </div>
                            <div v-else style="display: flex; align-items: center; justify-content: center;">
                                <img src="../icons/loadingripple.svg" style="height: 200px;" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin: 10px; display: flex; flex-direction: column;">
                    <label>Widget description *</label>
                    <input v-model="widget.content.desc" name="" id="" style="border: 1px solid #cfcfcf; border-radius: 6px; outline: none;height: 35px;" ></input>
                </div>
                <div style="margin: 10px;">
                    <label>Widget type *</label>
                    <Multiselect @select="handleWidgetTypeSelect" :options="['tasklist','dashboard', 'announcement']" v-model="widget.widgetType"/>
                </div>
                <div style="margin: 10px;">
                    <label>Widget Content *</label>
                    <Multiselect :loading="dataLoading" @select="handleWidgetSelect" :options="widgetOptions" label="name" v-model="widget.widget"/>
                </div>
                <!-- <div style="display: flex; align-items: baseline; justify-content: space-between; width: 70%; padding: 10px; border: 1px solid #cfcfcf; border-radius: 8px; margin-left: 0.6rem; margin-top: 2rem;">
                    <label>
                        Size *
                    </label>
                    <div style="display: flex; align-items: baseline;">
                        <label style="margin-right: 2px;">Height</label>
                        <input style="width: 2.5rem; outline: none;" type="number">
                    </div>
                    <label> X </label>
                    <div style="display: flex; align-items: baseline;">
                        <label style="margin-right: 2px;">Width</label>
                        <input style="width: 2.5rem; outline: none;" type="number">
                    </div>
                </div> -->
            </div>
            <div style="width: 5%;"></div>
            <div style="width: 65%; background: white;">
                <div>
                    <div class="preview__appeq">
                        <!-- <img v-if="selectedIcon" :src="'../icons/'"+selectedIcon/> -->

                        <!-- <img :src="'../images/'+selectedIcon" /> -->
                        <div v-if="widgetSelected" style="height: 400px;overflow-y: scroll;overflow-x: hidden;" class="overflowWidget">
                            <PopupIframe :widgetProp="widget" v-if="widget && widget.widget && widget.widget.type"></PopupIframe>
                            <div class="" v-else>
                                Preview
                            </div>
                        </div>
                        <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;" class="" v-else>
                            <h2>Widget Preview</h2>
                            <img style="height: 120px;" src="../icons/noData.svg" />
                            <h4>Please select a widget</h4>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="" style="display: flex;align-items: center;justify-content: flex-end;width: 100%;">
            <button @click="handleSave" style="width: 15%;height: 30px;">Save</button>
        </div>
        <div style="position: fixed; left: 0; top: 0; height: 100%; width: 100%; z-index: 999999999; background-color: rgba(0, 0, 0, 0.3);" v-if="openSelectIconModal">
            <div style="position: fixed; left: 24%;top: 15%;height: 70vh; width: 45vw; background: white; border-radius: 16px; border: none; padding: 14px;" v-if="openSelectIconModal">
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div class=""></div>
                    <h3 style=" margin-bottom: 0px;text-align: center;">Select Icon</h3>
                    <img src="../icons/Close.svg" style="cursor: pointer; height: 18px;" @click="openSelectIconModal=false;" alt="">
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; margin-top: 2rem; grid-gap: 3px;">
                    <div @click="handleSelectIcon(image)" class="widgetIconArrayEle" v-for="image in iconArray" style="display: flex; justify-content: space-around; flex-direction: column; align-items: center; width:100%; padding: 5px; margin-top: 1rem; border-radius: 5px; cursor: pointer;">
                        <img v-bind:src="(image.s3_url)" style="height: 40px;" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else style="background-color: white; height: 100vh;">
        <div style="display: flex; align-items: center; justify-content: center;">
            <img src="../icons/checked.gif" style="height: 200px;" alt="">
        </div>
        <div>
            <h2 style="text-align: center;">You have successfully saved a widget</h2>
        </div>
        <div style="display: flex; justify-content: center; align-items: center;">
            <div v-if="counting">
                Closing in {{ counter }}
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Server from '../components/API/Server';
import PopupIframe from "./PopupIframe.vue"
import TaskPopup from '../components/TaskPopup.vue';
export default {
    data(){
        return {
            selectedIcon:"/img/beacon.svg",
            savedSelectors:null,
            urlToSave:"",
            loading: false,
            widgetSelected:false,
            widget:{
                content: {
                    title: "",
                    desc: "",
                    filterCond: []
                },
                widget:{},

            },
            dataLoading:false,
            widgetIcon: '../icons/noData.svg',
            widgetIconOptions: [],
            openDropDownForWidgetIcon: false,
            widgetHeadline: '',
            widgetType: '',
            icons:["2.png"],
            widgetOptions:[],
            openSelectIconModal: false,
            showClosingModal: false,
            counting: false,
            counter: 3,
            iconArray: []
        }
    },
    created(){
        window.addEventListener("message",(ev)=>{
            const data = event.data;
    if (data && data.action === 'datasend') {
        this.savedSelectors=data.savedSelectors
        this.urlToSave=data.url
        // Assuming you have a container in your iframe to display the content
        // document.getElementById('content').innerHTML = data.htmlElement;
        // document.querySelector(".preview__appeq").innerHTML=(data.htmlElemnt)

    }
           
        })
    },
    methods: {
        async handleSave(){
            console.log(this.widget)
            console.log(this.savedSelectors)
            console.log(this.urlToSave)
            if(!this.selectedIcon || this.selectedIcon=='' || !this.widget.content.title || this.widget.content.title=='' || !this.widget.content.desc || this.widget.content.desc=='' || !this.widget.widgetType || this.widget.widgetType=='' || !this.widget.widget || this.widget.widget==''){
                this.$toasted.error("Please fill all the fields to continue");
                return;
            }
            this.widget={
                ...this.widget,
                icon:this.selectedIcon,
                displayRules:{
                    url:this.urlToSave
                },
                selector:this.savedSelectors,
                type:"manual"
            }
            console.log("ready to save",this.widget)
            this.showClosingModal = true;
            this.startCountdown();
            await Server.saveWidget(this.widget);
            setTimeout(() => {
                this.showClosingModal = false;
                window.close()
                this.handleClose();
            }, 3500);
        },
        startCountdown() {
            this.counting = true;
            this.reverseCount(this.counter);
        },
        reverseCount(startNumber) {
            if (startNumber > 0) {
                setTimeout(() => {
                this.counter = startNumber - 1;
                this.reverseCount(this.counter);
                }, 1000); // Decrease the counter every second (1000 milliseconds)
            } else {
                this.counting = false;
            }
        },
        handleSelectIcon(icon){
            this.selectedIcon = icon.s3_url;
            this.openSelectIconModal = false;
        },
        async handleOpenIconModal(){
            this.openSelectIconModal=true;
            this.loading = true;
            try {
                let iconsData = await Server.getWidgetIcons();
                console.log(iconsData, 'icons data');
                if(iconsData.code==200){
                    this.iconArray = iconsData.data;
                }
                // this.loading = false;
            } catch (error) {
                // this.loading = false;
            }
        },
        handleClose(){
            window.parent.postMessage({ action: "__appeq_close_post_settings_popup", data: {} }, "*");
        },
        handleWidgetSelect(){
            console.log(this.widget)
            this.widgetSelected=true
            // window.postMessage({data:[this.widget]})
        },
        async handleWidgetTypeSelect(){
            this.dataLoading=true
            if(this.widgetType!='summary'){
                const data=await Server.getWidgetContent(localStorage.getItem("__email__id"));
                console.log(this.widgetType)
                this.widget.widget.value=this.widget.widgetType
                this.widgetOptions=data.data.filter(d=>d.type==this.widget.widget.value)
                console.log(this.widgetOptions)
            }
            this.dataLoading=false

        },
        handleSelectWidgetIcon(icon){
            this.widgetIcon = icon;
            this.openDropDownForWidgetIcon = false;
        }
    },
    async mounted(){
        Intercom('update', {
            "hide_default_launcher": true
        });
        const widgetIcons=await Server.getIcons()
        this.iconArray =widgetIcons.data
        this.selectedIcon=this.iconArray[0].s3_url
    },
    components: {
        Multiselect,
        PopupIframe
    }
}

</script>

<style scoped lang="scss">
.preview__appeq{
    border: 1px solid #efefef;
    padding: 10px;
    border-radius: 8px;
}
.overflowWidget::-webkit-scrollbar{
    display: none;
}
.widgetIconArrayEle:hover{
    background-color: rgb(207, 205, 205);
}
</style>