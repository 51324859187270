<template >
    <!-- :style="{backgroundColor:this.openAddModal?'rgba(0,0,0,.3)':'white'}" -->
    <div :id="account_domain">
        <div style="width: 90vw;" class="summaryData">
            <div  class="ticket_info_headers">
                <div class="" style="display: flex;align-items: center;">
                    <div style="display: flex;align-items: center; width: 260px;">
                        <img @error="handleError($event)" style="height: 25px !important;" class="logoSummaryShow"
                            v-bind:src="getIcon(account_domain ? account_domain : '')" />
                        <span @click="redirectAcc" v-if="account_domain" style="cursor: pointer;" class="ticket_header_name">{{
                            getFeedName(account_domain) }}</span>
                        <span @click="redirectAcc" v-else style="cursor: pointer;" class="ticket_header_name">{{
                            getFeedName(accountName) }}</span>
                        <!-- <span><img src="../icons/redirect.png" style="height: 11px;margin-left: -6px; cursor: pointer;margin-right: 10px;" @click="redirectAcc"
                                alt=""></span> -->
                        </div>
                  
                </div>

                <!-- <div class="conv_blk">
                    <img class="conv_icon" src="../icons/conv_list_icon.svg" alt="">
                </div> -->
                <!-- <div v-if="(domInfo.findIndex(d=>d._id=='Account Pulse')==-1)" style="padding-top: 5px;" @click="openPulseModal=true" class="pulseIcon">
                    Set Pulse
                </div>
                <div v-else style="color: blue;font-weight: 700;cursor: pointer;" @click="openPulseModal=true" >
                    Set Pulse
                </div> -->
              
          
              
            </div>
            <div class="outer_bg_modal" v-if="this.openPlayBook">
            <div style="    position: fixed;
    left: 25%;
    top: 22%;
    background-color: white;
    border: 0.2px solid grey;
    width: 50%;
    height: 60%;
    border-radius: 10px;
    z-index: 9999999999;
    padding: 5px 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;"
                v-if="this.openPlayBook">
                <div style="height: 50vh;" class="">
                    <TriggerPlaybookModal @close="openPlayBook=false" :account_id="accountid" ></TriggerPlaybookModal>

                </div>
            </div>
        </div>
        <div style="    position: fixed;
    left: 25%;
    top: 22%;
    background-color: white;
    border: 0.2px solid grey;
    width: 50%;
    height: 60%;
    border-radius: 10px;
    z-index: 9999999999;
    padding: 5px 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;"
                v-if="this.openSellingOpportunity">
                <div style="height: 50vh;" class="">
                    <SellingOpportunity :account_id="accountid" :account_domain="account_domain" @close="openSellingOpportunity=false" />

                </div>
                </div>
            <div style="display: flex;align-items: flex-start;">
                <div style="display: flex;width: 60%;flex-direction: column;" class="ticket_info_body">
                <h3 style="color: #737373;padding-left: 12px;margin-top: 12px;margin-bottom: 8px">Key Data</h3>
                    <template v-if="!noDataFound">
                        <div v-if="!loadingSum" style="display: flex;align-items: flex-start;justify-content: space-between;font-size: 0.75vw;padding-left: 16px;"
                            v-for="summ of summaryData">
                            <div class="summary__data_obj" style="display:flex;align-items: center;justify-content: space-between" v-for="row of summ.appeq_summary_row">
                                <template
                                    v-if="row.appeq_summary_column.appeq_summary_label == 'health' || row.appeq_summary_column.appeq_summary_label == 'Health' || row.appeq_summary_column.appeq_summary_label == 'HEALTH'">

                                    <span style="font-style:italic;font-size:0.75vw;color: #817f7f;"> {{ loadingSum ? '' :
                                        row.appeq_summary_column.appeq_summary_label + ' : ' }}</span>

                                    <span class="healthSpan" :class="this.health ? this.health : ''"
                                        style="font-weight: 700;">
                                        {{ loadingSum ? '' : row.appeq_summary_column.appeq_summary_value }}
                                    </span>

                                </template>
                                <template v-else>
                                    <span v-if="row.appeq_summary_column.appeq_summary_type != 'graph'"
                                        style="font-style:italic;font-size:0.75vw;color: #817f7f;"> {{ loadingSum ? '' :
                                            row.appeq_summary_column.appeq_summary_label + ' : ' }}&nbsp;</span>
                                    <span 
                                    role="tooltip" :aria-label="row.appeq_summary_column.appeq_summary_value"
                                    style="margin-left: 4px;font-weight: 600;color: rgb(65, 62, 62);text-overflow: ellipsis;white-space: nowrap;flex: 1;"
                                        v-if="row.appeq_summary_column.appeq_summary_type != 'graph' && row.appeq_summary_column.appeq_summary_type != 'requesters' && row.appeq_summary_column.appeq_summary_type != 'lastTicket'
                                        && !isURL(row.appeq_summary_column.appeq_summary_value)
                                        "
                                        v-bind:innerHTML="loadingSum ? '' : (row.appeq_summary_column.appeq_summary_value && row.appeq_summary_column.appeq_summary_value.length>19) ? row.appeq_summary_column.appeq_summary_value.substr(0,16)+'...':row.appeq_summary_column.appeq_summary_value">
                                    </span>
                                    <span style="color: blue;cursor: pointer; font-weight: 600;  text-overflow: ellipsis; white-space: nowrap; flex: 1 1 0%;
                                    " @click="handleWebsite(row.appeq_summary_column.appeq_summary_value)"  v-if="isURL(row.appeq_summary_column.appeq_summary_value)">
                                        Link
                                    </span>
                                    <span :aria-label="row.appeq_summary_column.appeq_emails[index]"
                                        data-microtip-position="bottom-right" role="tooltip" class="requesterEmail"
                                        :style="{ 'background': this.getRandomColor() }"
                                        @click="openCompose(index, row.appeq_summary_column)"
                                        v-if="row.appeq_summary_column.appeq_summary_type == 'requesters' && Array.isArray(row.appeq_summary_column.appeq_summary_value)"
                                        v-for="data, index of row.appeq_summary_column.appeq_summary_value ">
                                        {{ data }}
                                    </span>
                                    <div v-if="row.appeq_summary_column.appeq_summary_type == 'graph'"
                                        :id="'chartDiv' + accountid" style="width:150px; height:120px;margin-left: 5px;">
                                    </div>
                                    <div style="margin-left: -10px;"
                                        v-if="row.appeq_summary_column.appeq_summary_type == 'graph'">
                                        New Tickets (Past 90 days)</div>
                                    <div style="display:flex;align-items: center;color:black"
                                        v-if="row.appeq_summary_column.appeq_summary_type == 'lastTicket'">
                                        <span style="font-weight: 600;">
                                            {{
                                                row.appeq_summary_column.appeq_summary_prevalue ?
                                                row.appeq_summary_column.appeq_summary_prevalue : 'NA'
                                            }}
                                        </span>
                                        <template v-if="row.appeq_summary_column.appeq_emails">
                                            <span :aria-label="row.appeq_summary_column.appeq_emails"
                                                data-microtip-position="right" role="tooltip" class="requesterEmail"
                                                :style="{ 'background': this.getRandomColor() }"
                                                @click="openCompose(index, row.appeq_summary_column)">
                                                {{ row.appeq_summary_column.appeq_summary_value }}
                                            </span>
                                        </template>


                                    </div>


                                </template>
                            </div>
                        </div>
                        <div style="display:flex;align-items:center;justify-content: center;" v-if="loadingSum">
                            <!-- <img style="height: 100px;width: 100px;" src="../ass" /> -->
                        </div>
                    </template>
                    <template v-else>
                        <div class="no_data_div">
                            <!-- <img style="height:130px;display: block;margin: auto;" src="../icons/noData.svg" /> -->
                            <span>No Summary Found</span>
                            <span>First, a summary should be created. <a target="_blank"
                                    href="https://app.appeq.ai/summary-creator">Create Summary</a></span>
                        </div>
                    </template>
                    <div class=""
                    style="margin-top: 0.75vw;"
                    >
                    <h3 style="color: #737373;padding-left: 0.75vw;;;margin-top: 12px;margin-bottom: 18px">Signals</h3>
                    <div class=""
                    style="display: flex;align-items: center;justify-content: space-between;padding: 2px 20px;"
                    >
                    <div style="display: flex;align-items: center;">
                    
                  
                        <div class="" 
            style="width: 50%;
            padding-left: 0px;
            "
            >
            <div style="color: blue;font-weight: 700;cursor: pointer; border: 1px solid #bfbdbd;"
                    @click="openPulseInfo = true">
                    <!-- View Pulse -->
                    <span v-if="account_domain" style="display: flex; align-items: center;">
                        <span style="width: 50px;  height: 20px; padding: 3px 5px; display: flex; align-items: center;">
                            <span data-html="true"
                                :aria-label="data.reason + '\r\n' + (data.description != undefined ? data.description.substr(0, 30) + '...' : '')"
                                @click="this.selectedPulse = idx;" data-microtip-position="bottom-right" role="tooltip"
                                :style="{ background: data.health, width: '8px', height: '20px', display: 'block', marginRight: '10px', cursor: 'pointer' }"
                                v-for="(data, idx) of pulseData"></span>
                        </span>
                    </span>
                </div>
            </div>
            <div class="" style="display: flex;align-items: center;padding-left: 20px;">
                        <span v-if="account_domain" :aria-label="(reason && reason!='') ? reason: 'NA'" data-microtip-position="right" role="tooltip" @click="openHealthAudit" class="healthSpan" :class="healthVal">
                            {{ healthVal }}
                        </span>
                        <span style="color: blue; font-weight: 700; font-size: 12px; margin-left: 6px;" > <span v-if="healthVal==='red'" @click="openHealthRed()" class="why_link">Why?</span></span>
                    </div>
                    <div class="">
                        <!-- <h2>Red</h2> -->
                    </div>
                    </div>
                    <div style="margin-right: 20px;display: flex;align-items: center;" class="">
                        <!-- <h4>Growth Signals</h4> -->
                    </div>
                </div>
                 
        </div>
                </div>
          
            </div>
          
            <!-- {{ domInfo }} -->
          

        </div>
        <div v-if="this.open && !this.loadingData">

            <sheetFeeds :layout="null"
                v-if="dataOfOpen[openedItem] && dataOfOpen[openedItem]['data'] && dataOfOpen[openedItem]['data']['data'] && dataOfOpen[openedItem]['data'].code==200 && dataOfOpen[openedItem]['data']['data'][0]"
                :sheet_list="dataOfOpen[openedItem]['data']['data']">
            </sheetFeeds>
            <div v-else style="display: flex; align-items: center; justify-content: center;">
                <!-- <img src="../icons/noData.svg" style="height: 150px;" alt=""> -->
            </div>

        </div>
        <div v-if="this.open && this.loadingData">
            <!-- <img style="height: 100px;width: 100px;margin-left: 35%;" src="../icons/loadingripple.svg" /> -->

        </div>
        <div class="outer_bg_modal" v-if="this.openAddModal">
            <div style="    position: fixed;
    left: 25%;
    top: 25%;
    background-color: white;
    border: 0.2px solid grey;
    width: 50%;
    border-radius: 10px;
    z-index: 9999999999;
    padding: 5px 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;"
                v-if="this.openAddModal">
                <AddTaskModal @save="openAddModal = false; saveTasks();" :taskSelected="taskSelected"
                    @close="openAddModal = false"></AddTaskModal>
            </div>
        </div>

        <!-- <div style="    position: fixed;
    left: 35%;
    top: 25%;
    background-color: white;
    border: 0.2px solid grey;
    width: 20%;
    border-radius: 10px;
    z-index: 9999999999;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;"
            v-if="this.openPulseModal">
            <PulseInfo @close="handlePulseClose($event)" :account_domain="account_domain" :account_id="accountid" />
        </div> -->
        <!-- <div style="position: fixed; height: 100%; top: 0px; width: 100%; z-index: 99999999; background-color: rgba(0,0,0,.3); left: 0px;"
            v-if="this.openPulseInfo">
            <div style="    position: fixed;
    left: 33%;
    top: 25%;
    background-color: white;
    border: 0.2px solid grey;
    width: 35%;
    height: 45%;
    border-radius: 10px;
    z-index: 9999999999;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;"
                v-if="this.openPulseInfo">
                <PulseInfo @close="handlePulseClose($event)" :account_domain="account_domain" :account_id="accountid"
                    :selectedPulseOpen="this.selectedPulse" />
            </div>
        </div> -->

    </div>
</template>
<script>
import Server from "./components/API/Server";
import moment from "moment";
import { JWT } from "node-jsonwebtoken";
// import SellingOpportunity from "./SellingOpportunity.vue";
import { toast } from 'vue3-toastify';
// import 'vue3-toastify/dist/index.css';
import { createChart } from 'lightweight-charts';
// import sheetFeeds from "../components/sheetFeeds.vue"
// import salesforceFeeds from "./Salesforcedeeds.vue"
// import HubSpotFeeds from './HubSpotFeeds.vue';
import Multiselect from "vue-multiselect";
// import AppEQAnalytics from "../analytics/appeqAnalytics"
// import feeds from "../feeds/instance"
// import PulseView from "./PulseView.vue";
// import TriggerPlaybookModal from "./TriggerPlaybookModal.vue";
// import PulseInfo from "./PulseInfo.vue"
// import AddTaskModal from "../components/AddTaskModal.vue"
export default {

    props: {
        isOpenPulse: false,
        tasksProps: [],
        info: [],
        reason: {
            type: String
        },
        account_domain: {
            type: String,
        },
        accountid: {
            type: String,
        },
        health: {
            type: String,
            // default: "RED"
        },
        closeSummary: {
            type: Function,
        }

    },
    name: "SummaryView",
    data() {
        return {
            oldTaskName: '',
            openPlayBook:false,
            isEdit: false,
            desc: "",
            duedate: '',
            openPulseInfo: false,
            openPulseModal: false,
            summaryData: [],
            checkedList: {},
            domInfo: [],
            open: false,
            checked: [],
            dataOfOpen: {},
            accountName: "",
            assignee: '',
            type: '',
            name: '',
            allTasks: [],
            noDataFound: false,
            healthIs: "",
            healthVal: "",
            slackEnabled: true,
            notesEnabled: true,
            openAddModal: false,
            dashboard: false,
            metabaseNumber: "",
            downArrow: {},
            loadingData: false,
            genieEnabled: true,
            taskType: {
                "TODO": "https://cdn-icons-png.flaticon.com/512/2387/2387679.png",
                "EMAIL": "https://cdn-icons-png.flaticon.com/512/2374/2374449.png",
                "CALL": "https://cdn-icons-png.flaticon.com/512/3014/3014736.png"
            },
            i: 0,
            tasks: [],
            openMore:false,
            openedItem: "",
            chartDi: null,
            lineData: {},
            users: [],
            opened: false,
            lastUpdatedAtHighLights: '',
            showHighBtn: false,
            showHighLights: false,
            disableHightLighBtn: false,
            openSellingOpportunity: false,
            selectedPulse: 0,
            highLights: "",
            loadingSum: true,
            pulseData: [],
            taskSelected: {},
            loadingHighLights: false
        }
    },
    components: {
        // PulseInfo,
        // HubSpotFeeds,
        // salesforceFeeds,
        // sheetFeeds,
        Multiselect,
        // PulseView,
        // SellingOpportunity,
        // TriggerPlaybookModal,
        // AddTaskModal
    },

    created() {

    },
    watch: {
        info:{ 
            handler (newVal,oldVal) {
                this.initData();
            },
            deep:true
        },
    },
    async mounted() {
        await this.initData();

    },
    methods: {
        isURL(text) {
            const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
            return urlPattern.test(text);
        },
        async initData(){
            if (localStorage.getItem("MY_CUSTOMERS")) {
                let data = JSON.parse(localStorage.getItem("MY_CUSTOMERS"));
                let res = data.data;
                if (!this.account_domain) {
                    console.log('inside');
                    for (let i = 0; i < res.length; i++) {
                        if (this.accountid == res[i]['id']) {
                            this.accountName = res[i]['name'];
                            break;
                        }
                    }
                }
            }
           
            // console.log(this.health)
            // this.health = this.health.toUpperCase()
            // this.healthIs = this.health
            console.log(this.healthIs)
            // this.tasks = this.tasksProps
            this.tasks=[]
            this.domInfo = this.info
            console.log(this.domInfo);
            if (this.domInfo && Array.isArray(this.domInfo) && this.domInfo.filter(d => d._id == "accounts").length > 0) {
                const idx = this.domInfo.findIndex(d => d._id == "accounts")
                this.domInfo.splice(idx, 1)

            }
            console.log(this.domInfo);
            const idx2 = this.domInfo?.findIndex(d => d._id && d._id.toLowerCase() == "pulse")
            if(idx2!=-1)
            this.domInfo?.splice(idx2,1)

        
            const idx3 = this.domInfo?.findIndex(d => d._id && d._id.toLowerCase() == "tasks")
            console.log("idx 3 is " + idx3)
            if(idx3!=-1)

            this.domInfo?.splice(idx3,1)
        
            console.log(this.domInfo);
            this.tasks?.map((data, i) => {
                this.checkedList[data.task_name + i] = false;
            })
            console.log(this.domInfo)
            this.loadingSum = true
            this.openPulseData();
        },
        handleWebsite(url){
            window.open(url,"_blank");
        },
        handleOpenTriggerPlaybookModal(){
            this.openPlayBook=!this.openPlayBook
        },
        handleOpenMoreOption(){
            this.openMore=!this.openMore
        },
        openHistory() {
            feeds.sendActionToTop("__appeq_open_account_audits", { account_domain: this.account_domain, id: this.accountid, type: "audits" });

        },
        openHealthAudit(){
            feeds.sendActionToTop("__appeq_open_account_audits", { account_domain: this.account_domain,id:this.accountid,type:"health" });
        },
        async openDash() {
            var METABASE_SITE_URL = "https://metabase.appeq.ai";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            console.log("params")
            console.log(this.accountid)
            console.log(this.account_domain)
            console.log(this.metabaseNumber)
            const jwt = new JWT(METABASE_SECRET_KEY);
            const token = await jwt.sign({
                resource: { dashboard: parseInt(this.metabaseNumber) },
                params: {
                    domain: this.account_domain,
                    id:this.accountid
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";
            AppEQAnalytics.actionCall("DashboardOpened","Dashboard",{
                dashboard_num:this.metabaseNumber
            })    
            feeds.sendActionToTop("__appeq_open_executive_dashboard", iframeUrl);

        },
        async redirectAcc() {
            const creds = await Server.getBusinessDataObs()
            if (creds.data.filter(d => d['business_object_name'] == 'accounts').length != 0) {
                let urlToRedirect = ''
                creds.data.map(d => {
                    if (d.instance_url) {
                        urlToRedirect = d.instance_url
                    }
                })
                urlToRedirect += '/lightning/r/' + 'Account' + '/' + this.accountid + '/view'
                window.open(urlToRedirect)

            }
        },
        async openPulseData() {
            let adminConfigs = localStorage.getItem('adminConfigs');
            if (adminConfigs) {
                adminConfigs = JSON.parse(adminConfigs);
                if (adminConfigs.business_object_name && adminConfigs.type && adminConfigs.type == 'pulse') {
                    const [data, data1] = await Promise.all([
                        [],
                        // Server.fetchAllDataForOpen(adminConfigs.business_object_name, 0, this.account_domain, this.accountid),
                        Server.fetchSummaryData(this.account_domain, this.accountid)
                    ]
                    )
                    this.openSummary(data1)
                    console.log(data);
                    if (data && data.data && data.data.data && data.data.data.data) {
                        if (data.data.data.data && Array.isArray(data.data.data.data) && data.data.data.data.length > 3) {
                            data.data.data.data = data.data.data.data.reverse();
                            data.data.data.data = data.data.data.data.slice(0, 3)
                        }
                        this.pulseData = data.data.data.data;
                    }
                } else {
                    console.log(this.account_domain)
                    const [data, data1] = await Promise.all([
                    [],
                    Server.fetchSummaryData(this.account_domain, this.accountid)
                    ]
                    )
                    this.openSummary(data1)
                    console.log(data, "gaurav1234")
                    console.log(data);
                    if (data.data && Array.isArray(data.data) && data.data.length > 3) {
                        data.data = data.data.reverse();
                        data.data = data.data.slice(0, 3)
                    }
                    this.pulseData = data.data;
                }
            } else {
                const [data, data1] = await Promise.all([
                    // Server.readPulseData(this.account_domain, this.accountid),
                    [],
                    Server.fetchSummaryData(this.account_domain, this.accountid)
                ]
                )
                this.openSummary(data1)
                if (data.data && Array.isArray(data.data) && data.data.length > 3) {
                    data.data = data.data.reverse();
                    data.data = data.data.slice(0, 3)
                }

                this.pulseData = data.data;
            }
        },
        handleOpenAccount() {
            feeds.sendActionToTop("__appeq_open_account_collabration", { account_domain: this.account_domain, id: this.accountid });
        },
        openNotes() {
            feeds.sendActionToTop("__appeq_open_notes_iframe_summary", { domain: this.account_domain, id: this.accountid, state: 'private' })
        },
        openNotesPublic() {
            feeds.sendActionToTop("__appeq_open_notes_iframe_summary", { domain: this.account_domain, id: this.accountid, state: 'public' })
        },
        handleOpen() {
            feeds.sendActionToTop("__appeq_open_profile_summary", { account_domain: this.account_domain });
            AppEQAnalytics.actionCall("GenieOpened", "Feed", { account_domain: this.account_domain })



        },
        handleError(e) {
            e.target.src = 'https://img.freepik.com/free-vector/abstract-company-logo_53876-120501.jpg'
        },
        handlePulseClose(event) {
            this.openPulseInfo = false;

            if (event) {
                this.openPulseModal = false;
                const idx = this.domInfo.findIndex(d => d._id == "Account Pulse");
                if (idx == -1) {
                    this.domInfo.push({ "_id": "Account Pulse", count: 1 })
                } else {
                    this.domInfo[idx] = { "_id": "Account Pulse", count: this.domInfo[idx]["count"] + 1 }
                    this.dataOfOpen['tasks'] = false;
                }
                this.openPulseData();
            } else {
                this.openPulseModal = false;

            }
        },
        isElementVisible(element) {
            // Get the position of the element relative to the viewport
            const elementRect = element.getBoundingClientRect();

            // Check if the element is fully visible within the viewport
            const isVisible = (
                elementRect.top >= 0 &&
                elementRect.left >= 0 &&
                elementRect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
                elementRect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );

            return isVisible;
        },
        findDays(obj, created_date) {
            //to change
            var diff = Math.floor((Date.parse(created_date) - Date.parse(new Date())) / 86400000);

            if (obj['status'] == 'closed')
                diff = Math.floor((Date.parse(obj.updated_at) - Date.parse(new Date(created_date))) / 86400000);
            else
                diff = Math.floor((Date.parse(created_date) - Date.parse(new Date())) / 86400000);
            return Math.abs(diff)
        },
        async markAsComplete() {
            try {

               
                // const creds = await Server.getBusinessDataObs()

                await Promise.all(this.checked.map(async data => {
                    data['status']='completed';
                    await Server.completeTasks(data)
                    // if (creds.data.findIndex(d => d.business_object_name == 'tasks') > -1) {
                    //     const idx = creds.data.findIndex(d => d.business_object_name == 'tasks')
                    //     const syncApplication = creds.data[idx]['appeq_supported_application']
                    //     const id = data['account_id']
                    //     const taskId = data['Id']
                    //     if (syncApplication == 'salesforce') {
                    //         this.handleAddToSalesforce(creds.data[idx]['access_token'], creds.data[idx]['refresh_token'], creds.data[idx]['instance_url'], id, taskId)
                    //     } else {
                    //         this.handleAddToHubspot(creds.data[idx]['access_token'], creds.data[idx]['refresh_token'], id)

                    //     }
                    // }
                }))
                // this.tasks = this.tasks.filter(data => this.ch.findIndex(d => d.task_name === data.task_name) == -1)
                this.tasks=(await Server.getTasksByAccId(this.accountid)).data
                toast.success("Task marked as completed")

                this.checked = [];
                this.checkedList = {}
                AppEQAnalytics.actionCall("TaskCompleted", "Feed", {})

                // this.$forceUpdate();    

            } catch (error) {
                toast.error("Unexpected error occurred . Please try again or contact support@appeq.ai")

            }
        },
        async handleAddToSalesforce(accessToken, refreshToken, instanceUrl, accountId, taskId) {
            const clientId = '3MVG9wt4IL4O5wvLgb9I5m8DvdLV3B_WpFrNAM6bHFe9w6BqDaJTyoQ2pI4JsP8LqwJ98EyTODeYtVFvLX91S';
            const clientSecret = '148F2E93D23C83B291CDB7197CC31EDED6E11B5FED7D55F04603957A7937045B';
            const redirectUri = chrome.identity.getRedirectURL();
            const tokenUrl = 'https://login.salesforce.com/services/oauth2/token';
            const data = {
                grant_type: 'refresh_token',
                client_id: clientId,
                client_secret: clientSecret,
                refresh_token: refreshToken
            };

            fetch(tokenUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: new URLSearchParams(data)
            })
                .then(response => response.json())
                .then(tokenData => {
                    const accessToken = tokenData.access_token;
                    console.log('New Access Token:', accessToken);

                    // Now you can use the new access token to interact with Salesforce API

                    const taskEndpoint = instanceUrl + '/services/data/v53.0/sobjects/Task/' + taskId;

                    const taskData = {
                        Status: 'Completed',
                    };

                    fetch(taskEndpoint, {
                        method: 'PATCH',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(taskData)
                    })
                        .then(response => response.json())
                        .then(responseData => {
                            console.log('Task created successfully:', responseData);
                            // Handle success
                            // Additional code as needed
                        })
                        .catch(error => {
                            console.error('Error creating task:', error);
                            // Handle error
                            // Additional code as needed
                        });

                })
                .catch(error => {
                    console.error('Error obtaining new access token:', error);
                    vm.syncStatus = 'ERROR';
                });
        },
        editClicked(s) {

            this.type = this.tasks[s].task_type
            this.assignee = this.tasks[s].assignee
            this.name = this.tasks[s].task_name
            this.desc = this.tasks[s].desc
            this.oldTaskName = this.tasks[s].task_name
            this.duedate = this.tasks[s].duedate
            this.taskSelected = this.tasks[s]
            this.isEdit = true;
            this.openAddModal = true;
            AppEQAnalytics.actionCall("TaskEdited", "Feed", { "task_name": this.name, "task_desc": this.desc, "task_duedate": this.duedate, "task_asignee": this.assignee, "task_type": this.type })
        },
        removeSelected() {
            this.checked = []
            this.checkedList = {}
        },
        checkChange(task, index) {
            try {
                console.log(task)
                if (task.task_name && this.checkedList[task.task_name + index] == false) {
                    this.checked = this.checked.filter(t => t .task_name!= task.task_name)
                    this.checkedList[task.task_name + index] = false;
                } else {
                    this.checked.push(task);
                    this.checkedList[task.task_name + index] = true;
                    console.log(task)
                }

            } catch (error) {
                console.log(error)
            }
        },
        async saveTasks() {
            try {
                const idx = this.domInfo.findIndex(d => d._id == "tasks");
                if (idx == -1) {
                    this.domInfo.push({ "_id": "tasks", count: 1 })
                } else {
                    this.domInfo[idx] = { "_id": "tasks", count: this.domInfo[idx]["count"] + 1 }
                    this.dataOfOpen['tasks'] = false;
                }
                this.tasks=(await Server.getTasksByAccId(this.accountid)).data

                this.dataOfOpen['tasks'] = false;
                AppEQAnalytics.actionCall("TaskCreated", "Feed", { "task_name": this.name, "task_desc": this.desc, "task_duedate": this.duedate, "task_asignee": this.assignee, "task_type": this.type })

            } catch (error) {
                this.openAddModal = false;
                toast.error("Unexpected error occurred . Please try again or contact support@appeq.ai")

            }

        },
        //opening modal for tasks 
        async addOpenModal() {
            //based on this checking if to add or edit the task
            // this.taskSelected = null;
            this.taskSelected={
                account_domain:{
                    domain:this.account_domain,
                    id:this.accountid

                }
            }
            this.openAddModal = true;
            this.isEdit = false;
            this.users = (await Server.getAllUsers()).data;
            this.users = this.users.map(data => data.email_id)
            this.name = ''
            this.desc = ''
            this.duedate = ''
            this.assignee = ''


        },

        async handleUpandDownClick(d) {
            this.loadingData = true
            this.downArrow[d._id] = this.downArrow[d._id] ? 0 : 1;
            Object.keys(this.downArrow).map(data => {
                if (data != d._id) {
                    this.downArrow[data] = 0
                }
            })
            this.open = this.downArrow[d._id] ? true : false
            this.openedItem = d._id
            if (this.openedItem == "Account Pulse")
                this.dataOfOpen[d._id] = (await Server.fetchAllDataForOpen("pulse", 0, this.account_domain, this.accountid)).data
            else
                this.dataOfOpen[d._id] = (await Server.fetchAllDataForOpen(d._id, 0, this.account_domain, this.accountid)).data
            console.log(this.dataOfOpen)
            this.loadingData = false
        },
        getHumanDate: function (date) {
            return moment(date).diff(new Date(), 'days') + ' days';
        },
        handleC() {
        },

        handleAssignToMeClick() {
            console.log("testing tasks", this.tasks);
            if (!this.onlyMe) {
                if (this.tasks && this.tasks.length > 0) {
                    this.allTasks = this.tasks;
                    let email = localStorage.getItem('__email__id');
                    this.tasks = this.tasks.filter(data => data.assignee == email);
                    this.onlyMe = true;
                }
            } else {
                this.tasks = this.allTasks;
                this.onlyMe = false;
            }
            console.log("testing tasks", this.tasks);
        },

        async handleClick() {

            try {
                let box = document.querySelector('#newSummaryDiv');
                let widthh = box.offsetWidth - 10;
                console.log(widthh);

                var element = document.getElementById("highLights");
                console.log(element);

                element.setAttribute("style", `display:block;width:${widthh}px`);
            } catch (e) {
                console.log(e);
            }

            this.disableHightLighBtn = true;
            this.loadingHighLights = true;
            this.showHighLights = true;
            document.getElementById("highLights").innerHTML = '';
            this.i = 0;
            this.highLights = (await Server.fetchTicketHighLights(this.account_domain)).data
            this.loadingHighLights = false;
            this.lastUpdatedAtHighLights = moment(Date.now()).format("DD MMM,YYYY")
            this.disableHightLighBtn = false;


        },
        async typeWriter() {
            if (this.i < this.highLights.length) {
                document.getElementById("highLights").innerHTML += this.highLights.charAt(this.i);
                this.i++;
                const wait = (ms) => new Promise((res) => setTimeout(res, ms))
                await wait(20);
                return await this.typeWriter()
            }

        },
        getRandomColor() {
            let maxVal = 0xFFFFFF; // 16777215
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            randomNumber = randomNumber.toString(16);
            let randColor = randomNumber.padStart(6, 0);
            return `#${randColor.toUpperCase()}`
        },
        openCompose(index, data) {
            window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${data.appeq_emails}&tf=1`)
        },
        async openSummary(data) {
            try {
                if (this.account_domain || this.accountid) {
                    try {
                        let features = localStorage.getItem("features")
                        if (features && JSON.parse(features)) {
                            features = JSON.parse(features)
                            features.map(data => {
                                if (data && data.feature_name == "appeqSlack" && data.enabled == false) {
                                    this.slackEnabled = false
                                }
                                if (data && data.feature_name == "appeqNotes" && data.enabled == false) {
                                    this.notesEnabled = false
                                }
                                if (data && data.feature_name == "appeqGenie" && data.enabled == false) {
                                    this.genieEnabled = false
                                }
                                if (data && data.feature_name == "appeqTrendsAndDashboard" && data.enabled == true) {
                                    this.dashboard = true;
                                    this.metabaseNumber = data.dashboardNum
                                }
                            })
                        }
                        this.loadingSum = true;

                        if (data && data.code == 404) {
                            this.noDataFound = true;
                            this.loadingSum = false;

                            return
                        }
                        if (data && data.length == 0) {
                            this.noDataFound = true;
                            this.loadingSum = false;
                            return
                        }

                        const email = localStorage.getItem("__email__id");
                        console.log(data)

                        // check for members for which summary to be shown
                        if (data && data.data && data.data[0].data)
                            this.summaryData = data.data[0].data
                        else {
                            this.noDataFound = true;
                            this.loadingSum = false;
                            return
                        }

                        // this.summaryData = data.data.data[0].data
                        await Promise.all(this.summaryData.map(async res => {

                            if (res.appeq_summary_row) {
                                await Promise.all(res.appeq_summary_row.map(async d => {
                                    if (d.appeq_summary_column.appeq_summary_value) {
                                        let val = d.appeq_summary_column.appeq_summary_value
                                        let funcName = val.toString().substr(0, val.toString().indexOf("("));
                                        val = val.toString().substr(val.toString().indexOf("("));

                                        d.appeq_summary_column.appeq_summary_bObj = val.split(",")[0].replace("(", "")

                                        if (d.appeq_summary_column.appeq_summary_value.error) {
                                            d.appeq_summary_column.appeq_summary_value = 'No Data'
                                            d.appeq_summary_column.appeq_summary_type = ''
                                            return
                                        }
                                        d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.data
                                        if (d.appeq_summary_column.appeq_summary_type == 'graph' && !d.appeq_summary_column.appeq_summary_value.error && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                            this.lineData['labels'] = d.appeq_summary_column.appeq_summary_value.map(data => data.date)
                                            this.lineData['datasets'] = [{ 'data': d.appeq_summary_column.appeq_summary_value.map(data => data.tickets) }]
                                            const ticketsData = d.appeq_summary_column.appeq_summary_value.map(data => {
                                                return { time: data.date.toString(), value: data.tickets }
                                            })
                                            this.lineData['data'] = ticketsData
                                            return;
                                        }
                                        if (d.appeq_summary_column.appeq_summary_type == 'lastTicket' && d.appeq_summary_column.appeq_summary_value && d.appeq_summary_column.appeq_summary_value['created_at']) {
                                            let date1 = new Date()
                                            var x = new moment(d.appeq_summary_column.appeq_summary_value && d.appeq_summary_column.appeq_summary_value['created_at'] ? d.appeq_summary_column.appeq_summary_value['created_at'] : null)
                                            var y = new moment(date1)
                                            var duration = moment.duration(x.diff(y))
                                            let duration_days = Math.abs(Math.floor(duration.asDays()))
                                            d.appeq_summary_column.appeq_summary_prevalue = duration_days + ' days ago by '
                                            d.appeq_summary_column.query = { 'id': d.appeq_summary_column.appeq_summary_value['id'] }
                                            d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value['requester_email']
                                            d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value['requester_email'].toString().split("@")[0][0]
                                        }
                                        if (d.appeq_summary_column.appeq_summary_type == 'requesters' && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                            d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value
                                            d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.map(data => data.toString().split("@")[0].split(".").map(d => d[0]).join(""))
                                        }
                                        d.appeq_summary_column.appeq_summary_value = typeof d.appeq_summary_column.appeq_summary_value == "object" && d.appeq_summary_column.appeq_summary_type != "requesters" ? "_" : d.appeq_summary_column.appeq_summary_value;
                                        if (d.appeq_summary_column.appeq_summary_label == 'Health') {
                                            d.appeq_summary_column.appeq_summary_value = this.health;
                                            console.log("health of it is", this.health);
                                        }
                                    }

                                })
                                )
                            }
                        }))
                        this.loadingSum = false;
                        if (localStorage.getItem("CUSTOMERSDATA")) {
                            const sum = JSON.parse(localStorage.getItem("CUSTOMERSDATA"))
                            const newSum = { ...sum, [this.accountid]: this.summaryData }
                            localStorage.setItem("CUSTOMERSDATA", JSON.stringify(newSum))
                        } else {
                            const sum = {}
                            const newSum = { ...sum, [this.accountid]: this.summaryData }
                            localStorage.setItem("CUSTOMERSDATA", JSON.stringify(newSum))
                        }
                        await new Promise((res) => setTimeout(res, 200))
                        if (this.lineData['data']) {
                            const chart = createChart(document.getElementById("chartDiv" + this.accountid), {
                                width: 150, height: 120, grid: {
                                    vertLines: {
                                        color: "transparent",
                                        visible: false
                                    },
                                    horzLines: {
                                        color: "transparent"
                                    },

                                },
                                leftPriceScale: {
                                    visible: true,
                                },

                                timeScale: {
                                    barSpacing: 9,
                                },

                            });
                            chart.priceScale('left').applyOptions({
                                autoScale: true,
                                visible: true,
                            });
                            chart.priceScale('right').applyOptions({
                                autoScale: true,
                                visible: false
                            });
                            const lineSeries = chart.addLineSeries();
                            lineSeries.applyOptions({

                                lineType: 2, lineStyle: 0,
                                priceScale: {
                                    position: 'left',

                                },


                            })
                            lineSeries.setData(this.lineData['data']);
                            this.chartDi = chart
                            if (this.highLights && this.showHighBtn) {
                                this.disableHightLighBtn = true;
                                try {
                                    let box = document.querySelector('#newSummaryDiv');
                                    let widthh = box.offsetWidth - 10;
                                    console.log(widthh);

                                    var element = document.getElementById("highLights");
                                    console.log(element);

                                    element.setAttribute("style", `display:block;width:${widthh}px`);
                                } catch (e) {
                                    console.log(e);
                                }
                                // await this.typeWriter(this.highLights)
                                this.disableHightLighBtn = false;
                            }
                        }
                    }
                    catch (err) {
                        this.loadingSum = false;
                        console.log(err);

                    }
                }
            } catch (e) {
                console.log("error")
                console.log(e)
            }
        },
        getIcon(context_link) {
            try {
                if (context_link == null)
                    return 'https://logo.clearbit.com/' + 'zendesk.com';
                else if (context_link.toString().split("@").length > 1)
                    return 'https://logo.clearbit.com/' + context_link.toString().split("@")[1];

                else if (context_link.split(".").length > 1 ? "" : ".com")
                    return 'https://logo.clearbit.com/' + context_link.toString().split(" ").join("") + ".com";
                else
                    return 'https://logo.clearbit.com/' + context_link;

            } catch (e) {
                console.log(e);
            }
        },
        closeSumm() {
            try {
                if (this.chartDi)
                    this.chartDi.remove();
            } catch (e) {
                console.log(e);
            }

            if (document.getElementById("chartDiv"))
                document.getElementById("chartDiv").remove();
            feeds.sendActionToTop("__appeq_close_summary", "");

        },
        handleRedirect(query, val) {
            if (typeof query != 'object') {
                query = JSON.parse(query)
            }
            let final = { ...query, account_domain: this.account_domain, business_object_name: val }
            console.log(final)
            feeds.sendActionToTop("__appeq_open_ticket_iframe_summary", final);

        },
        openHealthRed() {
            try {
                let final = { ...{ health: "red" }, account_domain: this.account_domain, account_id: this.accountid }
                // feeds.sendActionToTop("__appeq_open_ticket_iframe_summary", final);
                feeds.sendActionToTop("__appeq_open_ticket_iframe_summary", final);
            } catch (error) {
                console.log(error)
            }
        },
        getFeedName(name) {
            if (name) {
                let companyName = name;

                try {
                    if (companyName.toString().split(".").length > 1) {
                        companyName = companyName.toString().split(".")[0];
                    } else if (companyName.toString().split("@").length > 1) {
                        companyName = companyName.toString().split("@")[0];
                    }
                } catch (e) {
                    console.log(e);
                }

                name = companyName;
            }

            return name;
        },
    },
}
</script>
<style scoped>
/* @import './summary.css'; */


img {
    cursor: pointer;
    height: 9px;
    margin-left: 8px;
    margin-top: 2px;

}

.requesterEmail {
    background-color: green;
    color: #fff;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    /* padding: 4px; */
    margin-left: 3px;
    width: 20px;
    display: block;
    text-align: center;
}

.close {
    object-fit: cover;
}

.knowMoreHighLights {
    font-size: 16px;
    font-weight: 600;
    margin-left: -4px;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.knowMoreHighLights img {
    height: 25px;
    width: 25px;
    margin-top: 0px;
    margin-left: 0px;
}

#highLights {
    font-size: 0.75vw;
    color: blue;
    font-weight: 500;
}

.loadingHighLights {
    height: 40px;
    width: 40px;
}

.loadingHigh {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.checkbox-round {
    width: 1em;
    height: 1em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color: blue;
}

.hover-content {
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}

.hover-trigger:hover+.hover-content {
    /* opacity: 1; */
    visibility: visible;
    cursor: pointer;
    transition-delay: 0s;
}

.summaryData {
    border: none !important;
}

.summary__data_obj {
    width: 30% !important;
}

.show_more {
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
    outline: none;
    height: 30px;
    border-radius: 20px;
    border: none;
    height: 30px;
    border-radius: 5px;
    background-color: #6f55ff;
    color: white;

}

.no_data_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

[role~=tooltip][data-microtip-position=bottom-left]:hover:after {
    transform: translate3d(calc(-50% + 16px), 0, 0);
    overflow: hidden;
    word-wrap: break-word;
    height: auto;
    white-space: normal;
}

.complete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    background-color: green;
    background-color: transparent;

}

.edit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    border: 1px solid green;
    background-color: transparent;

}

input:focus {
    outline: none;
    border: none;
}

textarea:focus {
    outline: none;
    border: none;
}
</style>
<style scoped>
.show_more {
    text-align: center;
    cursor: pointer;
    outline: none;
    height: 30px;
    border-radius: 20px;
    border: none;
    height: 30px;
    border-radius: 5px;
    background-color: #6f55ff;
    color: white;

}

.show_more.configure {
    border: 1px solid blue;
}

.show_more:hover {
    background-color: blue;
    color: white;

}

.QueryStrings {
    justify-content: space-between;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 50%;
    margin-left: 7px;
    color: #838383;
    font-size: 14px;
    display: flex;
    /* border-right: 1px solid black; */
    align-items: center;
    overflow: auto;
    padding: 13px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;


}

.QueryStrings::-webkit-scrollbar {
    width: 1px;
}

.list_conversation {
    padding: 10px 10px;
    border-bottom: 1px solid #f1f3ff;
    cursor: pointer;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #090b10;
    margin: 0px 10px;
}

.list_conversation:hover {
    background: rgb(241 243 255 / 20%);
}

.list_conversation:last-child {
    border-bottom: none;
}

.list_active_conv {
    border-radius: 0.75vw;
    background-color: rgb(255 255 255);
    box-shadow: 0px 0px 5px #c8c8c8;
    position: absolute;
    right: 28px;
    margin-top: 25px;
    z-index: 1;
}

.header_div {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 10px 20px;
    align-items: center;
    justify-content: space-between;
}

.heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #090B10;
}


.close_icon {
    display: flex;
    cursor: pointer;
}

.close_icon:hover {
    background-color: #f1f3ff;
    border-radius: 25px;
}

.list_active_conv select {
    margin: 0 20px 20px 20px;
}

.state_label {
    text-transform: capitalize;
    font-style: italic;
    color: #888;
}

.tag_label {
    text-transform: capitalize;
    font-style: italic;
    color: #888;
}

.query_str_value {
    color: black;
    border: 0.6px solid #0000ff85;
    padding: 2px 18px;
    font-size: 0.68vw;
    display: flex;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    height: 22px;
    white-space: nowrap;
    text-transform: capitalize;
}

.query_str_value:hover {
    background: #0000ff85 !important;
    color: white;
}

.query_str_value span {
    cursor: pointer;
}

.query_str_value.selected {
    background: #0000ff85;
    color: white;
}

.title_div {
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 20px;
    color: #090B10;
}

.meta_data_div {
    font-style: normal;
    font-weight: 300;
    font-size: 0.75vw;
    line-height: 15px;
    color: #090B10;
    padding-top: 7px;
}

span.md_label {
    text-transform: capitalize;
    font-style: italic;
    margin-right: 3px;
    color: #888;
}

span.md_data {
    margin-right: 6px;
}

.main_container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 10px;
    position: relative;
    z-index: 9999;
}

.ticket_list {
    background: #fff;
    position: relative;
    display: flex;
    margin: auto;
    width: 97%;
    padding: 12px 11px;
    border-radius: 12px;
    justify-content: space-around;
    column-gap: 10px;
    align-items: center;
    margin-top: 12px;
    box-shadow: rgb(46 80 255 / 19%) 0px 8px 24px;
}


.tag_data {
    color: #4f60ff;
    font-style: italic;
}


.appeq_start_conv {
    cursor: pointer;
    border: 1px solid #FFF;
    display: inline-flex;
    border-radius: 7px;
}

.appeq_start_conv:hover {
    border: 1px solid #EEE;
}

.appeq_active_conv {
    cursor: pointer;
    color: blue;
}

.appeq_active_conv:hover {
    color: #4f60ff;
}

.col1.biz_obj_logo {
    width: 70px;
    display: flex;
}

.col1.biz_obj_logo>img {
    width: 35px;
    margin: 0 10px;
    height: 35px !important;
}

.col2.biz_obj_details {
    width: 60%;
}

.col3.biz_obj_tags {
    width: 10%;
}

.col4.biz_obj_state {
    width: 10%;
}

.col5.biz_obj_appeq {
    width: 13%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo_style {
    width: 25px;
    display: flex;
    position: absolute;
    background: #f1f3ff;
    border-radius: 25px;
    font-size: medium;
    height: 25px;
    align-items: center;
    justify-content: center;
    left: 23px;

}

.tickets_info {
    /* position: absolute;
    width: auto;
    background: #fff;
    border-radius: 6px;
    margin-top: 300px;
    left: 105px;
    border: 1px;
    box-shadow: 0 5px 10px #888;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #090b10;
    z-index: 1; */
    /* position: absolute;
    width: auto;
    height: auto;
    top: 17px;
    left: 0px;
    height: auto;
    width: auto;
    border-radius: 12px;
    padding: 15px;
    z-index: 111111111;
    background: transparent; */

    position: absolute;
    height: auto;
    width: auto;
    left: 85px;
    z-index: 11111187871;
    max-width: 750px;
    top: 20px;
}

.red {
    border: 1px solid red;
}

.green {
    border: 1px solid green;
}

.orange {
    border: 1px solid orange;
}

.ticket_info_headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    /* padding: 10px; */
    align-items: center;
    /* height: 50px; */
    border-bottom: 0.5px solid rgba(196, 196, 196, 0.74);
    z-index: 9999999;
    padding-bottom: 4px;
    height: 30px;
}

.ticket_info_headers .logo {
    width: 38px;
}

.ticket_info_headers .rec {
    font-size: 20px;
    color: #4f60ff;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 134%;
    padding: 10px;
}

.ticket_info_headers .conv_icon {
    width: 20px;
    display: none;

}

.ticket_info_headers .conv {
    font-size: 12px;
    color: #4e60ff;
}

.ticket_info_headers .close {
    cursor: pointer;
}

.line1 {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -10px;
}

.ticket_info_body {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.ticket_info_body .left {
    display: flex;
    flex-direction: column;
}

.ts_subheading {
    font-weight: 700;
    font-size: 15px;
    color: #4f60ff;
}

.red {
    border: 1px solid red;
}

.green {
    border: 1px solid green;
}

.orange {
    border: 1px solid orange;
}

.healthSpan {
    color: white;
    padding: 2px;
    text-transform: uppercase !important;
    border-radius: 3px;
    margin-left: 4px;
    cursor: pointer;
}

.healthSpan.red {
    background: red;
}

.healthSpan.green {
    background: green;
}

.healthSpan.orange {
    background: orange;
}

.conv_blk {
    display: flex;
    column-gap: 10px;
    align-items: center;
    flex: 1;
}

span.background {
    padding: 2px 5px;
    border-radius: 3px;
}

.ticket_info_body .right {
    display: flex;
    flex-direction: column;
}

.summary_notes {
    padding: 0 10px;
}

.summary_notes p {
    padding: 10px;
    margin: 0 0 10px 0;
    background-color: rgba(217, 217, 217, 0.35);
}

.small_avatar_img {
    width: 25px !important;
    height: 25px !important;
    margin-left: 4px;
    display: flex;
    border-radius: 50%;
    cursor: pointer;
    background-size: cover;
    background-position: 50%;
    background-color: #fff;
}

.small_avatar_img img {
    border-radius: 50%;
}

.memeber_list {
    display: flex;
}

.members_list {
    display: flex;
}

.popup_feature {
    width: -webkit-fill-available;
    top: 0;
    background: #f0f8ff;
    z-index: 11;
    display: flex;
    position: absolute;
    height: -webkit-fill-available;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.close_icon_feature {
    position: absolute;
    top: 0;
    right: 0;
}

.show_ticket_details {
    cursor: pointer;
    color: #4f60ff;
    margin-left: 10px;
    text-decoration: underline;
}

.details_ticket {
    background: #f1f3ff;
    position: absolute;
    /* flex-direction: column; */
    width: 70%;
    height: auto;
    z-index: 1;
    /* display: flex; */
    margin-top: 300px;
    border-radius: 10px;
    box-shadow: 0 0 1px rgb(0 0 0 / 11%), 0 0 2px rgb(0 0 0 / 11%), 0 0 4px rgb(0 0 0 / 11%), 0 0 8px rgb(0 0 0 / 11%), 0 0 16px rgb(0 0 0 / 11%);
    padding: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #090b10;
}

.close_ticket_details {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.comment_block {
    padding: 20px 20px;
    max-height: 399px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.redirect_feeds {
    width: 17px;
    margin-left: 10px;
    margin-top: 3px;
    cursor: pointer;
    position: absolute;
    opacity: .7;
}

.redirect_feeds_detail {
    width: 15px;
    left: 2vh;
    cursor: pointer;
    position: absolute;
    opacity: .7;
    top: 15px;
}

.fploader {
    display: flex;
    width: 90vh;
    height: 100%;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.account_url {
    cursor: pointer;
    color: blue;
}

.invoice_url {
    cursor: pointer;
    color: blue;
}

.myfploader {
    display: flex;
    width: 90vh;
    height: 100%;
    z-index: 9999;
    background-color: rgb(9, 11, 16, 0.2);
    align-items: center;
    justify-content: center;
    width: 100vw;
    align-content: center;
    flex-direction: column;
    position: fixed;
    left: 0px;
    top: 0px;
    color: white;
}

.query_str_label {
    white-space: nowrap;
    margin-right: 10px;
}

.delete {
    filter: invert(17%) sepia(99%) saturate(7275%) hue-rotate(1deg) brightness(102%) contrast(114%);
    height: 20px;
    margin-right: -10px;
    width: 21px;
    cursor: pointer;
}

.tag {
    border: none;
    color: black;
    padding: 4px 8px;
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 10px;
    display: flex;
    text-transform: capitalize;
    align-items: center;
    font-size: 0.68vw;
    opacity: 0.6;

}

.tag img {
    opacity: 0.8;
    height: 14px;
    width: 14px;
    margin-right: 10px;
}

.summary__data_obj {
    display: flex;
    align-items: center;
    width: 250px;
    margin-top: 10px;
    flex-wrap: wrap;
}

.loading {
    background-image: linear-gradient(90deg, rgb(216, 215, 215), rgb(253, 243, 243), rgb(160, 158, 158), grey);
    background-size: 1000%;
    background-position: right;
    border-radius: 20px;
    width: 250px;
    margin-bottom: 10px;
    height: 5px;
    animation: sweep 3s ease-in-out infinite alternate;
    box-shadow: 0 0 6px 0 #a3a3a331;
}

@keyframes sweep {
    0% {
        background-position: right;
    }

    100% {
        background-position: left;
    }
}

.ticket__red {
    border: 1px solid red;
}

.summaryHealthHeading {
    padding-left: 30px;
    font-size: 13px;
}

.summaryHealthHeadingValue {
    padding: 1px 12px;
    border-radius: 4px;
    color: white;
    margin-left: 5px;
    font-size: 12px;
}

.summaryHealthHeadingValue.red {
    background-color: red;
}

.summaryHealthHeadingValue.orange {
    background-color: orange;
}

.summaryHealthHeadingValue.green {
    background-color: green;
}

.No_Data {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25vh;
    flex-wrap: wrap;
    flex-direction: column;
}

.No_Data img {
    width: 300px;
}

.innerCircle {
    border-radius: 50%;
    padding: 4px;
    padding: 2px 5px;
    text-align: center;
    margin-right: 5px;
    font-family: 600;
    border: 1px solid blue;
}

.removeScroll::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.config__body {}

.comepletedBtn {
    margin-left: 50%;
    margin-right: 10px;
    cursor: pointer;
    background: blue;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 16px;
    font-size: 0.68vw;
}

.active_image_task {
    filter: invert(21%) sepia(82%) saturate(3067%) hue-rotate(96deg) brightness(92%) contrast(102%);
}

.pulseIcon {
    height: 22px;
    border-radius: 5px;
    width: 80px;
    margin-left: 4px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    background-color: #4e5fff;
    color: white;
}

.outer_bg_modal {
    position: fixed;
    height: 100%;
    top: 0px;
    width: 100%;
    z-index: 1111;
    background-color: rgba(0, 0, 0, .3);
    left: 0px;
}

[role~=tooltip][data-microtip-position=bottom-left]:after {
    transform: translate3d(calc(-100% + 16px), -10px, 0);
    top: 100%;
    width: max-content;
}
.moreOptions{
    position: absolute;
    top: 23px;
    right: 0px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.1), 0 0 1px 0 rgba(0,0,0,.1);
    padding: 10px;
    z-index: 9999999999;
    width: 220px;
    height: 76px;
    border-radius: 8px;

}
.item{
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    padding: 5px;

}
.tasks::-webkit-scrollbar{
    display: none;
}
.tasks::-webkit-scrollbar-thumb{
    background-color: none;
    width: 0px;
}
.hoverUp{
    transition: all 0.5s;
    height: 24px;
}
.hoverUp:hover{
    transform: scale(1.5) rotate(3deg);

}

.why_link:hover {
    text-decoration: underline;
    cursor: pointer;
}</style>

<style>

</style>./components/API/Server