<template>
    <div style="padding: 20px;height: 100%;width: 100%;overflow: auto;" v-if="!loading" class="">
        <!-- <div class="" v-for="data of columns">
            {{ data }}
        </div> -->
        <table v-if="objectList.length!=0">
            <th v-for="col of columns">
                {{ col }}
            </th>
            <tr v-for="data of objectList">
                <td v-if="data[col]" v-for="col of columns">
                    {{ data[col].toString().substr(0,80) }}{{ data[col] && data[col].length>80?"...":'' }}
                    </td>
            </tr>
        </table>
        <div style="display: flex;align-items: center;justify-content: center;" class="" v-else>
            <img src="../assets/nodata.svg" style="height: 200px;"/>
        </div>
    </div>
    <div style="display: flex;align-items: center;justify-content: center" class="" v-else>
        <img src="../assets/loadingRipple.svg" />
    </div>
</template>

<script>
import Server from '../components/API/Server';
export default{
    props:["widget"],
    data(){
        return{
            objectList:[],
            columns:[],
            loading:false
        }
    },
    async mounted(){
        try {
        this.loading=true
        const data=await Server.getObjectList(this.widget)
        if(data && data.data && data.data.length>0){
            this.objectList=data.data
            this.columns=Object.keys(data.data[0])
        }
        this.loading=false
    } catch (error) {
        this.loading=false
            
        }

    }
}
</script>

<style lang="scss" scoped>
    th{
        text-align: center;
        padding: 10px;
    }
    td{
        padding: 10px
    }
    tr{
        margin: 10px;
    }
</style>