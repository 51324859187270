<template>
    <div>
      <div v-if="!loading && current==0" style="display: flex; flex-direction: column;">
        <h6 style="margin-top: 1.5rem !important;">Enter Username</h6>
        <div style="border: 1px solid #dce2ea; display: flex; height: 40px;align-items: center;">
          <input type="text" style="border: none;font-size: 12px;font-weight: 300; width: 100%;" placeholder="Enter username" v-model="username"/>
        </div>
        <h6 style="margin-top: 1.5rem !important;">Enter Password</h6>
        <div style="border: 1px solid #dce2ea; display: flex; height: 40px;align-items: center;">
          <input type="text"style="border: none;font-size: 12px;font-weight: 300; width: 100%;" placeholder="Enter password" v-model="password"/>
        </div>
        <h6 style="margin-top: 1.5rem !important;">Enter the base url</h6>
        <div style="border: 1px solid #dce2ea; display: flex; height: 40px;align-items: center;">
          <input type="text" style="border: none;font-size: 12px;font-weight: 300; width: 100%;" placeholder="Enter base url" v-model="baseUrl"/>
        </div>
        <button class="connect_button" @click="authenticateERP">Authenticate</button>
      </div>
      <div v-else-if="!loading && current==2">
        <h5 class="successText">
          You have successfully configured Logic ERP
        </h5>
        <img src="../assets/success.svg" />
    </div>
      <div v-else style="display: flex; align-items: center; justify-content: center;">
        <img src="../icons/loadingripple.svg" style="height: 50px;" alt="">
      </div>
    </div>
  </template>
  
  <script>
  import Multiselect from "vue-multiselect";
  import Server from "./API/Server.js";
  
  export default {
    data(){
      return {
        username: "",
        password: "",
        baseUrl: '',
        current: 0,
        bObj: "",
        jiraOptions: [],
        projectKey: null,
        isSuccess: false,
        loading: false
      }
    },
    props: {
      selectedBobj: []
    },
    components: {
      Multiselect
    },
    methods: {
      async connectJira() {
        try {
          this.loading = true;
          if(!this.projectKey) {
            this.loading = false;
            return;
          }
          let resp = await Server.connectJira(this.email, this.apiKey, this.baseUrl, this.projectKey.key);
          console.log(resp);
          this.current = 2;
          this.$toasted.success("Jira credentials saved");
          this.loading = false;
          setTimeout(() => {
          window.location.reload()
        }, 2000);
        } catch (error) {
          this.$toasted.error("Access not given by Jira")
          this.loading = false;
        }
      },
      async authenticateERP() {
        try {
          this.loading = true;
          if(this.username === '' || this.password === '') {
            this.loading = false;
            return;
          }
          let res = await Server.authenticateERP(this.username, this.password, this.baseUrl, this.bObj);
          console.log(res);
          if(res.data.code !== 200) {
            this.$toasted.error('Some error occurred');
            this.loading = false;
            return;
          }
          this.current = 2;
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      }
    },
    async mounted() {
      setTimeout(() => {
        console.log('business object name', this.selectedBobj, 'business object');
      this.bObj = this.selectedBobj[0];
      }, 1000);
    }
  }
  </script>
<style scoped>
.connect_button{
    border: 1px solid blue; border-radius: 6px; background: white; color: black; height: 40px; margin: 10px 0px;
}
.connect_button:hover{
    background: rgb(88, 88, 255); color: white;
}
</style>