import { reactive } from "vue";
import Server from "../../components/API/Server";
import mixpanel from "../../analytics/mixpanel";

export const store = reactive({
  businessNames: [],
  businessKeys: {},
  thirdPartyHigh: false,
  staticTemplates: [
    {
      data: [
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "Total tickets",
                appeq_summary_value: "sumOfTickets(ticket,sumOfTickets)",
                appeq_summary_type: "feedsLink",
                query: "{}",
              },
            },
            {
              appeq_summary_column: {
                appeq_summary_label: "High or urgent",
                appeq_summary_value:
                  "highOrUrgenttickets(ticket,highOrUrgenttickets)",
                appeq_summary_type: "feedsLink",
                query: '{"$or":[{"priority":"high"},{"priority":"urgent"}]}',
              },
            },
          ],
        },
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "Open ticket",
                appeq_summary_value:
                  "sumOfOpenTickets(ticket,sumOfOpenTickets)",
                appeq_summary_type: "feedsLink",
                query: "{'status':'open'}",
              },
            },
            {
              appeq_summary_column: {
                appeq_summary_label: "Closed ticket",
                appeq_summary_value: "closedTickets(ticket,closedTickets)",
                appeq_summary_type: "feedsLink",
                query: {
                  status: "closed",
                },
              },
            },
          ],
        },
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "Last Ticket",
                appeq_summary_value: "lastTicket(ticket,lastTicket)",
                appeq_summary_type: "lastTicket",
              },
            },
            {
              appeq_summary_column: {
                appeq_summary_label: "CSAT Score",
                appeq_summary_value: "totalcsatscores(ticket,totalcsatscores)",
                appeq_summary_type: "feedsLink",
              },
            },
          ],
        },
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "Requesters",
                appeq_summary_value:
                  "recentrequesters(ticket,recentrequesters)",
                appeq_summary_type: "requesters",
              },
            },

            {
              appeq_summary_column: {
                appeq_summary_label: "90-Day Ticket Trend",
                appeq_summary_value: "ticketTrends(ticket,ticketTrends)",
                appeq_summary_type: "graph",
              },
            },
          ],
        },
      ],
      summary_name: "Support Summary",
      summary_template_name: "Support Summary",
    },
    {
      data: [
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "Total tickets",
                appeq_summary_value: "sumOfTickets(ticket,sumOfTickets)",
                appeq_summary_type: "feedsLink",
                query: "{}",
              },
            },
            {
              appeq_summary_column: {
                appeq_summary_label: "Website",
                appeq_summary_value:
                  "highOrUrgenttickets(ticket,highOrUrgenttickets)",
                appeq_summary_type: "feedsLink",
                query: '{"$or":[{"priority":"high"},{"priority":"urgent"}]}',
              },
            }
          ],
        },
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "Open ticket",
                appeq_summary_value:
                  "sumOfOpenTickets(ticket,sumOfOpenTickets)",
                appeq_summary_type: "feedsLink",
                query: {
                  status: "open",
                },
              },
            },
            {
              appeq_summary_column: {
                appeq_summary_label: "ARR",
                appeq_summary_value: "arrwon(Opportunities,arrwon)",
                appeq_summary_type: "feedsLink",
                query: {},
              },
            },
          ],
        },
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "Requesters",
                appeq_summary_value:
                  "recentrequesters(ticket,recentrequesters)",
                appeq_summary_type: "requesters",
              },
            },
            {
              appeq_summary_column: {
                appeq_summary_label: "Renewal In (days)",
                appeq_summary_value: "totalcsatscores(ticket,totalcsatscores)",
                appeq_summary_type: "feedsLink",
              },
            },
          ],
        },
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "90-Day Ticket Trend",
                appeq_summary_value: "ticketTrends(ticket,ticketTrends)",
                appeq_summary_type: "graph",
              },
            },

            {
              appeq_summary_column: {
                appeq_summary_label: "Open Opportunities",
                appeq_summary_value: "openopps(Opportunities,openopps)",
                appeq_summary_type: "feedsLink",
                query: {
                  hs_is_closed: "false",
                },
              },
            },
          ],
        },
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "Opportunity Amount",
                appeq_summary_value:
                  "opportunityAmount(Opportunities,opportunityAmount)",
                appeq_summary_type: "feedsLink",
                query: '{"hs_is_closed":"false"}',
              },
            }
          ]
        }
      ],
      summary_name: " Support + Opportunities Summary",
      summary_template_name: " Support + Opportunities Summary",
    },
    {
      data: [
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "Total tickets",
                appeq_summary_value: "sumOfTickets(ticket,sumOfTickets)",
                appeq_summary_type: "feedsLink",
                query: "{}",
              },
            },
            {
              appeq_summary_column: {
                appeq_summary_label: "Pending Invoices",
                appeq_summary_value:
                  "pendingCustomerInvoice(Customer Invoice,outstaningamount)",
                appeq_summary_type: "feedsLink",
                query: '{"Status":"Pending"}',
              },
            },
          ],
        },
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "Open ticket",
                appeq_summary_value:
                  "sumOfOpenTickets(ticket,sumOfOpenTickets)",
                appeq_summary_type: "feedsLink",
                query: '{"status":"open"}',
              },
            },
            {
              appeq_summary_column: {
                appeq_summary_label: "Outstanding Amount",
                appeq_summary_value:
                  "outstandingamount(Customer Invoice,outstandingamount)",
                appeq_summary_type: "feedsLink",
                query: '{"Status":"Pending"}',
              },
            },
          ],
        },
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "Requesters",
                appeq_summary_value:
                  "recentrequesters(ticket,recentrequesters)",
                appeq_summary_type: "requesters",
              },
            },

            {
              appeq_summary_column: {
                appeq_summary_label: "DSO",
                appeq_summary_value: "totalcsatscores(ticket,totalcsatscores)",
                appeq_summary_type: "feedsLink",
              },
            },
          ],
        },
        {
          appeq_summary_row: [
            {
              appeq_summary_column: {
                appeq_summary_label: "90-Day Ticket Trend",
                appeq_summary_value: "ticketTrends(ticket,ticketTrends)",
                appeq_summary_type: "graph",
              },
            },
          ],
        },
      ],
      summary_name: "Support + Invoices Summary",
      summary_template_name: "Support + Invoices Summary",
    },
  ],

  staticTemplatesDetails: [
    {
      data: [
        {
          label: "Total Tickets",
          detail: "Total number of tickets raised by the given customer.",
        },
        {
          label: "Open Tickets",
          detail: "Total number of Open tickets raised by the given customer.",
        },
        {
          label: "Last Ticket",
          detail: "Last ticket details raised by the given customer.",
        },
        {
          label: "Requesters",
          detail: " Last 5 Requesters who have raised the tickets for this.",
        },

        {
          label: "High or Urgent",
          detail:
            "Number of tickets which are high or urgent for the given customer",
        },

        {
          label: "Closed Tickets",
          detail: "Number of closed tickets for the given customer.",
        },
        {
          label: "CSAT Score",
          detail: "Aggregated CSAT Score for the given customer",
        },
        {
          label: "New Ticket Trend (Graph)",
          detail:
            "Last 90-Days trend of new tickets raised by the given customer",
        },
      ],
      summary_name: "Support Summary",
      summary_template_name: "Support Summary",
    },
    {
      data: [
        {
          label: "Total Tickets",
          detail: "Total number of tickets raised by the given customer",
        },
        {
          label: "Open Tickets",
          detail: "Total number of Open tickets raised by the given customer",
        },
        {
          label: "Requesters",
          detail:
            "Last 5 Requesters who have raised the tickets for this customer",
        },

        {
          label: "90-Day Ticket Trend",
          detail:
            "Last 90-Days trend of new tickets raised by the given customer",
        },

        {
          label: "Website",
          detail: "List the website or domain for the given customer",
        },
        {
          label: "ARR",
          detail:
            "Total Amount from the won opportunities from the given customer",
        },
        {
          label: "Renewal In (days)",
          detail: "Number of Days to contract Renewal",
        },
        {
          label: "Open Opportunities",
          detail:
            "Number of Opportunities in the pipeline for the given customer",
        },
        {
          label: "Opportunity Amount",
          detail:
            "Total Amount of Opportunities in the pipeline for the given customer",
        },
      ],
      summary_name: " Support + Opportunities Summary",
      summary_template_name: " Support + Opportunities Summary",
    },
    {
      data: [
        {
          label: "Total Tickets",
          detail: "Total number of tickets raised by the given customer",
        },
        {
          label: "Open Tickets",
          detail: "Total number of Open tickets raised by the given customer",
        },
        {
          label: "Requesters",
          detail:
            "Last 5 Requesters who have raised the tickets for this customer",
        },

        {
          label: "90-Day Ticket Trend",
          detail:
            "Last 90-Days trend of new tickets raised by the given customer",
        },

        {
          label: "Pending Invoices",
          detail: "List of pending invoices the given customer",
        },
        {
          label: "Outstanding Amount",
          detail: "Sum of the amount pending for the given customer",
        },
        {
          label: "DSO",
          detail:
            "Days Sales Outstanding = (Accounts Receivable/Net Credit Sales)x Number of days",
        },
      ],
      summary_name: "Support + Invoices Summary",
      summary_template_name: "Support + Invoices Summary",
    },
  ],

  dummarySummary: {
    appeq_summary_row: [
      {
        appeq_summary_column: {
          appeq_summary_label: "Total tickets",
          appeq_summary_value: "sumOfTickets(ticket,sumOfTickets)",
          appeq_summary_type: "feedsLink",
          query: "{}",
        },
      },
    ],
  },

  isModalOpen: false,
  onLoad: false,
  selectedIndexX: -1,
  isSuccess: false,
  showIsAddRow: [],
  selectedIndexY: -1,
  rowsinX: [],
  currentMember: [],
  segments: [],
  currentSummaryName: "",
  currentProfileName: "",
  currentHighLight: false,
  summaryList: [],
  profileList: [],
  finalObj: null,
  maxCols: -1,
  maxRows: -1,
  isEditModal: false,
  maxColandRow: [-1, -1],
  rowsinCols: [],
  deleteRow(sum, i, ind, highLight) {
    if(i==0 && sum.data[i].appeq_summary_row.length==1){
      return;
    }
    if(sum.data[i].appeq_summary_row.length==2){
      sum.data[i].appeq_summary_row.splice(ind, 1);
    }else{
      sum.data.splice(i,1);
    }
    // this.saveSummary(sum, highLight, true);
  },
  findMax(sum, i, ind) {
    let max = -1;
    let max2 = -1;
    sum = JSON.parse(JSON.stringify(sum));
    console.log(sum.data);
    if (sum.data && Array.isArray(sum.data))
      sum.data.map((d, idx) => {
        max =
          d.appeq_summary_row.length > max ? d.appeq_summary_row.length : max;
        max2 = d.appeq_summary_row.length >= max ? idx : max2;
      });
      console.log(i,ind);
      console.log(sum.data[sum.data.length-1].appeq_summary_row);
      console.log(sum.data[sum.data.length-1].appeq_summary_row.length);
      max = sum.data[sum.data.length-1].appeq_summary_row.length;
      max2 = sum.data.length-1;
    if (ind == max - 1 && i == max2) {
      return true;
    }
    return false;
  },

  async findMaxColsandRows() {
    //getting summary data from api if not present show default
    const data = await Server.getSummaryJson();
    console.log(JSON.stringify(data));
    if (data && data.data && data.data.length > 0) {
      // this.currentSummaryName = localStorage.getItem('currentSummaryName');
      if (this.currentSummaryName != "") {
        data.data.map((d) => {
          if (d.summary_name == this.currentSummaryName) {
            console.log("here in " + d.summary_name);
            this.finalObj = d.data;
            this.currentHighLight = d.ai;
            console.log("here in " + d.ai);
            this.currentProfileName = d.profile_name;
          }
        });
      }
      console.log("herer in ", this.currentHighLight);
    }

    let arr = [-1, -1];
    //finding the max number of collumns and rows to show delete icon in the last
    this.finalObj.map((d, index) => {
      this.maxCols =
        d.appeq_summary_row && d.appeq_summary_row.length > this.maxCols
          ? d.appeq_summary_row.length
          : this.maxCols;
      arr[0] =
        d.appeq_summary_row && d.appeq_summary_row.length >= this.maxCols
          ? index
          : arr[0];

      //changed from -2 to -1 for delete icon issue
      arr[1] = this.maxCols - 1;
    });
    this.maxColandRow = arr;
    this.rowsinX = this.finalObj.map((d) =>
      d.appeq_summary_row ? d.appeq_summary_row.length : 0
    );
    this.rowsinCols = new Array(this.maxCols).fill(0, 0, this.maxCols);
    this.finalObj.map((d) => {
      if (d.appeq_summary_row)
        Array(d.appeq_summary_row.length)
          .fill(1, 0, d.appeq_summary_row.length)
          .map((v, i) => {
            this.rowsinCols[i]++;
          });
    });
  },
  openModal(x, y) {
    this.selectedIndexX = x;
    this.selectedIndexY = y;
    this.isModalOpen = !this.isModalOpen;
  },
  addRow(sum, a, v, highLight, index) {
    let id = sum.data.length-1;
    console.log(id);
    if(id==5 && sum.data[id].appeq_summary_row.length==2){
      alert("Maximum numbers of data added. Can not add more items");
      return;
    }
    if(sum.data[id].appeq_summary_row.length==1){
      sum.data[id].appeq_summary_row.push({ appeq_summary_column: {} });
    }else{
      sum.data.push({ appeq_summary_row: [] });
      sum.data[id+1].appeq_summary_row.push({ appeq_summary_column: {} });
    }
    console.log(sum.data);
  },
  async deleteSummary(sum) {
    if (sum.profile_name && sum.profile_name == "Unassign") {
      sum.profile_name = null;
    }

    const res = await Server.delSumm(sum.summary_name, sum.profile_name);
    console.log(res);

    if (res && res.data && res.data.code == 200) {
      return [true, res.data.message];
    } else if (res && res.data && res.data.code == 500) {
      return [false, res.data.msg];
    } else return [false, "something went wrong please try after sometime"];
  },
  async saveSummary(sum, highLights, trends, edited, list, nameChange) {
    console.log(trends);
    this.summaryList = list;
    if (!edited) {
      let index = 0;
      this.summaryList.map((data) => {
        if (data.summary_name == sum.summary_name) {
          index++;
        }
      });
      if (index) sum.summary_name = sum.summary_name + index;
      console.log(this.segments);
    }
    // if(this.segments.filter(data=>{data=}))
    if (this.summaryList && this.summaryList.length == 0) {
      const data = await Server.saveSummary(
        sum.data,
        highLights,
        sum.summary_name,
        ["Customer Success"],
        store.thirdPartyHigh,
       trends.length==0 ? [] : trends
      );
      return data;
    } else {
      sum.profile_name =
        sum.profile_name && sum.profile_name != "Unassign"
          ? sum.profile_name
          : [];

      if (sum.profile_name && sum.profile_name == "Unassign") {
        profile = [];
      }
      console.log(trends);

      const data = await Server.saveSummary(
        sum.data,
        highLights,
        sum.summary_name,
        sum.profile_name,
        store.thirdPartyHigh,
        trends.length==0 ? [] :trends
        );
      const resData = await Server.getSummaryJson();
      console.log(resData.code, "guarav");
      this.summaryList = [];
      if (resData && resData.code == 200 && resData.data) {
        await resData.data.map((d) => {
          this.summaryList.push(d);
        });
      }

      return data;
    }
  },

  addColumn() {
    // if (this.finalObj.some(d => d.appeq_summary_row.length < this.maxCols)) {
    //     alert("Cannot add more columns first fill the current row");
    //     return;
    // }
    this.finalObj[0].appeq_summary_row.push({
      appeq_summary_column: { appeq_summary_label: "" },
    });
    this.rowsinCols.push(1);
    this.maxCols++;
  },
  closeModal() {
    this.selectedIndexX = -1;
    this.isSuccess = false;
    this.selectedIndexY = -1;
    this.isModalOpen = false;
  },
  onSave(summ, label, obj, key, func, result) {
    if (obj == "ticket") {
      func = key;
    }
    this.finalObj[this.selectedIndexX].appeq_summary_row[
      this.selectedIndexY
    ].appeq_summary_column["appeq_summary_value"] = `${func}(${obj},${key})`;

    this.finalObj[this.selectedIndexX].appeq_summary_row[
      this.selectedIndexY
    ].appeq_summary_column["appeq_summary_label"] = label;

    if (result && result.type) {
      this.finalObj[this.selectedIndexX].appeq_summary_row[
        this.selectedIndexY
      ].appeq_summary_column["appeq_summary_type"] = result.type;
      if (result.query) {
        this.finalObj[this.selectedIndexX].appeq_summary_row[
          this.selectedIndexY
        ].appeq_summary_column["query"] = result.query;
      }
    }

    console.log(this.finalObj);

    this.isModalOpen = false;
  },
});
