<template>
    <div class="card mycard">
        <h2 style="text-align: center;">Configure Trending</h2>
        <div style="display: flex;align-items: center;">
            <input v-model="trendInfo.enable" type="checkbox" /><span style="margin-left: 10px;">
                Trending Fields for {{ bObject }}
            </span>
        </div>
        <div v-if="trendInfo.enable" style="margin-top: 30px;">
            <span>Configure Frequency</span>
            <Multiselect v-model="trendInfo.frequncy" :options="['Daily', 'Weekly']" placeholder="Configure Frequency" />
        </div>
        <div v-if="trendInfo.enable" style="margin-top: 30px;">
            <span>Configure Keys</span>

            <Multiselect :multiple="true" v-model="trendInfo.keys" :options="options" placeholder="Select Keys" />
        </div>

        <!-- <EditModalforsummary v-if="bObject" :bObject="bObject" @onsave="handlesave($event)" :forTrends="true"/> -->
        <button style="margin-top: 40px;" @click="handlesave">Save</button>
    </div>
</template>
<script>
import EditModalforsummary from '../components/EditModalforsummary.vue';
import Modal from '../components/Modal.vue';
import Multiselect from 'vue-multiselect';
import Server from '../components/API/Server';
export default {
    props: {
        bObject: {
            type: 'String',
            default: "ticket"
        },
        trendObject:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {
            openModal: false,
            options: [],
            trendInfo: {
                enable: false,
                frequncy: '',
                keys: []
            }
        };
    },
    async mounted() {
        console.log(this.trendObject)
        this.trendInfo=this.trendObject
        await this.handleKeySelected()
    },
    methods: {
        handleModalClick() {
            this.openModal = !this.openModal
        },
        async handleKeySelected() {
            console.log(this.bObject)
            if (this.bObject == "ticket" || this.bObject == "Customer Invoice" || this.bObject == "Opportunities" || this.bObject == "Net Promoter Score") {
                this.bkeys = [];
                try {
                    this.resData = await Server.getFunction(this.bObject);
                    if (this.resData) {
                        this.resData.data.map(d => {
                            this.options.push(d.function_name);
                        })
                    }
                } catch (e) {
                    console.log(e);
                }
                return;
            }

            this.options = (await Server.getConfiguratorKeys(this.bObject)).data.keys;
        },
        async handlesave(event) {
            try {
                console.log((event))
                const data = await Server.saveTrend({ trends: this.trendInfo, business_object_name: this.bObject })
                console.log(data)
                if(data.code==200){
                    this.$toasted.success("Successfully saved the trends data")
                }
                window.location.reload()
            } catch (error) {
                console.log(error)
                this.$toasted.error("Something went wrong")
            }
        }
    },
    components: { Modal, EditModalforsummary, Multiselect }
}
</script>
<style lang="scss" scoped>
.mycard {
    padding: 10px;
}
</style>