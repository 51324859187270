<template lang="">
    <div>
    <div class="image">
      <img height="50px" :src="imageUrl.logoURL" />
    </div>
    <div v-if="!isSuccess">
      <h6 class="mt-3" v-if="imageUrl.productName == 'Zendesk Support Suite'">
        How do I find my subdomain
        <i @mouseover="handleIClick(0)" @mouseleave="handleIClick(0)" class="tim-icons icon-alert-circle-exc"
          v-bind:class="{ iconIsActive: isActive[0] }"></i>
        <div v-if=(this.isActive[0]) class="support">For Example,<br /><br />if you sign in at
          "acme.zendesk.com",<br /><br /> Enter “acme”.
          Do not enter the full “acme.zendesk.com”.
        </div>
      </h6>
      <h5 v-if="imageUrl.productName == 'Zendesk Support Suite'">
        Enter your {{ imageUrl.productName }}
        {{ imageUrl.productName == "Google Sheet" ? "Link" : "Subdomian" }}
      </h5>
      <div v-if="imageUrl.productName == 'Zendesk Support Suite'" class="zendeskInputBox">
        <div class="domainbox">https://</div>
        <input v-model="zendeskDomain" />
        <div class="domainbox">.zendesk.com</div>
      </div>
      <h5 class="mt-3" v-if="imageUrl.productName == 'Zendesk Support Suite'">
        Enter your zendesk email
        <i @mouseover="handleIClick(1)" @mouseleave="handleIClick(1)" class="tim-icons icon-alert-circle-exc"
          v-bind:class="{ iconIsActive: isActive[1] }"></i>
        <div v-if=(this.isActive[1]) class="support">Your Zendesk Login Email</div>
      </h5>
      <div v-if="imageUrl.productName == 'Zendesk Support Suite'" class="zendeskInputBox">
        <input v-model="zendeskEmail" />
      </div>
      <h5 class="mt-3" v-if="imageUrl.productName == 'Zendesk Support Suite'">
        Enter your zendesk token
        <i @mouseover="handleIClick(2)" class="tim-icons icon-alert-circle-exc"
          v-bind:class="{ iconIsActive: isActive[2] }"></i>
        <div @mouseleave="handleIClick(2)" v-if=(this.isActive[2]) class="support2">How do I find &nbsp; <a
            href="https://appeq.freshdesk.com/support/solutions/articles/84000374622-zendesk-integration-with-appeq"
            target="_blank"> My Zendesk API Key</a>
          <ul>
            <li>Sign in to Zendesk and click the icon next to your profile avatar in the upper right</li>
            <li>Select Admin Center in the dropdown menu</li>
            <li>Expand the Apps and integrations dropdown in the left menu</li>
            <li>Click Zendesk API under the expanded section</li>
            <li>Make sure that token access is enabled, and click Add API token</li>
            <li>Copy and add the API key below, and then click Save</li>
          </ul>
        </div>
      </h5>
   
      <div v-if="imageUrl.productName == 'Zendesk Support Suite'" class="zendeskInputBox">
        <input v-model="zendeskToken" />
      </div>
      <h5 class="mt-3" v-if="imageUrl.productName == 'Zendesk Support Suite'">
        How much data you want us to fetch
        <i @mouseover="handleIClick(3)" class="tim-icons icon-alert-circle-exc"
          v-bind:class="{ iconIsActive: isActive[3] }"></i>
        <div @mouseleave="handleIClick(3)" v-if=(this.isActive[3]) class="support">Older data ususally takes more time to sync. 
        </div>
      </h5>
      <div style="font-size:12px" v-if="imageUrl.productName == 'Zendesk Support Suite'" class="zendeskInputBox noborder">
        <input type="radio" v-model="time" name="radAnswer" value="1m" placeholder="6m"/><div style="white-space:nowrap">1 month</div>
        <input type="radio" v-model="time" name="radAnswer" value="3m"/><div style="white-space:nowrap">3 months</div>
        <input type="radio" v-model="time" name="radAnswer" value="6m"/><div style="white-space:nowrap">6 months</div>


      </div>
    </div>
    <div class="success mt-4" v-if="isSuccess">
      Your zendesk creds are successfully verified
    </div>
    <button @click="submitButtonclick()" 
      v-if="imageUrl.productName == 'Zendesk Support Suite' && !isSuccess && !isLoading "
      :class="{disabledButton:check()}"      
      >
      Submit
    </button>
    <div v-if="isLoading" class="loading">
      <img src="../assets/infinity_blue.svg" />
    </div>
    <span v-if="!isSuccess" class="error">{{ error }}</span>
  </div>
</template>
<script>
import Server from "./API/Server.js";
import LocalUtils from "./Utils/LocalUtils";
import mixpanel from "../analytics/mixpanel";
import moment from "moment"
export default {
  name: "DescPage",
  methods: {
    handleIClick(key) {
      console.log(key);
      this.$set(this.isActive, key, !this.isActive[key])
      console.log(this.isActive);
    },
    check(){
      if(this.zendeskEmail=="" || this.zendeskDomain=="" || this.zendeskToken==""){
          return true;
      }
      return false;
    },
    async submitButtonclick() {

      if(this.zendeskEmail=="" || this.zendeskDomain=="" || this.zendeskToken==""){
          return;
      }
      var dateFrom = moment(moment.now())
        .subtract(2, "months")
        .valueOf();
      console.log(dateFrom)
      this.isLoading = true
      const data = await Server.zendeskSubmit({
        adminEmail: LocalUtils.getEmail(),
        email: this.zendeskEmail,
        domain: this.zendeskDomain,
        token: this.zendeskToken,
        lastUpdatedAt: dateFrom,
        appeq_supported_application: "zendesk"
      });
      // await Server.onResync(LocalUtils.getEmail(), "tickets")
      const finalObj = [
        {
          "appeq_summary_row": [
            {
              "appeq_summary_column": {
                "appeq_summary_label": "Total tickets",
                "appeq_summary_value": "sumOfTickets(ticket,sumOfTickets)",
                "appeq_summary_type": "feedsLink",
                "query": "{}"
              }
            },
            {
              "appeq_summary_column": {
                "appeq_summary_label": "High or urgent",
                "appeq_summary_value": "highOrUrgenttickets(ticket,highOrUrgenttickets)",
                "appeq_summary_type": "feedsLink",
                "query": "{\"$or\":[{\"priority\":\"high\"},{\"priority\":\"urgent\"}]}"
              }
            }
          ]
        },
        {
          "appeq_summary_row": [
            {
              "appeq_summary_column": {
                "appeq_summary_label": "Open ticket",
                "appeq_summary_value": "sumOfOpenTickets(ticket,sumOfOpenTickets)",
                "appeq_summary_type": "feedsLink"
              }
            },
            {
              "appeq_summary_column": {
                "appeq_summary_label": "Closed ticket",
                "appeq_summary_value": "closedTickets(ticket,closedTickets)",
                "appeq_summary_type": "feedsLink",
                "query": {
                  "status": "closed"
                }
              }
            }
          ]
        },
        {
          "appeq_summary_row": [
            {
              "appeq_summary_column": {
                "appeq_summary_label": "Last Ticket",
                "appeq_summary_value": "lastTicket(ticket,lastTicket)",
                "appeq_summary_type": "lastTicket"

              }
            },
            {
              "appeq_summary_column": {
                "appeq_summary_label": "CSAT Score",
                "appeq_summary_value": "totalcsatscores(ticket,totalcsatscores)",
                "appeq_summary_type": "feedsLink"
              }
            },
          ]
        },
        {
          "appeq_summary_row": [
          {
              "appeq_summary_column": {
                "appeq_summary_label": "Requesters",
                "appeq_summary_value": "recentrequesters(ticket,recentrequesters)",
                "appeq_summary_type": "requesters"
              }
            },
           
            {
              "appeq_summary_column": {
                "appeq_summary_label": "Graph",
                "appeq_summary_value": "ticketTrends(ticket,ticketTrends)",
                "appeq_summary_type": "graph"
              }
            }
          ]
        }
      ];
      await Server.saveSummary(finalObj)
      //Server.callSceduler("ticket","");

      try {
        mixpanel.businessObjectAdded(localStorage.getItem("Business_obj_name"));
      } catch (e) {
        console.log(e);
      }

      this.isLoading = false
      if (!data.error) {
        this.isSuccess = true;
        this.$emit("changeStep")
      this.$toasted.success("Please wait we will notify you once the data has been resynced via mail .")
      
      }
      else {this.error = "Your credentials are invalid";
      this.$toasted.error("Invalid Credentials")
    
    }
    },
  },
  async mounted() {
    //  this.isAdmin= localStorage.getItem("IS_ADMIN");
    console.log(this.$router.currentRoute.path == '/integrations');
    try {
      const data = await Server.zendeskCredsFetch();
      if (data && data.data) {
        this.zendeskEmail = data.data.zendesk_email;
        this.zendeskDomain = data.data.zendesk_domain;
        this.zendeskToken = data.data.zendesk_token;
        this.$emit("changeStep")

      }
      // if(!this.isAdmin){
      //   // this.zendeskToken="X".repeat(this.zendeskToken.length-4)+this.zendeskToken.substring(this.zendeskToken.length-4)
      //   // this.zendeskEmail="X".repeat(this.zendeskEmail.length-4)+this.zendeskEmail.substring(this.zendeskEmail.length-4)
      //   // this.zendeskDomain="X".repeat(this.zendeskDomain.length-2)+this.zendeskDomain.substring(this.zendeskDomain.length-2)

      // }
    }
    catch (error) {
      this.$toasted.error(error);
    }
  },
  data() {
    return {
      time: "3m",
      isActive: [false, false, false, false],
      isLoading: false,
      zendeskEmail: "",
      zendeskDomain: "",
      zendeskToken: "",
      isAdmin: false,
      isSuccess: false,
      error: "",
    };
  },
  props: {
    imageUrl: {
      type: Object,
      default: {},
    },
  },
};
</script>
<style lang="scss" scoped>
.zendeskInputBox {
  &.noborder {
    border: none;
  }

  border: 1px solid rgb(220, 226, 234);
  display: flex;
  height: 40px;
  align-items: center;

  input {
    width: 100%;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
}

.domainbox {
  height: 40px;
  display: flex;
  align-items: center;
  background: rgb(244, 246, 250);
}

button {
  flex-shrink: 0;
  background: #6f55ff;
  color: white;
  border: 0px;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 56px;
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  margin-top: 20px;
}

.error {
  color: red !important;
  font-size: 10px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px !important;

  img {
    object-fit: contain;
  }
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

i:hover {
  scale: 1.2;
  transition: 0.2s ease-in-out;
}

.iconIsActive {
  scale: 2;
  transition: 0.2s ease-in-out;
  border-radius: 50%;

}

.icon-alert-circle-exc {
  position: absolute;
  margin-top: 5px;
  right: 30px;
  transition: 0.2s ease-in-out;
  color: blue;
}

.support {
  left: 350px;
  height: 100px;
  width: 320px;
  background: whitesmoke;
  position: absolute;
  margin-top: 15px;
  padding: 20px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: none;
  font-size: 12px;
  font-weight: 550;
  border-radius: 10px;

}

.support2 {
  left: 350px;
  height: 310px;
  width: 320px;
  z-index: 2;
  background: whitesmoke;
  position: absolute;
  margin-left: -30px;
  margin-top: -20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.disabledButton{
  background: gray !important;
}

</style>
