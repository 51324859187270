<template>
    <div class="step2">
        <button @mouseenter="skipTool=true" @mouseleave="skipTool=false" @click="changeStep(+3)" class="prev">Skip <i class="tim-icons icon-double-right"></i></button>
        <div class="skipTour" v-if="skipTool">
            <div class="arrow-up"></div>
            <p class="skipTourText">Click to End the Tour</p>
        </div>
        <div v-if="!showIframe" class="firstButto  ">
            <span class="span-text">
                Download our <span class="click-span" @click="onClickStart()">chrome extension </span> Now<br/>
            </span>
            <span class="fade-text">
                You will be redirected to Chrome Web Store, Click, Click  ‘Add to Chrome’
            </span>
           
            <br />
            <br />
            <br />
            <button @click="onClickStart()">Download</button>
        </div>
        <!-- <div v-if="showIframe" class="iframe">
            <iframe referrerpolicy="no-referrer"
                src="https://chrome.google.com/webstore/detail/appeq-customer-360-platfo/likcmamamahkjeneloakgacokbgdkfea?embedded=true" />
        </div> -->
    </div>
</template>

<script>
import mixpanel from '../../../analytics/mixpanel';
export default {
    data() {
        return {
            showIframe: false,
            skipTool:false,
            
        }
    },
    props: {
        currentStep: {
            type: Number,
            default: 1
        }
    },

    methods: {
        changeStep(index) {
            // currentStep = currentStep + 1;
            if(index==+3){
                try{
                    mixpanel.tourSkipped("add_chrome_extension");
                }catch(e){
                    console.log(e);
                }
            }

            this.$emit("changeStep", index)
        },
        onClickStart() {
            // this.showIframe = true
            
            window.open('https://chrome.google.com/webstore/detail/appeq-customer-360-platfo/likcmamamahkjeneloakgacokbgdkfea?embedded=true', 'winname');
            changeStep(+1);
        }
    },
    

}
</script>

<style src="./Step2.scss" lang="scss" scoped>

</style>