<template>
  <div>
    <div v-if="showImage" class="image">
      <img height="50px" :src="imageUrl.logoURL" />
    </div>
    <div v-if="!isSuccess">
      <h6 class="mt-3">
        <span id="fresh_desk_subdomain_div" style="color:black">How do I find my subdomain</span>
      </h6>
      <h5>
        <span>
          Enter your {{ imageUrl.productName }}
          {{ imageUrl.productName == "Google Sheet" ? "Link" : "Subdomian" }}
        </span>
      </h5>
      <div class="freshdeskInputBox">
        <div class="domainbox">https://</div>
        <input v-model="freshdeskDomain" />
        <div class="domainbox">.freshdesk.com</div>
      </div>
      <!-- <h5 class="mt-3">
        Enter your Freshdesk email
        <i @mouseover="handleIClick(1)" @mouseleave="handleIClick(1)" class="tim-icons icon-alert-circle-exc"
          v-bind:class="{ iconIsActive: isActive[1] }"></i>
        <div v-if=(this.isActive[1]) class="support">Your freshdesk Login Email</div>
      </h5> -->
      <!-- <div class="freshdeskInputBox">
        <input v-model="freshdeskEmail" />
      </div> -->
      <h5 class="mt-3">
        <span id="fresh_desk_token_div" style="color:black">Enter your Freshdesk Key</span>
      </h5>
      <div class="freshdeskInputBox">
        <input v-model="freshdeskToken" />
      </div>
    </div>
    <div v-if="isSuccess">
      <h5 class="successText">Your freshdesk creds are successfully verified</h5>
      <img src="../assets/success.svg" />
    </div>
    <button @click="submitButtonclick()" v-if="!isSuccess && !isLoading">
      Submit
    </button>
    <div v-if="isLoading" class="loading">
      <img src="../assets/infinity_blue.svg" />
    </div>
    <span v-if="!isSuccess" class="error">{{ error }}</span>
  </div>
</template>

<script>
import Server from "./API/Server.js";
import LocalUtils from "./Utils/LocalUtils";
import mixpanel from "../analytics/mixpanel";

export default {
  name: "DescPage",
  methods: {
    handleIClick(key) {
      console.log(key);
      this.$set(this.isActive, key, !this.isActive[key])
      console.log(this.isActive);
    },
    async submitButtonclick() {

      if(this.freshdeskEmail=='' || this.freshdeskDomain=='' || this.freshdeskToken==''){
        this.$toasted.error("Please Fill All Fields First");
        return
      }

      this.isLoading = true
      try {
        const res = await Server.freshdeskCredsFetch(this.freshdeskEmail, this.freshdeskDomain, this.freshdeskToken);
        console.log(res);

        if (res && res.code == 200) {

          const data = await Server.freshdeskSubmit({
            email: this.freshdeskEmail,
            domain: this.freshdeskDomain,
            api_key: this.freshdeskToken,
            business_object_name: "ticket"
          });
          // await Server.onResync(LocalUtils.getEmail(), "ticket")

          if (!data.error) {
            this.isLoading = false;
            this.isSuccess = true;
            try {
              mixpanel.businessObjectAdded(localStorage.getItem("Business_obj_name"));
            } catch (e) {
              console.log(e);
            }
            this.$toasted.success("We will notify you once the data has been resynced")
            if (this.showImage)
              this.$emit("changeStep")
            else{
              setTimeout(() => {
                window.location.reload()
              }, 2000);
            }
          }
          else this.error = "Your credentials are invalid";
        }
        else if (res.error) {
          this.error = res.msg;
        }
        else {
          this.error = "something went wrong please try after sometime";
        }

      } catch (e) {
        console.log(e);
      }
      // await Server.onResync(LocalUtils.getEmail(), "Freshdesk")
      this.isLoading = false;
    },
  },
  async mounted() {
    //  this.isAdmin= localStorage.getItem("IS_ADMIN");
    var allCred=JSON.parse(localStorage.getItem("ALL_CREDS"));
    this.freshdeskEmail = LocalUtils.getEmail();

    let presentCred=false;
    allCred.map(data=>{
      if(data.appeq_supported_application=="freshdesk"){
        presentCred=true;
      }
    })
    if(presentCred && this.showImage){
      this.$emit("changeStep");
      return;
    }
    console.log(this.$router.currentRoute.path == '/integrations');
    // try {
    //   const data = await Server.freshdeskCredsFetch();
    //   if (data && data.data) {
    //     this.freshdeskEmail = data.data.freshdesk_email;
    //     this.freshdeskDomain = data.data.freshdesk_domain;
    //     this.freshdeskToken = data.data.freshdesk_token;
    //   }
    //   // if(!this.isAdmin){
    //   //   // this.freshdeskToken="X".repeat(this.freshdeskToken.length-4)+this.freshdeskToken.substring(this.freshdeskToken.length-4)
    //   //   // this.freshdeskEmail="X".repeat(this.freshdeskEmail.length-4)+this.freshdeskEmail.substring(this.freshdeskEmail.length-4)
    //   //   // this.freshdeskDomain="X".repeat(this.freshdeskDomain.length-2)+this.freshdeskDomain.substring(this.freshdeskDomain.length-2)

    //   // }
    // }
    // catch (error) {
    //   this.$toasted.error(error);
    // }
  },
  data() {
    return {
      isActive: [false, false, false],
      isLoading: false,
      freshdeskEmail: "",
      freshdeskDomain: "",
      freshdeskToken: "",
      isAdmin: false,
      isSuccess: false,
      error: "",
    };
  },
  props: {
    showImage: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>

span{
  color: black !important;
}
.freshdeskInputBox {
  border: 1px solid rgb(220, 226, 234);
  display: flex;
  height: 40px;
  align-items: center;

  input {
    width: 100%;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
}

.domainbox {
  height: 40px;
  display: flex;
  align-items: center;
  background: rgb(244, 246, 250);
}

button {
  flex-shrink: 0;
  background: #6f55ff;
  color: white;
  border: 0px;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 56px;
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  margin-top: 20px;
}

.error {
  color: red !important;
  font-size: 10px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px !important;

  img {
    object-fit: contain;
  }
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

i:hover {
  scale: 1.2;
  transition: 0.2s ease-in-out;
}

.iconIsActive {
  scale: 2;
  transition: 0.2s ease-in-out;
  border-radius: 50%;

}

.icon-alert-circle-exc {
  position: absolute;
  margin-top: 5px;
  right: 30px;
  transition: 0.2s ease-in-out;
  color: blue;
}

.support {
  left: 350px;
  height: 100px;
  width: 320px;
  background: whitesmoke;
  position: absolute;
  margin-top: 15px;
  padding: 20px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: none;
  font-size: 12px;
  font-weight: 550;
  border-radius: 10px;

}

.support2 {
  left: 350px;
  height: 310px;
  width: 320px;
  background: whitesmoke;
  position: absolute;
  margin-left: -30px;
  margin-top: -20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
</style>
