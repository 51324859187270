<template>
    <div class="step3">
        <button @mouseenter="skipTool=true" @mouseleave="skipTool=false" @click="changeStep(+2)" class="prev">Skip <i class="tim-icons icon-double-right"></i></button>
        <div class="skipTour" v-if="skipTool">
            <div class="arrow-up"></div>
            <p class="skipTourText">Click to End the Tour</p>
        </div>
        <h2>Invite your team members</h2>
        <div>Please enter the email ids of your co workers we will share the link to them</div>
        <input placeholder="Enter email seperated by ," />
    </div>
</template>

<script>
export default {
    data() {
        return {
            
            skipTool:false,
            
        }
    },
    props: {
        currentStep: {
            type: Number,
            default: 1
        }
    },
    methods: {
        changeStep(index) {
            // currentStep = currentStep + 1;
            this.$emit("changeStep", index)
        },
    }

}
</script>

<style src="./Step3.scss" lang="scss" scoped>

</style>