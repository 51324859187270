<template lang="">
    <div>
        <div class="plgModal"   v-if="stepNumber==0">
        <h3>Welcome to AppEQ</h3>
        <p>Here is a short video explaning what we do</p>
        <div style="position: relative; padding-bottom: 62.5%; height: 0;width:50vw">
            <iframe src="https://www.loom.com/embed/3acf87889d5e4385b73ff05645da08f9" frameborder="0" mozallowfullscreen allowfullscreen webkitallowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <button class="mt-5 mb-2" @click="nextStep()">Continue</button>
        </div>
        <div class="plgModal"   v-if="stepNumber==1">
        <h3>Welcome to AppEQ</h3>
        <h4 style="text-align:center">Please select the platform you use for ticketing</h4>
        <div class="appsList">
            <div class="tile">
                <img @click="nextStep()" src="https://s2.googleusercontent.com/s2/favicons?domain_url=https://zendesk.com&sz=48"/>
                <h6>Zendesk</h6>
            </div>
        </div>
        </div>
        <div class="plgModal"   v-if="stepNumber==2">
        <h3>Welcome to AppEQ</h3>
            <Zendesc v-if="this.zendesk" @changeStep="nextStep()" :imageUrl="{productName:'Zendesk Support Suite',logoURL:'https://s2.googleusercontent.com/s2/favicons?domain_url=https://zendesk.com&sz=48'}"></Zendesc>
            <FreshDeskDescPage :showImage="true" v-if="!this.zendesk" @changeStep="nextStep()" :imageUrl="{productName:'Freshdesk',logoURL:'https://s2.googleusercontent.com/s2/favicons?domain_url=https://freshdesk.com&sz=48'}"></FreshDeskDescPage>
            
        </div>
        <div class="plgModal"   v-if="stepNumber==3">
        <h3>Welcome to AppEQ</h3>
        <p style="text-align:center">Horray You have successfully configured zendesk</p>
        <img  src="../assets/success.svg" />
        <button @click="installExtension()">Install Extension</button>
    </div>
        <div class="plgModal"   v-if="stepNumber==4">
        <h3>Welcome to AppEQ</h3>
        <p  v-if="loading" style="text-align:center">Please wait while we are loading all your data </p>
        <p  v-if="loading" style="text-align:center">Note : It may take a few minutes to load</p>
        <div class="loading" v-if="loading"><img src="../assets/Dual Ring-1s-200px (1).svg"/></div>
    
    </div>
    <div class="plgModal"   v-if="stepNumber==5">
        <h3>Welcome to AppEQ</h3>
        <p   style="text-align:center">Hurray Your data has been loaded </p>
        <img  src="../assets/success.svg" />
        <button @click="openFeeds()">Click to open feeds</button>
    </div>
    </div>
</template>
<script>
import Zendesc from './Zendesc.vue';
import Server from '../components/API/Server'
import mixpanel from '../analytics/mixpanel';
import FreshDeskDescPage from "../components/FreshDeskDescPage.vue"
export default {
    components: {
        Zendesc,
        FreshDeskDescPage
    },
    data() {
        return {
            stepNumber: 0,
            loading: false,
            email:'',
            zendesk:true,
            appName:'',
        }
    },
    async mounted() {
        const number=localStorage.getItem("stepNumberPlg");
        this.appName = localStorage.getItem("ticketPlgAppName");
        if(localStorage.getItem("ticketPlgAppName")=="freshdesk"){
            this.zendesk=false;
        }
        if(number){
            this.stepNumber=number;
            if(number==5){
                this.$emit("close")
            }
            if (number == 4) {
                this.loading = true;
                let pollTimer = window.setInterval(async () => {
                    var data;
                    if(this.zendesk){
                     data = await Server.credentailsQuery("ticket", "zendesk")
                    }else{
                     data = await Server.credentailsQuery("ticket", "freshdesk")
                    }
                    console.log(data)
                    if (data.data == "inactive") {
                        this.loading = false;
                        this.stepNumber++;
                        localStorage.setItem("stepNumberPlg",this.stepNumber)

                        window.clearInterval(pollTimer)
                    }
                }, 10000)
            }
        }
        try{
            let res = localStorage.getItem('ADMIN_CREDS');
            if (res) {
                this.email = res['__email__id'];
            }
        }catch(e){
            console.log(e);
        }
        
        // this.$refs.videoRef.play();
    }, methods: {
        async openFeeds(){
            try{
                mixpanel.openedTiccketPlgFeeds(this.email,this.appName);
            }catch(e){
                console.log(e);
            }
            window.postMessage({ action: "__appeq_open_ticket_iframe",val:true }, "*");
            this.$emit("close")
            // window.location.href=window.location.href.split("?")[0]
        },
        async nextStep() {
            this.stepNumber++;
            if(this.stepNumber==1){
                this.stepNumber=2
            }

            try{
                switch(this.stepNumber) {
                    case 2:
                        mixpanel.openedTicketPlgPage(this.email,'Ticket Plg Zendesk Page',this.appName);
                        break;
                    case 3:
                        mixpanel.openedTicketPlgPage(this.email,'Ticket Plg Zendesk Success Page',this.appName);
                        break;
                    case 4:
                        mixpanel.openedTicketPlgPage(this.email,'Ticket Plg Zendesk Configuration Loading Page',this.appName);
                        break;
                    case 5:
                        mixpanel.openedTicketPlgPage(this.email,'Ticket Plg Open Feeds Page',this.appName);
                    break;

                    default:
                }
            }catch(e){
                console.log(e);
            }

            localStorage.setItem("stepNumberPlg",this.stepNumber)
            if (this.stepNumber == 4) {
                this.loading = true;
                let pollTimer = window.setInterval(async () => {

                    const data = await Server.credentailsQuery("ticket", "zendesk")
                    console.log(data)
                    if (data.data == "inactive") {
                        this.loading = false;
                        this.stepNumber++;
                        localStorage.setItem("stepNumberPlg",this.stepNumber)

                        window.clearInterval(pollTimer)
                    }
                }, 10000)
            }
        },
        installExtension() {
            try{
                mixpanel.openedTiccketPlgInstallExtension(this.email,this.appName);
            }catch(e){
                console.log(e);
            }

            var win = window.open(process.env.VUE_APP_INSTALL_URL.toString(), '_blank', 'toolbar=1,scrollbars=1,location=1,statusbar=0,menubar=1,resizable=1,width=1200,height=1200');
            let pollTimer = window.setInterval(() => {
                try {
                    if (win.opener == null) {
                        this.nextStep()
                        window.location.reload()
                        window.clearInterval(pollTimer)
                    }
                } catch (error) {
                    console.log(error)
                }
            }, 100)
        }
    }

}
</script>
<style  lang="scss" scoped>
.plgModal {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    h3{
        font-weight: 550;
    }
    h4{
        margin-top: 30px;
    }
    button{
        border-radius: 10px;
    }
}

#video-container {
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
}

.appsList {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
}

.tile {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;

    &:hover {
        img {
            scale: 1.1;
        }
    }
}
button {
  flex-shrink: 0;
  background: #6f55ff;
  color: white;
  border: 0px;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 56px;
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  margin-top: 20px;
}
</style>