<template>
    <div class="tabs__global">
        <div :style="{'border-bottom': (selectedTab==tab) ? '2px solid blue':'','font-weight': (selectedTab==tab) ? '600':''}" class="tab__global" v-for="(tab, idx) in tabs" @click="handleSelectTab(tab, idx)">
            {{ tab }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        tabs: [],
        notIdx: false
    },
    data(){
        return {
            selectedTab: ''
        }
    },
    mounted(){
        console.log(this.tabs);
        this.selectedTab = this.tabs[0];
    },
    methods: {
        handleSelectTab(tab, idx){
            if(this.notIdx){
                this.$emit('selectTab', tab);
            }else{
                this.$emit('selectTab', idx);
            }
            this.selectedTab = tab;
        }
    }
}
</script>
<style scoped>
.tab__global{
    font-size: 0.75vw; margin-right: 1vw; cursor: pointer; padding: 4px;
}
.tabs__global{
    width: 60%; border-bottom: 1px solid rgb(239, 239, 239) !important; display: flex; align-items: center; margin-left: 0.3rem;
}
</style>