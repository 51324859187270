<template>
    <div class="">
        <div class="" v-if="loading" style="height: 68vh; overflow: scroll; display: flex; justify-content: center; align-items: center;">
            <img src="../assets/loadingRipple.svg" style="height: 7vw;"/>
        </div>
        <div class="" v-else-if="!loading && notificationSettings.length==0" style="height: 68vh; overflow: scroll; display: flex; justify-content: center; align-items: center;">
            <img src="../icons/noData.svg" style="height: 12vw;"/>
        </div>
        <GlobalTable v-else :columns="columns" :showAllTooltips="true" :tableData="notificationSettings" @handleAction="handleAction($event)"/>
        <div class="" v-if="openNudgeConfigration">
            <NudgesConfiguration :appeq_org_id="appeq_org_id" @delete="handleDelete" @save="handleSaveNudge($event)" :isEditingIdx="editingIndex"
                :nudge="selectedNudge" @close="openNudgeConfigration = false" />
        </div>
    </div>
</template>

<script>

import GlobalTable from "../layout/dashboard/GlobalTable.vue";
import Server from "./API/Server"
import NudgesConfiguration from "./NudgesConfiguration.vue"
export default {
    data() {
        return {
            notificationSettings: [],
            selectedNudge: {},
            settings: {},
            loading: false,
            appeq_org_id:"",
            editingIndex: -1,
            openNudgeConfigration: false,
            columns: [
                { title: "Name", key: "name", width: "19%" }, { title: "Desciption", key: "customMsg", width: "25%" }, { title: "Trigger", key: "trigger", width: "10%" }, { title: "Trigger Condition", key: "triggerCondition", width: "35%" }, { title: "Tasks", key: "tasksLen", width: "10%" },  { title: "Action", key: "action", width: "14%" }
            ]
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        async handleSaveNudge(event) {
            console.log(event)
            this.settings['customNudges'][this.editingIndex] = event
            await Server.saveNotificationSettings(this.settings);
            this.openNudgeConfigration = false;
            this.initData()
            this.$toasted.success("Nudge Edited Successfully")
        },
        handleAction(action){
            switch (action[0]) {
                case 'openConfigure':
                    this.handleClickConfigure(action[1], action[2]);
                    break;
            
                default:
                    break;
            }
        },
        handleClickConfigure(data, idx) {
            this.selectedNudge = data
            this.editingIndex = idx
            this.openNudgeConfigration = true;
        },
        async handleDelete() {
            console.log(this.isEditingIdx)
            this.settings['customNudges'].splice(this.editingIndex, 1)
            console.log(this.settings)
            await Server.saveNotificationSettings(this.settings);
            this.$toasted.success("Successfully deleted the nudge")
            this.openNudgeConfigration = false
            window.location.reload()
        },
        async initData() {
            try {
                this.loading = true;
                const data = (await Server.getNotificationSettings()).data;
                console.log(data);
                if (data && data.length > 0 && data[0].notificationSettings) {
                    this.appeq_org_id=data[0].appeq_org_id

                    this.settings = data[0].notificationSettings;
                    this.notificationSettings = data[0].notificationSettings.customNudges.map(d => {
                        console.log(d)
                        d.tasksLen = d.tasks ? d.tasks.length : 0;
                        d.triggerCondition = ''
                        if (d.trigger != 'manual') {

                            d.query.map((query, idx) => {
                                d.triggerCondition += `If ${query.keys} ${query.operator.label} ${query.value} `
                                if (idx != d.query.length - 1) {
                                    d.triggerCondition += ' and '
                                }
                            })
                        }

                        return d;
                    });
                    for(let i=0;i<this.notificationSettings.length;i++){
                        this.notificationSettings[i]['action'] = [{'img':'ConfigureIcon.png', 'action': 'openConfigure', 'hover': 'Nudge Configure'}];
                    }
                }
            }
            catch (error) {
            }
            finally {
                this.loading = false;
            }
        }
    },
    components: { NudgesConfiguration, GlobalTable }
}
</script>

<style lang="scss" scoped>
table {
    width: 100%;
}
</style>

<style lang="scss">
tr:hover {
    background-color: #f5f5f5;

}
td{
    border-color: rgba(255, 255, 255, 0.1);
    padding: 12px 7px;
    vertical-align: middle;
}
</style>