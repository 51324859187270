import { render, staticRenderFns } from "./FreshDeskDescPage.vue?vue&type=template&id=1b12be73&scoped=true"
import script from "./FreshDeskDescPage.vue?vue&type=script&lang=js"
export * from "./FreshDeskDescPage.vue?vue&type=script&lang=js"
import style0 from "./FreshDeskDescPage.vue?vue&type=style&index=0&id=1b12be73&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b12be73",
  null
  
)

export default component.exports