class LocalUtils {
  //returns the email id of the logged in user use this here to get ADMIN_CREDS
  getEmail = () => {
    return localStorage.getItem("ADMIN_CREDS")
      ? JSON.parse(localStorage.getItem("ADMIN_CREDS"))["__email__id"]
      : null;
  };
  generateRandomString = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  generateSalesforceId = () => {
    const idLength = 12;
    return this.generateRandomString(idLength);
  };
}

export default new LocalUtils();
