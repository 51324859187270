<template>
    <div>
      <div v-if="!isSuccess">
        <!-- <div style="cursor: pointer" class="mt-3" @click="showsteps = !showsteps">
          <div class="showStepDiv">
            <h6>How to grant access to sheet?
            </h6>
            <div class="dropDownIconBox">
              <i v-if=!showsteps class="tim-icons icon-minimal-down"></i>
              <i v-if=showsteps class="tim-icons icon-minimal-up"></i>
            </div>
          </div>
        </div>
        <div v-if="showsteps">
          <div :class="{ steps: true, boldStep: index == 3 }" v-for="(step, index) in steps">
            <i @click="copyText()" v-if="index == 3" class="tim-icons icon-single-copy-04 ml-1 mr-2" />
            <div v-if="index != 3" class="circle">{{ index + 1 }}</div>
            {{ step }}
          </div>
        </div> -->
        <h3>Copy instruction and send to developer
          <span @click="copyTxt()" style="font-size: 20px;cursor: pointer;" class="material-symbols-outlined">content_copy</span>


        </h3>
        <div style="text-align: left;border: 1px dotted red;padding: 4px;">
            <div id="copy">
              Hi there,<br/>
              Here is the instruction to send data to AppEQ through API<br/>
              
              <span>
                Token Key : {{ imageUrl.token }} 
                  <span @click="copyToken()" style="font-size: 20px;cursor: pointer;" class="material-symbols-outlined">content_copy</span>

              </span>
              <br/>
              Please refer to this document to start sending data to AppEQ through Rest API Interface.
              <br/>
              <a target="_blank" href="https://help.appeq.ai/en/articles/8145852-api-ingestion-guide-for-appeq">Link</a>
              <br/>
              Thanks,<br/>
              AppEQ Team
            </div>

        </div>
        <!-- <div style="margin-top: 10px;text-align: center;">
          Read this doc for more information
          <br/>
          <a target="_blank" href="https://help.appeq.ai/en/articles/8145852-api-ingestion-guide-for-appeq">Link</a>
        </div> -->
        <button @click="copyText()">
           Re-Initialize Token
        </button>
  
        <div v-if="isLoading" class="loading">
          <img src="../assets/infinity_blue.svg" />
        </div>
        <div class="error">{{ error }}</div>
      </div>
    
    </div>
  </template>
  
  <script>
  import Server from "./API/Server.js";
  import { useGapi } from 'vue-gapi'
  import Multiselect from 'vue-multiselect';
  import BaseDropdownVue from "./BaseDropdown.vue";
  import LocalUtils from "./Utils/LocalUtils";
  import mixpanel from "../analytics/mixpanel";
  
  export default {
    name: "GoogleSheetDescPage",
    components: {
      BaseDropdownVue,Multiselect
    },
    data() {
      return {
        isActive: false,
        sheetLink: "",
        isLoading: false,
        sheetEmail: "",
        sheetName: "",
        isAdmin: false,
        googleSheetKeys: [],
        isNextPage: false,
        sheet_list:[],
        businessObjectName:'',
        sheetResponse: [],
        showDropdown: false,
        domainId:'',
        showSecondaryKeyDropdown: false,
        error: "",
        saveKeys: false,
        selectedKeys: "",
        selectedTimeKey: "",
        previewData: false,
        isSuccess: false,
        showsteps: false,
        steps: [
          "Navigate to sheet",
          "Click on share at top right corner ",
          "Share the sheet to",
          "appeq-470@appeq-auth.iam.gserviceaccount.com",
        ],
  
        isMapping:false,
        currentKeys:[],
        emailKey:'',
        domainKey:'',
      };
    },
    async mounted() {
  
      this.businessObjectName = localStorage.getItem("Business_obj_name");
  
      console.log(this.$router.currentRoute.fullPath);
      const d = JSON.parse(localStorage.getItem("item_creds"))
      const data = d;
      if (data && data["email"]) {
        this.sheetEmail = data["email"];
      }
      if (data && data["sheetUrl"]) {
        this.sheetLink = data["sheetUrl"];
      }
      if (data && data["sheet_title"]) {
        this.sheetName = data["sheet_title"]
      }
      // this.isAdmin = localStorage.getItem("IS_ADMIN")
      // if (!this.isAdmin) {
      //   // this.sheetEmail="X".repeat(this.sheetEmail.length-4)+this.sheetEmail.substring(this.sheetEmail.length-4)
      //   // this.sheetLink="X".repeat(this.sheetLink.length/2)+this.sheetLink.substring(this.sheetLink.length/2)
      //   // this.sheetName="X".repeat(this.sheetName.length-2)+this.sheetName.substring(this.sheetEmail.length-2)
  
      // }
    },
    methods: {
      copyToken(){
        navigator.clipboard.writeText(
          this.imageUrl.token
        );
        this.$toasted.success("Successfully copied to clipboard")

      },
      copyTxt(){
        const txt=document.getElementById("copy").innerText
        navigator.clipboard.writeText(
          txt
        );
        this.$toasted.success("Successfully copied to clipboard")
      },
      handleIClick(key) {
        console.log(key);
        this.isActive = !this.isActive;
        console.log(this.isActive);
      },
      async handleSaveKeys() {
  
        if(this.isNextPage){
          this.isMapping = true;
          return
        }
  
  
        try {
          this.isLoading = true;
          const data = localStorage.getItem("Business_obj_name");
          // var re = new RegExp("/(\d[A-Z])\w+/",'global');
          let sheetId = ''
          const client=window.google.accounts.oauth2.initTokenClient({
            scope: 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file',
            client_id: '180578786917-pkbmnkbqd229un30jc1kstkkp0sbn9te.apps.googleusercontent.com',
            callback: async (user) => {
              console.log("HERE", user)
              const sheetIdArr = this.sheetLink.split('/');
              if (sheetIdArr.length > 5) {
                sheetId = sheetIdArr[5]
              }
              const res = await Server.givePermissionToSheet(sheetId,user['access_token'])
              if (res.code != 200) {
                this.$toasted.error("Access not granted to sheet . Please give access manually or login through google to give access");
                this.isLoading = false;
                return;
              }
  
              //for checking duplicate keys in sheet
              const getKeyResponse = await Server.getGoogleSheetKeys(this.sheetLink);
              if(getKeyResponse && getKeyResponse.data && getKeyResponse.data.data){
                let keys = getKeyResponse.data.data;
                this.currentKeys = getKeyResponse.data.data;
  
                // let duplicateFiels = [];
                // duplicateFiels = keys.filter((item, index) => keys.indexOf(item) !== index);
  
                // if(duplicateFiels.length > 0){
                //   this.error = "Please Remove Duplicate Feilds From The Sheet - " + duplicateFiels.join(", ");
                //   this.isLoading = false;
                //   this.$toasted.error(this.error);
                //   return
                // }
  
                if(getKeyResponse.data.sheet_names){
                  this.sheet_list = getKeyResponse.data.sheet_names;
  
                  if(this.sheet_list.length == 1){
                    this.sheetName = this.sheet_list[0];
                    this.isMapping = true;
                    this.isLoading = false;
                    return;
                  }else{
                    this.isNextPage = true;
                    this.isLoading = false;
                    return;
                  }
                }
  
              }
              this.isLoading = false;
             
            }
          });
          client.requestAccessToken((not) => {
          });
  
  
  
        } catch (error) {
          console.log(error);
          this.$toasted.error(error);
          this.isLoading = false;
        }
      },
  
      async saveSheet(){
          this.isLoading = true;
          let saveData;
          const data = localStorage.getItem("Business_obj_name");
          if (data)
            saveData = await Server.saveSheetData({
              sheet_url: this.sheetLink,
              email: LocalUtils.getEmail(),
              sheetName: this.sheetName,
              business_object_name: data,
              appeq_supported_application:"spreadsheet",
              domainKey: this.domainKey,
              domainId:this.domainId,
              emailKey: this.emailKey
  
            })
          console.log(saveData)
          if (saveData.code != 200) {
            this.$toasted.error("Unable to save google sheet credentials. Please give access to the sheet")
            this.isLoading = false;
            return;
          }
          this.$toasted.success("Google sheet credentials saved");
          this.isLoading = false;
          this.isSuccess = true;
          try{
            mixpanel.businessObjectAdded( localStorage.getItem("Business_obj_name"));
          }catch(e){
            console.log(e);
          }
  
          this.isLoading = false;
          window.location.reload();
          
      },
  
      selectPrimaryKey(item) {
        this.selectedKeys = item;
        this.showDropdown = false;
      },
      selectTimeKey(item) {
        if (item.toString().includes(".")) {
          this.showSecondaryKeyDropdown = false;
          return;
        }
        this.selectedTimeKey = item;
        this.showSecondaryKeyDropdown = false;
      },
      nameOfCustomEventToCall() {
        this.showDropdown = false;
      },
      // async saveSheet() {
      //   const data = localStorage.getItem("Business_obj_name");
      //   if (data)
      //     await Server.saveSheetData({
      //       sheet_url: this.sheetLink,
      //       sheetName: this.sheetName,
      //       email: this.sheetEmail,
      //       business_object_name: data,
      //       mapped_fields: {
      //         updated_at: ''
  
      //       }
      //     })
      // },
      async copyText() {
       const token=await Server.getTokenForIngestion(this.businessObjectName);
        this.imageUrl.token=token.token
      },
      async onsubmit() {
        try {
          this.isLoading = true
          const data = await Server.getgoogleSheetData({
            sheet_url: this.sheetLink,
          });
          localStorage.removeItem("item_creds")
          if (!data.error) {
  
            //get the keys from google sheet and check if . exists in it
            const data = await Server.getGoogleSheetKeys({
              sheet_url: this.sheetLink,
              sheetName: this.sheetName,
              email: this.sheetEmail,
            });
  
            if (data.data) {
              var fin = data.data.find(d => d.toString().includes("."));
              if (fin) {
                this.error = "Please remove . from header(" + fin + ") and then try again to authenticate";
                this.isLoading = false;
                return;
              }
            }
            const res = await this.saveSheet()
            this.isLoading = false
            this.error = ""
            this.isSuccess = true;
            this.googleSheetKeys = data.data;
            this.selectedKeys = data.data[0]
            this.selectedTimeKey = data.data[1]
          } else {
            console.log(data)
            this.isLoading = false
            this.error = data.message
          }
        }
  
        catch (error) {
          console.log(error);
          this.isLoading = false;
          this.error = error;
        }
      },
    },
    props: {
      imageUrl: {
        type: Object,
        default: {},
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  h6,
  h5 {
    margin-top: 25px;
    margin-bottom: 15px;
  }
  
  .zendeskInputBox {
    border: 1px solid rgb(220, 226, 234);
    display: flex;
    height: 40px;
    align-items: center;
  
    input {
      width: 100%;
    }
  
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none;
    }
  }
  
  .error {
    color: red;
    font-size: 12px;
  }
  
  .tableitem {
    width: 40px;
  }
  
  .steps {
    font-size: 12px;
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: baseline;
  }
  
  .circle {
    height: 20px;
    width: 20px;
    text-align: center;
    margin-top: 10px;
    margin-right: 5px;
    color: white;
    border-radius: 50%;
    background: #6f55ff;
  }
  
  .domainbox {
    height: 40px;
    display: flex;
    align-items: center;
    background: rgb(244, 246, 250);
  }
  
  button {
    flex-shrink: 0;
    background: #6f55ff;
    color: white;
    border: 0px;
    padding: 0px 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    height: 56px;
    cursor: pointer;
    border-radius: 6px;
    width: 100%;
    margin-top: 20px;
  }
  
  .boldStep {
    font-weight: 700;
    font-size: 11px;
    margin: 20px 0 20px 0;
  }
  
  .tim-icons {
    cursor: pointer;
    margin-right: 10px;
  }
  
  .success {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .error {
    color: red !important;
    font-size: 10px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dropDown {
    width: 100%;
  }
  
  .dropdownbox {
    border: 1px solid black;
    border-radius: 6px;
    cursor: pointer;
    padding: 4px;
    width: 100%;
  }
  
  .dropdownoptions {
    position: absolute;
    background-color: white;
    width: 88%;
    height: 100px;
    overflow: auto;
  }
  
  .dropdownoptions::-webkit-scrollbar {
    width: 0px;
  }
  
  li {
    list-style: none;
    cursor: pointer;
  }
  
  .successText {
    text-align: center;
  }
  
  .showStepDiv {
    display: flex;
    justify-content: space-between;
    align-items: center
  }
  
  i:hover {
    scale: 1.2;
    transition: 0.2s ease-in-out;
  }
  
  .iconIsActive {
    scale: 2;
    transition: 0.2s ease-in-out;
    border-radius: 50%;
  
  }
  
  .icon-alert-circle-exc {
    position: absolute;
    margin-top: 5px;
    right: 25px;
    transition: 0.2s ease-in-out;
    color: blue;
  }
  
  .support {
      left: 350px;
      height: 150px;
      width: 320px;
      background: whitesmoke;
      position: absolute;
      margin-left: -30px;
      margin-top: -20px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      display: flex;
      align-items: center;
      padding: 20px;
      text-transform: none;
      justify-content: center;
      flex-direction: column;
      border-radius: 10px;
  
      a{
        cursor: pointer;
      }
  }
  .template_sheet{
    font-weight: 600;
  }
  
  </style>
  