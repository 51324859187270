<template>
    <div>
        <h2 style="visibility: hidden;">Working</h2>
    <div style="padding-bottom: 50px;width: 100%; margin-left: 6rem;">
        <table class="styled-table">
            <thead>
                <tr>
                    <th v-for="column in columns" :key="column" style="border-right: 1px solid #e0e0e0; width: 60%;" :style="{textAlign: (column=='Actions') ? 'center' : 'left'}">
                        <span>{{ column }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in columnData" :key="index">
                    <td v-for="(column, index) in columns" :key="index" style="border-right: 1px solid #e0e0e0;" :style="{textAlign: (column=='Actions') ? 'center' : 'left'}">
                        <template v-if="column=='Actions'">
                            <span style="cursor:pointer;font-size: 22px;" @click="openConfigurePage(item)" class="material-symbols-outlined summary_action_visibility" v-tooltip="'Feeds Configuration'">
                                settings
                            </span>
                            <span style="cursor:pointer;font-size: 22px; margin-left: 10px;" @click="openNewWindow(item)" class="material-symbols-outlined summary_action_visibility" v-tooltip="'Internal Login'">
                                person
                            </span>
                        </template>
                        <template v-if="column=='Customer Name'">
                            <span><img :src="getLogo(item)" style="height: 20px; margin-right: 10px;" alt=""></span>
                            <span style="font-size: 18px;">
                                {{ item }}
                            </span>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div v-if="this.openConfigureModal==true">
        <Modal v-if="openConfigureModal" @close="handleClose()" :show="openConfigureModal">
            <i @click="handleClose()" class="tim-icons close-icon icon-simple-remove"></i>
            <Configure :customerName="customerName" @close="openConfigureModal = !openConfigureModal" />
        </Modal>
    </div>
    </div>
</template>
<script>
import Server from '../components/API/Server';
import Modal from "../components/Modal.vue";
import Configure from './Configure.vue';
export default {
    data() {
      return {
        columns: ['Customer Name', 'Actions'],
        tableData: {},
        columnData: {},
        openConfigureModal: false,
        customerName: ''
      };
    },
    components: {
    Modal,
    Configure
},
    async mounted(){
        let data = await Server.getAppeqClients();
        console.log(data);
        let res = data.data;
        console.log(res)
        for(let key of res){
            this.tableData[key.email] = key.customer_name;
        }
        console.log(this.tableData)
        this.columnData = this.tableData
    },
    methods: {
        getLogo(context_link) {
            try {
                if (context_link == null)
                    return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + 'salesforce.com' + '&sz=128';
                else
                    return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + context_link + '&sz=128';
            } catch (e) {
                console.log(e);
            }
        },
        openConfigurePage(value){
            this.customerName = value;
            this.openConfigureModal = true;
        },
        handleClose(){
            this.openConfigureModal = false;
        },
        openNewWindow(value) {
            let emailReq = '';
            // for(let key in this.tableData){
            //     if(this.tableData[key] == value){
            //         emailReq = key;
            //         break;
            //     }
            // }
            emailReq = 'onboarding@'+value;
            console.log(emailReq)
            let cred = localStorage.getItem("ADMIN_CREDS")
            cred = JSON.parse(cred);
            // console.log(cred);
            cred["__email__id"] = emailReq;
            console.log(cred['__email__id']);
            // console.log(cred);
            cred = JSON.stringify(cred);
            // console.log(cred)
           const win= window.open(process.env.VUE_APP_REDIRECTION_ROUTE+'/my-customers', '_blank')
           win.localStorage.setItem("ADMIN_CREDS", cred)
        }
    }
  };
</script>
<style scoped>
.nav-item{
    list-style: none;
}

.styled-table {
  width: 80%;
  border-collapse: collapse;
  border: 1px solid #e0e0e0;
  background: white;
}

.styled-table th,
.styled-table td {
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0; 
}

.styled-table th {
  font-weight: bold;
}

.styled-table td span.material-symbols-outlined {
  font-size: 22px;
  cursor: pointer;
}

.styled-table tbody tr:nth-child(even) {
  /* background-color: #f2f2f2; */
}

.styled-table tbody tr:last-child td {
  border-bottom: none;
}

</style>
<style scoped>
.modal-body{
    width: 800px !important;
    max-height: 685px !important;
}
.modal{
    left: -300px !important;
}
.modal-content{
    width: 800px !important;
    min-width: 800px !important;
}
</style>