<template>
    <div class="login">
        <img @click="logoClicked" class="back-image" src="../assets/appeq.png"/>
        <img class="back-image2" src="../assets/back-image2.svg">
        <div class="carousel">
            <h1 style="position: relative !important;" class="left_heading">In-App Customer Data & Insights : <span style="color:blue">Boost Revenue</span></h1>
            <!-- <h1 class="left_heading"><span class="blue-text">One-click</span> account insights for B2B organisations
            </h1>
            <h3 class="left_heading_text">Where you need. When you need</h3> -->

            <div class="card My-card">
                <!-- <div class="card-body My-card-body"> -->
                    <img src="../assets/loginBackImage/Screenshot 2022-12-26 at 3.33.11 PM.png"></img>
                <!-- </div> -->
            </div>
        </div>
        <div class="panel">
            <div class="login-panel" v-if="showRegistrationPage" style="padding-top: 10px;padding-bottom: 10px;">

                <div style="margin-top: 20px;margin-bottom: 40px" class="panel__heading">
                    Sign Up
                </div>
                <div class="checkAgreementDiv">
                    <input v-model="policyCheck" type="checkbox" /><span>Agree To <a 
                        
                        href="https://appeq.ai/terms-of-service/">Terms and Conditions</a></span>
                </div>
                <button @click="handleGoogleRegistration()" class="panel__googleBtn" :class="{disabled:!policyCheck}">
                    <img src="../assets/g-logo.svg" />
                    Sign up with google
                </button>
                <span class="or_span">or</span>
                <div class="grid">
                    <label class="label">Email <span style="font-size: 10px;">(Work Emails Only)</span></label>
                    <input v-model="email" placeholder="Name@YourCompany.com"
                        class="email" />
                </div>
               
                <!-- <div class="grid">
                    <label v-if="showPasscode" class="label">Password</label>
                    <div v-if="showPasscode" class="otp_block_2">
                        <v-otp-input v-model="passcode" v-on:keyup.enter="handleOnComplete" ref="otpInput"
                            input-classes="otp-input" separator="-" :num-inputs="4" :should-auto-focus="true"
                            :is-input-num="true" :conditionalClass="['one', 'two', 'three', 'four']"
                            :placeholder="['*', '*', '*', '*']" @on-complete="handleOnComplete" />
                    </div>
                </div> -->
                <div v-if="isLoading" class="loadingLogin" >
                    <img src="../assets/infinity_blue.svg" />
                </div>
                <!-- <small class="error">{{ error }}</small> -->

                <button @click="register()" class="panel__login" :class="{disabled:!policyCheck}">
                   Sign Up
                </button>
                <span class="signupDiv">
                    <span>Already have an account</span>
                    <span 
                        @click="showRegistrationPage=false"
                        class="signUpText"
                        >Sign In
                    </span>
                </span>
                <span style="margin-bottom: 20px;" class="forget">
                    <span>By continuing, you’re agreeing to our Terms of</span>
                    <br>
                    <a
                        target="_blank"
                        href="https://appeq.ai/privacy-policy/">Service, and Privacy Policy.</a></span>
                </div>
            
            <div class="login-panel" v-else>

                <div class="panel__heading">
                    Log In to your Account
                </div>
                <button @click="handleGoogleLogin()" class="panel__googleBtn">
                    <img src="../assets/g-logo.svg" />
                    Sign in Google Work Email
                </button>
                <span class="or_span">or</span>
                <div class="grid">                    
                    <label class="label">Email <span style="font-size: 10px;">(Work Emails Only)</span></label>
                    <input v-model="email" placeholder="Name@YourCompany.com" v-on:keyup.enter="handleOnclickPassCode()"
                        class="email" />
                </div>
                <div class="grid">
                    <label v-if="showPasscode" class="label">Password</label>
                    <div v-if="showPasscode" class="otp_block_2">
                        <v-otp-input v-model="passcode" v-on:keyup.enter="handleOnComplete" ref="otpInput"
                            input-classes="otp-input" separator="-" :num-inputs="4" :should-auto-focus="true"
                            :is-input-num="true" :conditionalClass="['one', 'two', 'three', 'four']"
                            :placeholder="['*', '*', '*', '*']" @on-complete="handleOnComplete" />
                    </div>
                </div>
                <div v-if="isLoading" class="loadingLogin">
                    <img src="../assets/infinity_blue.svg" />
                </div>
                <small class="error">{{ error }}</small>

                <button @click="handleOnclickPassCode()" class="panel__login">
                    {{
                        showPasscode ? 'Sign In' : 'Send
                                        passcode'}}
                </button>
                <span class="signupDiv">
                    <span>Do not have an account</span>
                    <span
                        @click="showRegistrationPage=true"
                        class="signUpText"
                        >Sign Up
                    </span>
                </span>
                <span class="forget">
                    <span>By continuing, you’re agreeing to our Terms of</span>
                    <br>
                    <a
                    target="_blank"

                        href="https://appeq.ai/privacy-policy/">Service, and Privacy Policy.</a>
                </span>
            </div>
            <div v-if="isFirstConfigure" class="firstCinfigurationDiv">
                <img src="../assets/firstConfiguration.svg"></img>
                <h3 class="left_heading">Setting up Your Dashboard Please Wait .....</h3>
                <img class="loadingGif" src="../assets/loading.gif"></img>
            </div>
        </div>
        <Modal @close="showUnSupportedBrowserModal=true" v-if="showUnSupportedBrowserModal" :show="showUnSupportedBrowserModal">
            <i @click="showUnSupportedBrowserModal=false" class="tim-icons close-icon icon-simple-remove"></i>
            <h3 style="text-align: center;">Unsupported Browser</h3>
            <div class="text-span-login">
                <i class="tim-icons icon-alert-circle-exc"></i>
                <span > AppEQ.ai extensions are currently supported only on Chrome Browser.
                </span>
            </div>
            <img style="display: block;margin:auto" width="200px" src="../assets/img/chromeLogo.png">

        </Modal>

    </div>
</template>

<script>
import OTPInput from "vue-otp-input";
import Server from "@/components/API/Server.js";
import mixpanel from "../analytics/mixpanel";
import Modal from "../components/Modal.vue"

export default {
    components: {
        OTPInput,
        Modal
    },
    data() {
        return {
            email: "",
            passcode: "",
            error: "",
            showRegistrationPage:true,
            showPasscode: false,
            name:"",
            policyCheck:false,
            isLoading: false,
            isFirstConfigure: false,
            showUnSupportedBrowserModal:false,
        };
    },
    methods: {
        logoClicked() {
            window.open("https://www.appeq.ai", '_blank');

        },
        async handleOnComplete(value) {
            this.isLoading = true,
                this.passcode = value;
            this.handleOnclickPassCode();
        },
        async handleOnclickPassCode() {
            this.isLoading = true;

            try {
                localStorage.clear()

                // if (this.email == "demo@appeq.ai" && !this.showPasscode) {
                //     this.showPasscode = true;
                //     return;
                // }

                // if (this.email == "demo@appeq.ai" && this.passcode == "4321") {
                //     localStorage.setItem("ADMIN_CREDS", JSON.stringify({ "__email__id": this.email }))
                //     -                    this.$router.push({path:'/integrations',query:{...this.$route.query}})
                //     this.isLoading = false;
                //     this.isFirstConfigure = false;
                //     return;
                // }
                if (!this.showPasscode) {
                    const resRegisterRoute = await Server.sendPasscode(this.email);
                    console.log(resRegisterRoute  );
                    if(resRegisterRoute && resRegisterRoute.data && resRegisterRoute.data.terms){
                        
                    }else{
                       
                        this.$toasted.error("Please Sign up and Enable Terms and Conditions");
                        this.isLoading = false;
                        return 
                        
                    } 
                    this.showPasscode = true
                }
                else {
                    this.isFirstConfigure = true;
                    const data = (await Server.loginwithEmail(this.email, this.passcode)).data;
                    // localStorage.setItem("IS_ADMIN", data.isAdmin);
                    const isVisised = await this.$getItem('isVisited');
                    console.log(data.__nakama__username);
                    localStorage.setItem("ADMIN_CREDS", JSON.stringify(data))
                    if(data){
                        try{
                            const res = (await Server.checkAdmin(data.__nakama__username));

                            if (res) {
                                console.log(res.code);
                                if(res.code==200 && res.data.isAdmin)
                                {
                                    localStorage.setItem("IS_ADMIN", 'true');
                                }
                                localStorage.setItem("role", resData.data.role);


                            }
                        }catch(e){
                            console.log(e);
                        }

                    }

                    //adding user in mixpanel mixpanel 
                    try {
                        //mixpanel.init(data.__nakama__username, this.email);
                        if(window.location.href.includes('ticketPlg')){
                            mixpanel.init(data.__nakama__username, this.email,'plg');
                        }else{
                            mixpanel.init(data.__nakama__username, this.email,'nonplg');
                        } 
                        mixpanel.dashboardLogin(data.__nakama__username, this.email, 'passcode');                 
                    } catch (e) {
                        console.log(e);
                    }

                    console.log(localStorage.getItem("IS_ADMIN"));
                    if (isVisised == null) {
                        this.$setItem('isVisited', 1)
                        this.$router.push("/tour");
                    }
                    if (data) {
                        this.$router.push({path:'/integrations',query:{...this.$route.query}})
                    }
                }
                this.error = ""
                this.showPasscode = true
                // Server.callSceduler('spreadsheet')
            } catch (e) {
                this.error = e;
                console.log(e);
            }
            this.isLoading = false;
            this.isFirstConfigure = false;
        },

        async register(){
            this.isLoading = true;
            if(!this.policyCheck){
                this.$toasted.error("Please Tick Agree our Terms and Policies Box")
            }
            else{
                await Server.registerFirstTimeUser(this.email,this.policyCheck);
                this.showPasscode = true;
                this.isLoading = false;
                this.showRegistrationPage = false;

            }
            this.isLoading = false;
        },
        async handleGoogleRegistration(){
            if(!this.policyCheck){
                this.$toasted.error("Please Tick Agree our Terms and Policies Box")
            }
            else{
                try {
                localStorage.clear();

                localStorage.setItem("IS_ADMIN", 'true');
                var client = window.google.accounts.oauth2.initTokenClient({
                    scope: 'profile email',
                    client_id: '180578786917-pkbmnkbqd229un30jc1kstkkp0sbn9te.apps.googleusercontent.com',
                    callback: async (user) => {
                        try {
                            this.isFirstConfigure = true;
                            const userProfile = await Server.getUserProfile(user['access_token']);

                            if (userProfile && userProfile.data && userProfile.data.email) {
                              


                                const res = await Server.checkLoginGoogleWithCheck(userProfile.data.email,this.policyCheck);
                                // if (resGoogleLogin && resGoogleLoginres.status==500){
                                //     this.$toasted.error("Not Signed Up,Please Sign Up first ");
                                //     this.isLoading = false;
                                //     return 
                                // }else{
                                //     const res = resGoogleLogin.data;
                                // }
                            
                                console.log(res.username);
                                if (res) {
                                    // try{
                                    //     const resData = (await Server.checkAdmin(res.username));
                                    //     console.log(resData.code);
                                    //     if(resData.code==200 && resData.data.isAdmin)
                                    //     localStorage.setItem("IS_ADMIN", 'true');

                                    // }catch(e){
                                    //     console.log(e);
                                    // }

                                    //adding user in mixpanel mixpanel 
                                    try {
                                        //mixpanel.init(res.username, userProfile.data.email);
                                        if(window.location.href.includes('ticketPlg')){
                                            mixpanel.init(res.username, userProfile.data.email,'plg');
                                        }else{
                                            mixpanel.init(res.username, userProfile.data.email,'nonplg');
                                        }
                                        mixpanel.dashboardLogin(res.username, userProfile.data.email, 'google');
                                    } catch (e) {
                                        console.log(e);
                                    }



                                }
                                if (res.email_id) {
                                    localStorage.setItem("ADMIN_CREDS", JSON.stringify({ "__email__id": res.email_id, "picture": userProfile.data.picture }));
                                    this.isFirstConfigure = false;
                                    
                                    this.$router.push({path:'/integrations',query:{...this.$route.query}})
                                } else {
                                    this.isFirstConfigure = false;
                                    if(res.msg){

                                    this.$toasted.error(res.msg)
                                    }else{
                                    this.$toasted.error("Something went wrong")

                                    }
                                    console.log(res)

                                }
                            } else {
                                this.isFirstConfigure = false;
                                this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")
                            }
                        } catch (error) {
                            this.isFirstConfigure = false;
                            console.log(error)
                            this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")
                        }
                    }
                })
                client.requestAccessToken((not) => {
                    console.log(not)
                });

            } catch (e) {
                this.isFirstConfigure = false;
                console.log(e);
                this.$toasted.error(e);
            }
            }
        },

        async handleGoogleLogin() {
            try {
                localStorage.clear();

                localStorage.setItem("IS_ADMIN", 'true');
                var client = window.google.accounts.oauth2.initTokenClient({
                    scope: 'profile email',
                    client_id: '180578786917-pkbmnkbqd229un30jc1kstkkp0sbn9te.apps.googleusercontent.com',
                    callback: async (user) => {
                        try {
                            this.isFirstConfigure = true;
                            const userProfile = await Server.getUserProfile(user['access_token']);
                            if(userProfile.data.email){
                               
                        }
                            if (userProfile && userProfile.data && userProfile.data.email) {
                                console.log(userProfile)
                                const saveProfileInfo=await Server.storeProfilePic(userProfile.data.email,userProfile.data.picture,userProfile.data.name)
                                const isTermsChecked= await Server.getUsersData(userProfile.data.email);

                                if( isTermsChecked && isTermsChecked.data && isTermsChecked.data.data && isTermsChecked.data.data.terms ){
                                    if(isTermsChecked.data.data.plan_name)
                                    localStorage.setItem("plan_name",isTermsChecked.data.data.plan_name)
                                    else
                                    localStorage.setItem("plan_name","free")
                                    localStorage.setItem("role",isTermsChecked.data.data.role)


                                }   
                                else{
                                    this.$toasted.error("Please Sign up and Enable Terms and Conditions");
                                    this.isLoading = false;
                                    this.isFirstConfigure = false;
                                    return; 
                                }
                                 



                                const res = await Server.checkLoginGoogle(userProfile.data.email);

                                if (res && res.code==500){
                                    this.$toasted.error("Not Signed Up, Please Sign Up first ");
                                    this.isLoading = false;
                                    this.isFirstConfigure = false;
                                    return 
                                }
                            
                                console.log(res.username);
                                if (res) {
                                    
                                    try {
                                        //mixpanel.init(res.username, userProfile.data.email);
                                        if(window.location.href.includes('ticketPlg')){
                                            mixpanel.init(res.username, userProfile.data.email,'plg');
                                        }else{
                                            mixpanel.init(res.username, userProfile.data.email,'nonplg');
                                        }
                                        mixpanel.dashboardLogin(res.username, userProfile.data.email, 'google');
                                    } catch (e) {
                                        console.log(e);
                                    }



                                }
                                if (res.email_id) {
                                    localStorage.setItem("ADMIN_CREDS", JSON.stringify({ "__email__id": res.email_id, "picture": userProfile.data.picture,...res }));
                                    
                                    this.isFirstConfigure = false;
                                    -                    this.$router.push({path:'/integrations',query:{...this.$route.query}})
                                } else {
                                    this.isFirstConfigure = false;
                                    this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")

                                }
                            } else {
                                this.isFirstConfigure = false;
                                this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")
                            }
                        } catch (error) {
                            this.isFirstConfigure = false;
                            console.log(error)
                            this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")
                        }
                    }
                })
                client.requestAccessToken((not) => {
                    console.log(not)
                });

            } catch (e) {
                this.isFirstConfigure = false;
                console.log(e);
                this.$toasted.error(e);
            }

        }
    },
    mounted() {

        try{

            let browserInfo = navigator.userAgent;
            let browser;

            if (!browserInfo.includes('Chrome')) {
                this.showUnSupportedBrowserModal = true;
            }
            
            
        }catch(e){
            console.log(e);
        }

        window.CookieConsent.init({
            modalMainTextMoreLink: null,
            showRejectAllButton:false,
            barTimeout: 1000,
            theme: {
            barColor: '#2C7CBF',
            barTextColor: '#FFF',
            barMainButtonColor: '#FFF',
            barMainButtonTextColor: '#2C7CBF',
            modalMainButtonColor: '#4285F4',
            modalMainButtonTextColor: '#FFF',
            },
            language: {
            current: 'en',
            locale: {
                en: {
                barMainText: 'This website uses cookies to ensure you get the best experience on our website.',
                closeAriaLabel: 'close',
                barLinkSetting: 'Cookie Settings',
                modalMainText:'',
                barBtnAcceptAll: 'Accept all cookies',
                modalMainTitle: 'Cookie settings',
                modalBtnSave: 'Save current settings',
                modalBtnAcceptAll: 'Accept all cookies and close',
                modalAffectedSolutions: 'Affected solutions:',
                learnMore: 'Learn More',
                on: 'On',
                off: 'Off',
                enabled: 'is enabled.',
                disabled: 'is disabled.',
                checked: 'checked',
                unchecked: 'unchecked',
                },
            
            }
            },
            categories: {
           
            },
        
        });

        if (localStorage.getItem('ADMIN_CREDS')) {
            -                    this.$router.push({path:'/integrations',query:{...this.$route.query}})
        } else {
            var client = window.google.accounts.id.initialize({
                scope: 'profile email',
                client_id: '180578786917-pkbmnkbqd229un30jc1kstkkp0sbn9te.apps.googleusercontent.com',
                callback: async (user) => {
                    try {
                        this.isFirstConfigure = true;
                        console.log(user)
                        const parseJwt = (token) => {
                            var base64Url = token.split('.')[1];
                            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                            }).join(''));

                            return JSON.parse(jsonPayload);
                        };
                        console.log(JSON.stringify(parseJwt(user.credential)));
                        const userProfile = JSON.parse(JSON.stringify(parseJwt(user.credential)))
                        console.log(userProfile)
                        if (userProfile && userProfile && userProfile.email) {
                            let res;
                            if(this.policyCheck){
                                 res = await Server.checkLoginGoogleWithCheck(userProfile.email,this.policyCheck);
                            }else{
                                 res = await Server.checkLoginGoogle(userProfile.email);
                            }
                            if (res) {
                                try{
                                    const resData = (await Server.checkAdmin(res.username));
                                    console.log(resData.code);
                                    if(resData.code==200 && resData.data.isAdmin)
                                    localStorage.setItem("IS_ADMIN", 'true');
                                    localStorage.setItem("role", resData.data.role);


                                }catch(e){
                                    console.log(e);
                                }

                                //adding user in mixpanel mixpanel 
                                try {
                                    mixpanel.init(res.username, userProfile.email);
                                    mixpanel.dashboardLogin(res.username, userProfile.email, 'google');
                                } catch (e) {
                                    console.log(e);
                                }



                            }
                            if (res.email_id) {
                                localStorage.setItem("ADMIN_CREDS", JSON.stringify({ "__email__id": res.email_id, "picture": userProfile.picture }));
                                this.isFirstConfigure = false;
                                this.$router.push({path:'/integrations',query:{...this.$route.query}})
                            } else {
                                this.isFirstConfigure = false;
                                this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")

                            }
                        } else {
                            this.isFirstConfigure = false;
                            this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")
                        }
                    } catch (error) {
                        this.isFirstConfigure = false;
                        console.log(error)
                        this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")
                    }
                }
            })
            window.google.accounts.id.prompt((notification) => {
                console.log(notification)
                if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                    window.google.accounts.id.prompt()

                    // continue with another identity provider.
                }
            });

        }
    }
}
</script>

<style  lang="scss">
.login {
    height: 100vh;
    display: flex;
}

.login-panel {
    height: fit-content;
    width: 410px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    padding-top:50px ;
    padding-bottom:50px ;
    margin-top: auto;
    margin-bottom: auto;
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.back-image2 {
    height: 300px;
    width: 300px;
    object-fit: contain;
    position: absolute;
    right: 0vw;
    bottom: 0vh;
    rotate: 0deg;
    opacity: 1.5;
    z-index: -1;
}

.back-image {
    height: 80px;
    width: 95px;
    position: absolute;
    top: 1vh;
    right: 1vw;
    cursor: pointer;

}

.loadingLogin {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -50px;

    img {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

.grid {
    display: grid;
}

.label {
    width: 100%;
    margin-top: 20px;
}

.My-card {
    width: 80% !important;
    height: 48%;
    z-index: 2;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    img {
        margin-left: 15px;
        height: auto;
        max-height: 360px;
    }
}

.carousel {
    display: flex;
    align-items: center;justify-content: center;flex-direction: column;
    height: 100%;
    width: 40vw;
    min-width: 600px;
    background: linear-gradient(180deg, #f6f8fd, #f2faff);

    &:after {
        content: "";
        position: absolute;
        background-position: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        z-index: 1;
        background: 50% auto no-repeat url('../assets/loginBackImage/linear-gradient-backgroud.png');
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        background: 50%/382px auto no-repeat url('../assets/loginBackImage/background-retina.png');
    }

    .left_heading {
        margin: 60px;
        margin-top: 110px;
        text-align: center;
        position: absolute;
        z-index: 2;

        .blue-text {
            color: #0061fe;
        }
    }

    .left_heading_text {
        text-align: center;
        margin: auto;
        margin-top: 120px;
        position: absolute;
        top: 140px;
        left: 100px;
        z-index: 2;
        color: #0061fe !important;
    }

    &_img-bottom {
        position: absolute;
        bottom: 30px;
        left: 7.5vw;
        height: 45vh;
        width: 35vw;
    }
}


.email {
    width: 370px;
    border-radius: 5px;
    height: 40px;
    border: 1px solid #dcddde;
    box-shadow: none;
    padding: 7px 14px;
    font-size: 13px;
    line-height: 1.42857143;
    background-color: #fff;
    box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.08);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline: 0;
}

.panel {
    display: flex;
    padding-right: 15vw;
    flex: 1%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__login {
        background-color: #0061fe;
        color: white;
        border-radius: 10px;
        width: 370px;
        margin-top: 50px;
        height: 40px;
        border: none;
        text-align: center;
        cursor: pointer;
    }

    .or_span {
        margin-top: 38px;
        display: block;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -10px;
    }

    &__login:hover {
        opacity: 0.6;
    }

    .otp_block_2 {
        div {
            div {

                .otp-input {
                    height: 35px;
                    width: 85px;
                    background: #FFFFFF;
                    border: 1px solid #ECEBEB;
                    border-radius: 6px;
                    text-align: center;
                    box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.08);

                }
            }
        }
    }

    &__heading {
        font-weight: 500;
        text-align: center;
        font-size: 30px;
        color: #212121;
        margin-bottom: 45px;
    }

    &__googleBtn {
        margin-top: 10px;
        color: black;
        cursor: pointer;
        background: #fff;
        border: 1px solid #d1d2d3;
        display: flex;
        width: 370px;
        font-size: 13px;
        align-items: center;
        height: 40px;
        border-radius: 5px;
        justify-content: center;
        box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.08);

        &:hover {
            background-color: #f7f7f7;
        }

        img {
            margin-right: 10px;
        }
    }

    .forget {
        color: #212121;
        text-align: center;
        font-size: 13px;
        margin-top: 20px;
        cursor: pointer;
        transition: color .2s ease;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
    }

    .error {
        color: red;
        margin-top: 10px;
    }

    a {
        color: #0061fe;
    }

}

.firstCinfigurationDiv {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: white;
    z-index: 999999;
    transition: 0.5s ease-in-out;

    img {
        height: 500px;
        position: absolute;
        left: 33vw;
        top: 25vh;
    }

    h3 {
        height: 500px;
        position: absolute;
        left: 34vw;
        top: 25vh;
    }

    .loadingGif {
        height: 70px;
        position: absolute;
        left: 48vw;
        top: 10vh;
    }

}
.close-icon {
    position: absolute;
    cursor: pointer;
    right: 27px;
    top: 24px;
    outline: 0;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    color: #555555;

    &:hover {
        scale: 1.09;
        color: black;
    }
}

.text-span-login {
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 80px;
    background: rgb(255, 249, 230);
    color: rgb(99, 99, 99);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 10px;

    i {
        margin-right: 10px;
        margin-left: 5px;
        color: rgb(202, 143, 0);
    }

}
.signupDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.panel__login.disabled{
    background-color: rgb(222, 222, 222);
    color: black;
}

.panel__googleBtn.disabled{
    background-color: rgb(230, 227, 227);
    color: black;
}

.checkAgreementDiv{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    font-size: 17px;

    span{
        margin-left:20px;
        color: black;
    }
}
.signUpText{
    margin-left:10px;
    font-weight: 700;
    font-size: 17px;
    color: #0061fe !important;
    cursor: pointer;

    &:hover{
        text-decoration: underline;
    }

}

</style>