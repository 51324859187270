<template >
    <div>
        <div style="width: 40%;">
            <base-input :value="formData.account_id"  disabled=true label="Account ID"></base-input>
        </div>
        <div style="width: 100%;display: flex;justify-content: space-between;">
            <base-input v-model="formData.name" :disabled="disabled['name']" style="flex: 0.7;" label="Account Name"></base-input>
            <base-input :error="error['account_domain']"  :disabled="disabled['account_domain']" @input="onChange($event)" v-model="formData.account_domain" style="flex: 0.9;margin-left: 20px;" label="Account Domain"></base-input>

        </div>
        <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 8px;">
            <base-input  v-model="formData.acv" :disabled="disabled['acv']"  style="flex: 0.7;" label="ACV"></base-input>
            <base-input  v-model="formData.arr" :disabled="disabled['arr']" style="flex: 0.7;margin-left: 20px;" label="ARR"></base-input>
            <div style="flex: 0.7;margin-left: 20px;">
                <label>
                    CSM
                </label>
                <Multiselect :multiple="true" style="margin-top: -2px !important;" :options="allUsers" v-model="formData.CSM" >
                    <template  slot="tag" slot-scope="{ option }">
                        <span v-if="option" class="tag-text">{{ truncateText(option) }}</span>
                    </template>
                </Multiselect>
            </div>
            <!-- <base-input  v-model="formData.CSM" :disabled="disabled['CSM']" style="flex: 0.7;margin-left: 20px;" label="CSM"></base-input> -->
        </div>
        <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 8px;">
            <base-input  :disabled="disabled['renewalDate']" v-model="formData.renewalDate" type="date" style="flex: 0.7;" label="Renewal Date"></base-input>
            <base-input :disabled="disabled['sponsorEmail']" v-model="formData.sponsorEmail" style="flex: 0.7;margin-left: 20px;" label="Sponsor Email"></base-input>
            <base-input :disabled="disabled['championEmail']" v-model="formData.championEmail" style="flex: 0.7;margin-left: 20px;" label="Champion Email"></base-input>
        </div>
        <div style="display: flex;justify-content: flex-end;align-items: center;margin-top: 30px;">
            <button style="width: 20%;height: 40px;border: 1px solid #4e5fff;background-color: transparent;color: #4e5fff;">Clear</button>
            <button  @click="submitForm" style="width: 20%;height: 40px;margin-left: 20px;">Save</button>

        </div>
    </div>  
</template>
<script>
import BaseInput from "./Inputs/BaseInput.vue"
import LocalUtils from "./Utils/LocalUtils";
import Server from "./API/Server"
import Multiselect from "vue-multiselect";
export default {
    components:{
    BaseInput,
    Multiselect
},
computed: {
  truncatedLabels() {
    const maxLength = 12; // Adjust the maximum length according to your preference
    return this.formData.CSM.map((option) =>{
      option.length > maxLength ? option.slice(0, maxLength) + '...' : option
    }
    );
  },
},
    async mounted(){
        this.formData.account_id=LocalUtils.generateSalesforceId()
        const users = await Server.getAllUsers()
        console.log(users)
        this.allUsers=users.data.map(d=>d.email_id)
        this.formData['account_domain']=this.accountData['domain']
        let allCreds=localStorage.getItem("ALL_CREDS")
        if(allCreds){
            allCreds=JSON.parse(allCreds)
            allCreds.map(d=>{
                if(d.business_object_name=="accounts"){
                    if(d.mappedFields){
                        d.mappedFields.map(o=>{
                            if(o.selectedKey)
                            this.disabled['account_domain']=true
                            this.disabled[o.key]=true
                            // this.formData['account_domain']=this.formData['name']
                        })
                    }
                }
            })    
        }
        if(this.accountData){
            console.log(this.accountData)
            Object.keys(this.accountData).map(data=>{
                if(data=="CSM" && this.accountData["CSM"]){
                    console.log(this.accountData["CSM"],"sdasd")
                    this.formData["CSM"]=Array.isArray(this.accountData["CSM"])?this.accountData["CSM"]:[]

                }   
                else if(this.formData[data]!=undefined){
                    this.formData[data]=this.accountData[data]
                    // this.formData['account_domain']=this.accountData['domain']

                }
            })
        }
        console.log(this.formData)
    },
    props:{
        accountData:{type:Object}
    },
    data(){
        return{
            formData:{
                account_id:"",
                name:'',
                allUsers:[],
                account_domain:'',
                acv:'',
                arr:'',
                renewalDate:'',
                CSM:[],
                sponsorEmail:'',
                championEmail:'',
            },
            error:{},
            disabled:{
                
            }
          }  
    },
    methods:{
        truncateText(text) {
      const maxLength = 7; // Adjust the maximum length according to your preference
      return text&&text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    },
        async submitForm(){
            const data=await Server.updateAccountData(this.formData)
            console.log(data)
            if(data.code==200){
                this.$toasted.success("🙌 Data updated successfully")
            }else{
                this.$toasted.error(" Error while updating data ")

            }
            this.$emit("close")
        },
        onChange(event){
            console.log(event)
            if(!event.toString().includes(".")){
                this.error['account_domain']="Please enter a valid domain"
            }else{
                this.error['account_domain']=''
            }
        }
    }
}
</script>
<style lang="scss">
    
</style>