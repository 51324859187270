<template>
    <div class="email-template-configuration">
      <h2 style="text-align: center;margin-top: 20px;margin-bottom: 4px;">
        Email Template
      </h2>
      <img @click="cancel()" src="../assets/img/feed_close.svg" style="cursor: pointer; font-size: 25px;position: absolute;right: 0px;top: 0px;" alt="">
      <!-- <div class="tabs">
        <button class="tab" @click="selectTab('configuration')">Configuration</button>
      </div> -->
  
      <div v-if="selectedTab === 'configuration' && !loading" class="configuration">
        <div style="display: flex;align-items: center;justify-content: space-around;" class="category">
            <label style="font-size: 14px;font-weight: 600;">
              <input type="radio" name="templateType" value="text" v-model="templateType" />
              Text-based template 
            </label>
            <label style="font-size: 14px;font-weight: 600;">
              <input type="radio" name="templateType" value="cgpt" v-model="templateType" />
              CGPT prompt template
            </label>
          </div>
        <div class="testimonials">
          <div class="cc-bcc">
            <!-- <input type="text" placeholder="CC (comma separated emails)" v-model="ccEmails" /> -->
            <input type="text" placeholder="Enter Your Template Name" v-model="name" />
          </div>
          <!-- <p><input v-model="description" type="text" style="outline: none; border: none; border-bottom: 1px solid rgb(177, 177, 177);"></p> -->
          <p><textarea placeholder="Enter Description" v-model="description" name="" id="" style="width: 100%; border: 1px solid #ddd;
    border-radius: 4px; padding: 10px;" rows="2"></textarea></p>
        </div>
  
        <div class="email-form">
          
          <div v-if="templateType=='text'" style="height: 49vh; overflow: scroll;">
            <div class="cc-bcc">
              <label>Subject : </label>
            <!-- <input type="text" placeholder="CC (comma separated emails)" v-model="ccEmails" /> -->
              <input type="text" placeholder="Subject" v-model="bccEmails" />
          </div>
          <div class="email-body">
            <label>Body : 

              <div @click="openReadMore" style="margin-top: 20px;font-size: 8px;color: blue;cursor: pointer;" class="">Read More?</div>

            </label>

            <textarea rows="12" v-model="emailContent">{{ defaultEmailContent }}</textarea>
          </div>
          <div style="display: flex;align-items: center;">
            Dynamic Variable <img v-tooltip="'Insert Dynamic Variable'" src="../assets/brackets.png" style="height: 22px;margin-left: 10px; cursor: pointer;" @click="openMoreOptions=!openMoreOptions;openSuggestType('user')" alt="">
          </div>
          <div v-if="openMoreOptions" @click="openMoreOptions=false;" style="position: fixed; top: 0%; left: 0%; height: 100%; width: 100%;">
            <div style="height: 34vh; border: 1px solid #b3b3b3; box-shadow: 0 2px 10px rgba(0,0,0,0.1); border-radius: 6px; width: 24vw; position: fixed; left: 21%; bottom: 17%; background: aliceblue;">
              <div style="display: flex; background: white; height: 45px; border-radius: 6px 6px 0px 0px;" @click.stop>
                <div @click="openSuggestType('contacts')" style="cursor: pointer; padding-bottom: 5px; margin: 10px; margin-bottom: 0px; font-size: 16px; color: black;" :style="{'border-bottom': (selectedSuggestType=='contacts')?'3px solid blue':'none', 'font-weight': (selectedSuggestType=='contacts')?'600':''}">Contacts</div>
                <div @click="openSuggestType('user')" style="cursor: pointer; padding-bottom: 5px; margin: 10px; margin-bottom: 0px; font-size: 16px; color: black;" :style="{'border-bottom': (selectedSuggestType=='user')?'3px solid blue':'none', 'font-weight':(selectedSuggestType=='user')? '600':''}">User</div>
              </div>
              <div style="height: 28vh; overflow-x: hidden; overflow-y: scroll;">
                <div v-for="item in suggestedOptions" @click="handleItemFromSuggestion(item.key, 'text')" style="display: flex; cursor: pointer; align-items: center; margin: 10px; padding: 10px; font-size: 16px;">
                <!-- <div style="flex: 0.05;"></div> -->
                <div style="color: blue; font-weight: 600; flex: 0.4;">
                  {{ item.key }}
                </div>
                <div style="flex: 0.2;"></div>
                <div style="font-weight: 600; flex: 0.4;">
                  {{ item.value }}
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
          <div v-else style="height: 49vh;">
            <div class="email-body">
            <textarea rows="12" v-model="prompt">{{ prompt }}</textarea>
          </div>
          <div v-if="openMoreOptions" @click="openMoreOptions=false;" style="position: fixed; top: 0%; left: 0%; height: 100%; width: 100%;">
            <div style="height: 34vh; border: 1px solid #b3b3b3; box-shadow: 0 2px 10px rgba(0,0,0,0.1); border-radius: 6px; width: 24vw; position: fixed; left: 21%; bottom: 17%; background: aliceblue;">
              <div style="display: flex; background: white; height: 45px; border-radius: 6px 6px 0px 0px;" @click.stop>
                <div @click="openSuggestType('contacts')" style="cursor: pointer; padding-bottom: 5px; margin: 10px; margin-bottom: 0px; font-size: 16px; color: black;" :style="{'border-bottom': (selectedSuggestType=='contacts')?'3px solid blue':'none', 'font-weight': (selectedSuggestType=='contacts')?'600':''}">Contacts</div>
                <div @click="openSuggestType('user')" style="cursor: pointer; padding-bottom: 5px; margin: 10px; margin-bottom: 0px; font-size: 16px; color: black;" :style="{'border-bottom': (selectedSuggestType=='user')?'3px solid blue':'none', 'font-weight':(selectedSuggestType=='user')? '600':''}">User</div>
              </div>
              <div style="height: 28vh; overflow-x: hidden; overflow-y: scroll;">
                <div v-for="item in suggestedOptions" @click="handleItemFromSuggestion(item.key, 'ai')" style="display: flex; cursor: pointer; align-items: center; margin: 10px; padding: 10px; font-size: 16px;">
                <!-- <div style="flex: 0.05;"></div> -->
                <div style="color: blue; font-weight: 600; flex: 0.4;">
                  {{ item.key }}
                </div>
                <div style="flex: 0.2;"></div>
                <div style="font-weight: 600; flex: 0.4;">
                  {{ item.value }}
                </div>
              </div>
              </div>
            </div>
          </div>
          <div style="display: flex;align-items: center;">
            Dynamic Variable <img v-tooltip="'Insert Dynamic Variable'" src="../assets/brackets.png" style="height: 22px;margin-left: 10px; cursor: pointer;" @click="openMoreOptions=!openMoreOptions;openSuggestType('user')" alt="">
          </div>
          </div>
  
          <div class="actions">
            <button @click="cancel" style="background-color: white; color: black; border: 1px solid #5f5f5f;">Cancel</button>
            <button @click="save">Save</button>
          </div>
        </div>
      </div>
      <div v-else-if="selectTab === 'configuration' && loading" style="display: flex; justify-content: center; align-items: center;">
        <img src="../assets/loadingRipple.svg" style="height: 200px;" alt="">
      </div>
  

      <!-- Logs tab content would go here -->
  
    </div>
  </template>
  
  <script>
  import Server from "./API/Server";
  export default {
    data() {
      return {
        selectedTab: 'configuration',
        templateType: 'text',
        openMoreOptions: false,
        selectedSuggestType: 'user',
        suggestedOptions: [],
        editingStatus: false,
        name: '',
        description: '',
        loading: false,
        ccEmails: '',
        bccEmails: '',
        emailContent: ``,
        prompt: '',
        promptId: '',
        defaultEmailContent: ''
      };
    },
    props: {
      promptdata: {},
      isEditing: false
    },
    methods: {
      openReadMore(){
        window.open("https://help.appeq.ai/en/articles/9013085-email-assist")
      },
      selectTab(tab) {
        this.selectedTab = tab;
      },
      handleItemFromSuggestion(val, type){
        if(type=='ai'){
          this.prompt += val;
        }else{
          if(!this.emailContent || !this.defaultEmailContent){
            this.defaultEmailContent = val;
            this.emailContent = val;
          }else{
            this.defaultEmailContent += val;
            this.emailContent += val;
          }
        }
        this.openMoreOptions = false;
      },
      async openSuggestType(val){
        if(val == 'user'){
          this.suggestedOptions = [
          {key: '{{first_name}}', value: 'Rajat'},
          {key: '{{last_name}}', value: 'Shukla'},
          {key: '{{company}}', value: 'AppEQ.ai'},
          {key: '{{email}}', value: 'rajat@appeq.ai'},
          {key: '{{domain}}', value: 'appeq.ai'}
          ];
        }else{
          let data = await Server.getConfiguratorKeys('Contacts');
          console.log(data);
          if(data.data.fields){
            this.suggestedOptions = [];
            for(let i=0;i<data.data.fields.length;i++){
              this.suggestedOptions.push({key: '{{'+data.data.fields[i].key+'}}'});
            }
          }
          // this.suggestedOptions = [];
        }
        this.selectedSuggestType = val;
      },
      toggleCCBCC() {
        
        // Logic to toggle CC/BCC fields
      },
      cancel() {
        // Logic to handle cancel
        this.$emit("close")
      },
      async save() {
        // Logic to handle save
        if(!this.templateType || !this.name || !this.description){
          return;
        }
        if(this.templateType=='text'){
          if(!this.bccEmails || !this.emailContent){
            return;
          }
          let data = {
            'templateSubject': this.bccEmails,
            'templateBody': this.emailContent
          };
          this.loading = true;
          let response = {};
          if(this.editingStatus){
            response = await Server.savePrompt(this.name, this.description, this.templateType,data, this.promptId,  true);
          }else{
            response = await Server.savePrompt(this.name, this.description, this.templateType,data);
          }
          console.log(response);
          if(response.code == 200){
            this.loading = false;
            this.$toasted.success('Successfully saved your template');
            this.$emit('close');
          }
        }else{
          if(!this.prompt){
            return;
          }
          let data = {
            'prompt': this.prompt
          };
          this.loading = true;
          let response = {};
          if(this.editingStatus){
            response = await Server.savePrompt(this.name, this.description, this.templateType,data, this.promptId, true);
          }else{
            response = await Server.savePrompt(this.name, this.description, this.templateType,data);
          }
          console.log(response);
          if(response.code == 200){
            this.loading = false;
            this.$toasted.success('Successfully saved your template');
            this.$emit('close');
          }
        }
      }
    },
    mounted() {
      if(this.promptdata!={}){
        if(this.isEditing){
          this.editingStatus = this.isEditing;
        }
        this.name = this.promptdata.name;
        if(this.promptdata.promtType == 'CGPT'){
          this.templateType = 'cgpt';
          this.prompt = this.promptdata.prompt;
          this.promptId = this.promptdata.promptId;
          this.description = this.promptdata.description;
        }else{
          this.templateType = 'text';
          this.bccEmails = this.promptdata.templateSubject;
          this.defaultEmailContent = this.promptdata.templateBody;
          this.emailContent = this.promptdata.templateBody;
          this.promptId = this.promptdata.promptId;
          this.description = this.promptdata.description;
        }
      }
      this.defaultEmailContent = this.emailContent;
      setTimeout(()=>{
            document.querySelector(".email-template-configuration").style.right="0px"

        },200)
    }
  };
  </script>
  
  <style scoped>
.email-template-configuration {
  font-family: 'Arial', sans-serif;
  margin: 0px auto;
  z-index: 88888;
  transition: right 1s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.11) 0px -1px 1px, rgba(0, 0, 0, 0.11) 0px -2px 2px, rgba(0, 0, 0, 0.11) 0px -4px 4px, rgba(0, 0, 0, 0.11) 0px -6px 8px, rgba(0, 0, 0, 0.11) 0px -8px 16px;
  border: 1px solid rgb(236, 236, 236);
  background: #fff;
  border-radius: 8px;
  position: fixed;
  right: -80vw;
  width: 80vw;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  overflow: hidden;
  height: 100vh;
  top: 0px;
  overflow-y: scroll;
}

.tabs {
  display: flex;
  color: black !important;
}

.tab {
  flex: 1;
  padding: 10px;
  color: black !important;
  text-align: center;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: #e6e6e6;
}

.configuration {
  padding: 45px;
}

.testimonials {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.testimonials h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

.testimonials p {
  font-size: 14px;
  color: #666;
}

.category label {
  display: block;
  margin: 10px 0;
}

.category input[type='radio'] {
  margin-right: 10px;
}

.cc-bcc {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 12px 0;
  label{
    flex: 0.05;
  }
}

.cc-bcc input[type='text'] {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 0px;
}
.email-body{
  display: flex;
  align-items: center;
  label{
    flex: 0.05;
  }
}
.email-body textarea {
  padding: 10px;
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 150px;
  font-size: 14px;
  margin-left: 0px;

  margin-bottom: 20px;
}

.actions {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.actions button {
  margin-left: 10px;
  height: 35px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 120px;
  transition: background-color 0.3s;
}
.cancelBtn{
  border: 1px solid #4e5fff;
}

.actions button:hover {
  opacity: 0.7;
}

button:focus {
  outline: none;
}

button:active {
  transform: scale(0.98);
}

/* Specific button styles */
button {
  color: white;
}

button:nth-child(1) {
}

button:nth-child(2) {
}

</style>
  