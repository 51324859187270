<template>
    <div v-if="!loading" class="card mycard">
        <vue-confirm-dialog></vue-confirm-dialog>

        <h2 id="nudge_setting_header" style="text-align: center;margin-top:10px;margin-bottom: 4px;">Nudge Settings</h2>
        <span style="text-align: center;font-size: 14px;display: flex;align-items: center;justify-content: center;">Your
            users will be nudged based on below configurations in the notification icon &nbsp;
            <span class="material-symbols-outlined">
                notifications
            </span>
        </span>
        <div>
            <div style="display: flex; justify-content: space-evenly; width: 30%; margin-top: 2rem;">
                <div @click="changeTab('custom')" :style="{'background': (activeTab == 'custom') ? '#5a5af7':'#b7b3b3', 'color': (activeTab == 'custom') ? 'white':'black'}" style="padding: 3px 8px; border-radius: 6px 6px 0px 0px; cursor: pointer;">
                    Custom
                </div>
                <div @click="changeTab('system')" :style="{'background': (activeTab == 'system') ? '#5a5af7':'#b7b3b3', 'color': (activeTab == 'system') ? 'white':'black'}" style="padding: 3px 8px; border-radius: 6px 6px 0px 0px; cursor: pointer;">
                    System
                </div>
            </div>
            <div v-if="activeTab=='system'" style="box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1); border-radius: 0.2857rem; margin-left: 1.3rem; padding: 2rem;">
                <!-- <div v-if="activeTab=='custom'">Custom</div> -->
                <div style="height: 75vh;">
                    <SystemNudge :notificationSetting="notificationSettings"/>
                </div>
            </div>
        </div>

        <div class="notification__body" style="margin-top: 0px;" v-if="activeTab=='custom'">

            <div style="margin-left: 20px;">
                <div style="margin-bottom: 5px; display: none;" class="card mycard">
                    <h4 id="nudge_setting_system_generated">System Nudge</h4>
                    <div style="display: flex;justify-content: space-between;width: 100%;">

                        <div class="notification--tile">
                            <div style="display: flex;align-items: center;">
                                <div style="color:blue;font-size:16px;display:flex;align-items:center;margin-right: 10px;">
                                    [
                                    <div
                                        style="background:red;color:red;border-radius:50%;width:13px;font-size:9px;margin-top:1px;">
                                        R</div>
                                    ]
                                </div>
                                Enable Red Account Notifications

                            </div>
                            <VueToggles id="healthChange" style="margin-left: 20px;" height="25" width="60" checkedText="On"
                                uncheckedText="Off" checkedBg="#5850ec" uncheckedBg="lightgrey"
                                :value="notificationSettings['healthChange']"
                                @click="notificationSettings['healthChange'] = !notificationSettings['healthChange'];startEditing=true;" />
                        </div>
                        <div style="padding-left: 40px;width: 44%;" class="notification--tile">
                            <div>

                                Enable Account Pulse
                                <div v-if="notificationSettings['pulseSettingChange']" style="font-size: 11px;">
                                    Nudge will be generated every <input type="number" style="width: 10%;"
                                        placeholder="enter days" v-model="notificationSettings['pulseTime']" /> days
                                </div>
                            </div>
                            <VueToggles id="pulseSettings" style="margin-left: 20px;" height="25" width="60"
                                checkedText="On" uncheckedText="Off" checkedBg="#5850ec" uncheckedBg="lightgrey"
                                :value="notificationSettings['pulseSettingChange']" @click="changePulse" />
                        </div>
                    </div>
                    <div v-if="startEditing" style="display: flex;justify-content: flex-end;">
                        <button @click="saveNotifications()"
                            style="width: 120px;height: 35px;border-radius: 20px;margin-top: 20px;"> Save </button>

                    </div>
                </div>
                <div class="card mycard">
                    <h4 id="nudge_setting_user_generated" style="display: none;">Custom Nudge</h4>

                    <button @click="openAddNotificationModal = true;isEditing=false;editingIndex=-1;isEditingTask=-1; pageOpen = 0; tempName = ''; tempMsg = ''; toDos = []; tempQuery = [{
                        'business_object_name': '',
                        'keys': '',
                        'operetor': '',
                        'value': '',
                        'desc': ''
                    }];"
                        style="width: 150px;height: 30px;position: absolute;right: 30px;margin-top: 40px;border-radius: 5px;">
                        <i style="padding-bottom: 2px;margin-right: 5px;" class="tim-icons icon-simple-add"></i>

                        Add
                    </button>
                    <table style="margin-top: 80px;" class="table tablesorter">
                        <thead>
                            <slot name="columns">
                                <th v-for="column in columns" :key="column">{{ column }}</th>
                            </slot>
                        </thead>
                        <tbody>

                            <tr v-for="row, i in notificationSettings.customNudges">
                                <td v-for="col in columns">
                                    <div v-if="col == 'Description'">
                                        {{ row['customMsg'] }}
                                    </div>
                                    <div v-if="col == 'Name'">
                                        {{ row['name'] }}
                                    </div>
                                    <div v-if="col == 'Last Updated By'">
                                        {{ row['email'] }}
                                    </div>
                                    <div v-if="col == 'Actions'">
                                        <i v-tooltip="'Delete Entry'" @click="handleDelete(i)"
                                            style="font-size: 18px;cursor: pointer;color: red;"
                                            class="tim-icons icon-trash-simple"></i>
                                        <i v-tooltip="'Edit Entry'"
                                            style="font-size: 18px;cursor: pointer;margin-left: 10px;"
                                            v-tooltip.auto-end="'Click to Edit '" @click="openModal(i)"
                                            class="tim-icons icon-pencil"></i>

                                    </div>
                                    <div v-if="col == 'Status'">
                                        <VueToggles height="25" width="60" checkedText="On" uncheckedText="Off"
                                            checkedBg="#5850ec" uncheckedBg="lightgrey" :value="row['active']"
                                            @click="handleClickCustomNudge(i)" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;"
                        v-if="notificationSettings.customNudges.length == 0">
                        <img style="width:300px;display: flex;align-items: center;justify-content: center;"
                            src="../assets/nodata.svg" />

                        <button @click="openAddNotificationModal = true; tempName = ''" style="height: 30px;width: 40%;">
                            <i class="tim-icons icon-simple-add"></i>

                            Add Custom Nudge</button>

                    </div>


                </div>

            </div>
            <br />
            <br />
            <br />
            <br />
        </div>
        <Modal class="add_notification_modal" v-if="openAddNotificationModal"
            :show="openAddNotificationModal">
            <i @click="handleClose()" class="tim-icons close-icon icon-simple-remove"></i>
            <h2 id="nudge_setting_custom_nudge">Add / Edit Nudge</h2>
            <div v-if="pageOpen == 0">

                <div style="display: flex;justify-content:flex-start;align-items: center;margin-top: 30px;width: 100%;">
                    <div
                        style="width: 45%;display: flex;justify-content: flex-start;align-items: flex-start;margin-right: 90px;">
                        <span style="margin-left: 20px;" class="mylabel">Name&nbsp;</span>
                        <div style="width: 80%">
                            <input style="width: 90%;margin-left: 10px;" v-model="tempName" placeholder="Enter Name" />
                        </div>
                       
                    </div>
                    <div style="display: flex; width: 52%; justify-content: space-evenly;">
                            <span class="mylabel">Trigger Type</span>
                            <span style="display: flex; justify-content: space-around; width: 40%;">
                                <span><input type="radio" name="trigger" id="Auto" v-model="triggerType" value="auto"> Auto</span>
                                <span><input type="radio" name="trigger" id="Manual" v-model="triggerType" value="manual"> Manual</span>
                            </span>
                        </div>



                </div>
                <div style="height: 30vh;">
                    <img v-if="triggerType=='manual'" style="height: 350px;position: absolute;left: 35%;" src="../assets/manual.svg" />
                    <span v-if="!triggerType || triggerType=='auto'">
                    <div
                        style="width: 47%;display: flex;justify-content: flex-start;align-items: flex-start;align-items: center; margin-top: 2rem;">

                        <span class="mylabel" style="width: 25%;">Business Object&nbsp;</span>

                        <Multiselect style="margin-left: 10px;" openDirection="below"
                            @select="handleBusinessObjectSelectTemp()" :hide-selected="true"
                            v-model="tempQuery[0]['business_object_name']" :options="bObjectList" />
                    </div>
                <br />
                    <span style="
        display: flex;
        text-align: center;
        font-weight: 600;
        align-items: center;
        justify-content: center;">Define Nudge Criteria</span>
        <div class="tempQueryWrapper">



<div v-for="not, index of tempQuery" class="row">
    <div style="width: 30%;">
        <span>Property (Select a function or key)</span>
        <i style="margin-left: 90px;" class="tim-icons icon-alert-circle-exc iconIsActive"></i>
        <div style="display: flex;align-items: center;position: relative;">
            <!-- <Multiselect @select="handleSelectFunctions()" class="noborderRight"  style="width: 95px !important;border-right: 0px !important;" placeholder="FN" openDirection="below" tagPosition="top"   :hide-selected="true"
        v-model="tempFu" :options="['FX','Keys']" /> -->
            <!-- class="noborderLeft"/ -->
            <Multiselect v-if="!isFields" openDirection="below" @select="handleSeletcKeysTemp(index)"
                :hide-selected="true" v-model="tempQuery[index]['keys']" :options="tempKeys" />
            <Multiselect v-else v-model="tempQuery[index]['keys']" openDirection="below" @select="handleSeletcKeysTemp(index)"
             label="label" :taggable="true" :searchable="true" :hide-selected="true" :options="tempKeys" /> 
        </div>

    </div>
    <div style="width: 30%;">
        <span>Operator</span>
        <Multiselect openDirection="below" :hide-selected="true" label="label"
            v-model="tempQuery[index]['operetor']" :options="tempOps" />
    </div>
    <div style="width: 30%;">
        <span>Value</span>
        <Multiselect @tag="addTag($event, index)" :taggable="true" openDirection="below"
            :hide-selected="true" v-model="tempQuery[index]['value']" :options="tempValues[index]" />
    </div>
    <div style="margin-top: 12px;" @click="handleRemoveClick(index)">
        <img src="../assets/img/feed_close.svg" style="cursor: pointer;" />
    </div>
</div>
<div @click="addRowTemp" class="lastrow">
    <span class="material-symbols-outlined">
        add
    </span>
    Add
</div>
</div>
                </span>
                </div>
                
                <p style="color: red;margin-left: 10px;margin-bottom: 0px;">{{ error.name }} </p>
            </div>
            <div v-if="pageOpen == 1">
                <div style="display: flex;justify-content:flex-start;align-items: center;width: 100%;">
                    <div
                        style="width:80%;display: flex;justify-content: flex-start;align-items: flex-start;flex-direction: column;">
                        <span class="mylabel">Nudge Objective &nbsp;</span>
                        <div style="width: 95%">
                            <textarea rows="2" style="width: 100%;margin-left: 1px;" v-model="tempMsg"
                                placeholder="Enter the business objective which will be met if this nudges and corresponding actions are followed." />
                        </div>


                    </div>

                </div>
                <div>
                    <span style="font-size: 17px;" class="mylabel">Playbook</span>
                    <div style="display: flex;align-items: center; height: 35px;">
                        <span class="mylabel">Notifications</span>
                        <input v-model="inapp" disabled style="margin: 20px;" type="checkbox" />In-App

                    </div>
                    <!-- <div style="display: flex; align-items: center; height: 35px; margin-bottom: 10px;">
                        <span style="font-size: 14px; font-weight: 700;">Action</span>
                        <span class="actionType_multiselect" style="display: flex; margin-left: 20px;">
                            <Multiselect :options="['Redirect', 'View']" v-model="actionType" placeholder="Select action" />
                            <span v-if="actionType=='Redirect'" style="display: flex; align-items: center; font-size: 17px;"><input type="radio" v-model="selectedBObj" name="selectedBObj"  :value="optionBObject" id="" style="margin-left: 20px; margin-right: 5px; height: 30px;"> {{ optionBObject }}</span>
                            <span v-if="actionType=='Redirect'" style="display: flex; align-items: center; font-size: 17px;"><input type="radio"  v-model="selectedBObj"  :value="tempQuery[0]['business_object_name']" id="" style="margin-left: 20px; margin-right: 5px; height: 30px;"> {{ tempQuery[0]['business_object_name'] }}</span>
                       
                        </span>
                    </div> -->
                </div>
                <div>
                    <span style="display: flex;" class="mylabel">TO DO
                        <div v-if="!addTodo" class="addTasksBtn" @click="addTodo = !addTodo;isEditingTask=-1; name = ''; desc = ''; due_date = ''; recurring_date = '';"
                            style="display: flex;align-items: center;border: 1px solid #4e5fff;border-radius: 20px;padding: 2px 10px;margin-left: 20px;">
                            <span style="cursor: pointer;font-size: 14px;" class="material-symbols-outlined">
                                add
                            </span> <span style="cursor: pointer;font-size: 10px;">Add Tasks</span>
                        </div>
                        <div v-if="addTodo" class="addTasksBtn" @click="addTodo = !addTodo; name = ''; desc = ''; due_date = ''; recurring_date = '';"
                            style="display: flex;align-items: center;border: 1px solid #4e5fff;border-radius: 20px;padding: 2px 10px;margin-left: 20px;">
                            <span style="cursor: pointer;font-size: 14px;" class="material-symbols-outlined">
                                close
                            </span> <span style="cursor: pointer;font-size: 10px;">Close Tasks</span>
                        </div>
                    </span>
                    <div v-if="addTodo" style="height: 250px;overflow: scroll;">
                        <div style="display: flex; align-items: center;justify-content: space-between;">
                            <div style="width: 47%;">
                                <span>Title</span>
                                <br />
                                <br />
                                <input :class="{ 'required-field': !name && showErr }" id="name" required v-model="name" placeholder="Enter task name"
                                    style="width: 100%;border: none;border-bottom: 1px solid #e8e8e8;" type="textarea" />
                            </div>
                            <div style="width: 47%;">
                                <span>Description</span>
                                <br />
                                <textarea  :class="{ 'required-field': !desc && showErr }" id="desc" v-model="desc" placeholder="Enter task description"
                                    style="width: 100%;border: none;border-bottom: 1px solid #e8e8e8;" type="textarea" />
                            </div>

                        </div>
                        <div style="display: flex;align-items: center; margin-top: 0.7rem;" class="task_desc_external">
                            <div style="margin-right: 15px; width: 30%;">
                                <span style="display: block;">Task Type</span>
                                <Multiselect openDirection="below" v-model="type" placeholder="Select the type of task"
                                    :options="['EMAIL', 'CALL', 'TODO','MEETING','BUSINESS REVIEW']" />
                            </div>
                            <div style=" margin-right: 15px; width: 30%;" >
                                <span style="display: block;">Relative Due date</span>
                                <input id="duedate" v-model="due_date" style="width: 100%;margin-top: 30px;" type="number"
                                    placeholder="Number of days relative to trigger date" />
                            </div>
                            <div style="margin-left: 15px; margin-right: 5px; width: 40%;" >
                                <span style="display: block;">Recurring</span>
                                <input v-model="recurring_date" style="width: 100%;margin-top: 30px;" type="number"
                                    placeholder="Enter number of days or leave blank if not recurring" />
                            </div>
                        </div>
                        <div style="display: flex;align-items: center; margin-top: 0.7rem;">
                            <h4 style="margin-bottom: 0px;">Assign to</h4>
                            <div style="display: flex;align-items: center;margin-left: 10px;">
                                <div style="margin-left: 10px;display: flex;align-items: center;">
                                    <input v-model="selectedUserType" value="CSM" name="selectedUser" type="radio" />
                                    <span style="margin-left: 4px;">CSM</span>
                                </div>
                                <div style="margin-left: 10px;display: flex;align-items: center;">
                                    <input v-model="selectedUserType" value="NA" name="selectedUser" type="radio" />
                                    <span style="margin-left: 4px;">Unassigned</span>
                                </div>

                                <select style="    background: transparent;
    border: none;
    margin-left: 10px;" v-if="selectedUserType == 'User'">
                                    <option>adasd</option>
                                </select>
                            </div>
                        </div>

                    </div>
                    <div v-if="!addTodo" style="height: 250px;overflow: scroll;">
                        <table style="margin-top:20px" class="table tablesorter">
                            <thead>
                                <slot name="columns">
                                    <th v-for="column in ['ID', 'Name', 'Desc', 'Type','Due Date', 'Action']" :key="column">
                                        <span :id="'user_' + column">
                                            {{ column }}
                                        </span>
                                    </th>
                                </slot>
                            </thead>
                            <tbody>
                                <tr v-for="u, ind of toDos">
                                    <td v-for="column in ['ID', 'Name', 'Desc', 'Type','Due Date', 'Action']" :key="column">
                                        <div v-if="column == 'ID'">
                                            {{ ind + 1 }}
                                        </div>
                                        <div v-if="column == 'Name'">
                                            {{ u['task_name'] }}
                                        </div>
                                        <div v-if="column == 'Due Date'">
                                           T + {{ u['due_date'] }}
                                        </div>
                                        <div v-if="column == 'Desc'">
                                            {{ u.task_desc }}
                                        </div>
                                        <div v-if="column == 'Type'">
                                            {{ u.type }}
                                        </div>
                                        <div style="display: flex;align-items: center;" v-if="column == 'Action'">
                                            <img style="cursor:pointer" v-tooltip.bottom-end="'Edit Task'"
                                                @click="handleEdit(u, ind)" class="summary_action_visibility"
                                                src="../assets/edit.svg" />
                                            <span @click="deleteTasks(u, ind)"
                                                style="cursor: pointer;color: red;margin-left: 4px;"
                                                v-tooltip.bottom-end="'Delete Task'" class="material-symbols-outlined">
                                                delete
                                            </span>

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>


                    </div>

                </div>
            </div>
            <div style="display: flex;justify-content: flex-end;" :style="{'margin-top': this.pageOpen==0 && this.triggerType!='manual' ? '10rem' : '0'}">

                <div style="position: relative;height: 50px;margin-top: 30px;display: flex;align-items: end;">
                    <button v-if="pageOpen == 1" @click="handleClearClick" class="close_modal_notification">Prev</button>
                    <button @click="handleSaveClick" class="save_modal_notification">{{ addTodo && pageOpen == 1 ? 'Save Task':pageOpen==0?'Next':'Save'}}</button>
                </div>
            </div>




        </Modal>

    </div>
 
</template>
<script>
import Multiselect from 'vue-multiselect';
import Server from "../components/API/Server"
import moment from 'moment';
import { operetors } from './Functions';
import Modal from '../components/Modal.vue'
import LocalUtils from '../components/Utils/LocalUtils';
import SystemNudge from '../components/SystemNudge.vue';

export default {
    components: { Multiselect, Modal, SystemNudge },

    methods: {
        changeTab(tabName){
            console.log("asdsa")
            console.log(tabName)
            this.activeTab=tabName
        },
        deleteTasks(u, ind) {
            this.toDos.splice(ind, 1)
        },
        changePulse() {
            this.startEditing = true
            console.log("hekrher")
            this.notificationSettings['pulseSettingChange'] = !this.notificationSettings['pulseSettingChange']
        },
        handleEdit(u, indx) {
            this.addTodo = true
            this.isEditingTask = indx
            this.desc = u['task_desc']
            this.type = u['type']
            this.name = u['task_name']
            this.due_date = u['due_date']
            this.recurring_date = u['recurring_date']
            this.selectedUserType = u['user']
        },
        handleRemoveClick(i) {
            if (this.tempQuery.length == 1) {
                return;
            }
            this.tempQuery.splice(i, 1)
        },

        addTag(newTag, index) {
            const parts = newTag.split(', ');

            const tag = newTag;
            this.tempValues[index].push(tag);
            this.tempQuery[index]['value'] = tag
        },

        async openModal(i, edit) {
            this.tempName = this.notificationSettings.customNudges[i].name
            this.tempQuery = this.notificationSettings.customNudges[i].query
            this.tempMsg = this.notificationSettings.customNudges[i].customMsg
            this.toDos = this.notificationSettings.customNudges[i].tasks || []
            this.triggerType = this.notificationSettings.customNudges[i].trigger
            if(this.notificationSettings.customNudges[i].configureActions){
                this.actionType = this.notificationSettings.customNudges[i].configureActions.action;
                this.selectedBObj = this.notificationSettings.customNudges[i].configureActions.redirectTo;
            }

            this.pageOpen = 0;
            this.isEditing = true
            this.editingIndex = i
            this.tempValues = []
            this.oldNotific = { tempQuery: this.tempQuery, toDos: this.toDos, tempName: this.tempName, tempMsg: this.tempMsg }
            const data = await Server.getConfiguratorKeys(this.tempQuery[0]['business_object_name'])
            console.log(data);
            if(data.data.keys){
                this.tempKeys = (data.data.keys.map((d) => d));
            }
            await Promise.all(this.tempQuery.map(async (d, index) => {
                const res = await Server.getValue(d['keys'], d['business_object_name']);
                this.tempValues.push([...res.data])
                // this.tempValues[index] = [...res.data]


            }))
            this.openAddNotificationModal = true

            console.log(this.tempQuery)

        },
        async handleClickCustomNudge(i) {
            this.$confirm(
                    {
                        message: 'Are you sure you want to change the status?',
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        callback: async confirm => {
                            if (confirm) {
                                const data = await Server.saveNotificationSettings(this.notificationSettings);
                                this.notificationSettings['customNudges'][i]['active'] = !this.notificationSettings['customNudges'][i]['active']

                            } else {
                            }
                        }
                    });

        },
        async saveNotifications() {
            const data = await Server.saveNotificationSettings(this.notificationSettings);
            this.startEditing = false
        },
        async handleDelete(i) {
            try {
                this.notificationSettings.customNudges.splice(i, 1)
                const data = await Server.saveNotificationSettings(this.notificationSettings);
                if (data.code == 200) {
                    this.$toasted.success("Deleted successfully")
                }
            } catch (error) {

            }
        },
        async handleSeletcKeys(index) {
            const res = await Server.getValue(this.notificationSettings['query'][index]['keys'], this.notificationSettings['query'][0]['business_object_name']);
            this.values[index] = [...res.data]
            console.log(this.values[index])
            var ans = false
            var dateFormat = 'DD-MM-YYYY';
            if (this.values) {
                this.values[index].map(data => {
                    console.log(data)
                    if (moment(moment(data).format(dateFormat), dateFormat, true).isValid()) {
                        ans = true;
                    }
                })
            }
            if (ans) {
                this.values[index].unshift("TODAY()")
            }
        },
        async handleSeletcKeysTemp(index) {
            const res = await Server.getValue(this.tempQuery[index]['keys'], this.tempQuery[0]['business_object_name']);
            this.tempValues[index] = [...res.data]
            this.tempQuery[index]['operetor']=''
            var ans = false
            var dateFormat = 'DD-MM-YYYY';

            if (this.tempValues) {
                this.tempValues[index].map(data => {
                    if (moment(moment(data).format(dateFormat), dateFormat, true).isValid()) {
                        ans = true;
                    }
                })
            }
            if (ans) {
                this.tempValues[index].unshift("TODAY()")
            }
        },
        async handleBusinessObjectSelect() {
            const data = await Server.getConfiguratorKeys(this.notificationSettings['query'][0]['business_object_name'])
            this.keys = (data.data.keys.map((d) => d));

        },
        async handleBusinessObjectSelectTemp() {
            const data = await Server.getConfiguratorKeys(this.tempQuery[0]['business_object_name'])
            if(data && data.data && data.data.fields && data.data.fields.length>0){
                this.tempKeys = data.data.fields;
                if(this.tempQuery[0]['keys']){
                        this.tempQuery[0]['keys']=this.tempKeys.filter(d=>d.key==this.tempQuery[0]['keys'])[0]
                }
                this.isFields = true;
                return;
            }
            if( data && data.data && data.data.keys)
            this.tempKeys = (data.data.keys.map((d) => d));
            if (this.tempQuery[0]['business_object_name'] == "ticket")
                this.tempKeys.unshift("AgeOfTickets")
            else if(this.tempQuery[0]["business_object_name"]=="Account_Pulse__c" ||this.tempQuery[0]["business_object_name"]=="Pulse__c" ){
                this.tempKeys.unshift("AgeOfLastPulse")
                this.tempKeys.unshift("Nudge trigger date")

            }
            if(this.tempQuery[0]['business_object_name']=='accounts'){
                this.tempKeys.unshift('Fx(open_tickets)');
            }
            

            if(this.tempQuery[0]['business_object_name']=='accounts'){
                this.tempKeys.unshift('Fx(transaction_drop_percentage)');
            }
            const email=LocalUtils.getEmail()
            if(email.split("@")[1]=="questionpro.com"){
                this.tempKeys.unshift('Fx(product_usage_drop)');

            }

        },

        async handleSelectFunctions() {
            if (this.tempFu == "FX") {
                this.tempKeys = ["AgeOfTickets"]
                const data = await Server.getFunction(this.tempQuery[0]['business_object_name'])
                data.data.map(d => {
                    this.tempKeys.push(d.function_name);
                })

            } else {
                const data = await Server.getConfiguratorKeys(this.tempQuery[0]['business_object_name'])
                this.tempKeys = (data.data.keys.map((d) => d));
            }
        },
        async addRow() {
            this.notificationSettings.query.push(
                {
                    business_object_name: this.notificationSettings['query'][0]['business_object_name'],
                    keys: '',
                    operetor: '',
                    value: ''
                }
            )
            this.values.push([])

        },
        async addRowTemp() {
            this.tempQuery.push(
                {
                    business_object_name: this.tempQuery[0]['business_object_name'],
                    keys: '',
                    operetor: '',
                    value: ''
                }
            )
            this.tempValues.push([]);

        },
        async saveChanges() {
            try {
                const data = await Server.saveNotificationSettings(this.notificationSettings)
                if (data.code == 200) {
                    this.$toasted.success("Successfully save notification settings")
                } else {
                    this.$toasted.error("Something went wrong ")
                }


            } catch (error) {
                this.$toasted.error("Something went wrong ")

            }
        },
        handleClose() {
                this.$confirm(
                    {
                        message: 'Are you sure you want to close without saving?',
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        callback: async confirm => {
                            if (confirm) {
                                this.openAddNotificationModal = false;

                            } else {
                            }
                        }
                    });
        },
        handleClearClick() {
            this.showErr=false;
            if(this.addTodo){
                this.addTodo=false;
                return;
            }
            this.pageOpen = 0;
        },
        async handleSaveClick() {
            if (this.pageOpen == 0) {
                this.pageOpen = 1;
                this.addTodo = false
                return;
            }
            if (this.addTodo) {
                if (  !this.name && !this.desc) {
                    this.showErr=true
                    this.$toasted.error("Task Cannot be empty please enter all fields")

                    return
                }
                if(!this.name){
                    this.showErr=true

                    this.$set("", "name", null); // Trigger reactivity
                    return
                }
                if(!this.desc){
                    this.showErr=true

                    this.$set("", "desc", null); // Trigger reactivity
                    return
                }
               
                this.addTodo = false
                if (this.isEditingTask != -1) {
                    this.toDos[this.isEditingTask] = {
                        task_desc: this.desc,
                        type: this.type,
                        task_name: this.name,
                        due_date: this.due_date,
                        recurring_date: this.recurring_date,
                        user: this.selectedUserType
                    }
                } else
                    this.toDos.push({
                        task_desc: this.desc,
                        type: this.type,
                        task_name: this.name,
                        due_date: this.due_date,
                        recurring_date: this.recurring_date,
                        user: this.selectedUserType
                    })
                this.$toasted.success("Added todo successfully")
                return;

            }
            console.log(this.selectedBObj);
            console.log(this.actionType);
            if (!this.tempName || !this.tempMsg || !this.actionType) {
                this.$toasted.error("Sorry,Notification cannnot be empty please configure something ")
                return;
            }

                if(this.tempQuery.filter(d=>!(d.value)).length>0 && this.triggerType!='manual'){
                    this.$toasted.error("Sorry,Notification cannnot be empty please configure something")
                    return;       
                }
                console.log(this.actionType);
                console.log(this.selectedBObj);
                let actionsData = {
                action: this.actionType,
                redirectTo: this.selectedBObj
            };
            console.log(actionsData)
            if (this.isEditing) {
                console.log(actionsData)
                this.notificationSettings.customNudges[this.editingIndex] = {
                    query: this.tempQuery,
                    customMsg: this.tempMsg,
                    name: this.tempName,
                    active: true,
                    tasks: this.toDos,
                    id: this.notificationSettings.customNudges.length,
                    trigger: this.triggerType,
                    configureActions: actionsData,
                    email: LocalUtils.getEmail()
                }
            } else{
                console.log(actionsData)
                this.notificationSettings.customNudges.push({
                    query: this.tempQuery,
                    customMsg: this.tempMsg,
                    name: this.tempName,
                    active: true,
                    tasks: this.toDos,
                    id: this.notificationSettings.customNudges.length,
                    trigger: this.triggerType,
                    configureActions: actionsData,
                    email: LocalUtils.getEmail()
                });
            }
               console.log(this.notificationSettings);
            const data = await Server.saveNotificationSettings(this.notificationSettings);
            this.openAddNotificationModal = false;
            this.triggerType = 'auto';
            this.actionType = 'View';
            var creds = JSON.parse(localStorage.getItem("ALL_CREDS"))
        console.log(creds);
        var flag = false;
        for(let i=0;i<creds.length;i++){
            if(creds[i].type && creds[i].type=='csm_account'){
                this.optionBObject = creds[i].business_object_name;
                flag = true;
                break;
            }
        }
        if(flag == false){
            this.optionBObject = 'accounts';
        }

        }
    },
    async mounted() {
        this.loading = true;
        const data = await Server.getNotificationSettings()
        var creds = JSON.parse(localStorage.getItem("ALL_CREDS"))
        console.log(creds);
        var flag = false;
        for(let i=0;i<creds.length;i++){
            if(creds[i].type && creds[i].type=='csm_account'){
                this.optionBObject = creds[i].business_object_name;
                flag = true;
                break;
            }
        }
        if(flag == false){
            this.optionBObject = 'accounts';
        }
        this.bObjectList = creds.map(data => data.business_object_name)
        if(this.bObjectList.length==0){
            this.bObjectList.push("accounts")
        }
        this.ops = operetors
        this.tempOps = operetors
        if (data && data.data && data.data.length > 0) {
            console.log(data.data)
            this.notificationSettings = { ...data.data[0].notificationSettings }
        }
        if (!this.notificationSettings['pulseSettingChange']) {
            this.notificationSettings['pulseSettingChange'] = false;
        }
        // if (this.notificationSettings['query'][0]['business_object_name']) {
        //     const data = await Server.getConfiguratorKeys(this.notificationSettings['query'][0]['business_object_name'])
        //     this.keys = (data.data.keys.map((d) => d).filter(d => d != null));
        // }
        
        // if (this.tempQuery[0]['business_object_name']) {
        //     const data = await Server.getConfiguratorKeys(this.tempQuery[0]['business_object_name'])
        //     this.tempKeys = (data.data.keys.map((d) => d).filter(d => d != null));
        // }


        if (!this.notificationSettings['pulseTime']) {
            this.notificationSettings['pulseTime'] = 14;
        }
        this.loading = false;

    },
    data: function () {
        return {
            
            optionBObject:'',
            keys: [],
            inapp: true,
            oldNotific: {},
            isEditingTask: -1,
            toDos: [],
            relativeDuedate: '',
            users: [],
            assignee: '',
            type: '',
            selectedUserType: '',
            name: '',
            addTodo: false,
            pageOpen: 0,
            isEditing: false,
            actionType: 'View',
            selectedBObj: '',
            tempKeys: [],
            isFields: false,
            tempFu: "Keys",
            ops: [],
            editingIndex: -1,
            tempOps: [],
            error: { name: '', msg: '' },
            values: [[]],
            desc: '',
            columns: ['Name', 'Description', 'Last Updated By', 'Status', 'Actions'],
            tempValues: [[]],
            tempName: '',
            tempMsg: '',
            openAddNotificationModal: false,
            loading: false,
            due_date: 0,
            recurring_date: '',
            tempQuery: [{
                business_object_name: '',
                keys: '',
                operetor: '',
                value: ''
            }],
            triggerType: 'auto',
            startEditing: false,
            notificationSettings: {
                showNotification: true,
                playSound: true,
                newTicket: false,
                pulseSettingChange: false,
                healthChange: true,
                customSettings: true,
                selectedCustomer: true,
                customMsg: '',
                query: [{
                    business_object_name: '',
                    keys: '',
                    operetor: '',
                    value: ''
                }],
                customNudges: [],
            },
            bObjectList: [],
            activeTab: 'custom', 
            showErr:false,
        }
    }
}
</script>
<style lang="scss" scoped>
.mycard {
    padding: 20px;
}

.notification {
    &__body {
        margin-top: 20px;
        width: 100%;

    }

    &--tile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        width: 40%;

        input {
            margin-right: 10px;
        }
    }
}

.notification__custom {
    margin-left: 30px;
    margin-top: 30px;
}

.row {
    margin-top: 20px;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-around;
}

.lastrow {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: blue;
    justify-content: flex-end;
    margin-bottom: 30px;
    float: right;
}

input {
    border: none;
    border-bottom: 1px solid #e8e8e8;
}

textarea {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
}

.add_notification_modal {
    .tempQueryWrapper {
        max-height: 280px;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 0px 0px 1px 1px rgb(229, 231, 255) inset;
        padding: 20px;
        border-radius: 5px;
    }

    .close_modal_notification,
    .save_modal_notification {
        width: 150px;
        border-radius: 5px;
        height: 45px;
        margin: 5px;
    }



}

input:focus {
    border: none;
    outline: none;
}

textarea:focus {
    border: none;
    outline: none;
}

.mylabel {
    font-size: 14px;
    font-weight: 700;
}

.addTasksBtn:hover {
    color: white;
    background-color: #4e5fff;
}

.addTasksBtn {
    color: #4e5fff
}</style>
<style>
.vc-text{
    color: black !important;
}
.task_desc_external{
    .multiselect__tags {
    width: 20rem;
    margin-left: 0rem;
}
.multiselect__select{
    right: 110px;
}
}
.actionType_multiselect{
    .multiselect{
        width: 300px !important;
    }
    .multiselect__tags{
        width: 300px;
        height: 25px;
        margin-bottom: 7px;
        min-height: 30px;
        display: flex;
    align-items: center;
    }
    .multiselect__placeholder{
        padding-top: 0px;
    }
    .multiselect__select{
        height: 33px;
    }
    .multiselect__single{
        line-height: 18px;
    }
}
.required-field{
    border-bottom: 1px solid red !important;
}
</style>