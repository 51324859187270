<template>
  <div>
    <!-- <DashboardLayout/> -->
    <router-view ></router-view>

  </div>
</template>

<script>
import DashboardLayout from './layout/dashboard/DashboardLayout.vue';

  export default {
    components:{
      DashboardLayout
    },
    methods: {
      disableRTL() {
        if (!this.$rtl.isRTL) {
          this.$rtl.disableRTL();
        }
      },
      toggleNavOpen() {
        let root = document.getElementsByTagName('html')[0];
        root.classList.toggle('nav-open');
      }
    },
    mounted() {
      // this.$watch('$route', this.disableRTL, { immediate: true });
      // this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
    }
  };
</script>

<style lang="scss">

.tooltip{
  margin: 10px;
}
  
</style>
