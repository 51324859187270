<template>

    <div style="display: flex;flex-direction: column;" class="">
        <h6 class="mt-4" v-if="!isNextPage">Enter your Tenant ID</h6>
        <div class="zendeskInputBox">
            <input placeholder="Enter Username" v-model="productUsage.tenant_id" />
        </div>
        <h6 class="mt-4" v-if="!isNextPage">Enter your Client ID</h6>

        <div class="zendeskInputBox">
            <input placeholder="Enter Secret Token" v-model="productUsage.client_id" />
        </div>
        <h6 class="mt-4" v-if="!isNextPage">Enter your Client Secret</h6>

        <div class="zendeskInputBox">
            <input placeholder="Enter Secret Token" v-model="productUsage.client_secret" />
        </div>
        <h6 class="mt-4" v-if="!isNextPage">Enter your URL</h6>

        <div class="zendeskInputBox">
            <input placeholder="Enter URL" v-model="productUsage.resource_url" />
        </div>
        <button v-if="!isLoading" @click="handleSaveCreds" style="margin-top: 20px;">Save</button>
        <div v-if="isLoading" class="loading">
        <img src="../assets/infinity_blue.svg" />
      </div>
    </div>
</template>

<script>
import Server from "./API/Server"
export default {
    data() {
        return {
            productUsage: {
                client_id: "",
                tenant_id: "",
                resource_url: "",
                client_secret: ""
            },
            isLoading:false,
        }
    },
    methods: {
        async handleSaveCreds() {
            try {
                this.isLoading=true;
            const data = await Server.authCrm(this.productUsage)
            if (data && data.code == 200) {
                this.$toasted.success(
                    "Please wait we will notify you once the data has been resynced via mail ."
                );
                this.selectedBuisnessObjectList = localStorage.getItem("Business_obj_name").split(',');

                await Promise.all(this.selectedBuisnessObjectList.map(async d => {
                    await Server.saveCrm(this.productUsage, d)
                }))
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
                mixpanel.businessObjectAdded(localStorage.getItem("Business_obj_name"));
            } else {
                this.$toasted.error("Auth Failed please try again !")
            }
        } catch (error) {
            
            this.$toasted.error("Auth Failed please try again!") 
            }finally{
                this.isLoading=false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.zendeskInputBox {
    border: 1px solid rgb(220, 226, 234);
    display: flex;
    height: 40px;
    align-items: center;

    input {
        width: 100%;
    }

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: none;
    }
}
</style>