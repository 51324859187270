import axios from "axios";
let apiUrl = 'https://api1.appeq.ai';

class AppEQAnalytics {
  async actionCall(event_name, event_type, custom_data, email_id) {
    const currentURL = window.location.href;
    const data = { agent: navigator.userAgent };
          try {
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/events/save`, {
              user_id: email_id? email_id : localStorage.getItem('__email__id'),
              event_name: event_name,
              event_type: event_type,
              product: "extension",
              source: currentURL,
              standard_data: data,
              custom_data: custom_data,
            });
            console.log("Analytics working")
          }
          catch (error) {
            console.error("Analytics error");
          }
  }
}


export default new AppEQAnalytics();
