<template>
  <div style="padding-bottom: 50px;width: 100%;">
    <table class="table tablesorter" :class="tableClass">
      <thead>
        <tr>
          <slot name="columns">
            <th v-for="column in columns" :key="column">
              <span :id="'data_user'+column">{{ column }}</span>
            </th>
          </slot>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index" :class="{ unlinkedRow: item.linked == 'No' }">
          <slot :row="item" v-if="item.linked == 'Yes'">

            <td style="text-transform:capitalize" v-for="(column, index) in columns" :key="index"
              v-if="hasValue(item, column)">
              <div v-if="column=='Source'" class="" >
              <img v-tooltip="item['appeq_supported_application']" v-if="column == 'Source' && item['source']=='spreadsheet'" style="height:26px;margin-right:10px" id ="spreed-target"
                  v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +'docs.google.com/spreadsheets/u/0/' + '.com&sz=48')"/>
                  <img v-tooltip="item['appeq_supported_application']" v-if="column == 'Source' && item['source'].toLowerCase().includes('api')" style="height:26px;margin-right:10px" src="../assets/img/API2.png" alt="">
                  <img v-tooltip="item['appeq_supported_application']" v-if="column == 'Source' && item['source'].toLowerCase().includes('appequpload')" style="height:26px;margin-right:10px" src="../assets/appeq.png" alt="">

              <img v-tooltip="item['appeq_supported_application']" v-if="column == 'Source' && item['source'] !='spreadsheet' && !item['source'].toLowerCase().includes('api') && !item['source'].toLowerCase().includes('appequpload')" style="height:26px;margin-right:10px"
                v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + (item['appeq_supported_application'].toLowerCase().includes('zoho') ? 'zoho' : item['appeq_supported_application']) + '.com&sz=48')" />
              <img  v-if="column == 'Source'" v-tooltip.bottom-end="'Open application'" @click="openApplication(item)" style="height:12px;opacity:0.7;margin-top:-2px;cursor: pointer;" src="../assets/redirect.png"/>

              </div>

                <template v-if="column=='Trends'">
                <span @click="openModalForTrending(item.business_object_name,item)" style="cursor:pointer;font-size: 22px;"
                                    class="material-symbols-outlined summary_action_visibility" v-tooltip="'Trends View'">
                                    trending_up
                                </span>
              </template>
              <template v-if="column=='Bi-Directional'">
                <div class="" style="    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
">
                <span v-if="item.business_object_name=='tasks'" style="cursor:pointer;font-size: 22px;"
                                    class="material-symbols-outlined summary_action_visibility" v-tooltip="'Bi-Directional'">
                                    compare_arrows
                </span>
                <span v-else style="cursor:pointer;font-size: 22px;"
                                    class="material-symbols-outlined summary_action_visibility" v-tooltip="'Uni-Directional'">
                  arrow_forward
                </span>
                <div @click="handleHubspot(item)" style="color: blue;font-size: 10px;cursor: pointer;" v-tooltip="'Re-Connect to enable Bi-Directional Sync'">
                  {{item['business_object_name']=='accounts' && item['appeq_supported_application']=='hubspot' ?'Re-Connect':''}}
                </div>
              </div>
              </template>
              <template v-else-if="column=='Added By'">
                <span style="text-transform: none !important;font-size: 14px;">{{ itemValue(item, column) }}</span>
              </template>
              <template v-else-if="column=='total data'">
                <span v-if="isLoadingData"><img src="../assets/loadingRipple.svg" style="height: 40px;" alt=""></span>
                <span v-else>{{ item['total data'] }}</span>
              </template>
              <template v-else-if="column!='Source'">
                {{ itemValue(item, column) }}
                <!-- <div style="display:flex">

                    <template v-if="column=='Business Object'">
                      <span @click="showBlinkModal = !showBlinkModal" class="red_blink_alert">r</span>
                    </template>
                </div>  -->
              </template>



              <i v-tooltip.bottom-end="'Configure Red Condition'"  style="cursor: pointer;" @click="handleRed(item['business object'])" v-if="column == 'Red condition'" :class="item['business object'].toLowerCase()=='opportunities' || item['business object'].toLowerCase()=='ticket' ?'red':'disred'" class="tim-icons icon-alert-circle-exc"></i>
              <template v-if="column == 'Action'">
                <div style="display: flex;align-items: center;">
                  <!-- <span class="material-symbols-outlined" @click="feedsConfiguration(item)" style="cursor: pointer; font-size: 21px; margin-right: 10px;" v-tooltip.bottom-end="'Feeds Configuration'">
                    settings
                  </span> -->
                  <!-- <img v-if="item.business_object_name=='Net Promoter Score'" @click="showMapModel=true;mappedFields=item.mappedFields;selectedBObjecte=item.business_object_name" v-tooltip.bottom-end="'Map Fields'"  src="../assets/visit.png" class="edit" style="margin-right: 10px;" />
                  <img v-if="item.business_object_name=='Account_Pulse__c'" @click="showMapModel=true;mappedFields=item.mappedFields;selectedBObjecte=item.business_object_name" v-tooltip.bottom-end="'Map Fields'"  src="../assets/visit.png" class="edit" style="margin-right: 10px;" />
                  <img v-if="item.business_object_name=='Pulse__c'" @click="showMapModel=true;mappedFields=item.mappedFields;selectedBObjecte=item.business_object_name" v-tooltip.bottom-end="'Map Fields'"  src="../assets/visit.png" class="edit" style="margin-right: 10px;" />
                  <img v-if="item.business_object_name=='Customer DB'" @click="showMapModel=true;mappedFields=item.mappedFields;selectedBObjecte=item.business_object_name" v-tooltip.bottom-end="'Map Fields'"  src="../assets/visit.png" class="edit" style="margin-right: 10px;" /> -->

                  <!-- <img v-if="item.type=='csm_account'" @click="showMapModel=true;mappedFields=item.mappedFields;selectedBObjecte=item.business_object_name" v-tooltip.bottom-end="'Map Fields'"  src="../assets/visit.png" class="edit" style="margin-right: 10px;" /> -->

                  <!-- <img v-if="item.business_object_name=='accounts'" @click="showMapModel=true;mappedFields=item.mappedFields;selectedBObjecte=item.business_object_name" v-tooltip.bottom-end="'Map Fields'"  src="../assets/visit.png" class="edit" style="margin-right: 10px;" /> -->
                <!-- <img v-tooltip.bottom-end="'Edit configuration'" v-if="isAdmin && item.appeq_supported_application=='spreadsheet'" class="edit" @click="editClicked(item)" src="../assets/edit.png" /> -->

                <!-- <img v-tooltip.bottom-end="'only Admin can configure'" v-if="!isAdmin" class="editFalse"  src="../assets/edit.png" /> -->
                <!-- <img  v-tooltip.bottom-end="'Edit Keys'"   @click="settingsClicked(item)" class="settings refresh" src="../assets/settings-gear-svgrepo-com.svg"/> -->


                <!-- <img v-tooltip.bottom-end="'Refresh Data'" @click="handleRefresh(item)" class="refresh" :class="{'loader':schedulerLoad && refreshedItem==item}" style="margin-left:15px"
                  src="../assets/icons8-available-updates-100.png" /> -->

                <img style="height: 15px !important;" v-tooltip.bottom-end="'Refresh Data'" class="reset" :style="{'margin-left': (item.appeq_supported_application=='spreadsheet') ? '0px' :'0px'}" @click="handleRefresh(item)" src="../assets/refresh.svg" />
                  <!-- <img v-tooltip.bottom-end="'ReAuth'"  @click="reAuth(item)" v-if="item.auth && item.auth=='expired'" class="expired" src="../assets/expired.svg" /> -->
                <!-- <i  v-if="isAdmin" v-tooltip.bottom-end="'Delete Source'"   class="delete tim-icons icon-trash-simple" @click="handleAction(item,'delete')" ></i> -->
                  <img src="../assets/ConfigureIcon.png" v-tooltip.bottom-end="'Configure'" style="height: 1.2vw; cursor: pointer; margin-left: 0.5vw;" @click="showConf=true;selectedObj=item['business_object_name']; selectedSource=item['source'];" alt="">
                </div>

              </template>


            </td>
          </slot>

          <slot :row="item" v-if="item.linked == 'No'">
            <td v-for="(column, index) in columns" :key="index" v-if="hasValue(item, column)">
              <img v-if="column == 'Source'" style="height:26px;margin-right:10px;object-fit: cover;"
                v-bind:src="('https://thumbs.dreamstime.com/b/placeholder-icon-vector-isolated-white-background-your-web-mobile-app-design-placeholder-logo-concept-placeholder-icon-134071364.jpg')" />

              {{ itemValue(item, column) }}
              <i v-if="column == 'Status'" class="tim-icons icon-refresh-02  status-icon-unlinked"></i>

              <template v-if="column == 'Action'">
                  <img v-if="isAdmin" class="edit" @click="onAddClick(item)"  v-tooltip.bottom-end="'Add Source'"
                    src="../assets/iconmonstr-link-2.svg" />

                  <img v-if="!isAdmin" class="editFalse" v-tooltip.bottom-end="'only Admin can Add Source'"
                    src="../assets/iconmonstr-link-2.svg" />

              </template>


              <!-- <img v-if="column=='Action'" style="height:26px;margin-left:10px" v-bind:src="('https://upload.wikimedia.org/wikipedia/commons/b/bc/Refresh_icon.png')" /> -->

            </td>
          </slot>
        </tr>

      </tbody>
    </table>
    <modal :show="searchModalVisible" class="modal-search" id="searchModal" :centered="true" @close="checkClose()"
      :show-close="true">
      <div class="headers" slot="header">
        <div v-if="!showDescPage && !showBusinessObjectPage" class="title">Select Data Source</div>
        <div v-if="showDescPage || showBusinessObjectPage" class="title">
          {{ selectedItem.productName }}
        </div>
      </div>

      <div v-if="!error" class="mainApp">
        <div v-if="(!showDescPage && !showBusinessPage  && !showBusinessObjectPage && this.categorizedApp.Others)"   :key="this.categorizedApp.Others.key">
          <div>
            <div class="mycard">
              <div class="keyItems">{{ 'Google SpreadSheet' }}</div>
              <div class="horizontalDiv">
                <div @mouseover="inside.tooltip = true" @mouseleave="inside.tooltip = false" @click="changePage(inside)"
                  :class="{
                      item: true,
                      disabled: !enabledApps.includes(inside.productName)

                    }" v-for="(inside, index) in this.categorizedApp.Others.items">
                                      <img class="imageUrl" :src="inside.logoURL" />
                    <div  v-if="
                        inside.tooltip"  class="enabled">
                      {{ inside.productName }}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="(!showDescPage && !showBusinessPage && !showBusinessObjectPage && item.key != 'Others' && handle(item))" v-for="(item, index) in this.categorizedApp" :key="index">
          <div>
            <div class="mycard">
              <div class="keyItems">{{ item.key }}</div>
              <div class="horizontalDiv">
                <div @mouseover="inside.tooltip = true" @mouseleave="inside.tooltip = false" @click="changePage(inside)"
                  :class="{
                      item: true,

                    }" v-for="(inside, index) in item.items">
                                      <img class="imageUrl" :src="inside.logoURL" />
                                      <div v-if="
                                          inside.tooltip
                                        " class="enabled">
                                        {{ inside.productName }}
                                      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showBusinessPage">
          <h4>Click to see the buisness options<i class="tim-icons icon-alert-circle-exc"></i></h4>
          <div v-click-outside="outsideClick" class="dropDown">
            <div @click="(showDropdown = !showDropdown)" class="dropdownbox">
              {{ !(selectedBusinessObj && selectedBusinessObj["business_object_name"]) ? "Please select a Business
              Objective":selectedBusinessObj['business_object_name']}}
              <div class="dropDownIconBox">
                <i v-if=!showDropdown class="tim-icons icon-minimal-down"></i>
                <i v-if=showDropdown class="tim-icons icon-minimal-up"></i>
              </div>
            </div>
            <div v-if="showDropdown" class="dropdownoptions">
              <template v-for="(business) of businessObjs">
                <li v-if="!occupiedBuisnessObj.includes(business['business_object_name'])"
                  @click="onItemSelected(business)">{{
                            business['business_object_name']
                        }}
                </li>
              </template>

            </div>
          </div>
          <img src="../assets/selectBusiness.svg" />
          <button class="businessBtn" @click="handleBusinessNext()">Next</button>
        </div>
        <div v-if="showBusinessObjectPage && !showDescPage">
          <div class="image">
              <img height="50px" :src="selectedItem.logoURL" />
            </div>
            <span id="data_source_business_objec_applist" style="font-size: 16px;font-weight: 800;    display: flex;align-items: center;justify-content: center;">Choose the Business Objects to Sync</span>
            <template v-if="selectedItem.productName != 'Google Sheet'">
              <Multiselect
              class="multiStyle"
              :multiple="true"
              select-label=""
              :hide-selected="true"
              :options="business_object"
              v-model="selectedBObjecte"
              ></Multiselect>
              <span v-if="!(selectedBObjecte.length==0)" class="textShow">You can select multiple buisness objects</span>

            </template>
            <template v-else>
              <Multiselect
              class="multiStyle"
              select-label=""
              :hide-selected="true"
              :options="business_object"
              v-model="selectedBObjecte"
              ></Multiselect>
              <span v-if="!(selectedBObjecte.length==0)" class="textShow">You can select one buisness object for Google Sheet</span>

            </template>

            <span v-if="selectedBObjecte.length==0" class="error">Supported Buisness Object for this appliation is already connected . Please unlink first and then connect</span>
            <button :class="selectedBObjecte.length>0?'':'disabled'" @click="goToNextPage()" style="margin-top: 50px;">Next</button>
        </div>
        <descPage v-if="showDescPage && !showBusinessObjectPage" :imageUrl="selectedItem"></descPage>
      </div>
      <div class="error" v-if="error">{{ error }}</div>
    </modal>
    <modal :show="trendingModal" v-if="trendingModal"  @close="checkClose()">
        <i @click="checkClose()" class="tim-icons close-icon icon-simple-remove"></i>


      <TrendingView :trendObject="trendingObject" :bObject="treninngBObject"/>
      </modal>
    <modal v-if="showKeysModal" :centered="true" @close="checkClose()">
      <div class="headers" slot="header">
        <div class="title">Select Keys</div>
        <div v-if="showDescPage" class="title">
          {{ selectedItem.productName }}
        </div>
      </div>
      <Keys :application="application" v-if="showKeysModal" ></Keys>
    </modal>
    <modal :show="showBlinkModal" class="blink_modal_business_object"  :centered="true" @close="checkClose()" >
      <i @click="checkClose()" class="tim-icons close-icon icon-simple-remove"></i>
      hello
    </modal>
    <modal :show="showResetDeleteModal"  :centered="true" @close="checkClose()">
      <div class="headers" slot="header">
        <div class="title">Please Confirm {{ selectedAction }}</div>
        <img v-if="isLoading" class="loading" src="../assets/loadingRipple.svg">
        <template v-else>
          <div class="title">

            <img v-if="selectedRedDelItem['source']=='spreadsheet'" style="height:39px;margin-right:10px;margin-top: 20px;" id ="spreed-target"
                    v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +'docs.google.com/spreadsheets/u/0/' + '.com&sz=48')"/>

            <img v-if="selectedRedDelItem['source'] !='spreadsheet'" style="height:39px;margin-right:10px;margin-top: 20px;"
              v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + selectedRedDelItem['appeq_supported_application'] + '.com&sz=48')" />
          </div>
          <div class="resetText">
            <span style="text-transform: capitalize;">{{ selectedAction }} {{ selectedRedDelItem['business_object_name'] }}</span>
            from
              <span style="text-transform: capitalize;"> {{ selectedRedDelItem['appeq_supported_application'] }}</span>
          </div>
        </template>

      </div>
      <button id="delReset"  v-if="!isLoading" @click="takeAction()" >Continue</button>
      <div class="error" v-if="error">{{ error }}</div>
    </modal>
    <modal class="invite_user_modal" :show="showMapModel" v-if="showMapModel">
      <i @click="showMapModel=false" class="tim-icons close-icon icon-simple-remove"></i>

      <h2 style="text-align: center;"> Map Account Fields</h2>
      <MapAccountFields :mappedFields="mappedFields" :source="selectedSource" :bObject="selectedBObjecte"/>
    </modal>
    <modal v-if="showfeedsConfigModal" class="feeds_config" :show="showfeedsConfigModal">
      <i @click="showfeedsConfigModal=false;" class="tim-icons close-icon icon-simple-remove"></i>
      <Configure :feedsData="feedsData" @close="handleCloseFeeds()" />
    </modal>
    <ObjectConfiguration @close="showConf=false" v-if="showConf" :source="selectedSource" :bObject="selectedObj"/>
  </div>
</template>
<script>
import Modal from "@/components/Modal";
import Keys from "../pages/Keys.vue";
import ObjectConfiguration from "../pages/ObjectConfiguration.vue";
import ConfiguratorVue from "./Configurator.vue";
import Dropdown from "@/components/BaseDropdown.vue"
import DescPage from "@/components/DescPage";
import Server from "./API/Server.js";
import moment from "moment";
import Multiselect from "vue-multiselect";
import LocalUtils from "./Utils/LocalUtils";
import TrendingView from "../pages/TrendingView.vue"
import MapAccountFields from "./MapAccountFields.vue"
import Configure from "../pages/Configure.vue"
export default {
  name: "base-table",
  data() {
    return {
      schedulerLoad: false,
      selectedObj:"",
      showMapModel:false,
      refreshedItem:null,
      selectedSource: '',
      upHere: [],
      application:"",
      appDatas: [],
      showDescPage: false,
      selectedBusinessObj: '',
      occupiedBuisnessObj: [],
      availableBuisnessObjects: [],
      selectedItem: {},
      showResetDeleteModal:false,
      selectedAction:'',
      selectedRedDelItem:{},
      isLoading:false,
      isLoadingData: false,
      success:false,
      showConf:false,
      searchModalVisible: false,
      showKeysModal: false,
      showBlinkModal: false,
      showSettingsModal:false,
      categorizedApp: {},
      enabledApps: ['Google Sheet', 'Zendesk Support Suite', 'Salesforce','HubSpot','Freshdesk'],
      error: "",
      trendingModal:false,
      showfeedsConfigModal: false,
      feedsData: {},
      treninngBObject:'',
      showBusinessPage: true,
      trendingObject:{},
      mappedFields:{},
      showDropdown: false,
      businessObjs: [],
      selectedBusinessObj: {},
      isAdmin:true,
      doNotShow : ["Product Management","AppEQ","CogniSaaS","Gmail","Others"],

      showBusinessObjectPage:false,
      business_object:[],
      selectedBObjecte:[''],
      appsList: [],
    };
  },
  components: {
    Modal,
    DescPage,
    MapAccountFields,
    TrendingView,
    Dropdown,
    ObjectConfiguration,
    Keys,
    ConfiguratorVue,
    Multiselect,
    Configure
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    loadingStatus: {
      type: Boolean,
      default: false,
    },
    allData: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
  },
   async beforeMount(){
        this.isAdmin = localStorage.getItem("IS_ADMIN") === 'true'? true: false;
        try{
          console.log(this.isAdmin);
          const appData = await Server.getApps()
          console.log(appData);
          this.appsList = appData.data
        }catch(e){
          console.log(e);
        }
    },
    async mounted(){
      console.log(this.loadingStatus);
      this.isLoadingData = this.loadingStatus;
    },

  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  watch: {
    loadingStatus() {
      console.log(this.loadingStatus);
      this.isLoadingData = this.loadingStatus;
    },
  },
  methods: {

    handleHubspot(item){
      let url = `https://app.hubspot.com/oauth/authorize?client_id=6951dc5d-cab1-43b6-a76e-34a85d504058&redirect_uri=${process.env.VUE_APP_REDIRECTION_ROUTE}/integrations&scope=tickets%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.custom.read%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.companies.read%20crm.objects.owners.read%20crm.objects.quotes.read%20crm.schemas.quotes.read`;
      var win = window.open(url, "_blank");
      let pollTimer = window.setInterval(async () => {
        try {
          var url = new URL(win.location.href);
          if (url.searchParams.get("code")) {
            console.log(url.searchParams.get("code"));
            win.close();
            window.clearInterval(pollTimer);
            this.isLoading = true;
            let code = url.searchParams.get("code");
            const data = await Server.getAccessToken(code);
            console.log(data)
            const bObj = item.business_object_name;
           await Promise.all(bObj.split(",").map(async d=>{
              const saveHub = await Server.saveHubspotData({
              appeq_supported_application: "Hubspot",
              business_object_name: d,
              access_token: data.data["access_token"],
              refresh_token:data.data["refresh_token"],
              email: LocalUtils.getEmail(),
              lastUpdatedAt:item.lastUpdatedAt,
              cusror:item.cusror,
            });
            }));
            // bObj.split(",").map(async data=>{
            //     await Server.onResync(LocalUtils.getEmail(), data);

            //   })
            console.log(data);
            this.$toasted.success(
              "You have enabled bidirectional sync"
            );

            this.isLoading = false;
            this.isSuccess = true;
            //Server.callSceduler("spreadsheet",data)
            window.location.reload();
          }
        } catch (error) {
          console.log(error);
        }
      }, 1000);
    },



    reAuth(item){
      if(item.appeq_supported_application=="salesforce"){
        try {
        let domain = item.instance_url;
        let client_id =
          "3MVG9wt4IL4O5wvLgb9I5m8DvdLV3B_WpFrNAM6bHFe9w6BqDaJTyoQ2pI4JsP8LqwJ98EyTODeYtVFvLX91S";
        let client_secret =
          "148F2E93D23C83B291CDB7197CC31EDED6E11B5FED7D55F04603957A7937045B";
        let url = `${domain}/services/oauth2/authorize?response_type=code&client_id=${client_id}&redirect_uri=${process.env.VUE_APP_REDIRECTION_ROUTE}/integrations`;
        let win = window.open(url, "_blank", "height=570,width=520");
        let pollTimer = window.setInterval(async () => {
          try {
            var url = new URL(win.location.href);
            if (url.searchParams.get("code")) {
              console.log(url.searchParams.get("code"));
              win.close();
              window.clearInterval(pollTimer);
              this.isLoading = true;
              let code = url.searchParams.get("code");
              const data = await Server.getSalesforceaccess(
                code,
                client_id,
                client_secret,
                domain
              );
              const bObj = item.business_object_name;
              console.log(bObj.split(","))
              const newDataToPush={
                ...item
              }
              newDataToPush['access_token']=data.data["access_token"];
              newDataToPush['refresh_token']=data.data["refresh_token"];
              newDataToPush['admin-email']=LocalUtils.getEmail()

              newDataToPush['auth']="success";

              await Promise.all(bObj.split(",").map(async d=>{
                const res = await Server.saveSalesforceData(
               newDataToPush
              );
            if(res.data.code!=200){
                this.$toasted.error("Error in your credentials. Please try again after some time or contact us at support@appeq.ai");
                return;
              }
           return res
            }));

              // bObj.split(",").map(async data=>{
              //   await Server.onResync(LocalUtils.getEmail(), data);

              // })
              this.$toasted.success(
                "Please wait we will notify you once the data has been resynced via mail ."
              );
              this.isLoading = false;
              this.success = true;
              this.$toasted.success(
                "You have successfully configured salesforce . Please wait for some time you will get notified when we pull all your data"
              );
              this.success=true;
              window.location.reload()
            }
          } catch (error) {}
        }, 1000);
      } catch (error) {
        this.error = error;
        console.log(error)
      }
      }
    },
    openModalForTrending(bobjec,item){
      this.treninngBObject=bobjec
      this.trendingObject=item.trends
      this.trendingModal=!this.trendingModal;
    },
    settingsClicked(item){
      this.showKeysModal=true
      this.application=item.appeq_supported_application
      localStorage.setItem("Business_obj_name", item['business_object_name'])

    },
      openApplication(item){

        if(item.appeq_supported_application=='spreadsheet'){
          window.open(item.sheetUrl)
        }else if(item.appeq_supported_application=='zendesk'){
          window.open('https://'+item.domain+'.zendesk.com')
        }else if(item.appeq_supported_application=='hubspot'){
          window.open('https://app.hubspot.com/login');
        }else if(item.appeq_supported_application=='freshdesk'){
          window.open('https://www.freshworks.com/freshdesk/');
        }else if(item.appeq_supported_application=='salesforce'){
          window.open(item.instance_url)
      }
        else{
          window.open('https://www.salesforce.com/in/form/signup/freetrial-sales/?d=jumbo1-btn-ft')
        }
      },
      handle(app){
            return !(this.doNotShow.includes(app.key));
      },

    // async onStartCollectBuisnessObjects(){

    //   const businessRes = await Server.getBusinessDataObs();
    //   this.occupiedBuisnessObj = this.data.map(d => d["business object"]);

    //   if (businessRes.data.length > 0)
    //       this.businessObjs = businessRes.data;

    //   this.businessObjs.map((item)=>{
    //     if(!this.occupiedBuisnessObj.includes(item["business_object_name"])){
    //       this.availableBuisnessObjects.push(item);
    //     }
    //   })

    // },

    handleShowSettingsModal(item) {
      console.log(item)
      this.selectedBusinessObj = item["business object"]
      this.showSettingsModal = !this.showSettingsModal
    },
    outsideClick() {
      this.showDropdown = false
    },
    handleBusinessNext() {
      // Show only help desk objects for ticket
      if (this.selectedBusinessObj['business_object_name'] == 'ticket') {
        this.categorizedApp = { ['Help Desk']: this.categorizedApp['Help Desk'] }
      }
      this.showBusinessPage = false;
      // setting the businness object name in localstorage instead of passing as props
      localStorage.setItem("Business_obj_name", this.selectedBusinessObj['business_object_name'])
    },
    onItemSelected(item) {

      this.selectedBusinessObj = item;

      this.showDropdown = false;

    },
   async formatApps(serverRes,buisnessObject,sOps) {
      let arr = []
      this.allData.map((o) => {
          if (o['business_object_name']==buisnessObject) {
            o['supported_apps'].map(d=>{
              serverRes['selected_apps'].map(c=>{
                if(c.productName==d && sOps.findIndex(d=>d.productName==c.productName)>-1){
                  console.log(c.productName);
                    if(!arr.includes(c))
                      arr.push(sOps[sOps.findIndex(d=>d.productName==c.productName)])
                }
              })
            })
          }
      })
      console.log(arr);
      return arr;

   },
    async onAddClick(item) {
      try {

        this.business_object=item.business_object;
        console.log(this.appsList);
        console.log(item);

        this.showBusinessPage = false;
        console.log("here in click",item);
        console.log(this.allData);
        //get apps list from server and then use it to show the data in the UI
        const data = await Server.getAppList();
        //for filtering datasource
        //const data = item['supportedApps'];

        //if data not present throw error
        //TODO : Check user logged in or not and throw proper error
        if (data && data.error) {
          this.error = "User is not logged in";
          this.searchModalVisible = true;
          return;
        }

        const dataToShow = await this.formatApps(data,item['business object'],data.selected_apps);
        console.log(dataToShow);

        //getting business objects from api and showing it in a dropdown
        //on the modal
        // console.log(item["business object"]);

        // data.selected_apps = formatApps(item['supported_apps'], data.selected_apps['supported_Apps']);

        localStorage.setItem("Business_obj_name", item["business object"]);
        this.appDatas = data.selected_apps;
        let testArr = {};
        //mapping the appdatas to category wise items so that its easier to display in UI
        dataToShow.map((data) => {
          //check if category already exists in the map
          // if(data.productName=='Freshdesk'){
          //   testArr['Help Desk']["items"].push({ ...data, tooltip: false });
          // }
          // else
          if (testArr[data.g2Category]) {
            testArr[data.g2Category]["items"].push({ ...data, tooltip: false });
          } else {
            //if category not exists create a new array and push the data
            testArr[data.g2Category] = {
              items: [{ ...data, tooltip: false }],
              key: data.g2Category,
            };
          }
        });
        this.categorizedApp = testArr;
        this.searchModalVisible = true;
      } catch (e) {
        console.log(e)
      }
    },
    async handleRefresh(item) {
      this.schedulerLoad = true;
      this.refreshedItem=item
      this.$toasted.success("Please wait we will notify you once the data has been resynced")

      // await Server.onResync(item.org_id,item["business object"])
      await Server.newReSync(item.org_id, item['business object']);
      this.$toasted.success("Your data has been resynced.")
      this.schedulerLoad = false
    //   setTimeout(function(){
    //     // window.location.replace("https://app.appeq.ai/integrations");
    //     window.location.reload()
    // }, 2000);
    },
    async editClicked(item) {
      try {
        console.log(item)
        //when edit clicked then show the google desc box and check if spreadsheet present
        //variable to show modal and then inside modal three pages if reuquired
        if(item.source=="API"){
          this.selectedItem={
            imageUrl:'https://app.appeq.ai/img/appeq.cc5b93eb.png',
            productName:"API Ingestion",
            token:item.api_token
          }
        // if (item["source"] == 'spreadsheet') {
        //   const res = data.selected_apps.find(d => d.productName == 'Google Sheet')
        //   this.selectedItem = res
        // }
        // else if (item['source'] == 'salesforce') {
        //   const res = data.selected_apps.find(d => d.productName.toString().includes("Salesforce"))
        //   this.selectedItem = res

        // } else if(item['source'].includes('hubspot')){
        //   const res = data.selected_apps.find(d => d.productName.toString().includes("HubSpot"))
        //   this.selectedItem = res
        // } else if(item['source']=='freshdesk'){
        //   const res = data.selected_apps.find(d => d.productName.toString().includes("Freshdesk"))
        //   this.selectedItem = res;
        //   console.log("here");
        // }else {
        //   const res = data.selected_apps.find(d => d.productName.toString().includes("Zendesk"))
        //   this.selectedItem = res
        // }
        // // setting the businness object name in localstorage instead of passing as props
        localStorage.setItem("Business_obj_name", item["business object"]);
        localStorage.setItem("item_creds", JSON.stringify(item))
        this.searchModalVisible = true;
        this.showDescPage = true;
        this.showBusinessPage = false;
        return
        }
        const data = await Server.getAppList();
        //getting app info from apps array on which item edit is clicked for now only sheet and zendesk
        //later modify it to whatever we want
        //only changing this.selecteditem to the selected variable
        console.log(item);
        const res=data.selected_apps.findIndex(d=>{
          if(d.productName && item.appeq_supported_application=="spreadsheet"){
            return d.productName.toString().toLowerCase().includes('google sheet');
          }
          else if(d.productName){
            return d.productName.toString().toLowerCase().includes(item.appeq_supported_application.toLowerCase())
          }
          else{
            return false
          }
          });
        console.log(res)
        this.selectedItem=data.selected_apps[res]
        // if (item["source"] == 'spreadsheet') {
        //   const res = data.selected_apps.find(d => d.productName == 'Google Sheet')
        //   this.selectedItem = res
        // }
        // else if (item['source'] == 'salesforce') {
        //   const res = data.selected_apps.find(d => d.productName.toString().includes("Salesforce"))
        //   this.selectedItem = res

        // } else if(item['source'].includes('hubspot')){
        //   const res = data.selected_apps.find(d => d.productName.toString().includes("HubSpot"))
        //   this.selectedItem = res
        // } else if(item['source']=='freshdesk'){
        //   const res = data.selected_apps.find(d => d.productName.toString().includes("Freshdesk"))
        //   this.selectedItem = res;
        //   console.log("here");
        // }else {
        //   const res = data.selected_apps.find(d => d.productName.toString().includes("Zendesk"))
        //   this.selectedItem = res
        // }
        // // setting the businness object name in localstorage instead of passing as props
        localStorage.setItem("Business_obj_name", item["business object"]);
        localStorage.setItem("item_creds", JSON.stringify(item))
        this.searchModalVisible = true;
        this.showDescPage = true;
        this.showBusinessPage = false;
      } catch (e) {
        console.log(e);
      }
    },
    checkClose() {
      this.showSettingsModal = false;
      this.showBlinkModal = false;
      this.isLoading = false;
      this.trendingModal=false
      this.selectedBObjecte = [''];
      this.business_object = [];
      this.showBusinessObjectPage = false;
      this.error = '';
      this.searchModalVisible = false;
      this.showKeysModal=false;
      this.showResetDeleteModal=false;
      if (!this.showDescPage) {
        this.searchModalVisible = false;
      } else {
        this.showDescPage = !this.showDescPage;
      }
    },
    changePage(item) {
      //change page from applist to desc page inside of applist

        this.selectedItem = item;
        console.log(item);
        this.business_object = [];
        this.appsList.map(data=>{
          if(data.apps.productName == item.productName){
            this.business_object = data.apps.business_object_name;
            console.log(data);
          }
        })
        var creds=JSON.parse(localStorage.getItem("ALL_CREDS"));
        let newArr=[]
        this.business_object.map((data,index)=>{
            if(creds.filter(d=>d.business_object_name==data).length>0){
            }else{
                newArr.push(data)

            }

        })
        const nArr=[]
        if(item.productName.toLowerCase()=="salesforce"){
        const idx=creds.findIndex(d=>d['appeq_supported_application']=='salesforce')
        console.log(idx)
        if(idx!=-1){
          nArr.push(...creds[idx]['custom_objects'])
           }
         }
        this.business_object = newArr;
        console.log("sadfsd",this.business_object);
        if(item.productName != 'Google Sheet')
          this.selectedBObjecte = this.business_object;
        // this.showDescPage = true;
        this.showBusinessObjectPage = true;
        this.business_object=[...this.business_object,...nArr]

    },
    goToNextPage(){

      if(this.selectedBObjecte == ''){
        this.$toasted.error("Please Select a Business Object First");
        return
      }

      this.showBusinessObjectPage = false;
      localStorage.setItem("Business_obj_name",this.selectedBObjecte);
      console.log(this.selectedBObjecte);
      this.showDescPage = true;
    },
    hasValue(item, column) {
      // if(column.toLowerCase() in item)
      return item[column.toLowerCase()] !== "undefined";
      // else
      //   return false;

    },
    itemValue(item, column) {
      if (!(column.toLowerCase() in item)) {

        if (column == 'Source') {
          return '-';
        }

        if(column=="Data Synced At"){
          if(item["updated_time"]){
            try{
              let b  = item["updated_time"].split(" ")[0];
              var time = parseInt(b);
              console.log(time);

              if(time && time>100){
                return '-'
              }
              else{
                return item.updated_time;
              }
            }
            catch(e){
              console.log(e);
            }

          }
        }
      }
      if(item[column.toLowerCase()]&& item[column.toLowerCase()].toString().toLowerCase()=="accounts"){
          return "Accounts / Companies"
        }
        if(item[column.toLowerCase()]&& item[column.toLowerCase()].toString().toLowerCase()=="opportunities"){
          return "Opportunities / Deals"
        }
      if (column == 'Status')
        return "";
      return item[column.toLowerCase()];
    },
    handleAction(item,action){
      this.showResetDeleteModal = true;
      this.selectedAction = action;
      this.selectedRedDelItem = {...item};
      console.log(this.selectedRedDelItem,action);

    },
    handleRed(bobje){
      this.$router.push(`/red-condition?business_object_name=${bobje}`)
    },
    feedsConfiguration(value){
      console.log(value);
      this.feedsData = value;
      this.showfeedsConfigModal = true;
    },
    handleCloseFeeds(){
      this.showfeedsConfigModal = false;
    },
    async takeAction(){
      try{
        this.isLoading = true;
        const email = LocalUtils.getEmail();
        const res = await Server.resetSource(this.selectedAction,this.selectedRedDelItem['business_object_name'],this.selectedRedDelItem['appeq_supported_application'],email);
       // await new Promise(resolve => setTimeout(resolve, 2000));
        console.log(res);
        if(res && res.code==200){
          this.isLoading = false;
          if(this.selectedAction=='reset')
            this.$toasted.success("Reset Request Recieved. We are reseting your source");
          else
            this.$toasted.success("Delete Request Recieved. We are Deleting your source");
          this.showResetDeleteModal = false;
          await new Promise(resolve => setTimeout(resolve, 1000));
          window.location.reload();
        }
        else if(res && res.code==300){
          console.log(res);
          let bobjs = []
          res.data.map((o)=>{
            bobjs.push(o.business_object_name)
          })
          this.$toasted.error("Can't delete parent node. \n Unlink "+ bobjs +" first ");
          this.error = "Can't delete parent node. \n Unlink "+ bobjs +" first " ;
        }
        else{
          this.$toasted.error("Oops an error occured please try after some time");
          this.error = res.error;
        }
      }
      catch(e){
        console.log(e);
      }
      this.isLoading = false;
      console.log(this.selectedAction,this.selectedRedDelItem['business_object_name'],this.selectedRedDelItem['appeq_supported_application'],this.selectedRedDelItem['email']);
    }
  },
};
</script>
<style scoped lang="scss">
@import '../assets/sass/black-dashboard/custom/themes.scss';
.expired{
  height: 30px;
  filter: invert(15%) sepia(88%) saturate(7282%) hue-rotate(359deg) brightness(100%) contrast(108%);
  margin-left: 10px;
  cursor: pointer;
  animation: filterAnimation 4s linear infinite; /* 20 seconds animation duration */

}
@keyframes filterAnimation {
  0%, 50% {
    filter: invert(15%) sepia(88%) saturate(7282%) hue-rotate(359deg) brightness(170%) contrast(120%);
  }
  25%, 75% {
    filter: none; /* No filter effect */
  }
}
table {
  margin-top: 26px;

  td {
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 818px) {
  .testAccountButton {
    height: 60px;
  }
}

.status-icon-linked {
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 1000;
  background: green;
  border-radius: 50%;
}

.status-icon-unlinked {
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 1000;
  background: #b47e01;
  border-radius: 50%;
}

.testAccountButton {
  width: 20%;
  height: 40px;
  cursor: pointer;
  padding: 0px 16px;
  font-size: 13px;
  font-weight: 550;
  border-radius: 6px;
  border: 1px solid #6f55ff;
  color: #6f55ff;
  background: white;
  transition: 0.5s;
  position: absolute;
  right: 20px;
  top: 10px;
}

.testAccountButton:hover {
  color: white;
  border: 1px solid white;
  background: #6f55ff;
  transition: 0.5s;
}

.buttonTestHead {
  width: 19%;
}

.table {
  overflow: hidden;
}

.mainApp {
  height: 500px;
  width: 350px;
  overflow: auto;
  background: rgb(255, 255, 255);
}

.mainApp::-webkit-scrollbar {
  width: 12px;
}

.disabled {
  opacity: 0.2;
  cursor: auto;
}

.imageUrl {
  height: 45px;
  width: 65px;
  margin-left: 20px;
}

span {
  font-size: 12px;
}

.horizontalDiv {
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(31, 45, 61, 0.08) 0px 1px 4px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.keyItems {
  border-bottom: 1px solid #eaeef3;
  font-size: 15px !important;
  margin-top: 30px;
  font-weight: 600;
}

.headers {
  margin-top: 25px;
  width: 100%;
  margin-bottom: -25px;
}

.subtitle {
  font-size: 13px;
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(90, 96, 107) !important;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.mycard {
  margin-bottom: 10px;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(31, 45, 61, 0.08) 0px 1px 4px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.item:hover {
  opacity: 0.9;
  cursor: pointer;
}

.tool {
  position: absolute;
  color: white;
  background: grey;
  border-radius: 4px;
  padding: 4px;
  font-size: 10px;
}
.enabled{
  position: absolute;
  color: white;
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
  background: black !important;
  opacity: 2;
  margin-top: 10px;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
}
.textShow{
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0) !important;
  margin-top: 50px;
  font-size: 15px !important;
}

li {
  list-style: none;
  cursor: pointer;
}

.businessBtn {
  background-color: #4e5fff;
  color: white;
  border-radius: 10px;
  width: 200px;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.editIcon {
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dropdownbox {
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 6px;
  cursor: pointer;
  padding: 10px;
}

.dropdownoptions {
  position: absolute;
  background-color: #f6f7fe;
  width: 87%;
  margin-top: 5px;
  border-radius: 6px;
  cursor: pointer;
  padding: 10px;
  min-height: 400px;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.10);

  li:hover {
    background-color: rgb(190, 190, 198);
  }

}

.settings {
  height: 26px;
  filter: $icon-filter;
}

.edit:hover {
  scale: 1.2;
  transition: 0.2s ease-in-out;

}

.edit {
  cursor: pointer;
  transition: 0.3s ease-in-out;
  filter: invert(0%) sepia(0%) saturate(7489%) hue-rotate(138deg) brightness(20%) contrast(98%);
  height: 20px;
}

.delete{
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 17px;
  color: red;
  margin-left: 15px;
  &:hover {
    scale: 1.2;
    transition: 0.2s ease-in-out;

  }
}
.reset{
  cursor: pointer;
  transition: 0.3s ease-in-out;
  height: 20px;
  margin-left: 0px;
  &:hover {
    scale: 1.2;
    transition: 0.2s ease-in-out;

  }
}
.editFalse{
  cursor: pointer;
  transition: 0.3s ease-in-out;
  filter: invert(0%) sepia(0%) saturate(7489%) hue-rotate(138deg) brightness(20%) contrast(98%);
  height: 20px;
  opacity: 0.5;
}

.unlinkedRow:hover {
  opacity: 1;
}

.unlinkedRow {
  opacity: 0.4;
  text-transform: capitalize;
}

@keyframes rotateSpinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.refresh {
  height: 25px;
  cursor: pointer;
  filter: invert(0%) sepia(0%) saturate(7489%) hue-rotate(138deg) brightness(20%) contrast(98%);

  &:hover {
    scale: 1.2;

    // transition: 1s;
    // transform: rotateX(180deg);

  }
  &.loader{
    animation-name: rotateSpinner;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
  }
}
.settings{
  height: 20px;
  margin-left: 25px;

}
.resetText{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;

  span{
    margin: 3px;
    font-size: 16px;
    color: black;
  }

}
#delReset{
    background: #6f55ff;
    color: white;
    border: 0px;
    padding: 0px 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    height: 56px;
    cursor: pointer;
    border-radius: 6px;
    width: 100%;
    margin-top: 20px;
  }
  .loading{
    display: block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .red{
    color: red;
    font-weight: 700;
  }
  .disred{
    pointer-events: none;
    opacity: 0.4;
  }

  .image {
    display: flex;
    justify-content: center;
    height: 100px !important;
  }

  .red_blink_alert{
    display: block;
    width: 10px;
    height: 10px;
    margin-top: -1px;
    margin-left: 1px;
    font-size: 0px;
    cursor: pointer;
    border-radius: 50%;
    background-color: red;
    color: red;
    animation-name: blink;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;


  }
  @keyframes blink {
  0% {
    opacity: 0;
    scale:1;
  }
  20% {
    opacity: 0.3;
    scale:1.03;
  }

  40% {
    opacity: 0.6;
    scale:1.05;
  }
  50% {
    opacity: 1;
    scale:1.07;
  }
  60% {
    opacity: 0.6;
    scale:1.05;
  }
  80% {
    opacity: 0.3;
    scale:1.03;
  }
  100% {
    opacity: 0;
    scale:1;
  }
}
</style>

<style>

  .feeds_config{
    .modal-dialog-centered{
    justify-content: center;
  }
  .modal-content{
    min-width: 800px;
  }
  }
</style>
