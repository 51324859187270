<template>
    <div>
        <div class="heading">
            <h2>Configure feed view for {{ bObjName }}</h2>
        </div>
            <div :style="{display: [!rightValue ? 'flex' : 'none']}" style="height: 400px; display: flex; align-items: center; flex-direction: column; justify-content: center;">
                <img style="width: 300px; display: flex; align-items: center;" src="../assets/nodata.svg" />
            </div>
            <div :style="{visibility: [rightValue? '' : 'hidden']}" v-if="this.showEditColumns==true">
                <div style="display: flex; justify-content: end; flex-direction: column;">
                    <div style="display: flex;justify-content: space-evenly;margin-top: 0px;">
                    <div style="border: 1px solid grey;width: 40%;height: 300px;overflow: scroll;padding: 20px; border-radius:10px; cursor: pointer;">
                        <div style="font-size: 15px;text-align: center;font-weight: 600;">
                            CRM Fields
                        </div>
                        <span style="position: fixed; display: flex; flex-direction: column; align-items: center; width: 37%;">
                            <input type="text" v-model="inputSearch1" name="search" placeholder="Search" id="" style="text-align: center; width: 200px; margin-left: 70px; border-radius: 10px; padding: 3px; font-size: 16px; border: none; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);">
                        </span>
                        <span style="display: flow-root; margin-top: 30px;">
                            <div v-if="!loading" :style="{ 'border': leftSelected.includes(col) ? '1px solid green' : '', 'padding': '10px', 'margin-top': '10px' ,'margin-left': '20px', 'font-size': '12px'}"
                                @click="handleLeftSelected(col)" v-for="col of filteredList1()" :key="col">
                                {{ col }}
                            </div>
                            <div class="item" v-if="inputSearch1 && !filteredList1().length">
                                <p style="color: black; font-size: 20px; margin-top: 30px; text-align: center;">No results found!</p>
                            </div>
                            <div v-if="loading" style="display: flex; justify-content: center; align-items: center;">
                                <img src="../assets/loadingRipple.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div style="height: 250px;display: flex;align-items: center;justify-content: center;flex-direction: column;">
                        <span @click="handleTransfer('right')" :style="{ 'opacity': leftSelected.length == 0 ? '0.5' : '', 'cursor': 'pointer' }" class="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                        <br />
                        <span @click="handleTransfer('left')" :style="{ 'opacity': rightSelected.length == 0 ? '0.5' : '', 'cursor': 'pointer' }" class="material-symbols-outlined">
                            arrow_back_ios
                        </span>
                    </div>
                    <div style="border: 1px solid grey;width: 40%;height: 300px;overflow: scroll;padding: 20px; border-radius:10px; cursor: pointer;">
                        <div style="font-size: 15px;text-align: center;font-weight: 600;">
                            Fields in AppEQ
                        </div>
                        <span style="position: fixed; display: flex; flex-direction: column; align-items: center; width: 37%;">
                            <input type="text" v-model="inputSearch2" name="search" placeholder="Search" id="" style="text-align: center; width: 200px; margin-left: 70px; border-radius: 10px; padding: 3px; font-size: 16px; border: none; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);">
                        </span>
                        <span style="margin-top: 30px; display: flow-root;">
                            <div v-if="!loading2" v-for="col of filteredList2()" :key="col"  style="display: flex; justify-content: space-between;">
                                <span :style="{ 'border': rightSelected.includes(col) ? '1px solid green' : '', 'padding': '10px', 'margin-top': '10px', 'margin-left': '0px', 'font-size': '12px', 'min-width': '207px'}" @click="handleRightSelected(col)">
                                    {{ col }}
                                </span>
                                <span style="padding: 10px; margin-top: 10px; display: flex; justify-content: space-between;">
                                    <span @click="sendSelectedToTop(col)" :style="{color: (columns[0] == col) ? '#A2A5A2' : ''}" class="material-symbols-outlined">expand_less</span>
                                    <span @click="sendSelectedToBottom(col)" :style="{color: (columns[columns.length-1] == col) ? '#A2A5A2' : ''}" class="material-symbols-outlined">expand_more</span>
                                </span>
                            </div>
                            <div class="item" v-if="inputSearch2 && !filteredList2().length">
                                <p style="color: black; font-size: 20px; margin-top: 30px; text-align: center;">No results found!</p>
                            </div>
                            <div v-if="loading2" style="display: flex; justify-content: center; align-items: center;">
                                <img src="../assets/loadingRipple.svg" alt="">
                            </div>
                        </span>
                    </div>
            
                </div>
                </div>
                
                <div style="width: 130px; float: right;margin: 20px 30px;">
                    <button style="    border-radius: 20px;
    width: 120px;
    height: 30px;;" @click="saveKey()"><span v-if="!loading3">Save</span> <span v-if="loading3" style="display: flex; justify-content: center; align-items: center;">Saving...</span> </button>
                </div>
            </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import Server from '../components/API/Server';
import LocalUtils from '../components/Utils/LocalUtils';
export default {
    components: {
        Multiselect
    },
    props: ['feedsData'],
    data(){
        return{
            leftValue: '',
            rightValue: '',
            inputSearch1: '',
            inputSearch2: '',
            leftSelected: [],
            rightSelected: [],
            bkeys: [],
            bObjName: '',
            showEditColumns: false,
            customers: [],
            customerEmailsData: {},
            businessObjects: [],
            columns:['Actions'],
            count: {},
            originalColumns: [],
            businness_object_name: '',
            appeq_supported_application: '',
            layout: [],
            selectedKeys: [],
            loading: false,
            loading2: false,
            loading3: false
        }
    },
    async mounted(){
        console.log(this.feedsData);
        this.bObjName = this.feedsData['business_object_name'];
        this.rightValue = this.bObjName;
        if(this.rightValue){
            this.showEditColumns = true;
        }
        this.loading = true;
        this.bkeys = (await Server.getConfiguratorKeysForCustomers(this.rightValue, LocalUtils.getEmail())).data.keys;
        this.loading = false;
        console.log(this.bkeys);
        this.loading2 = true;
        let columnsData = await Server.getAdminConfigs();
        this.loading2 = false;
    console.log(columnsData.data);
    if(columnsData.data.length==0){
        this.columns = ['Actions'];
    }else{
        let res = [];
        let bon = '';
        for(let i=0;i<columnsData.data[0].feeds_configuration.length;i++){
            if(columnsData.data && columnsData.data[0] && columnsData.data[0].feeds_configuration[i]){
                let bon = columnsData.data[0].feeds_configuration[i].business_object_name;
                let res = columnsData.data[0].feeds_configuration[i].keys;
                if(bon==this.rightValue){
                    this.columns = [...res];
                }
            }
        }
        console.log(res);
    }
    let idx = 0;
    for(let i=0;i<this.columns.length;i++){
        if(this.columns[i]=='Actions'){
            idx = i;
            break;
        }
    }
    console.log(idx);
    console.log(this.columns);
    // this.columns.slice(idx+1);
    const tempArr = this.columns.filter(function (letter) {
    return letter !== 'Actions';
});
tempArr.push('Actions');
            console.log(tempArr)
            this.columns = tempArr;
            this.count.left = true;
    console.log(this.showEditColumns);
    this.businessObjects = [];
    let localData = '';
    console.log(this.leftValue)
    if(this.leftValue){
        localData = this.customerEmailsData[this.leftValue];
    }
    const data = (await Server.googleSheetCredentials(localData)).data;
            console.log(data);
            for(let i=0;i<data.length;i++){
                this.businessObjects.push(data[i].business_object_name)
            }
            console.log(this.businessObjects)
        },
    methods:{
        async optionSelectedLeft(option, id) {
            console.log(this.showEditColumns);
            if(this.count.left==false || this.count.right==false){
                this.showEditColumns = false;
            }
    this.count.left = true;
    console.log(this.showEditColumns);
    this.businessObjects = [];
    let localData = '';
    this.leftValue = this.customerName;
    console.log(this.leftValue)
    console.log(this.customerEmailsData)
    if(this.leftValue){
        localData = this.customerEmailsData[this.leftValue];
    }
    const data = (await Server.googleSheetCredentials(localData)).data;
            console.log(data);
            for(let i=0;i<data.length;i++){
                this.businessObjects.push(data[i].business_object_name)
            }
            console.log(this.businessObjects)
},
async optionSelectedRight(option, id) {
    this.loading = true;
    console.log(this.showEditColumns);
    if( this.count.right==false){
                this.showEditColumns = false;
            }
console.log(option)
    this.count.right = true;
    this.rightSelected = [];
    this.columns = ['Actions'];
    let data = await Server.getAdminConfigs();
    console.log(data.data);
    if(data.data.length==0){
        this.columns = ['Actions'];
    }else{
        let res = [];
        let bon = '';
        for(let i=0;i<data.data[0].feeds_configuration.length;i++){
            if(data.data && data.data[0] && data.data[0].feeds_configuration[i]){
                let bon = data.data[0].feeds_configuration[i].business_object_name;
                let res = data.data[0].feeds_configuration[i].keys;
                if(bon==this.rightValue){
                    this.columns = [...res];
                }
            }
        }
        console.log(res);
    }
    console.log(this.columns);
if( this.count.right == true){
    this.bkeys = [];
    this.showEditColumns = true;
    console.log(this.customerEmailsData[this.leftValue])
    this.bkeys = (await Server.getConfiguratorKeysForCustomers(this.rightValue, this.customerEmailsData[this.leftValue]));
            console.log(this.bkeys);
            this.bkeys = this.bkeys.data.keys;
            this.bkeys = this.bkeys ? this.bkeys.filter((obj) => !this.columns.some((obj2) => obj2 === obj)) : [];
            console.log(this.bkeys.length);
            for(let key in this.bkeys){
                if(this.columns.includes(this.bkeys[key])){
                    delete this.bkeys[key];
                }
            }
            console.log(this.bkeys.length);
            this.bkeys.sort();
}
if( this.count.right==false){
                this.showEditColumns = false;
            }
            console.log(this.showEditColumns);
            this.loading = false;
},
filteredList1(){
    return this.bkeys.filter((item) =>
    item.toLowerCase().includes(this.inputSearch1.toLowerCase())
  );
},
filteredList2(){
    return this.columns.filter((item) =>
    item.toLowerCase().includes(this.inputSearch2.toLowerCase())
  );
},
sendSelectedToTop(fc){
            if(fc == this.columns[0]){
                return;
            }
            console.log(fc);
            console.log(this.columns);
            const idx = this.columns.findIndex(d => d == fc);
            // let temp=this.columns[idx]
            // this.columns[idx]=this.columns[idx+1]
            this.columns.splice(idx, 1);
            this.columns.splice(idx-1,0,fc);
            console.log(this.columns)
        },
        sendSelectedToBottom(fc){
            if(fc == this.columns[this.columns.length-1]){
                return;
            }
            console.log(fc);
            console.log(this.columns);
            const idx = this.columns.findIndex(d => d == fc);
            this.columns.splice(idx, 1);
            this.columns.splice(idx+1,0,fc);
            console.log(this.columns)
        },
        handleLeftSelected(fc) {
            if (this.leftSelected.filter(d => d == fc).length > 0) {
                const idx = this.leftSelected.findIndex(d => d == fc);
                this.leftSelected.splice(idx, 1);
                return;
            }
            this.leftSelected.push(fc);
            console.log(this.leftSelected)
        },
        handleRightSelected(fc) {
            if (fc == 'CSM' || fc.toLowerCase() == 'health' || fc.toLowerCase() == 'pulsesettings' || fc == 'Actions') {
                this.$toasted.error("Sorry , You cannot select predefined fields")
                return;
            }
            else if (this.rightSelected.includes(fc)) {
                const idx = this.rightSelected.findIndex(d => d == fc)
                this.rightSelected.splice(idx, 1)
                return;
            }else{
                this.rightSelected.push(fc);
            }
            console.log(this.rightSelected)
        },
        generateUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        handleTransfer(method) {
            if (method == 'right') {
                this.leftSelected.map(data => {
                    console.log(this.rightValue)
                    if (this.rightValue == "ticket" || this.rightValue == "Customer Invoice" || this.rightValue == "Opportunities" || this.rightValue == "Actions") {
                        // if (this.columns.length >= 15) {
                        //     this.$toasted.error("Maximum 15 columns are allowed")
                        //     return;
                        // }
                        // if (this.columns.length < 10) {
                        //     this.$toasted.error("Minimum 10 columns are required")
                        //     return;
                        // }
                        const isNotUnique = this.columns.some(col => col === data);
                        if(!isNotUnique){
                            this.columns.splice(this.columns.length - 2, 0, data)
                        }
                        this.columns = this.columns.filter(function (v, i, self) {
                            return i == self.indexOf(v);
                        });

                    } else {
                        console.log(this.columns)
                        const isNotUnique = this.columns.some(col => col === data);
                        if (!isNotUnique) {
                            this.columns.splice(this.columns.length - 2, 0, data)
                        }
                        this.columns = this.columns.filter(function (v, i, self) {
                            return i == self.indexOf(v);
                        });

                    }
                    const idx = this.bkeys.findIndex(d => d == data)
                    this.bkeys.splice(idx, 1)
                    this.$toasted.success("Added to right")
                    console.log(this.leftSelected);
                })
                this.leftSelected.length = 0;
                console.log(this.leftSelected);
                this.bkeys.sort();
            } else {
                console.log(this.rightSelected)
                console.log(this.columns)
                this.rightSelected.map(data => {
                    const isNotUnique = this.bkeys.some(col => col === data);
                    console.log(isNotUnique)
                    console.log(this.columns);
                console.log(this.bkeys.length);
                        if(!isNotUnique){
                            this.bkeys.splice(this.bkeys.length - 2, 0, data)
                        }
                        this.columns = this.columns.filter(val => !this.rightSelected.includes(val));
                        console.log(this.columns);
                console.log(this.bkeys.length);
                })
                this.rightSelected.length = 0;
                console.log(this.rightSelected)
                this.bkeys.sort();
            }
        },
        async saveKey() {
            try {
                console.log("clicked")
               if(this.rightValue==''){
                    return false;
               }
               this.businness_object_name = this.rightValue;
               this.selectedKeys = this.columns;
                // let finalFeeds = this.feedsConfig.map(d => d['business_object_name'] != this.businness_object_name ? d : null).filter(d => d != null)
                this.loading3 = true;
                let data = await Server.getAdminConfigs();
                console.log(data.data);
                let finalFeeds=[]
                if( data && data.data && data.data[0] && data.data[0].feeds_configuration){
                    finalFeeds = data.data[0].feeds_configuration;

                }
                console.log(this.businness_object_name)
                let idx=-1
              finalFeeds.map((d,index)=>{
                    if(d["business_object_name"]===this.businness_object_name){
                idx=index
              }})
                if(idx!=-1){
                    finalFeeds[idx]={ 'business_object_name': this.businness_object_name, keys: this.selectedKeys,layout:this.layout,appeq_supported_application:this.appeq_supported_application }
                 }else
                finalFeeds.push({ 'business_object_name': this.businness_object_name, keys: this.selectedKeys,layout:this.layout,appeq_supported_application:this.appeq_supported_application })
                let email = LocalUtils.getEmail();
                await Server.saveConfigurator({
                    "email": email,
                    feeds_configuration: finalFeeds
                });
                this.loading3 = false;
                this.$emit('close')
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>
<style scoped>
.heading{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
.options{
    display: flex;
    justify-content: space-evenly;
    height: 100px;
    align-items: center;
}
.options_left,.options_right{
    text-align: center;
}
.options_right{
    .multiselect__tags{
        width: 20rem;
    }
.multiselect{
    width: 310px !important;
}
}
</style>