import { reactive } from "vue";

export const keyStore = reactive({
  defaultKeys: [
    {
      data: [
            {
              "h": 2,
              "i": {
                "label": "By :",
                "value": "requester.email"
              },
              "moved": false,
              "w": 2,
              "x": 0,
              "y": 2
            },
            {
              "h": 2,
              "i": {
                "label": "Assignee :",
                "value": "assignee.email"
              },
              "moved": false,
              "w": 2,
              "x": 2,
              "y": 2
            },
            {
              "h": 2,
              "i": {
                "label": "Updated At :",
                "value": "updated_at"
              },
              "moved": false,
              "w": 2,
              "x": 4,
              "y": 2
            },
            {
              "h": 2,
              "i": {
                "label": "Priority :",
                "value": "priority"
              },
              "moved": false,
              "w": 2,
              "x": 2,
              "y": 0
            },
            {
              "h": 2,
              "i": {
                "label": "Status :",
                "value": "status"
              },
              "moved": false,
              "w": 2,
              "x": 4,
              "y": 0
            },
            {
              "h": 2,
              "i": {
                "label": "CSAT :",
                "value": "satisfaction_rating.score"
              },
              "moved": false,
              "w": 2,
              "x": 6,
              "y": 2
            },
            {
              "h": 2,
              "i": {
                "label": "",
                "value": "subject"
              },
              "moved": false,
              "w": 2,
              "x": 0,
              "y": 0
            },
            {
              "h": 2,
              "i": {
                "label": "Days Open :",
                "value": "created_at"
              },
              "moved": false,
              "w": 2,
              "x": 6,
              "y": 0
            }
          ],
      business_object_name: "ticket",
      appeq_supported_application:"zendesk"
    },
    {
      data: [ { "h": 1, "i": { "label": "ARR : ", "value": "ARR" }, "moved": false, "w": 2, "x": 0, "y": 0 }, { "h": 1, "i": { "label": "Billing Date : ", "value": "Billing Date" }, "moved": false, "w": 2, "x": 2, "y": 0 }, { "h": 1, "i": { "label": "Customer Name : ", "value": "Customer Name" }, "moved": false, "w": 2, "x": 4, "y": 0 }, { "h": 1, "i": { "label": "Due Date : ", "value": "Due Date" }, "moved": false, "w": 2, "x": 6, "y": 0 }, { "h": 1, "i": { "label": "Health : ", "value": "Health" }, "moved": false, "w": 2, "x": 0, "y": 1 }, { "h": 1, "i": { "label": "Invoice Amount : ", "value": "Invoice Amount" }, "moved": false, "w": 2, "x": 4, "y": 1 }, { "h": 1, "i": { "label": "Invoice Number : ", "value": "Invoice Number" }, "moved": false, "w": 2, "x": 6, "y": 1 }, { "h": 1, "i": { "label": "Primary Industry : ", "value": "Primary Industry" }, "moved": false, "w": 2, "x": 2, "y": 1 }, { "h": 1, "i": { "label": "Product Type : ", "value": "Product Type" }, "moved": false, "w": 2, "x": 4, "y": 2 }, { "h": 1, "i": { "label": "Region : ", "value": "Region" }, "moved": false, "w": 2, "x": 0, "y": 2 }, { "h": 1, "i": { "label": "Renewal Date : ", "value": "Renewal Date" }, "moved": false, "w": 2, "x": 2, "y": 2 }, { "h": 1, "i": { "label": "SalesforceId :", "value": "SalesforceId" }, "moved": false, "w": 2, "x": 6, "y": 2 }, { "h": 1, "i": { "label": "Status : ", "value": "Status" }, "moved": false, "w": 2, "x": 0, "y": 3 } ],
      business_object_name: "Customer Invoice",
      appeq_supported_application: "spreadsheet"
    },
    {
      data: [ { "h": 1, "i": { "label": "ARR : ", "value": "ARR" }, "moved": false, "w": 2, "x": 0, "y": 0 }, { "h": 1, "i": { "label": "Billing Date : ", "value": "Billing Date" }, "moved": false, "w": 2, "x": 2, "y": 0 }, { "h": 1, "i": { "label": "Customer Name : ", "value": "Customer Name" }, "moved": false, "w": 2, "x": 4, "y": 0 }, { "h": 1, "i": { "label": "Due Date : ", "value": "Due Date" }, "moved": false, "w": 2, "x": 6, "y": 0 }, { "h": 1, "i": { "label": "Health : ", "value": "Health" }, "moved": false, "w": 2, "x": 0, "y": 1 }, { "h": 1, "i": { "label": "Invoice Amount : ", "value": "Invoice Amount" }, "moved": false, "w": 2, "x": 4, "y": 1 }, { "h": 1, "i": { "label": "Invoice Number : ", "value": "Invoice Number" }, "moved": false, "w": 2, "x": 6, "y": 1 }, { "h": 1, "i": { "label": "Primary Industry : ", "value": "Primary Industry" }, "moved": false, "w": 2, "x": 2, "y": 1 }, { "h": 1, "i": { "label": "Product Type : ", "value": "Product Type" }, "moved": false, "w": 2, "x": 4, "y": 2 }, { "h": 1, "i": { "label": "Region : ", "value": "Region" }, "moved": false, "w": 2, "x": 0, "y": 2 }, { "h": 1, "i": { "label": "Renewal Date : ", "value": "Renewal Date" }, "moved": false, "w": 2, "x": 2, "y": 2 }, { "h": 1, "i": { "label": "SalesforceId :", "value": "SalesforceId" }, "moved": false, "w": 2, "x": 6, "y": 2 }, { "h": 1, "i": { "label": "Status : ", "value": "Status" }, "moved": false, "w": 2, "x": 0, "y": 3 } ],
      business_object_name: "Net Promoter Score",
      appeq_supported_application: "spreadsheet"
    },
    {
      data: [ { "h": 1, "i": { "label": "ARR : ", "value": "ARR" }, "moved": false, "w": 2, "x": 0, "y": 0 }, { "h": 1, "i": { "label": "Billing Date : ", "value": "Billing Date" }, "moved": false, "w": 2, "x": 2, "y": 0 }, { "h": 1, "i": { "label": "Customer Name : ", "value": "Customer Name" }, "moved": false, "w": 2, "x": 4, "y": 0 }, { "h": 1, "i": { "label": "Due Date : ", "value": "Due Date" }, "moved": false, "w": 2, "x": 6, "y": 0 }, { "h": 1, "i": { "label": "Health : ", "value": "Health" }, "moved": false, "w": 2, "x": 0, "y": 1 }, { "h": 1, "i": { "label": "Invoice Amount : ", "value": "Invoice Amount" }, "moved": false, "w": 2, "x": 4, "y": 1 }, { "h": 1, "i": { "label": "Invoice Number : ", "value": "Invoice Number" }, "moved": false, "w": 2, "x": 6, "y": 1 }, { "h": 1, "i": { "label": "Primary Industry : ", "value": "Primary Industry" }, "moved": false, "w": 2, "x": 2, "y": 1 }, { "h": 1, "i": { "label": "Product Type : ", "value": "Product Type" }, "moved": false, "w": 2, "x": 4, "y": 2 }, { "h": 1, "i": { "label": "Region : ", "value": "Region" }, "moved": false, "w": 2, "x": 0, "y": 2 }, { "h": 1, "i": { "label": "Renewal Date : ", "value": "Renewal Date" }, "moved": false, "w": 2, "x": 2, "y": 2 }, { "h": 1, "i": { "label": "SalesforceId :", "value": "SalesforceId" }, "moved": false, "w": 2, "x": 6, "y": 2 }, { "h": 1, "i": { "label": "Status : ", "value": "Status" }, "moved": false, "w": 2, "x": 0, "y": 3 } ],
      business_object_name: "New Feature Requests",
      appeq_supported_application: "spreadsheet"
    },
    {
      data: [ { "x": 0, "y": 0, "w": 2, "h": 2, "i": { "label": "Name : ", "value": "Name" }, "moved": false }, 
      { "x": 2, "y": 0, "w": 2, "h": 2, "i": { "label": "Type : ", "value": "Type" }, "moved": false }, 
      { "x": 4, "y": 0, "w": 2, "h": 2, "i": { "label": "#Emp : ", "value": "NumberOfEmployees" }, "moved": false }, 
      { "x": 6, "y": 0, "w": 2, "h": 2, "i": { "label": "Billing City : ", "value": "BillingCity" }, "moved": false }, 
      { "x": 0, "y": 2, "w": 2, "h": 2, "i": { "label": "Description : ", "value": "Description" }, "moved": false }],
      business_object_name: "accounts",
      appeq_supported_application: "salesforce"
    },
    {
      data: [ { "h": 2, "i": { "label": "Account Id : ", "value": "AccountId" }, "moved": false, "w": 2, "x": 0, "y": 0 }, { "h": 2, "i": { "label": "Name : ", "value": "Name" }, "moved": false, "w": 2, "x": 2, "y": 0 }, { "h": 2, "i": { "label": "Amount : ", "value": "Amount" }, "moved": false, "w": 2, "x": 4, "y": 0 }, { "h": 2, "i": { "label": "Close Date : ", "value": "CloseDate" }, "moved": false, "w": 2, "x": 6, "y": 0 }, { "h": 2, "i": { "label": "Stage : ", "value": "StageName" }, "moved": false, "w": 2, "x": 0, "y": 2 }, { "h": 2, "i": { "label": "Description : ", "value": "Description" }, "moved": false, "w": 2, "x": 2, "y": 2 }, { "h": 2, "i": { "label": "Probability : ", "value": "Probability" }, "moved": false, "w": 2, "x": 4, "y": 2 } ],
      business_object_name: "Opportunities",
      appeq_supported_application: "salesforce"
    },
    {
      data: [ { "h": 2, "i": { "label": "Name : ", "value": "Name" }, "moved": false, "w": 2, "x": 0, "y": 0 }, 
      { "h": 2, "i": { "label": "Email : ", "value": "Email" }, "moved": false, "w": 2, "x": 2, "y": 0 }, 
      { "h": 2, "i": { "label": "Title : ", "value": "Title" }, "moved": false, "w": 2, "x": 4, "y": 0 }, 
      { "h": 2, "i": { "label": "Department : ", "value": "Department" }, "moved": false, "w": 2, "x": 6, "y": 0 }, 
      { "h": 2, "i": { "label": "LeadSource : ", "value": "LeadSource" }, "moved": false, "w": 2, "x": 0, "y": 2 }, 
      { "h": 2, "i": { "label": "Phone : ", "value": "Phone" }, "moved": false, "w": 2, "x": 2, "y": 2 }, 
      { "h": 2, "i": { "label": "Created Date : ", "value": "CreatedDate" }, "moved": false, "w": 2, "x": 4, "y": 2 } ],
      business_object_name: "Contacts",
      appeq_supported_application: "salesforce"
    },
    {
      data: [ { "x": 0, "y": 0, "w": 2, "h": 2, "i": { "label": "Name : ", "value": "name" }, "moved": false }, 
      { "x": 2, "y": 0, "w": 2, "h": 2, "i": { "label": "Domain : ", "value": "domain" }, "moved": false }, 
      { "x": 4, "y": 0, "w": 2, "h": 2, "i": { "label": "Created on : ", "value": "createdate" }, "moved": false }, 
      { "x": 6, "y": 0, "w": 2, "h": 2, "i": { "label": "ID :", "value": "id" }, "moved": false }, 
      { "x": 0, "y": 2, "w": 2, "h": 2, "i": { "label": "Last Modified On : ", "value": "hs_lastmodifieddate" }, "moved": false }],
      business_object_name: "accounts",
      appeq_supported_application: "hubspot"
    },
    {
      data: [ { "h": 2, "i": { "label": "Name : ", "value": "dealname" }, "moved": false, "w": 2, "x": 0, "y": 0 }, { "h": 2, "i": { "label": "Amount : ", "value": "amount" }, "moved": false, "w": 2, "x": 2, "y": 0 }, { "h": 2, "i": { "label": "Created on : ", "value": "createdate" }, "moved": false, "w": 2, "x": 4, "y": 0 }, { "h": 2, "i": { "label": "Updated on :", "value": "updatedAt" }, "moved": false, "w": 2, "x": 6, "y": 0 }, { "h": 2, "i": { "label": "Deal Stage : ", "value": "dealstage" }, "moved": false, "w": 2, "x": 0, "y": 2 }, { "h": 2, "i": { "label": "Close Date : ", "value": "closedate" }, "moved": false, "w": 2, "x": 2, "y": 2 } ],
      business_object_name: "Opportunities",
      appeq_supported_application: "hubspot"
    },
    {
      data: [ { "x": 0, "y": 0, "w": 2, "h": 2, "i": { "label": "First Name : ", "value": "firstname" }, "moved": false }, 
      { "x": 2, "y": 0, "w": 2, "h": 2, "i": { "label": "Last Name : ", "value": "lastname" }, "moved": false }, 
      { "x": 4, "y": 0, "w": 2, "h": 2, "i": { "label": "Email : ", "value": "email" }, "moved": false }, 
      { "x": 6, "y": 0, "w": 2, "h": 2, "i": { "label": "Created on :", "value": "createdAt" }, "moved": false }],
      business_object_name: "Contacts",
      appeq_supported_application: "hubspot"
    },
    {
      data: [
        {
          "h": 2,
          "i": {
            "label": "By :",
            "value": "requester.email"
          },
          "moved": false,
          "w": 2,
          "x": 0,
          "y": 2
        },
        {
          "h": 2,
          "i": {
            "label": "Assignee :",
            "value": "assignee.email"
          },
          "moved": false,
          "w": 2,
          "x": 2,
          "y": 2
        },
        {
          "h": 2,
          "i": {
            "label": "Updated At :",
            "value": "updated_at"
          },
          "moved": false,
          "w": 2,
          "x": 4,
          "y": 2
        },
        {
          "h": 2,
          "i": {
            "label": "Priority :",
            "value": "priority"
          },
          "moved": false,
          "w": 2,
          "x": 2,
          "y": 0
        },
        {
          "h": 2,
          "i": {
            "label": "Status :",
            "value": "status"
          },
          "moved": false,
          "w": 2,
          "x": 4,
          "y": 0
        },
        {
          "h": 2,
          "i": {
            "label": "CSAT :",
            "value": "satisfaction_rating.score"
          },
          "moved": false,
          "w": 2,
          "x": 6,
          "y": 2
        },
        {
          "h": 2,
          "i": {
            "label": "",
            "value": "subject"
          },
          "moved": false,
          "w": 2,
          "x": 0,
          "y": 0
        },
        {
          "h": 2,
          "i": {
            "label": "Days Open :",
            "value": "created_at"
          },
          "moved": false,
          "w": 2,
          "x": 6,
          "y": 0
        }
      ],
      business_object_name: "ticket",
      appeq_supported_application: "freshdesk"
    },
  ],
});
