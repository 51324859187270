<template>
    <div>
        <div style="display: flex; align-items: center; justify-content: space-between;">
            <div>
                <div style="font-weight: 600; font-size: 18px; color: #676767;">Custom {{ businessObj }} Fields</div>
                <div style="font-size: 15px; color: #737171;">Custom fields can be used for deriving additional insights
                    and trends. <span style="color: blue; cursor: pointer;">Read More</span></div>
            </div>
            <div>
                <button @click="openNewCustomFieldModal = true;"
                    style="background-color: #7070ff; padding: 5px; width: 140px; height: 40px; font-size: 18px; font-weight: 600; border: none; border-radius: 7px; cursor: pointer; outline: none; margin-right: 1rem;">New
                    Field</button>
            </div>
        </div>
        <div style="margin: 20px;">
            <!-- <div>
                <div style="display: flex; align-items: center; justify-content: space-between; padding: 10px; background: #e5e5e5;">
                    <div v-for="data in tableColumns" :style="{'width': data.width, textAlign: 'center', color: '#635f5f', fontSize: '16px'}">
                    {{ data.title }}
                </div>
                </div>
                <div style="overflow: scroll; height:360px;">
                    <div v-for="(item, idx ) in tableData" style="display: flex; padding: 10px; align-items: center;" :style="{'background': (item['child']) ? 'aliceblue': (idx==selectedIdx) ? '#9696ff' : 'none', 'justify-content': (item['child']) ? 'space-around' : 'space-between', 'width': (item['child'])?'96.5%':'', 'margin-left': (item['child'])?'1rem':''}">
                    <div v-for="data in tableColumns" :style="{'width': data.width, 'text-align': (item['child'] && data.key=='actions')?'end': (item['child'] && data.key=='name')?'left':'center'}">
                        <span v-if="data.key=='name'" style="color: #4f4f4f; font-size: 14px;" :style="{'font-weight': (idx==selectedIdx) ? '600':'normal'}">
                            {{ item[data.key] }}
                        </span>
                        <span v-else-if="data.key=='category'" style="color: #4f4f4f; font-size: 14px;" :style="{'font-weight': (idx==selectedIdx) ? '600':'normal'}">
                            {{ item[data.key] }}
                        </span>
                        <span v-else-if="data.key=='type'" style="color: #4f4f4f; font-size: 14px;">
                            {{ item[data.key] }}
                        </span>
                        <span v-else-if="data.key=='details'" style="color: #4f4f4f; font-size: 14px;">
                            {{ item[data.key] }}
                        </span>
                        <span v-else-if="data.key=='email'" style="color: #4f4f4f; font-size: 14px;">
                            {{ item[data.key].split("@")[0] }}
                        </span><span v-else :style="{'margin-left': (item['category']=='timeseries')?'26px':'0px'}">
                            <img v-if="!item['child']" @click="handleDelete(item)" src="../assets/deleteIconRed.webp" style="cursor: pointer; height: 30px;" alt="">
                            <img @click="handleTimeSeriesData(item, idx)" v-if="item['category']=='timeseries' && !item['child'] && (!showTimeSeriesData || selectedIdx!=idx)" src="../assets/downarrow.png" style="height: 16px; position: relative; cursor: pointer; margin-left: 10px; margin-top: 0px; filter: invert(100%) sepia(1%) saturate(3832%) hue-rotate(177deg) brightness(57%) contrast(80%);" alt="">
                            <img @click="handleCloseTimeSeriesData(item, idx)" v-if="item['category']=='timeseries' && !item['child'] && showTimeSeriesData && selectedIdx==idx" src="../assets/up-arrow.png" style="height: 16px; position: relative; cursor: pointer; margin-left: 10px; margin-top: 0px;" alt="">
                        
                        </span>
                    </div>
                </div>
                </div>
            </div> -->
            <table v-if="!loading" style="width: 100%;">
                <tr>
                    <th :style="{ width: data.width }" v-for="data in tableColumns">
                        {{ data.title }}
                    </th>
                </tr>
                <tr style="margin-top: 20px;" v-for="(item, idx ) in tableData">
                    <td style="padding: 8px" v-for="data in tableColumns">
                        <div v-if="data.key != 'action'" style="color: #4f4f4f !important;  font-size: 14px; overflow: hidden;      white-space:nowrap; 
        text-overflow: ellipsis;
" class="">
                            {{ item[data.key] }}
                        </div>
                        <span v-else>
                            <img v-if="!item['child']" @click="handleDelete(item)" src="../assets/deleteIconRed.webp"
                                style="cursor: pointer; height: 30px;" alt="">

                        </span>
                    </td>
                </tr>
            </table>
            <div v-else style="display: flex; align-items: center; justify-content: center;">
                <img src="../assets/loadingRipple.svg" style="height: 200px;" alt="">
            </div>
        </div>
        <div v-if="openNewCustomFieldModal"
            style="height: 100%; width: 100%; position: fixed; top: 0px; left: 0px; z-index: 9999999999999; background-color: rgba(0, 0, 0, 0.2); border-radius: 9px;">
            <div v-if="openNewCustomFieldModal"
                style="position: fixed; right: 2%; top: 12%; background-color: white; border: 0.2px solid grey; width: 55%; height: 75vh; border-radius: 10px; z-index: 9999999999; 
    padding: 5px 0px; box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; ">
                <NewCustomFieldModal @save="handleSave" :bObj="businessObj" @close="openNewCustomFieldModal = false;" />
            </div>
        </div>
    </div>
</template>

<script>
import Server from "./API/Server";
import NewCustomFieldModal from "./NewCustomFieldModal.vue";
export default {
    data() {
        return {
            tableColumns: [
                { 'key': 'name', 'title': 'Name', 'width': '20%' },
                { 'key': 'category', 'title': 'Category', 'width': '18%' },
                { 'key': 'desc', 'title': 'Description', 'width': '30%' },
                { 'key': 'email', 'title': 'Created By', 'width': '20%' },
                { 'key': 'action', 'title': 'Action', 'width': '12%' }
            ],
            tableData: [],
            showTimeSeriesData: false,
            parentData: false,
            loading: false,
            businessObj: "",
            selectedIdx: -1,
            openNewCustomFieldModal: false,
        }
    },
    props: {
        bObject: "",
    },
    methods: {
        async handleDelete(item) {
            const data = (await Server.deleteCustomFilds(item))
            this.$toasted.success("Record Deleted Successully")
            const dt = (await Server.getCustomAccountfields(this.businessObj)).data.data
            this.tableData = dt

        },
        async handleSave() {
            const data = (await Server.getCustomAccountfields(this.businessObj)).data.data
            this.tableData = data
            this.openNewCustomFieldModal = false;
        },
        handleTimeSeriesData(data, idx) {
            let count = 0;
            console.log(this.tableData);
            for (let i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].child) {
                    console.log('child found');
                    this.tableData.splice(i, 1);
                    count++;
                    i--;
                }
            }
            this.selectedIdx = idx - count;
            this.showTimeSeriesData = true;
            let val = data.appeqKey;
            let email = data.email;
            if (val.length === 0) {
                return;
            }
            let newData = [];
            for (let i = 0; i < val.length; i++) {
                newData.push({ 'name': val[i], 'category': 'timeseries', 'email': email, 'child': true });
            }
            this.tableData = [...this.tableData.slice(0, idx + 1), ...newData, ...this.tableData.slice(idx + 1)];
        },
        handleCloseTimeSeriesData(data, idx) {
            this.showTimeSeriesData = false;
            this.selectedIdx = -1;
            let val = data.appeqKey;
            let email = data.email;
            if (val.length === 0) {
                return;
            }
            let newData = [];
            for (let i = 0; i < val.length; i++) {
                newData.push({ 'name': val[i], 'category': 'timeseries', 'email': email, 'child': true });
            }
            for (let i = 0; i < this.tableData.length; i++) {
                console.log('code there')
                for (let j = 0; j < newData.length; j++) {
                    console.log('code near');
                    if (this.tableData[i].name === newData[j].name && this.tableData[i].category === newData[j].category) {
                        console.log('code here')
                        this.tableData.splice(i, 1);
                        i--;
                        break;
                    }
                }
            }
        }
    },
    async mounted() {
        this.loading = true;
        this.tableData = [];
        this.businessObj = this.bObject;
        const data = (await Server.getCustomAccountfields(this.businessObj)).data.data
        this.tableData = data
        console.log(this.tableData)
        this.tableData = this.tableData.map(d => {
            d.desc = `${d.category} on ${d.performOperationOnKey}`
            return d
        })

        this.loading = false;
    },
    components: { NewCustomFieldModal }
}
</script>