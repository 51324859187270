export const functions = ["COUNT", "VALUE", "DIFF"];

export const operetors = [
  { label: "Equal to", value: "==", query: "$eq" },
  { label: "Greater Than", value: ">", query: "$gt" },
  { label: "Not Equal to", value: "!=", query: "$ne" },

  { label: "Less Than", value: "<", query: "$lt" },
  { label: "Greater Than Equal to", value: ">=", query: "$gte" },
  { label: "Less Than Equal to", value: "<=", query: "$lte" },
  { label: "In", value: "in", query: "$in" },
  { label: "Not In", value: "not in", query: "$nin" },




];

export const dateops = [
  { label: "Age Equal to", value: "==", query: "$ageeq" },
  { label: "Age Greater Than", value: ">", query: "$agegt" },
  { label: "Age Not Equal to", value: "!=", query: "$agene" },

  { label: "Age Less Than", value: "<", query: "$agelt" },
  { label: "Age Greater Than Equal to", value: ">=", query: "$agegte" },
  { label: "Age Less Than Equal to", value: "<=", query: "$agelte" },
];
