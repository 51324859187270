<template >
    <div>
        <div v-if="!showInModal" class="card" style="margin-left: 3px;">
            <div style="justify-content: space-between;padding:20px;margin: 0px;align-items: center;" class="row">
                <div>
                    <span style="font-size: 26px;font-weight: 700;">Good {{ timeofday }}, <span
                            style="text-transform: capitalize;">{{ email.split("@")[0] }} </span> 👋</span>
                    <br />
                    <span style="font-size: 15px;font-weight: 500;">
                        🚀 Unlock valuable insights about your accounts to drive revenue retention
                    </span>
                </div>
            </div>
        </div>
        <div style="padding: 20px;" class="card">
            <div style="display: flex;align-items: center;justify-content: center;">
                <!-- <Multiselect v-model="businessObject" :options="['ticket','Opportunities']"/> -->
                <div></div>

                <h2 v-if="!showInModal" id="my_customer_header"
                    style="text-align:center;margin-left:30px;margin-bottom: 4px;">My Customers</h2>


            </div>
            <br>
            <div v-if="!showInModal" style="display: flex;align-items: center;justify-content: flex-end;">
                <button v-if="allCreds.length == 0" style="width: 15%;height: 40px;" @click="handleOpenModal()">Add</button>

            </div>


            <div v-if="!showInModal"
                style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;">
                <div style="display: flex;align-items: center;margin-bottom: 10px;margin-left: 10px;">

                    <div @click="changeTab(0)"
                        :style="{ borderBottom: selectedTab == 0 ? '2px solid blue' : '', fontWeight: selectedTab == 0 ? '700' : '400' }"
                        style="cursor: pointer;padding: 5px;">All Customers</div>

                    <div @click="changeTab(1)"
                        :style="{ borderBottom: selectedTab == 1 ? '2px solid blue' : '', fontWeight: selectedTab == 1 ? '600' : '400' }"
                        style="margin-left: 20px;cursor: pointer;padding: 5px;">My Customers</div>
                    <div @click="changeTab(2)"
                        :style="{ borderBottom: selectedTab == 2 ? '2px solid blue' : '', fontWeight: selectedTab == 2 ? '600' : '400' }"
                        style="margin-left: 20px;cursor: pointer;padding: 5px;">My Watchlist</div>

                </div>
                <div style="display: flex;align-items: center;">
                    <input v-model="searchField" v-on:keyup.enter="searchChange"
                        style="border-radius: 20px;margin-right: 10px;border: none;border-bottom: 1px solid grey;"
                        placeholder="search accounts" v-if="openSearchBox" />
                    <span v-if="openSearchBox" @click="openSearchBox = false" style="cursor: pointer;margin-right: 10px;"
                        class="material-symbols-outlined">
                        close
                    </span>


                <!-- </span></span> -->
                <span v-tooltip="'Search'"><span v-if="!openSearchBox" @click="openSearchBox = true" style="cursor: pointer;margin-right: 10px;"
                        class="material-symbols-outlined">
                        Search</span>
                    </span>
                    <span v-tooltip="'Download'"><span v-if="!openSearchBox" @click="handleDownload()" style="cursor: pointer;margin-right: 10px;"
                        class="material-symbols-outlined">
                        download</span>
                    </span>

                    <span v-tooltip="'Settings'"><span @click="handleClickColumns()" style="cursor: pointer;" class="material-symbols-outlined">
                        settings</span>
                    </span>
                </div>

            </div>


            <div style="height: 50vh;">

                <img style="height: 120px;display: flex;
        align-items: center;justify-content: center;position: absolute;left: 45%;" v-if="loading"
                    src="../assets/loadingRipple.svg">

                <ve-table
                v-if="!loading"
                :fixed-header="true" :max-height="380" :virtual-scroll-option="virtualScrollOption"
                   :columns="columns" :table-data="this.selectedOrgs"
                    :sort-option="sortOption" :cell-style-option="cellStyleOption">
                </ve-table>
                <div v-if="selectedOrgs.length == 0" style="display: flex;align-items: center;flex-direction: column;    flex-direction: column;
    position: absolute;
    left: 42%;
    top: 40%;">
                    <img style="height: 200px;width: 200px;" src="../assets/nodata.svg" />
                    <span>
                        No Customers present

                    </span>
                </div>

            </div>

            <div v-if="allCustomers.length"
                style="text-align: center;display: flex;align-items: center;justify-content: center;margin-top: 40px;">
                <ve-pagination @on-page-number-change="pageNumberChange" :total="totalDataCount" :page-size="10" />

            </div>
        </div>

        <br />
        <Modal class="invite_user_modal" v-if="loadingModal" :show="loadingModal">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;">
                <img src="../assets/infinity_blue.svg" />
            </div>
        </Modal>
        <Modal class="invite_user_modal" @close="handleClose()" v-if="showAddModal" :show="showAddModal">
            <i @click="handleClose()" class="tim-icons close-icon icon-simple-remove"></i>
            <h3 id="my_customer_modal_add_customer">Edit Customers -
                {{ accountData && accountData.name ? accountData.name : '' }}
            </h3>
            <CustomerForm @close="handleCloseAddModal()" :accountData="accountData"></CustomerForm>
        </Modal>

        <Modal class="invite_user_modal" :show="showEditColumns">
            <i @click="handleCloseColumns" class="tim-icons close-icon icon-simple-remove"></i>

            <h2 style="text-align: center;">Select columns you want to display </h2>
            <div style="display: flex;justify-content: space-between;margin-top: 10px;">
                <div style="border: 1px solid grey;width: 40%;height: 250px;overflow: auto;padding: 10px;cursor: pointer;">
                    <div :style="{ 'border': leftSelected.includes(col) ? '1px solid green' : '', 'padding': '10px' }"
                        @click="handleLeftSelected(col)" v-for="col of bkeys">
                        {{ col }}
                    </div>
                </div>
                <div
                    style="height: 250px;display: flex;align-items: center;justify-content: center;flex-direction: column;">
                    <span @click="handleTransfer('right')"
                        :style="{ 'opacity': leftSelected.length == 0 ? '0.5' : '', 'cursor': 'pointer' }"
                        class="material-symbols-outlined">
                        arrow_forward_ios
                    </span>
                    <br />
                    <span @click="handleTransfer('left')"
                        :style="{ 'opacity': rightSelected.length == 0 ? '0.5' : '', 'cursor': 'pointer' }"
                        class="material-symbols-outlined">
                        arrow_back_ios
                    </span>
                </div>
                <div style="border: 1px solid grey;width: 40%;height: 250px;overflow: auto;padding: 10px;cursor: pointer;">
                    <div v-for="col of columns" style="display: flex;
    justify-content: space-between;">
                        <span @click="handleRightSelected(col)" :style="{ 'border': rightSelected.includes(col) ? '1px solid green' : '', 'padding': '10px', 'margin-top': '10px', 'display': 'flex', 'justify-content': 'space-between', 'width': '75%' }">
                            {{ col.field }}
                        </span>
                        <span style="padding: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;">
                            <span @click="sendSelectedToTop(col)" :style="{color: (columns[0].field == col.field) ? '#a2a5a2' : ''}" class="material-symbols-outlined">expand_less</span>
                            <span @click="sendSelectedToBottom(col)" :style="{color: (columns[columns.length-1].field == col.field) ? '#a2a5a2' : ''}" class="material-symbols-outlined">expand_more</span>
                        </span>
                    </div>
                </div>

            </div>
            <button @click="handleSaveColumns"
                style="width: 20%;margin-top: 10px;height: 35px;position: absolute;right: 10px;">Save</button>
        </Modal>
        <Modal v-if="openGraphModal" :show="openGraphModal">
            <i @click="openGraphModal = false" class="tim-icons close-icon icon-simple-remove"></i>
            <h2 style="text-align: center;">Trending Data</h2>
            <Multiselect :label="'key'" @select="chabgeBobject()" v-model="selectedBObject" :options="occpBusiness" />
            <img style="height: 120px;display: flex;
        align-items: center;justify-content: center;position: absolute;left: 40%;" v-if="loadingGraphData"
                src="../assets/loadingRipple.svg">
            <LineChart v-if="!loadingGraphData" :chartData="chartData" />
            <div style="display: flex;align-items: center;justify-content: center;">
                <button style="width: 40%;" @click="saveAscolumnstrends">Add To Table</button>
            </div>
        </Modal>
        <Modal v-if="csmEdit" :show="csmEdit">
            <i @click="csmEdit = false" class="tim-icons close-icon icon-simple-remove"></i>
            <h2 style="text-align: center;">Assign CSM</h2>
            <span>Select a user to assign as CSM</span>
            <Multiselect :multiple="true" v-model="selectedUser" :options="users" />
            <button @click="handleSaveCsmData" style="margin-top: 50%;">Save</button>
        </Modal>
        <Modal v-if="openPulseModal" :show="openPulseModal">
            <i @click="openPulseModal = false" class="tim-icons close-icon icon-simple-remove"></i>
            <h2 style="text-align: center;">Account Pulse</h2>
            <div style="display: flex;align-items: center;justify-content: space-between;">
                <input type="radio" v-model="health" name="radAnswer" value="red" placeholder="6m" />
                <div style="color: white;background-color: red;padding: 0px 10px;">RED</div>
                <input type="radio" v-model="health" name="radAnswer" value="orange" placeholder="6m" />
                <div style="color: white;background-color: orange;padding: 0px 10px;">ORANGE</div>
                <input type="radio" v-model="health" name="radAnswer" value="green" placeholder="6m" />
                <div style="color: white;background-color: greenyellow;padding: 0px 10px;">GREEN</div>
            </div>
            <Multiselect :options="health == 'green' ? reasonListGood : reasonList" v-model="selectedReason" @select="handleReasons()" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" />
            <textarea style="margin-top: 20px;width: 100%;border: 1px solid #e8e8e8;" placeholder="Enter your comment"
                rows="4" v-model="descselected" />
            <button :disabled="!(health && reasons && selectedReason.length>0 && descselected)" @click="handleSaveAccountPulse">Save</button>
        </Modal>
        <Modal v-if="openPulseInfo" :show="openPulseInfo">
            <i @click="openPulseInfo = false" class="tim-icons close-icon icon-simple-remove"></i>
            <h2 style="text-align: center;">Account Pulse</h2>
            <!-- <span style="font-weight: 600;text-align: center;text-transform: capitalize;">
            {{ pulseInfo[0]['account_domain'] }}
           </span>  -->

            <div style="display: flex;align-items: center;border: 1px solid #e8e8e8;padding: 10px;" class="trends">
                <div @click="selectedPulse = index"
                    :style="{ background: info.health, boxShadow: selectedPulse == index ? 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px' : '' }"
                    style="width: 20px;height: 80px;margin-left: 10px;cursor: pointer;" v-for=" info, index of pulseInfo">

                </div>
            </div>
            <br />
            <span style="font-weight: 700;font-size: 15px;">
                Reason: {{ selectedPulse != -1 ? pulseInfo[selectedPulse]['reason'] : '' }}

            </span>
            <br />
            <span style="font-weight: 500;font-size: 12px;">
                {{ selectedPulse != -1 ? pulseInfo[selectedPulse]['description'] : '' }}

            </span>
            <br />
            <span style="font-weight: 500;font-size: 12px;">
                Created At: {{ selectedPulse != -1 ? getHumanDate(pulseInfo[selectedPulse]['created_date']) + ' ago' : '' }}
            </span>
        </Modal>
        <Modal class="account-status-modal" v-if="showModalAccountStatus" :show="showModalAccountStatus">
            <i @click="toggleBack()" class="tim-icons close-icon icon-simple-remove"></i>
            <h2 style="text-align: center;">Account Status</h2>
            <AccountStatusModal :status="statusSelected" :previousAccountStatus="previousAccountStatus" @save="initData" :account="openAccountsForStatus"/>
        </Modal>
        <Modal class="account-status-modal" v-if="showPlayBookModal" :show="showPlayBookModal">
            <i @click="toggleBackFromPlaybook()" class="tim-icons close-icon icon-simple-remove"></i>
            <h2 style="text-align: center; margin-bottom: 10px; font-size: 30px;">Playbook</h2>
            <TriggerPlaybookModal @close="showPlayBookModal=false" :account_id="accountIdPlaybook" :domain="accountDomainPlaybook" @save="initData"/>
        </Modal>
    </div>
</template>
<script>
import Server from '../components/API/Server';
import Modal from '../components/Modal.vue';
import moment from 'moment';
import AccountStatusModal from './AccountStatusModal.vue';
import TriggerPlaybookModal from './TriggerPlaybookModal.vue';
import Bar from "../components/Charts/BarChart.js"
import LineChart from "../pages/LineChart.js"
import "vue-easytable/libs/theme-default/index.css"; // import style
import CustomerForm from '../components/CustomerForm.vue';
import Multiselect from 'vue-multiselect';
import SummaryViewForShowingSummary from '../components/SummaryViewForShowingSummary.vue';
import debounce from "debounce"
import VueEasytable from "vue-easytable"; // import library
import LocalUtils from '../components/Utils/LocalUtils';
import VueToggles from "vue-toggles/dist/vue-toggles.ssr";


export default {
    components: {
        Modal,
        LineChart,
        CustomerForm,
        VueEasytable,
        Multiselect,
        Bar,
        AccountStatusModal,
        TriggerPlaybookModal,
        SummaryViewForShowingSummary
    },
    props: {
        showInModal: {
            type: Boolean,
            default: false
        },
        user: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            valueStatus: '',
            notesValue: '',
            effectiveDateValue: '',
            optionsStatus: ['Active', 'Inactive'],
            valueReason: '',
            optionsReason: ['Trail', 'Paid Plan', 'Churn'],
            error1: '',
            statusSelected:false,
            showGraphData: false,
            showEdit: false,
            timeofday: "Morning",
            selectedTab: 1,
            email: "",
            isManager: false,
            loadingModal: false,
            openPulseModal: false,
            openPulseInfo: false,
            pulseInfo: [],
            searchField: '',
            keys: [],
            selectedReason: '',
            descselected: '',
            pulseInfo: false,
            selectedPulse: -1,
            reasonList: ["Low Product Usage", 'Relationship and Engagment Risk', 'Support Risk', 'Other Risk'],
            reasonListGood: ["Good Product Usage", 'Good Relationship and Engagment', 'Good Support Feedback', 'Other Reason'],

            selectedKeys: "",
            cellStyleOption: {
                headerCellClass: ({ column, rowIndex }) => {
                    return "table-header-cell-class";
                },
            },
            showEditColumns: false,
            businessObject: 'ticket',
            showMenu: [],
            openDropdownIndex: -1,
            accountDomainForDropdown: -1,
            myOrgs: [],
            Opportunities_app: '',
            leftSelected: [],
            rightSelected: [],
            ticket_app: '',
            virtualScrollOption: {
                enable: true,
            },
            allCreds: {},
            csmEdit: false,
            newSelectedOrg: [],
            barOptions: {},
            totalDataCount: 0,
            loadingGraphData: false,
            chartData: {
                labels: [
                ],
                datasets: [
                    {
                        label: "Deals",
                        data: []
                    }
                ]
            },
            ticketChartData: {
                labels: [

                ],
                datasets: [
                    {

                        data: []
                    }
                ]
            },
            sortOption: {
                sortChange: (params) => {
                    console.log("sortChange::", params);
                    this.sortChange(params);
                },
            },
            occpBusiness: [],
            users: [],
            health: '',
            selectedUser: [],
            selectedIndex: [],
            openGraphModal: false,
            accountDomainForGraph: -1,
            allCustomers: [],
            finalOrgs: [],
            showSummary: -1,
            selectedOrgs: [],
            toggleCondition: false,
            showModalAccountStatus: false,
            showPlayBookModal: false,
            accountDomainPlaybook: '',
            accountIdPlaybook: '',

            selectedBObject: 'accounts',
            openSearchBox: false,
            previousAccountStatus:[],
            labels: {
                ticket: ['Total Open Tickets', 'Total High Or Urgent Tickets', 'Total Opportunities', 'Open Opportunities'],
                // Opportunities:['Total Opportunities','Open Opportunities','Pending Opportunities','Total ARR/Expected ARR'],

            },
            accountData:{},
            reasons: '',
            openAccountsForStatus:{},
            queies: {
                ticket: [{ business_object_name: "ticket", status: "open" }, { business_object_name: "ticket", priority: "high" }, { 'business_object_name': "Opportunities" }, { "$or": [{ hs_is_closed: "true" }, { IsClosed: true }] }],
                // Opportu.nities:['{business_object_name:"Opportunities"}','Open Opportunities','Pending Opportunities','Total ARR/Expected ARR'],

            },
            topData: {
                ticket: [],
                Opportunities: []
            },
            showAddModal: false,
            bkeys: [],
            loading: false,
            originalColumns: [],
            columns: [
                {
                    field: "name",
                    key: "a",
                    title: "Customer Name",
                    business_object_name: "Opportunities",
                    sortBy: "",
                    align: "left",
                    width: "25%",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        const text = row[column.field] ? row[column.field] : row.domain;
                        const src = this.getIcon(row.domain)
                        return (
                            <span v-tooltip={{ 'content': text }} class="text-bold" style="color:#1890ff;">
                                <img id={row.domain} onError={e => { e.target.src = 'https://img.freepik.com/free-vector/abstract-company-logo_53876-120501.jpg' }} style={{ 'height': '30px', 'marginRight': '10px', 'width': '30px', 'border-radius': '50%', 'object-fit': 'contain' }} src={src} />
                                {text.toString().substr(0, 25)}
                            </span>
                        );
                    },


                },
                {
                    field: "CSM",
                    key: "adlk",
                    title: "CSM",
                    business_object_name: "Opportunities",
                    width: "12%",

                    align: "left",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        const text = row[column.field];

                        return (
                            <span style="display:flex;align-items:center;font-size:12px">
                                {text&&Array.isArray(text) ? text.map(data => <div style={{marginLeft:'2px',textTransform:'capitalize'}}  v-tooltip={{ 'content': data }}>{data.toString().split('@')[0]}</div>) : 'NA'}
                                <span v-tooltip={{ 'content': 'Edit' }} onClick={async (e) => { this.csmEdit = true;console.log(text); text!=undefined && Array.isArray(text)?(this.selectedUser=text):(this.selectedUser=[]);this.accountDomainForGraph = row;this.users=[]; let data = (await Server.getAllUsers()).data; for(let i of data){ if(i.role == "Admin" || i.role == "CSM" || i.role == "Manager" || i.isAdmin==true){ this.users.push(i.email_id); } } }} style="margin-left:10px;font-size:14px;cursor:pointer;" class="material-symbols-outlined">edit</span>
                            </span>
                        );
                    },

                },
                {
                    field: "arr", title: "ARR", business_object_name: "accounts", "key": this.generateUUID(), sortBy: "", type: "key"

                },

                {
                    field: "health",
                    key: "e",
                    title: "Account Health",
                    business_object_name: "Opportunities",

                    align: "left",
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        const text = row[column.field];
                        const src = this.getIcon(text)
                        return (
                            <span class="text-bold" style={{ color: 'white', background: text, padding: '5px', borderRadius: '5px', textTransform: 'capitalize' }}>
                                {{ text }}
                            </span>
                        );
                    },

                },
                {
                    field: "pulsesettings",
                    key: "pulse",
                    title: "Account Pulse",
                    business_object_name: "Opportunities",

                    align: "left",
                    'renderBodyCell': ({ row, column, rowIndex }, h) => {
                        let text = row[column.field];

                        if (text && Array.isArray(text) && text.length > 3) {
                            text = text.slice(0, 3)
                        }
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '70px', border: '1px solid grey', height: '30px', padding: '5px 0px', display: 'flex', alignItems: 'center' }}>
                                    {text.map((data, idx) => <span v-tooltip={{ 'content': data.reason + "<br/>" + (data.description != undefined ? data.description.substr(0, 20) + "..." : '') }} onClick={(e) => { this.openPulseInfo = true; this.pulseInfo = row[column.field]; this.selectedPulse = row[column.field].length-idx-1 }} style={{ background: data.health, width: '8px', height: '20px', display: 'block', marginLeft: '10px', cursor: 'pointer' }}></span>)}
                                </div>
                                {text.length > 0 ? <span onClick={(e) => { this.openPulseModal = true; this.accountDomainForGraph = row; this.selectedReason = ''; this.descselected = ''; this.health = '' }} style={{ color: 'blue', fontSize: '10px', marginLeft: '4px', cursor: 'pointer' }}>Set Pulse </span> : <button onClick={(e) => { this.openPulseModal = true; this.accountDomainForGraph = row; this.selectedReason = ''; this.descselected = ''; this.health = '' }} style="height: 22px;border-radius: 5px; width: 80px;margin-left:4px;font-size:10px">Set pulse</button>}
                            </div>
                        );
                    }
                },
                                  {
                                    field:'actions',
                                    key:'actions',
                                    business_object_name:"action",
                                    title:"Actions",
                                    align:"left",
      renderBodyCell : ({ row, column, rowIndex }, h) => {
                         const src = '../assets/eye.png'
                        const isDropdownOpen = (this.openDropdownIndex === rowIndex && this.accountDomainForDropdown === row.domain);

                         let checked=row.accountStatus&&Array.isArray(row.accountStatus)?(row.accountStatus[row.accountStatus.length-1].status=='Active'):false
                      return (
                        <div style={{overflow: "visible"}}>
                                <div style={{display:'flex',justifyContent:'flex-end',position: 'relative'}}>
                               
                                <span v-tooltip={{ 'content': 'Edit' }}>
                                <span style="margin-left:10px;cursor:pointer; font-size: 18px; margin-top: 5px;"  onClick={(e) => { this.handleOpenAccountData(row.domain, row.id) }} class="material-symbols-outlined summary_action_visibility">
                                    Edit
                                </span>
                            </span>
                           
                            <span>
                                <span v-tooltip={{ 'content': 'Menu' }} style="margin-left:5px;cursor:pointer; font-size: 20px; margin-top: 5px;" class="material-symbols-outlined summary_action_visibility" onClick={(e)=>{this.toggleMenu(rowIndex, row)}}>
                                    more_vert
                                </span>
                                <div class='item_list' style={{ display: isDropdownOpen ? 'block' : 'none', position: 'absolute', top: 'auto', bottom: 'calc(100% + 5px)', left: '0', background: 'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '5px',zIndex: 999, borderRadius: '8px',
    padding: '5px 7px'}}>
                                    <div style={{ cursor: 'pointer', width: 'max-content', display: 'flex',position: 'relative', height: '34px',
    alignItems: 'center'}} onClick={(e)=>{this.showPlayBookModal=true; this.accountDomainPlaybook=row.domain;this.accountIdPlaybook=row.id}}><span class="material-symbols-outlined">book</span> Trigger playbook</div>
                                </div>
                            </span>
                            </div>
                            </div>
                     
                        );
                     }
                                  }

            ]
        }
    },
    async mounted() {
        try {
            this.email = LocalUtils.getEmail()
            this.isManager = localStorage.getItem("role") == "Manager"
            var today = new Date()
            var curHr = today.getHours()

            if (curHr < 12) {
                this.timeofday = 'morning'
            } else if (curHr < 18) {
                this.timeofday = 'afternoon'
            } else {
                this.timeofday = 'evening'
            }
            const allCreds = JSON.parse(localStorage.getItem("ALL_CREDS"));
            allCreds.map(data => {
                if (data.trends && data.trends.enable) {
                    data.trends.keys.map(d => {
                        this.occpBusiness.push({
                            business_object_name: data.business_object_name,
                            key: d
                        })
                    })

                }
            })
            // this.columns.splice(1, 0, {
            //         field: "CSM", title: "CSM", business_object_name: "pulse", "key": this.generateUUID(), sortBy: "",
            //     })
            //     this.columns.splice(1, 0, {
            //         field: "pulsesettings", title: "Account Pulse", business_object_name: "pulse", "key": this.generateUUID(), sortBy: "",
            //     })
            const cols = {}
            if (cols.columns) {
                this.columns = cols.columns;
                this.originalColumns = [...cols.columns];
                this.columns.map((d) => {
                    console.log(d.field)
                    if (d.field == "CSM") {
                        d['renderBodyCell'] = ({ row, column, rowIndex }, h) => {
                            const text = row[column.field];
                            const src = this.getIcon(text)

                            return (
                                <span style="display:flex;align-items:center;font-size:12px">
                                    {Array.isArray(text) ? text.map(data => <div style={{ marginLeft: '2px', textTransform: 'capitalize' }} v-tooltip={{ 'content': data }}>{data.toString().split('@')[0]}</div>) : 'NA'}
                                    <span onClick={async (e) => { this.csmEdit = true; console.log(text); text != undefined && Array.isArray(text) ? (this.selectedUser = text) : (this.selectedUser = []); this.accountDomainForGraph = row; this.users = (await Server.getAllUsers()).data.map(d => d.email_id) }} style="margin-left:10px;font-size:14px;cursor:pointer;" class="material-symbols-outlined">edit</span>
                                </span>
                            );
                        }
                    }
                    if (d.field == 'name') {
                        d['renderBodyCell'] = ({ row, column, rowIndex }, h) => {
                            const text = row[column.field] ? row[column.field] : row.domain;
                            const src = this.getIcon(row.domain)
                            return (
                                <span v-tooltip={{ 'content': text }} class="text-bold" style="color:#1890ff;">
                                    <img id={row.domain} onError={e => { e.target.src = 'https://img.freepik.com/free-vector/abstract-company-logo_53876-120501.jpg' }} style={{ 'height': '30px', 'marginRight': '10px', 'width': '30px', 'border-radius': '50%', 'object-fit': 'contain' }} src={src} />
                                    {text.toString().substr(0, 25)}
                                </span>
                            );
                        }
                    }
                    if (d.field == 'health') {
                        d['renderBodyCell'] = ({ row, column, rowIndex }, h) => {
                            const text = row[column.field];
                            const src = this.getIcon(text)
                            return (
                                <span class="text-bold" style={{ color: 'white', background: text, padding: '5px', borderRadius: '5px', textTransform: 'capitalize' }}>
                                    {{ text }}
                                </span>
                            );
                        }
                    }
                    if (d.field == "pulsesettings") {
                        d['renderBodyCell'] = ({ row, column, rowIndex }, h) => {
                            const text = row[column.field];
                            {/* text = text.reverse(); */}
                            if (text && Array.isArray(text) && text.length > 3) {
                                text = text.slice(0, 3)
                            }
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '70px', border: '1px solid grey', height: '30px', padding: '5px 0px', display: 'flex', alignItems: 'center' }}>
                                        {text.map((data, idx) => <span v-tooltip={{ 'content': data.reason + "<br/>" + (data.description != undefined ? data.description.substr(0, 20) + "..." : '') }} onClick={(e) => { this.openPulseInfo = true; this.pulseInfo = text; this.selectedPulse = idx }} style={{ background: data.health, width: '8px', height: '20px', display: 'block', marginLeft: '10px', cursor: 'pointer' }}></span>)}
                                    </div>
                                    {text.length > 0 ? <span onClick={(e) => { this.openPulseModal = true; this.accountDomainForGraph = row; this.selectedReason = ''; this.descselected = ''; this.health = '' }} style={{ color: 'blue', fontSize: '10px', marginLeft: '4px', cursor: 'pointer' }}>Set Pulse </span> : <button onClick={(e) => { this.openPulseModal = true; this.accountDomainForGraph = row; this.selectedReason = ''; this.descselected = ''; this.health = '' }} style="height: 22px;border-radius: 5px; width: 80px;margin-left:4px;font-size:10px">Set pulse</button>}
                                </div>
                            )
                        }

                    }
                    if (d.field == "Trends") {
                        d['renderBodyCell'] = ({ row, column, rowIndex }, h) => {
                            const text = row[column.field];
                            const src = this.getIcon(text)
                            return (
                                <span onClick={(e) => this.handleGraphClick(rowIndex, row)} style="color:blue;cursor:pointer">
                                    View
                                </span>
                            );
                        }
                    }
                    if (d.trend) {
                        d['renderBodyCell'] = ({ row, column, rowIndex }, h) => {
                            return (
                                <span onClick={(e) => { this.handleGraphClick(rowIndex, row) }} style="cursor:pointer;"
                                    class="material-symbols-outlined summary_action_visibility">
                                    trending_up
                                </span>
                            );
                        }
                    }
                     if (d.field == 'Actions') {
                         d['renderBodyCell'] = ({ row, column, rowIndex }, h) => {
                         const src = '../assets/eye.png'
                         let checked=row.accountStatus&&Array.isArray(row.accountStatus)?(row.accountStatus[row.accountStatus.length-1].status=='Active'):false
                      return (
                        <div style={{overflow: "visible"}}>
                                <div style={{display:'flex',justifyContent:'flex-end',position: 'relative'}}>
                                <div>
                                    <label v-tooltip={{ 'content': 'Account Status' }} onClick={e=>{this.statusSelected=!checked;this.showModalAccountStatus=true;this.openAccountsForStatus={domain:row.domain,id:row.id};this.previousAccountStatus=row.accountStatus;}} class="switch">
                                        <input v-model={checked} type="checkbox" style={{height: "17px"}} class='checkbox'></input>
                                            <div class="slider round" style={{height: "17px",marginTop: "5px"}}></div>
                                    </label>
                                </div>
                                <span v-tooltip={{ 'content': 'Edit' }}>
                                <span style="margin-left:10px;cursor:pointer; font-size: 18px; margin-top: 5px;"  onClick={(e) => { this.handleOpenAccountData(row.domain, row.id) }} class="material-symbols-outlined summary_action_visibility">
                                    Edit
                                </span>
                            </span>
                          
                            <span>
                                <span v-tooltip={{ 'content': 'Menu' }} style="margin-left:5px;cursor:pointer; font-size: 20px; margin-top: 5px;" class="material-symbols-outlined summary_action_visibility" onClick={(e)=>{this.toggleMenu(rowIndex, row)}}>
                                    more_vert
                                </span>
                                <div class='item_list' style={{ display: isDropdownOpen ? 'block' : 'none', position: 'absolute', top: 'auto', bottom: 'calc(100% + 5px)', left: '0', background: 'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', padding: '5px',zIndex: 999, borderRadius: '8px',
    padding: '5px 7px'}}>
                                    <div style={{ cursor: 'pointer', width: 'max-content', display: 'flex',position: 'relative', height: '34px',
    alignItems: 'center'}} onClick={(e)=>{this.showPlayBookModal=true; this.accountDomainPlaybook=row.domain;this.accountIdPlaybook=row.id}}><span class="material-symbols-outlined">book</span> Trigger playbook</div>
                                </div>
                            </span>
                            </div>
                            </div>
                     
                        );
                     }
                     }
                }
                )
            }
            if (this.showInModal) {
                let idx = this.columns.findIndex(d => d.field == 'Actions');
                if (idx != -1) {
                    this.columns.splice(idx, 1)
                }
                idx = this.columns.findIndex(d => d.field == 'Trends');
                if (idx != -1) {
                    this.columns.splice(idx, 1)
                }
            }
            if (localStorage.getItem("ALL_CREDS")) {
                this.allCreds = JSON.parse(localStorage.getItem("ALL_CREDS"))
                this.allCreds.map(d => {
                    console.log(d);
                    if (d.business_object_name == 'ticket') {
                        this.ticket_app = d.appeq_supported_application;
                    }
                    if (d.business_object_name == 'Opportunities') {
                        this.Opportunities_app = d.appeq_supported_application;
                    }
                })
            }
            {/* this.bkeys = (await Server.getConfiguratorKeys(this.selectedBObject)).data.keys; */ }

        } catch (e) {
            console.log(e);
        }

        this.initData();
    },
    methods: {
        handleCloseColumns() {
        this.showEditColumns = false;

    },
handleModalAccountStatus(){
    if(this.valueStatus==''){
        this.error1 = "Status is required!";
        console.log("Status is required!")
        setTimeout(()=>{
            this.error1 = '';
        },4000);
        return false;
    }else if(this.valueReason==''){
        this.error1 = "Reason is required!";
        console.log("Reason is required!");
        setTimeout(()=>{
            this.error1 = '';
        },4000);
        return false;
    }
    else if(this.effectiveDateValue==''){
        this.error1 = "Effective Date is required!";
        console.log("Effective Date is required!");
        setTimeout(()=>{
            this.error1 = '';
        },4000);
        return false;
    }
    else if(this.notesValue==''){
        this.error1 = "Note is required!";
        console.log("Note is required!");
        setTimeout(()=>{
            this.error1 = '';
        },4000);
        return false;
    }
    else{
        {/* dataAccountStatus.push(this.valueStatus,this.valueReason,this.effectiveDateValue,this.notesValue); */}
        this.showModalAccountStatus = false;
        {/* this.toggleCondition = false; */}
    }
}
,
handleClearModal(){
    this.valueStatus=='';
    this.valueReason=='';
    this.effectiveDateValue=='';
    this.notesValue=='';
    {/* console.log(this.effectiveDateValue); */}
    {/* console.log(this.notesValue); */}
},
        handleDownload(){
      const csvContent = "data:text/csv;charset=utf-8," +
        this.selectedOrgs.map(row => JSON.stringify(row)).join("\n");

            this.showEditColumns = false;
            this.columns = [...this.originalColumns];
            this.$toasted.info("Changes discarded");
        }
        ,
        handleDownload() {
            const csvContent = "data:text/csv;charset=utf-8," +
                this.selectedOrgs.map(row => JSON.stringify(row)).join("\n");

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "table_data.csv");
            document.body.appendChild(link);
            link.click();
        },
        handleCloseAddModal() {
            this.showAddModal = false;
            this.changeTab(1)
        },
        async handleOpenAccountData(domain, id) {
            this.loadingModal = true
            const data = await Server.getAccountsDetails(domain, id)
            console.log(data)
            if (data.data) {
                this.accountData = data.data
                this.loadingModal = false

                this.showAddModal = true
            } else {
                this.$toasted.error("Error in the following account . Please try again after some time")
            }
        },
        async handleMoreOptions(domain,id){
            console.log(this.openAccountsForStatus)
            const idx=this.allCustomers.findIndex((d=>d.id==this.openAccountsForStatus.id));
            console.log(idx);
            var ele = document.getElementsByClassName("item_list");
            console.log(ele);
            ele[idx].style.display='block';
            console.log('item list');
        },
        async toggleMenu(rowIndex, row) {
      if (this.openDropdownIndex === rowIndex) {
        this.openDropdownIndex = -1;
      } else {
        this.openDropdownIndex = rowIndex;
        this.accountDomainForDropdown = row.domain;
      }
    },

        async handleClickColumns() {
            this.bkeys = (await Server.getConfiguratorKeys("accounts")).data.keys;
            this.bkeys = this.bkeys.filter((obj) => !this.columns.some((obj2) => obj2['field'] === obj));
            this.showEditColumns = true;
            this.bkeys.sort()

            {/* var creds = JSON.parse(localStorage.getItem("ALL_CREDS"));
            creds.map(d=>{

            }) */}

        },
        getHumanDate: function (date) {
            var date = new Date((date))
            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = Math.floor(seconds / 31536000);

            if (interval > 1) {
                return interval + " years";
            }
            interval = Math.floor(seconds / 2592000);
            if (interval > 1) {
                return interval + " months";
            }
            interval = Math.floor(seconds / 86400);
            if (interval > 1) {
                return interval + " days";
            }
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
                return interval + " hours";
            }
            interval = Math.floor(seconds / 60);
            if (interval > 1) {
                return interval + " minutes";
            }
            return Math.floor(seconds) + " seconds";
        },
        getRandomColor() {
            let maxVal = 0xFFFFFF; // 16777215
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            randomNumber = randomNumber.toString(16);
            let randColor = randomNumber.padStart(6, 0);
            return `#${randColor.toUpperCase()}`
        },
        async handleSaveCsmData() {
            try {
                await Server.saveCsmData(this.selectedUser, this.accountDomainForGraph)
                console.log(this.accountDomainForGraph)
                const idx = this.selectedOrgs.findIndex(d => d.id == this.accountDomainForGraph['id'])
                if (idx != -1) {
                    if (this.selectedOrgs[idx].CSM)
                        this.selectedOrgs[idx].CSM = this.selectedUser
                    else {
                        this.selectedOrgs[idx] = { CSM: this.selectedUser, ...this.selectedOrgs[idx] }
                        this.changeTab(this.selectedTab)

                    }
                }
                console.log(idx)
                this.$toasted.success("saved successfully")

                this.csmEdit = false
            } catch (e) {
                console.log(e)
            }
        },
        async handleSaveAccountPulse() {
            try {
                const data = await Server.savePulse({ account_domain: this.accountDomainForGraph['domain'], account_id: this.accountDomainForGraph['id'], ...this.accountDomainForGraph, business_object_name: "pulse", health: this.health, reason: this.reasons, description: this.descselected })
                if (data.code == 200) {
                    this.$toasted.success("Successfully saved account pulse")
                }
                const idx = this.selectedOrgs.findIndex(d => d.id == this.accountDomainForGraph['id'])
                if (idx != -1) {
                    this.selectedOrgs[idx].pulsesettings.push({ health: this.health, reason: this.reasons, description: this.descselected, created_date: new Date() })
                }
                this.openPulseModal = false;
            } catch (e) {
                console.log(e)
                this.$toasted.error(e)
            }
        },
        handleReasons(){
            this.reasons = '';
            for(let i=0;i<this.selectedReason.length-1;i++){
                this.reasons += this.selectedReason[i];
                this.reasons += ' and ';
            }
            this.reasons += this.selectedReason[this.selectedReason.length-1];
        },

        async saveAscolumnstrends() {
            this.loading = true
            this.openGraphModal = false;
            this.columns.splice(this.columns.length - 4, 0, {
                field: this.selectedBObject.key, title: this.selectedBObject.key, business_object_name: this.selectedBObject.business_object_name, "key": this.generateUUID(), sortBy: "",
                "type": true, "trend": true
            })
            await Server.saveColumns({ columns: this.columns })
            this.columns.map(d => {
                if (d.trend) {
                    d['renderBodyCell'] = ({ row, column, rowIndex }, h) => {
                        const text = row[column.field];

                        return (
                            <span onClick={(e) => this.handleGraphClick(rowIndex, row)} style="cursor:pointer;"
                                class="material-symbols-outlined summary_action_visibility">
                                trending_up
                            </span>
                        );
                    }
                }
            })
            await this.initData()

            this.loading = false

        },
        async searchChange() {
            this.selectedOrgs = []
            this.allCustomers.map(d => { d.domain.toString().toLowerCase().includes(this.searchField.toLowerCase())  || d.name.toString().toLowerCase().includes(this.searchField.toLowerCase())? this.selectedOrgs.push(d) : '' })
            this.loading = true;
            const data = await Server.getAllAllowedDomains(this.searchField,1,10)

                this.myOrgs = data.data.map(d => d.domain != null ? d : null).filter(data => data != null)
                this.allCustomers = this.myOrgs
                this.totalDataCount = data.count;
                this.selectedOrgs = this.allCustomers.slice(0, 10)
                const cols = this.columns
                const doms = this.selectedOrgs.map(d => { return { domain: d.domain, id: d.id } })
                const dat = await Server.getAccountsInfo(doms, cols)
                this.selectedOrgs = dat.map((data, idx) => {
                    console.log(dat)
                    const id=this.selectedOrgs.findIndex(d=>data.id==d.id)
                    return { domain: data.domain, name: data.name , ...dat[idx],id:data.id,...this.selectedOrgs[id] }
                })
            this.loading = false;

            this.selectedOrgs = this.selectedOrgs.slice(0, 10)
        },
        async changeTab(tabNumber) {
            this.selectedTab = tabNumber;
            this.searchField = ''
            if (tabNumber == 0) {
                this.loading = true
                const data = await Server.getAllAllowedDomains('',1,10)

                this.myOrgs = data.data.map(d => d.domain != null ? d : null).filter(data => data != null)
                this.allCustomers = this.myOrgs
                this.totalDataCount = data.count - (data.count)%10;
                this.selectedOrgs = this.allCustomers
                const cols = this.columns
                const doms = this.selectedOrgs.map(d => { return { domain: d.domain, id: d.id } })
                const dat = await Server.getAccountsInfo(doms, cols)
                this.selectedOrgs = dat.map((data, idx) => {
                    console.log(dat)
                    const id=this.selectedOrgs.findIndex(d=>data.id==d.id)
                    return { domain: data.domain, name: data.name , ...dat[idx],id:data.id,...this.selectedOrgs[id] }
                })
                this.loading = false

            } else if (tabNumber == 1) {
                this.allCustomers = []
                this.initData()
            } else {
                this.loading = true
                try {
                    this.loading = true;
                    const mySelected = await Server.getAccountsData("watch")
                    this.allCustomers = []
                    if (mySelected && mySelected.data && mySelected.data && Array.isArray(mySelected.data.accountDomains))
                        this.allCustomers = mySelected.data.accountDomains

                    console.log(this.allCustomers)
                    this.selectedOrgs = this.allCustomers.slice(0, 10)

                    const dat = await Server.getAccountsInfo(this.selectedOrgs, this.columns)
                    this.selectedOrgs = this.selectedOrgs.map((data, idx) => {
                        console.log(dat)
                        return { domain: data.domain, name: data.name, ...dat[idx], id: data.id }
                    })
                    this.loading = false;

                    if (this.selectedOrgs.length == 0) {

                        window.isCustomerEmpty = true;
                        console.log("set customer empty", window.isCustomerEmpty);

                    }

                } catch (error) {
                    console.log(error)
                }
                this.loading = false
            }

        },
        async pageNumberChange(e) {
            this.loading = true;
            console.log(e);
            const data = await Server.getAllAllowedDomains('',e,10)

                this.myOrgs = data.data.map(d => d.domain != null ? d : null).filter(data => data != null)
                this.allCustomers = this.myOrgs
                this.totalDataCount = data.count-(data.count)%10;
                this.selectedOrgs = this.allCustomers
                const cols = this.columns
                const doms = this.selectedOrgs.map(d => { return { domain: d.domain, id: d.id } })
                const dat = await Server.getAccountsInfo(doms, cols)
                this.selectedOrgs = dat.map((data, idx) => {
                    console.log(dat)
                    const id=this.selectedOrgs.findIndex(d=>data.id==d.id)
                    return { domain: data.domain, name: data.name , ...dat[idx],id:data.id,...this.selectedOrgs[id] }
                })
            this.loading = false;


        },
        sortChange(params) {
            let data = this.selectedOrgs.slice(0);
            console.log(params)
            // data.sort((a, b) => {
            //    return a[params.]
            // });
            let selectedSort = ''

            Object.keys(params).forEach(d => {
                if (params[d]) {
                    selectedSort = d
                }
            })
            data.sort((a, b) => {

                if (params[selectedSort] === "asc") {
                    if (typeof (a[selectedSort]) == 'string') {
                        return a[selectedSort].localeCompare(b[selectedSort])
                    }
                    return a[selectedSort] - b[selectedSort];
                } else if (params[selectedSort] === "desc") {
                    if (typeof (a[selectedSort]) == 'string') {
                        return b[selectedSort].localeCompare(a[selectedSort])
                    }
                    return b[selectedSort] - a[selectedSort];
                } else {
                    return 0;
                }
            });

            this.selectedOrgs = data;
        },
        sendSelectedToTop(fc){
            if(fc.field == this.columns[0].field){
                return;
            }
            console.log(fc.field);
            console.log(this.columns);
            const idx = this.columns.findIndex(d => d == fc);
            this.columns.splice(idx, 1);
            this.columns.unshift(fc);
            console.log(this.columns)
        },
        sendSelectedToBottom(fc){
            if(fc.field == this.columns[this.columns.length-1].field){
                return;
            }
            console.log(fc.field);
            console.log(this.columns);
            const idx = this.columns.findIndex(d => d == fc);
            this.columns.splice(idx, 1);
            this.columns.push(fc);
            console.log(this.columns)
        },

        handleLeftSelected(fc) {
            if (this.leftSelected.filter(d => d == fc).length > 0) {
                const idx = this.leftSelected.findIndex(d => d == fc);
                this.leftSelected.splice(idx, 1);
                return;
            }
            this.leftSelected.push(fc);
        },
        handleRightSelected(fc) {
            if (fc.field == 'CSM' || fc.field.toLowerCase() == 'health' || fc.field.toLowerCase() == 'pulsesettings' || fc.field.toLowerCase() == 'actions') {
                this.$toasted.error("Sorry , You cannot select predefined fields")
                return;
            }
            if (this.rightSelected.includes(fc)) {
                const idx = this.rightSelected.findIndex(d => d == fc)
                this.rightSelected.splice(idx, 1)
                return;
            }
            this.rightSelected.push(fc);
        },
        generateUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        handleTransfer(method) {
            if (method == 'right') {
                console.log(this.bkeys.length);
                console.log(this.columns.length);
                this.leftSelected.map(data => {
                    if (this.selectedBObject == "ticket" || this.selectedBObject == "Customer Invoice" || this.selectedBObject == "Opportunities") {
                        if (this.columns.length >= 15) {
                            this.$toasted.error("Only 15 columns are allowed")
                            return;
                        }
                        if (this.columns.length < 10) {
                            this.$toasted.error("Minimum 10 columns are required")
                            return;
                        }
                        this.columns.splice(this.columns.length - 2, 0, {
                            field: data, title: data, business_object_name: this.selectedBObject, "key": this.generateUUID(), sortBy: "",
                        })

                    } else {
                        const isNotUnique = this.columns.some(col => col.field === data);
                        if (!isNotUnique) {
                            this.columns.splice(this.columns.length - 2, 0, {
                                field: data, title: data, business_object_name: this.selectedBObject, "key": this.generateUUID(), sortBy: "", type: "key"
                            })
                        }
                        this.columns = this.columns.filter(function (v, i, self) {
                            return i == self.indexOf(v);
                        });

                    }
                    const idx = this.bkeys.findIndex(d => d == data)
                    this.bkeys.splice(idx, 1)
                })
                console.log(this.bkeys.length);
                console.log(this.columns.length);
                this.$toasted.success("Added to right")
                this.leftSelected = [];
            } else {
                console.log("yaha")
                console.log(this.columns.length);
                console.log(this.bkeys.length);
                // this.columns.splice()
                const valuesArr = this.columns.filter((value, index) => {
                    return this.rightSelected.findIndex(d => d.field == value.field) == -1;
                })
                this.rightSelected.map(data=>{
                    const isNotUnique = this.bkeys.some(col => col === data);
                if (!isNotUnique) {
                    this.bkeys.splice(this.bkeys.length - 2, 0,
                        data.field
                    )
                }
                })
                console.log(valuesArr.length)
                console.log(this.bkeys.length)
                this.columns = valuesArr
                this.rightSelected = [];
                this.bkeys.sort();
            }
        },

        async handleSaveColumns() {
            if (this.columns.length > 15) {
                this.$toasted.error("Cannot add more than 15 columns please reduce the columns")
                return;
            } else if (this.columns.length < 10) {
                this.$toasted.error("Minimum 10 columns are required")
                return;
            }
            else {
                this.showEditColumns = false;
                await Server.saveColumns({ columns: this.columns })
                this.$toasted.success("Columns saved")

                this.initData()
            }
        },
        async handleShowKeys() {
            if (this.selectedBObject == "ticket" || this.selectedBObject == "Customer Invoice" || this.selectedBObject == "Opportunities") {
                this.bkeys = [];
                try {
                    this.resData = await Server.getFunction(this.selectedBObject);
                    if (this.resData) {
                        this.resData.data.map(d => {
                            this.bkeys.push(d.function_name);
                            console.log(this.bkeys);
                        })
                    }
                } catch (e) {
                    console.log(e);
                }
                return;
            }
            this.bkeys = (await Server.getConfiguratorKeys(this.selectedBObject)).data.keys;
            console.log(this.bkeys)
        },
        handleCustomize() {
            this.showEdit = !this.showEdit
        },
        handleShowData(val) {
            console.log(val)
            this.showGraphData = val;
        },
        async handleOpenModal() {
            this.showAddModal = true;
        },

        async initData() {
            try {
                this.loading = true;
                this.showModalAccountStatus=false

                const mySelected = await Server.getAccountsData(false,this.user)
                this.allCustomers = []
                if (mySelected && mySelected.data && mySelected.data && Array.isArray(mySelected.data))
                    this.allCustomers = mySelected.data

                console.log(this.allCustomers)
                this.selectedOrgs = this.allCustomers.slice(0, 10)

                const dat = await Server.getAccountsInfo(this.selectedOrgs, this.columns)
                this.selectedOrgs = dat.map((data, idx) => {
               console.log(dat)
                    const id=this.selectedOrgs.findIndex(d=>data.id==d.id)
                    return { domain: data.domain, name: data.name , ...dat[idx],id:data.id,...this.selectedOrgs[id] }
                })
                const orgs = this.selectedOrgs.map(d => d.domain)
                var creds = JSON.parse(localStorage.getItem("ALL_CREDS"));
                let key = '';
                this.loading = false;

                if (this.selectedOrgs.length == 0) {

                    window.isCustomerEmpty = true;
                    console.log("set customer empty", window.isCustomerEmpty);

                }

            } catch (error) {
                console.log(error)
            }
        },
        openSummary(index) {
            if (this.showSummary == index) {
                this.showSummary = -1;
            }
            else {
                this.showSummary = index;
            }
        },
        closeSummary() {
            this.showSummary = -1;
        },
        handleClick(org) {

            console.log(org)
            if (this.newSelectedOrg.filter(d => d.domain == org.domain).length >= 1) {
                const idx = this.newSelectedOrg.findIndex(d => d.domain === org.domain);
                this.newSelectedOrg.splice(idx, 1)
                console.log("her")
            } else
                this.newSelectedOrg.push(org)

        },
        toggleBack(){
            this.showModalAccountStatus=false;
            const idx=this.allCustomers.findIndex(d=>d.id==this.openAccountsForStatus.id)
            console.log(idx)
            console.log(this.selectedOrgs[idx]);
            let cb = document.getElementsByClassName('checkbox');
            cb[idx].checked = !cb[idx].checked;


        },
        toggleBackFromPlaybook(){
            this.showPlayBookModal = false;
        },
        async handleGraphClick(index, row) {
            try {
                this.accountDomainForGraph = row.domain
                this.selectedBObject = this.occpBusiness
                this.chartData.datasets[0].data = []
                this.chartData.labels = []
                if(!this.selectedBObject || !this.selectedBObject.business_object_name){
                    this.$toasted.error("Trends not configured.Please configure keys for which you want to see the trends")
                    return
                }
                const data = await Server.getTrendingData(row.domain, this.selectedBObject.business_object_name)
                console.log(data)
                if (data && data.data)
                    data.data.map(d => {
                        console.log(d)
                        const jsonD = JSON.parse(d[5])
                        this.keys = Object.keys(jsonD)
                        this.selectedKeys = this.keys[0]
                        this.chartData.datasets[0].label = this.selectedBObject.key
                        this.chartData.datasets[0].data.push(jsonD[this.selectedBObject.key])
                        this.chartData.labels.push(moment(d[2]).format("DD,MMM   "))

                    })
                this.openGraphModal = true;
            } catch (e) {
                console.log(e)
                if (e.message)
                    this.$toasted.error(e.message)
                else
                    this.$toasted.error("Something went wrong")


            }
        },
        async chabgeBobject() {
            this.loadingGraphData = true
            this.chartData.datasets[0].data = []
            this.chartData.labels = []
            const data = await Server.getTrendingData(this.accountDomainForGraph, this.selectedBObject.business_object_name)
            data.data.map(d => {
                console.log(d)
                this.keys = []
                const jsonD = JSON.parse(d[5])
                this.keys = Object.keys(jsonD)
                this.selectedKeys = this.keys[0]
                this.chartData.datasets[0].label = this.selectedBObject.key

                this.chartData.datasets[0].data.push(jsonD[this.selectedBObject.key])
                this.chartData.labels.push(moment(d[2]).format("DD,MMM   "))

            })
            this.loadingGraphData = false
        },

        async handleDelete(ind) {
            this.selectedOrgs.splice(ind, 1)
            const data = await Server.saveAccountsdata(this.selectedOrgs)
            this.initData()

        },
        handleClose() {
            this.showAddModal = false;
        },
        debounceInput: debounce(async function (e) {
            console.log(e.target.value)
            const data = await Server.getAllAllowedDomains(e.target.value, 1, 10)
            this.totalDataCount = data.count-(data.count)%10;
            this.myOrgs = data.data.map(d => d.domain != null ? d : null).filter(data => data != null)

            //   this.$store.dispatch('updateInput', e.target.value)
        }, 700)
        ,
        imageUrlAlt(event) {
            console.log("No asbd")
            event.target.src = "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg  "
        },
        async handleSaveToWatch(orgs) {
            const data = await Server.saveAccountsdata(orgs)
            if (data.code == 200) {
                this.$toasted.success("Org added successfully")
            } else {
                this.$toasted.error("Something Went Wrong . Please try again later")
            }
        },
        async handleSave(org) {

            if (this.newSelectedOrg.length == 0) {
                this.$toasted.error("No Customer Selected.");
                return;
            }

            this.newSelectedOrg.map(data => {
                this.selectedOrgs.push(data)
            })
            const data = await Server.saveAccountsdata({ account_domain: this.selectedOrgs })
            if (data.code == 200) {
                this.$toasted.success("Org added successfully")
            } else {
                this.$toasted.error("Something Went Wrong . Please try again later")
            }
            this.showAddModal = false
            this.initData();
        },
        async handleselectOrg(org, index) {
            try {
                this.selectedOrgs.unshift(org)
                const data = await Server.saveAccountsdata({ account_domain: this.selectedOrgs })
                if (data.code == 200) {
                    this.myOrgs.splice(index, 1)
                } else {
                    this.$toasted.error("Something Went Wrong . Please try again later")
                }
            } catch (error) {
                this.$toasted.error("Something Went Wrong  . Please try again later")

            }
        },

        getIcon(context_link) {
            try {
                if (context_link == null)
                    return 'https://logo.clearbit.com/' + 'zendesk.com';
                else if (context_link.toString().split("@").length > 1)
                    return 'https://logo.clearbit.com/' + context_link.toString().split("@")[1];

                else if (context_link.split(".").length > 1 ? "" : ".com")
                    return 'https://logo.clearbit.com/' + context_link.toString().split(" ").join("") + ".com";
                else
                    return 'https://logo.clearbit.com/' + context_link;

            } catch (e) {
                console.log(e);
            }
        },
        async saveEnabled(){
            if(this.toggleCondition == true){
                this.toggleCondition = false;
                this.showModalAccountStatus = false;
            }
            else{
                this.toggleCondition = true;
            this.showModalAccountStatus = true;
            }
        },
    }
}
</script>
<style lang="scss" scoped>

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}
.switch input {
    display: none;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 21px;
    left: 0px;
    bottom: 0px;
    background-color: blue;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: lightblue;
}

input:focus+.slider {
    box-shadow: 0 0 1px #101010;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.mycard {
    cursor: pointer;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: row !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: 0.2s ease-in-out;

    &:hover {
        scale: 1.02;
        background-color: #e3e6fa7a;
        transition: 0.2s ease-in-out;
    }

    span {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 700;

    }

    input {
        margin-right: 10px;
    }
}

.containerDiv {
    width: 35%;
    padding: 0;
    height: 500px;
    border-radius: 10px;
    padding: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    .containerScrollableDiv {
        height: 77%;
        overflow: scroll;
        padding: 20px;
        overflow-x: hidden;
    }

    .containerScrollableDiv ::-webkit-scrollbar {
        width: 0px;

    }

    .containerScrollableDiv ::-webkit-scrollbar-thumb {
        width: 0px;

    }

}

.divHeading {
    width: 100%;
    text-align: center;
}

.data-bars {
    height: 300px;
    width: 435px;
    margin-top: 30px;
}

.searchBar {
    width: 100%;
    border: none;
    border-bottom: 1px solid rgb(118, 118, 118);
}

.loadingRipple {
    position: absolute;
    left: 40vw;
    top: 250px;
    width: 200px;
}

.summaryDivWrapper {
    margin-top: 5px;
    position: absolute;
    right: 180px;
    background: white !important;
}

.card_display_div_figure {
    img {
        width: 20px;
    }
}
</style>
<style>
.ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content thead.ve-table-header.ve-table-fixed-header tr th {
    z-index: 0;
    position: relative;
}

.ve-table-fixed-header.ve-table-header {
    z-index: 0;
}

.table-header-cell-class {
    color: var(--table-column-heading-color) !important;
    font-weight: 700 !important;
    border: none !important;
    z-index: 1;

}

.ve-table-body.ve-table-row-hover.ve-table-row-highlight {}

.ve-table.ve-table-border-around {
    border: none !important;
}

.ve-table-body-td {
    border-bottom: none !important;
}
button:disabled{
        background-color: grey !important;
        color: white !important;
    }
    ::placeholder {
  color: black;
  opacity: 1;
}

:-ms-input-placeholder {
  color: black;
}

::-ms-input-placeholder {
  color: black;
}
.multiselect-styling {
  --ms-tag-bg: #dadada;
  --ms-tag-color: black;
  --ms-tag-radius: 25px;
  padding: 6px;
  min-height: 80px;
  border-radius: 20px;
  --ms-placeholder-color: #9ca3af;
  --ms-tag-mx: 15px;
  --ms-tag-my: 0.5rem;
  --ms-ring-color: black;
  --ms-ring-width: 0.8px;
}

.switch input {
    display: none;
}
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 21px;
    left: 0px;
    bottom: 0px;
    background-color: blue;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: lightblue;
}

input:focus+.slider {
    box-shadow: 0 0 1px #101010;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
[aria-label][role~=tooltip][data-microtip-position=top-right]:hover:after {
    background: rgba(17,17,17,.9);
    border-radius: 4px;
    color: #fff;
    content: attr(aria-label);
    padding: 0.5em 0.5em;
    transform: translate3d(calc(-50% + 16px), 0, 0);
    position: absolute;
}
.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 1.5px;
    background-color: blue;
    transition: 0.4s;
    /* top: 3px; */
}



</style>
<style>
.ve-table-body-td{
    overflow: visible !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
