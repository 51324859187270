import { render, staticRenderFns } from "./PlayBookCustom.vue?vue&type=template&id=5b66c303&scoped=true"
import script from "./PlayBookCustom.vue?vue&type=script&lang=js"
export * from "./PlayBookCustom.vue?vue&type=script&lang=js"
import style0 from "./PlayBookCustom.vue?vue&type=style&index=0&id=5b66c303&prod&lang=scss&scoped=true"
import style1 from "./PlayBookCustom.vue?vue&type=style&index=1&id=5b66c303&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b66c303",
  null
  
)

export default component.exports