<template>
    <div class="step">
        
        <div class="step__header">
            
            <div class="step__header--title">
                Select Your Apps where your account information resides
            </div>
            <div class="step__header--subtitle">
                AppEQ will pull actionable account insights from these Apps
            </div>
            <button @mouseenter="skipTool=true" @mouseleave="skipTool=false" @click="changeStep(+4)" class="prev">Skip <i class="tim-icons icon-double-right"></i></button>
            <div class="skipTour" v-if="skipTool">
                <div class="arrow-up"></div>
                <p class="skipTourText">Click to End the Tour</p>
            </div>
        </div>
        <div class="step__desc">
        
            <div v-if="this.categorizedApp.Others" class="step__desc--tile">
                <div class="steps__desc--tile__left">
                    {{ "Google SpreadSheet" }}
                </div>
                <div class="wrapperDiv">
                    <div v-for="(data, i) in this.categorizedApp.Others.items" v-if="data.logoURL" class="mycard"
                        @click="selectApp(data.productName)">
                        <img  @mouseover="data.tooltip = true" @mouseleave="data.tooltip = false" :class="selectedApp.includes(data.productName) ? 'selected ' : ''" :src="data.logoURL" >
                        <div v-if="data.tooltip" :class="{tools:data.tooltip}">
                            {{ data.productName }}
                            <div class="arrow-up"></div>
                        </div>
                        <i v-if="fun(data.productName)" class="tim-icons checked icon-check-2"></i>
                    </div>
                </div>

            </div>
            
            <template v-for="(app, index) in (categorizedApp)">
                <div v-if="handle(app)" class="step__desc--tile">
                    <div class="steps__desc--tile__left">
                        {{ app.key }}
                    </div>
                    <div class="wrapperDiv">
                        <div v-for="(data, i) in app.items" v-if="data.logoURL" class="mycard"
                            @click="selectApp(data.productName)">
                            <img  @mouseover="data.tooltip = true" @mouseleave="data.tooltip = false" :class="selectedApp.includes(data.productName) ? 'selected ' : ''" :src="data.logoURL" >
                            <div v-if="data.tooltip" :class="{tools:data.tooltip}">
                               {{ data.productName }}
                               <div class="arrow-up"></div>
                            </div>
                            <i v-if="fun(data.productName)" class="tim-icons checked icon-check-2"></i>
                        </div>
                    </div>

                </div>
            </template>
            
        </div>
    </div>
</template>

<script>
import FooterVue from '../Footer.vue';
import mixpanel from '../../../analytics/mixpanel';

export default {
    name: "Step1",
    components: {
        FooterVue
    },
    data() {
        return {
            selectedApp: [],
            skipTool:false,
            doNotShow : ["Product Management","AppEQ","CogniSaaS","Gmail","Others"],
        }
    },
    props: {
        categorizedApp: {},
        currentStep: {
            type: Number,
            default: 1
        }
    },
    methods: {
        changeStep(index) {
            // currentStep = currentStep + 1;
    
            if(index==+4){
                try{
                    mixpanel.tourSkipped("select_your_app");
                    mixpanel.selectedAppsIntour(this.selectedApp);
                }catch(e){
                    console.log(e);
                }
            }

            

            this.$emit("changeStep", index)
        },

        selectApp(productName) {
            const selectedIndex = this.selectedApp.indexOf(productName)
            if (selectedIndex == -1){
                this.selectedApp.push(productName);
                try{
                    mixpanel.selectedAppIntour(productName);
                }catch(e){
                    console.log(e);
                }
            }
            else this.selectedApp.splice(selectedIndex, 1)

        },
        handle(app){
            return !(this.doNotShow.includes(app.key));
        },
        fun(pName){
            if(this.selectedApp.length!=0){
                return this.selectedApp.includes(pName);
            }
            return false;
        }
    },
    mounted: function () {
        let testArr = {};
        try{
            mixpanel.tourStarted();
        }catch(e){
            console.log(e);
        }
    },
}
</script>

<style src="./Step1.scss" lang="scss" scoped>
</style>