<template>
    <div>
        <button style="    cursor: pointer;
    width: 6vw;
    height: 1.5vw;
    border-radius: 8px;
    margin-top: 0px;
    outline: none;
    background: #4e5fff;" @click="handleButtonClick">+ Add</button>
    </div>
</template>
<script>
export default{
    methods:{
        handleButtonClick(){
            this.$emit('button');
        }
    }
}
</script>