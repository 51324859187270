<template>
    <div class="mainModal">
        <LeftSideVue :currentStep="this.currentStep"></LeftSideVue>
        <div class="right">
            <Step1 v-if="(currentStep == 1)" :categorizedApp="categorizedApp" :currentStep="currentStep" @changeStep="changeStep($event)"></Step1>
            <Step2 v-if="(currentStep == 2)" :categorizedApp="categorizedApp" :currentStep="currentStep" @changeStep="changeStep($event)"></Step2>
            <Step4 v-if="(currentStep == 3)" :categorizedApp="categorizedApp" :currentStep="currentStep" @changeStep="changeStep($event)"></Step4>
            <Step3 v-if="(currentStep == 4)" :categorizedApp="categorizedApp" :currentStep="currentStep" @changeStep="changeStep($event)"></Step3>
            <FooterVue :downloaded="downloaded" :currentStep="currentStep" @changeStep="changeStep($event)"></FooterVue>
        </div>
    </div>
</template>

<script>
import Step1 from './Step1/Step1.vue';
import Step2 from './Step2/Step2.vue';
import Step3 from './Step3/Step3.vue';
import Step4 from './Step4/Step4.vue';
import FooterVue from './Footer.vue';
import LeftSideVue from './LeftSide/LeftSide.vue';
import Server from '../../components/API/Server';
export default {
    data() {
        return {
            appsList: [],
            currentStep: 1,
            categorizedApp: {},
            downloaded:false,
        }
    },
    methods: {
        changeStep(index) {
            if (this.currentStep + index <= 0) {
                return;
            }
            if (this.currentStep + index > 4) {
                this.$router.push("/integrations")
                return;
            }
            this.currentStep += index;

        }
    },
    name: "Onboarding",
    async beforeMount() {
        let testArr = {}
        this.appsList = (await Server.getAllowedApps()).data["selected_apps"];
        this.appsList.map((data) => {
            if (testArr[data.g2Category]) {
                testArr[data.g2Category]["items"].push({ ...data, tooltip: false });
            } else {
                testArr[data.g2Category] = {
                    items: [{ ...data, tooltip: false }],
                    key: data.g2Category,
                };
            }
        });

        this.downloaded = new URL(location.href).searchParams.get('welcome') == 1 ? true: false; 
        console.log(this.downloaded);

        this.categorizedApp = testArr;
    },
    components: {
        Step1,
        Step2,
        Step3,
        Step4,
        LeftSideVue,
        FooterVue
    }
}
</script>

<style lang="scss" scoped>
.mainModal {
    display: flex;
    position: fixed;
    left: 0%;
    top: 0%;
    bottom: 0%;
    z-index: 999999;
    right: 0%;
    background-color: white;
    box-shadow: rgb(0, 0, 0) 0px 2px 20px;
    ;
}

.right {
    display: flex;
    flex-direction: column;
    width: 66%;
}
</style>