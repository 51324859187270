<template>
    <div style="padding: 10px;height: 100vh;background: rgb(77, 73, 66);color: white !important;">
        <h2 style="margin-bottom: 8px;color: white !important;text-align: center;font-size: 20px;">Launchers</h2>
        <img @click="handleClose" style="cursor: pointer; height: 18px;position: absolute;right: 10px;top: 10px;" src="../icons/Close.svg" alt="">

        <p style="color: white;text-align: center;font-size: 12px;margin-top: 10px;">Click the element on your page where you want a clickable icon that will open a Smart Popup.</p>
        
        <div style="display: flex;justify-content: end;height: 30px;flex-direction: column; align-items: center;position: absolute;bottom: 12px;left: 36%;width: 28%;">
            <button @click="handleOpenPopup" style="height: 28px; width: 100%; border-radius: 7px;">Select</button>
        </div>
    </div>
</template>
<script>
export default{
    mounted(){
        Intercom('update', {
            "hide_default_launcher": true
        });
    },
    methods: {
        handleClose(){
            window.parent.postMessage({ action: "__appeq_pre_widget_iframe_div_close", data: {} }, "*");

    },
        handleOpenPopup(){
            window.parent.postMessage({ action: "__appeq_close_pre_popup", data: {} }, "*");
        }
    }
}
</script>