<template>
  <nav style="padding: 5px;z-index: 1;background: white !important;margin-bottom: 0px !important;background: #e6e6e6 !important;margin-bottom: 0px !important;" class="card navbar navbar-expand-lg navbar-absolute backGround"
    :class="{ 'navbar-transparent': !showMenu }">
    <!-- <vue-confirm-dialog></vue-confirm-dialog> -->

    <div class="container-fluid">

      <div style="display: none !important;" class="navbar-wrapper">

        <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
          <button type="button" class="navbar-toggler" aria-label="Navbar toggle button" @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
      </div>
      <div>
        <h3 style="margin-bottom: 0px;font-size: 16px;font-weight: 600;color: white !important;">
          <!-- {{ email.toString().split("@")[1] == 'appeq.ai' ? 'AppEQ.ai' : email.toString().split("@")[1] }} -->
        </h3>
      </div>

      <div class="user_setting">


      </div>
      <!-- <div class="prod" style="margin-left: 30%;">
        <Multiselect style="height: 30px !important;min-height: 30px !important;" v-if="isSandbox" v-model="selectedMode" @select="changeEnv" :options="['Production', 'Sandbox']" />
      </div> -->
       <div  v-if="!extensionInstalled" style="height: 30px;display: flex;align-items: center;color: black !important;" v-tooltip.bottom-start="'Get Our Chrome Extension'"
        :class="{ 'disabled': extensionInstalled }" class="click-span blink-me" @click="onClickStart()">

        <span class="material-symbols-outlined">
          download
        </span>
        <p style="color: black !important;">
          {{ extensionInstalled ? 'Extension Installed' : 'Install Extension' }}
        </p>
      </div> 


      <div>
        <div
          style="border-color: 1px solid #4E5FFF;width: 134px;display: flex;align-items: center;justify-content: space-around;"
          @click="showUserProfileMenu = !showUserProfileMenu">
          <!-- <img height="30px" :src="getIcon(email)"/> -->
          <img style="height: 40px;border-radius: 50%;border: 2px solid #4E5FFF;" v-if="pic" :src="pic" />
          <avatar v-if="!pic" style="height: 40px;width: 40px;" :username="name"></avatar>
        </div>
        <div class="user_setting_div" v-if="showUserProfileMenu">

          <div class="name_wrapper">
            <div style="width:60px;margin-left:15px">
              <avatar v-if="!pic" style="height: 40px;width: 40px;" :username="name"></avatar>
              <img style="height: 40px;border-radius: 50%;" v-if="pic" :src="pic" />

            </div>

            <div>
              <h4 v-if="authenticated" class="profile-name">{{ name }}</h4>
              <h4 v-if="authenticated" class="profile-email">{{ email }}</h4>
              <span style="font-weight: 600;font-size: 8px;" v-if="authenticated" class="profile-admin">({{
                isAdmin ? 'Admin' : 'User' }})</span>

            </div>

          </div>
          <!-- <hr> -->
          <ul>
            <a href="/support-center">
              <li>
                <span class="material-symbols-outlined">
                  support_agent
                </span>
                Support Center
              </li>
            </a>

            <a target="_blank" href="https://www.appeq.ai/terms-of-service">

              <li>
                <span class="material-symbols-outlined">
                  forum
                </span>
                Terms and Conditions
              </li>
            </a>
            <a target="_blank" href="https://www.appeq.ai/privacy-policy">
              <li>
                <span class="material-symbols-outlined">
                  policy
                </span>
                Privacy Policy
              </li>
            </a>





          </ul>

          <div class="support-div">
            <br>
            <div @click="handleLogout()" class="support-div-content" style="margin-left: 1.3vw; justify-content: flex-start;">
              <span class="logoutButton" v-if="authenticated"><i class="tim-icons icon-button-power"></i>Logout</span>
            </div>
          </div>

        </div>


      </div>



    </div>
  </nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import Server from "@/components/API/Server.js";
import mixpanel from "../../analytics/mixpanel";
import Avatar from 'vue-avatar'
import LocalUtils from "../../components/Utils/LocalUtils"
import Multiselect from "vue-multiselect";

export default {
  components: {
    CollapseTransition,
    Avatar,
    Multiselect
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return name;
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  props: {
    email: {},
    authenticated: {
      type: Boolean
    },
    isAdmin: {
      type: Boolean
    },
    name: {
      type: String
    },
    pic: {
      type: String
    }

  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      showUserProfileMenu: false,
      extensionInstalled: false,
      imageUrl: "",
      isSandbox:false,
      searchModalVisible: false,
      selectedMode:'Sandbox',
      theme: "light",
    };
  },
  async mounted() {
    // await Server.checkLogin()
    if(localStorage.getItem("features")){

    }else{
      const data=await Server.getAllowedApps()
      console.log(data,"---gaurav")
      if(data && data.data && data.data.data && data.data.data.features){
        localStorage.setItem("features",JSON.stringify(data.data.data.features))
      }else{
        localStorage.setItem("features",JSON.stringify({}))

      }
    }
    console.log("gaurav1234")
    if(LocalUtils.getEmail().includes("sandbox")){
      this.isSandbox=true
    }
    else {
      const user=await Server.getUsersData(LocalUtils.getEmail())
      this.isSandbox=user.data.data.sandbox
      console.log(user)
    }
    console.log(LocalUtils.getEmail().toString().toLowerCase())
    if(LocalUtils.getEmail().toString().includes("sandbox")){
      this.selectedMode='Sandbox'
    }else{
      this.selectedMode="Production"
    }
    setInterval(() => {
      if (document.getElementById("__appeq_frame")) {
        this.extensionInstalled = true
      }
    }, 2000)

  },
  methods: {
    changeEnv(e) {
      console.log(e)
      this.$confirm(
        {
          message: `Are you sure you want to change the env to ${e} ?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: async confirm => {
            if (confirm) {
              if(e.toLowerCase()=="production"){
              const email=LocalUtils.getEmail().split("sandbox")[0]
              console.log(email)
              const data=await Server.checkLoginGoogleWithCheck(email,true )
              console.log(data)
              if(data){
                localStorage.setItem("ADMIN_CREDS", JSON.stringify({ "__email__id": data.email_id}));
                window.location.reload()

              }
            }else{
              const email=LocalUtils.getEmail()+"sandbox"
              console.log(email)
              const data=await Server.checkLoginGoogleWithCheck(email,true )
              console.log(data)
              if(data){
                localStorage.setItem("ADMIN_CREDS", JSON.stringify({ "__email__id": data.email_id}));
                window.location.reload()

              }

            }
            }
          }
        })
    },
    getIcon(context_link) {
      try {
        console.log(context_link)
        if (context_link == null)
          return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + 'zendesk.com' + '&sz=128';
        else if (context_link.toString().split("@").length == 0)
          return context_link
        else
          return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + context_link.toString().split("@")[1] + '&sz=128';
      } catch (e) {
        console.log(e);
      }
    },
    checkIfInstalled() {
      console.log("HERE")
      if (document.getElementById("__appeq_frame")) {
        return false
      }
      return true;
    },
    async loginwithgoogle() {
      this.$router.push({ path: '/' })


    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleLogout() {
      localStorage.clear();
      this.pic = '';
      this.name = ''
      this.authenticated = false;
      this.$router.push("/");
      this.$toasted.success("Successfully logged out");
    },
    logout() {
      localStorage.clear();
      this.authenticated = false;
      this.$router.push("/");
      this.$toasted.success("Successfully logged out")
    },
    logoClicked() {
      window.open("https://www.appeq.ai", '_blank');

    },
    handleOutsideClick() {
      this.showUserProfileMenu = false;
    },
    onClickStart() {
      // this.showIframe = true
      try {
        mixpanel.extentionDownload();
      }
      catch (e) {
        console.log(e);
      }
      console.log(process.env.VUE_APP_INSTALL_URL)

      window.open(process.env.VUE_APP_INSTALL_URL.toString(), 'winname');

    },
    toggleTheme() {
      this.theme = this.theme === "light" ? "dark" : "light";
      if (this.theme === "dark") {
        document.documentElement.style.setProperty("--wrapper-background-color", "#333");
        document.documentElement.style.setProperty("--sidebar-background-color", "#333");
        document.documentElement.style.setProperty("--sidebar-text-color", "#b4bbbf");
        document.documentElement.style.setProperty("--login-button-background-color", "#333");
        document.documentElement.style.setProperty("--login-button-color", "#b4bbbf");
        document.documentElement.style.setProperty("--login-button-border-color", "#333");
        document.documentElement.style.setProperty("--hr-line-color", "#b4bbbf");
        document.documentElement.style.setProperty("--table-background-color", "#f1f3ff");
        document.documentElement.style.setProperty("--table-column-heading-color", "black");
        document.documentElement.style.setProperty("--table-text-color", "#333");
        document.documentElement.style.setProperty("--icon-filter", "sepia(13%) blur(2px) hue-rotate(73deg) opacity(9%);");

      }
      else {
        document.documentElement.style.setProperty("--wrapper-background-color", "#f7f8ff7a");
        document.documentElement.style.setProperty("--sidebar-background-color", "white");
        document.documentElement.style.setProperty("--sidebar-text-color", "#333");
        document.documentElement.style.setProperty("--login-button-background-color", "white");
        document.documentElement.style.setProperty("--login-button-color", "#333");
        document.documentElement.style.setProperty("--login-button-border-color", "white");
        document.documentElement.style.setProperty("--hr-line-color", "#333");
        document.documentElement.style.setProperty("--table-background-color", "white");
        document.documentElement.style.setProperty("--table-column-heading-color", "black");
        document.documentElement.style.setProperty("--table-text-color", "#333");
        document.documentElement.style.setProperty("--icon-filter", "invert(89%) sepia(0%) saturate(2340%) hue-rotate(42deg) brightness(115%) contrast(100%)");

      }

    }
  },

};

</script>
<style scoped lang="scss">
.toggle-box:hover {
  scale: 1.1;
  transition: 0.5s;
}

.toggle-box {
  transition: 0.5s;

}

.card {
  box-shadow: none;
}

.toggle-box-label-left:empty {
  margin-left: -10px;
}

.toggle-box-label-left:before,
.toggle-box-label-left:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /*transition*/
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  outline: none;
}

.toggle-box input[type=checkbox],
.toggle-box input[type=checkbox]:active {
  position: absolute;
  top: -5000px;
  height: 0;
  width: 0;
  opacity: 0;
  border: none;
  outline: none;
}

.toggle-box label {
  display: inline-block;
  position: relative;
  padding: 0px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: rgba(149, 149, 149, 0.51);
  font-weight: normal;
}

.toggle-box-label-left:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  line-height: 34px;
  text-indent: 40px;
  height: 16px;
  width: 16px;
  margin: 4px;
  /*border-radius*/
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  right: 26px;
  bottom: 0px;
  background: #3446fc;
  transform: rotate(-45deg);
  box-shadow: 0 0 10px white;
}

.toggle-box-label-left:after {
  content: "";
  display: inline-block;
  width: 40px;
  height: 24px;
  /*border-radius*/
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.15);
  vertical-align: middle;
  margin: 0 10px;
  border: 2px solid #3446fc;
}

.toggle-box input[type=checkbox]:checked+.toggle-box-label-left:before {
  right: 17px;
  box-shadow: 5px 5px 0 0 #eee;
  background: transparent;
}

.toggle-box input[type=checkbox]:checked+.toggle-box-label-left:after {
  background: rgba(0, 0, 0, 0.15);
  border: 2px solid white;
}

.toggle-box input[type=checkbox]+.toggle-box-label-left {
  color: rgba(250, 250, 250, 0.51);
  font-weight: bold;
}

.toggle-box input[type=checkbox]:checked+.toggle-box-label-left {
  color: rgba(149, 149, 149, 0.51);
  font-weight: normal;
}

.toggle-box input[type=checkbox]:checked+.toggle-box-label-left+.toggle-box-label {
  color: rgba(250, 250, 250, 0.51);
  font-weight: bold;
}

.mybtn {
  cursor: pointer;
  flex-shrink: 0;
  border: 1px solid #3446fc;
  color: #3446fc;
  background: white;
  padding: 0px 16px;
  font-size: 14px;
  font-weight: 500;
  height: 56px;
  border-radius: 6px;
  margin-top: -2px;
}

.click-span {
  border: 0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #3a33ff;
  font: 500 14px "Google Sans", Arial, sans-serif;
  height: 36px;
  letter-spacing: .25px;
  padding: 0;
  text-shadow: none;
  text-transform: none;
  cursor: pointer;
  outline: 0;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  position: absolute;
  right: 160px;

  p {
    color: #333dfd;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    padding: 10px
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;

}



.user_setting_div {

  background-color: #fefefe;
  width: 290px;
  height:275px;
  position: absolute;
  z-index: 12898;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  border-radius: 5px;
  padding: 10px;
  margin-left: -200px;
  margin-top: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .link_div a {
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 10px;
    color: black;

    span {
      margin-right: 15px;
    }

    &:hover {
      background-color: #e3e6ff;
      transition: 0.2s ease-in-out;
    }

  }

  .name_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .profile-name {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0px;
    }

    .profile-admin {
      font-size: 12px;
      margin-bottom: 5px;
    }

    .profile-email {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0px;
      text-transform: none;
    }
  }

  ul {
    list-style: none;
    padding: 20px;

    li {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: #e3e6fa7a;
        transition: background-color 0.2s ease 0s;
      }
    }
  }

  span,
  i {
    margin-right: 10px;
    font-weight: 200;
  }

  .support-div {

    position: absolute;
    bottom: 10px;
    width: 93%;

    .support-div-content {
      display: flex;
      align-items: center;
      color: black;
      padding: 10px;

      span,
      i {
        font-weight: 500 !important;
      }

      &:hover {
        background: #e3e6fa7a;
        transition: background-color 0.2s ease 0s;
      }

    }
  }



}</style>
<style> .prod{ 
.multiselect__input{
  font-size: 10px !important;
}
.multiselect__single{
  font-size: 10px !important;
  
}
.multiselect__tags {
   height: 25px;
   min-height: 30px;
   padding: 5px 40px 0 5px !important;
 }
 }
 .blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
 </style>