<template>
    <div style="height: 100vh;">
        <div class="" style="display: flex;align-items: center;justify-content: space-between;">
            <!-- <div class=""></div> -->
            <!-- <div class="" style="display: flex;align-items: center;">
                <img src="../icons/analytics.png" style="height: 20px;margin-left: 60px;" />
                <h1 style="text-align: center;font-size: 25px;margin-left: 10px;">Dashboard Templates</h1> 
            </div> -->
            <div @mouseleave="showMoreInfo=false;">
                <div class="" style="display: flex;align-items: center;">
                <!-- <img @click="toggleShowMore()" @mouseover="showMoreInfo=true;" class="showInfo" style="margin-right: 10px;cursor: pointer;height: 16px;" src="../icons/question.png" /> -->
                
                <!-- <img @click="close()" style="margin-right: 10px;cursor: pointer;" src="../icons/crossButton.svg" /> -->
            </div>
                <div v-if="showMoreInfo" style="position: absolute; right: 3%; top: 6.5%;">
                <div v-if="showMoreInfo" style="width: 236px; border: none; background: aliceblue; padding: 10px; margin-top: 10px; border-radius: 10px; box-shadow: 0 1px 2px rgba(0,0,0,.07), 0 2px 4px rgba(0,0,0,.07), 0 4px 8px rgba(0,0,0,.07), 0 8px 16px rgba(0,0,0,.07), 0 16px 32px rgba(0,0,0,.07), 0 32px 64px rgba(0,0,0,.07);">
                    <span style="color: black; font-size: 13px; font-weight: 600;">Efficient, customizable dashboards for data visualization and streamlined decision-making.</span>
                    <span style="cursor: pointer; text-decoration: underline; color: blue; margin-left: 5px;">Read More</span>
                </div>
            </div>
            </div>
            </div>
        <div style="margin-left: 45px;margin-right: 45px;margin-top: 30px;">
            <div v-if="!showIframe" style="display: grid;grid-template-columns: repeat(4, minmax(0, 1fr)); text-align: center;gap: 30px 35px;">
                <div class="templates" v-for="(data, idx) in templateData" style=" background: rgb(255, 246, 246); padding: 15px; border-radius: 12px; display: flex;
    align-items: center;box-sizing: border-box;">
                    <div style="display: flex; flex-direction: column; align-items: center; margin-top: -20px;">
                        <span style="font-size: 18px; margin: 10px; font-weight: 600;margin-top: 15px;margin-bottom: 6px;color: #000000a3;">{{ data.title }}</span>
                        <span style="font-size: 20px; margin: 8px;margin-bottom: 5px;"><img :src='require("../icons/" + data.imageURL)' style="height: 35px;" alt=""></span>
                        <span style="font-size: 12px; margin: 10px;margin-bottom: 20px;" v-html="data.description"></span>
                        <div style="display: flex;align-items: center;">
                            <span @click="handleDashboardNumberCheck(data);" style="color: blue; margin-right: 20px; cursor: pointer; font-size: 10px;" :style="{'color': (!data.dashbordNum) ? 'grey' : 'blue'}">Request Activation</span>
                            <span style="margin-left: 20px;" v-if="!data.dashbordNum" aria-label='Coming Soon' data-microtip-position="bottom-right" role="tooltip">
                                <button style="color: blue; cursor: pointer; font-size: 10px; padding: 5px 10px; background: transparent; border-radius: 12px;" :style="{'color': (!data.dashbordNum) ? 'grey' : 'blue'}" @click="handlePreview(data.dashbordNum, data.params)">
                                    Preview
                                </button>
                            </span>
                            <span v-if="data.dashbordNum" style="margin-left: 20px;">
                                <button class="templatesBtn" style="color: blue; cursor: pointer; font-size: 10px; padding: 5px 10px; background: transparent;" :style="{'color': (!data.dashbordNum) ? 'grey' : 'blue'}" @click="handlePreview(data.dashbordNum, data.params)">
                                    Preview
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="" v-else>
                <iframe style="height: 87vh;border: none;" :src="iframeSrc"/>
            </div>
    <div @click="sendReq=false" v-if="sendReq" style="height: 100%; width: 100%; position: fixed; top: 0; left: 0; z-index: 1111; background-color: rgba(0,0,0,.3);">

            <div  v-if="sendReq" style="    position: fixed;
    top: 40%;
    left: 35%;
    width: 30%;
    height: 20%;
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    z-index: 999999;
    display: flex;
    padding: 12px 35px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    " class="">
    <div style="color:#000000a3;font-size: 12px;font-weight: 600;">
        Sending a request to activate Dashboard {{ templateReq }} for your account . Our team will get back to you with a email soon
    </div>
    <div style="display:flex;align-items:flex-end;justify-content:flex-end;width: 100%;margin-top: 20px;">
        <button @click="handleSendReq">Send Request</button>
    </div>
    </div>
    </div>
        </div>
        <div style="display: flex; align-items: center; justify-content: center; margin-top: 30px;">
            <span style="color: blue; font-weight: 400; font-size: 14px;">To enable any template, contact support@AppEQ.ai.</span>
        </div>
        <!-- <div class="" style="display: flex;align-items: center;justify-content: flex-end;">
            <span style="color: black; font-weight: 400; font-size: 12px;">Powered by <a style="color: blue;" href="">AppEQ.ai</a></span>
            
        </div> -->
    </div>
</template>
<script>
import template from "./template.json";
import { JWT } from "node-jsonwebtoken";
import Server from '../components/API/Server';
import AppEQAnalytics from "../analytics/appeqAnalytics"
export default{
    data() {
        return {
            templateData: [],
            showIframe:false,
            iframeSrc:"",
            sendReq:false,
            showMoreInfo: false,
            disableReqButton: false,
            templateNumber: "",
            templateReq:""
        }
    },
    async mounted(){
        try {
        const temps=await Server.getTemplates()
        if(temps.code==200)
        this.templateData = temps.data;
    } catch (error) {
        console.log(error)    
        }
    },
    methods:{
        close() {
            // feeds.sendActionToTop("__appeq_close_ticket_iframe", "");
        },
        toggleShowMore(){
            if(this.showMoreInfo==true){
                this.showMoreInfo = false;
            }else{
                this.showMoreInfo = true;
            }
        },
        handleDashboardNumberCheck(data){
            if(!data.dashbordNum){
                this.disableReqButton = true;
                return;
            }
            this.sendReq=true;
            this.templateReq=data.title; 
            this.templateNumber=data.dashbordNum;
        },
        async handleSendReq(){
            await Server.sendReq({
                email:localStorage.getItem("__email__id"),
                template:this.templateReq,
                dashboardNum: this.templateNumber

            })
            this.sendReq=false
            toast.success("Request send successfully please wait while our team reaches out to you")
            AppEQAnalytics.actionCall(`ActivationRequestClicked`,"Dashboard",{dashboard_num:(this.templateNumber ? this.templateNumber : '67')}) 
        },
        async handlePreview(dashNum, params){
            if(!dashNum){
                return;
            }
            var METABASE_SITE_URL = "https://app.appeq.ai/bi";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            const token = await jwt.sign({
                resource: { dashboard:dashNum?dashNum: 67 },
                params: params,
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=true";
            // feeds.sendActionToTop("__appeq_open_template_dashboard", iframeUrl);

            this.iframeSrc=iframeUrl
            this.showIframe=true;
        }
    }
}
</script>
<style scoped="css">
.templatesBtn:hover{
    background-color: blue !important;
    color: white !important;
}
.templatesBtn{
    cursor: pointer;
    background: blue;
    color: white;
    border: 1px solid blue;
    border-radius: 12px;
}
button {
    background: #4e5fff;
    color: #fff;
    border: 0;
    padding: 0 16px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    height: 30px;
    cursor: pointer;
    border-radius: 6px;
}
.templates{
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    /* box-shadow: rgba(0, 0, 0, 0.11) 0px -1px 1px, rgba(0, 0, 0, 0.11) 0px -2px 2px, rgba(0, 0, 0, 0.11) 0px -4px 4px, rgba(0, 0, 0, 0.11) 0px -6px 8px, rgba(0, 0, 0, 0.11) 0px -8px 16px; */
}
</style>