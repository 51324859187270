import mixpanel from "mixpanel-browser";

mixpanel.init("cda11e4dea93609f5bc9e40c01479132", {
  persistence: "localStorage",
});
mixpanel.set_config({
  persistence: "localStorage",
  opt_out_tracking_by_default: false,
  opt_out_tracking_persistence_type: "localStorage",

  // opt users out of browser data storage by this Mixpanel instance by default
  opt_out_persistence_by_default: false,
});
mixpanel.opt_in_tracking({
  persistence_type: "localStorage",
});
class Mixpanel {
  async init(name, email_id, type) {
    //AppEQ Embed: Project Token : 5e871fe705e955800054680cabb6e911
    //AppEQ Chrome Extension: Project Token : cda11e4dea93609f5bc9e40c01479132

    mixpanel.identify(email_id);
    mixpanel.people.set({
      Plan: "Free",
      $name: name,
      $email: email_id,
      $type: type,
    });
  }

  dashboardLogin(username, email, loginThrough) {
    mixpanel.track("dsh_login", {
      username,
      email,
      loginThrough,
    });
  }

  businessObjectAdded(businessObjectName) {
    mixpanel.track("dsh_businessObject_added", {
      businessObjectName,
    });
  }

  extentionDownload() {
    mixpanel.track("dsh_extension_download");
  }

  summarySaved() {
    mixpanel.track("dsh_summary_template_saved");
  }

  visitedSummaryTemplate() {
    mixpanel.track("dsh_visited_summary_template");
  }

  visitedSummaryTemplateDesigner() {
    mixpanel.track("dsh_visited_summary_template_designer");
  }

  tourStarted() {
    mixpanel.track("dsh_tour_started");
  }

  tourSkipped(page) {
    mixpanel.track("dsh_tour_skipped", {
      onPage: page,
    });
  }
  selectedAppIntour(app) {
    mixpanel.track("dsh_tour_selectedApp", {
      selectedApp: app,
    });
  }

  openedTicketPlg(username, email, appName) {
    mixpanel.track("dsh_ticket_plg_opned", {
      username,
      email,
      appName,
    });
  }
  openedTicketPlgPage(email, page, appName) {
    mixpanel.track("dsh_ticket_plg_page_opened", {
      email,
      page,
      appName,
    });
  }
  openedTiccketPlgInstallExtension(email, appName) {
    mixpanel.track("dsh_ticket_plg_installed_extension", {
      email,
      appName,
    });
  }
  openedTiccketPlgFeeds(email, appName) {
    mixpanel.track("dsh_ticket_plg_opened_feeds", {
      email,
      appName,
    });
  }
}

export default new Mixpanel();
