<template>
    <div>
        <div style="height: 100%;padding: 20px;">
            <div style="display: flex;justify-content: space-between; ">
                <Multiselect  style="flex: 1;" v-model="accountStatus['status']" placeholder="Status"
                    :options="optionsStatus" />
                <Multiselect style="flex: 1;margin-left: 12px;" v-model="accountStatus['reason']" placeholder="Reason"
                    :options="optionsReason" />
                <input type="date" placeholder="Effective Date" style="border-radius: 5px;
    border: 1px solid #e8e8e8;flex: 1;height: 45px;margin-top: 13px;margin-left: 12px;padding: 0px 8px;"
                    v-model="accountStatus['effectiveDate']">
            </div>
            <input v-model="accountStatus['notes']" type="text" placeholder="Notes" style="width: 100%; height: 60px; border-radius: 6px;margin-top: 20px;
    border: 1px solid #e8e8e8; padding: 10px;">

        </div>
        <div style="display: flex; margin: 10px;justify-content: flex-end;">
            <button
                style="background: transparent; color: blue; border: 1px solid blue; border-radius: 7px; height: 40px; width: 100px; margin: 5px;">Clear</button>
            <button @click="handleSaveAccountsData"
                style="background: blue; color: white; border-radius: 7px; height: 40px; width: 100px; margin: 5px;">Save</button>
        </div>
        <hr style="margin-top: 2rem;">
        <div style="overflow: scroll;
    height: 200px;">
            <table style="width: 100%;">
                <tr>
                    <th v-for="col of columns" :style="{'width': accountWidth[col]}">
                    {{ col }}
                    </th>
                </tr>
                <tr v-for="acc of previousAccountStatus" >
                    <td v-for="(col,idx) of columns" :style="{'width': accountWidth[idx]}">
                        {{ acc[mappedFields[idx]] }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Server from '../components/API/Server';
import moment from 'moment';
export default {
    components: {
        Multiselect
    },
    props:{
        account:{type:Object},
        previousAccountStatus:{type:Array},
        status:{type:Boolean}

    },
    mounted(){
        this.accountStatus['effectiveDate']=new Date().toISOString().slice(0,10) 
        console.log(this.accountStatus)
        if(this.previousAccountStatus && Array.isArray(this.previousAccountStatus)){
        this.previousAccountStatus.reverse()
            
            console.log(this.previousAccountStatus)
            this.previousAccountStatus.map(d=>{
                console.log(d["created_at"])
                d["created_at"]=moment(d["created_at"]).format("DD MMM ,YYYY")
            })
            this.accountStatus['status']=this.previousAccountStatus[this.previousAccountStatus.length-1]["status"]

        }
    },
    methods: {
        async handleSaveAccountsData() {
            try {
                this.accountStatus={...this.accountStatus,...this.account}
                console.log(this.accountStatus)
                if(  this.accountStatus["notes"].length==0 || this.accountStatus["reason"].length==0){
                    this.$toasted.error("Please fill all the fields before saving")
                    return
                }
                const data = await Server.saveAccountstatus(this.accountStatus)
                console.log(data)
                if(data.code==200){
                    this.$toasted.success("Successfully saved accounts status")
                    this.$emit("save")
                }else{
                    this.$toasted.error(data.msg)

                }

            } catch (error) {
                console.log(error)
                this.$toasted.error(error.toString())
            }   
        },
    },

    data() {
        return {
            optionsStatus: ['Active', 'Inactive'],
            optionsReason: ['Trail', 'Paid Plan', 'Churn'],
            columns:["Date","Status","Reason","Notes","Effective Date"],
            mappedFields:["created_at","status","reason","notes","effectiveDate"],
            accountStatus: {
                'status': '',
                'reason': '',
                'effectiveDate': new Date().toISOString().split('T')[0],
                'notes': '',
                'created_at': new Date()
            },
            accountWidth: [
                '10%',
                '10%',
                '10%',
                '40%',
                '10%'
            ]
        }
    }
}
</script>
<style lang="">
    
</style>