<template>
    <div style="margin: 20px;">
        <div style="display: flex; justify-content: space-between;">
            <div class="systemCard" style="background: rgb(255 246 246); width: 27%; border-radius: 20px; height: 33vh;">
                <h3 style="margin-top: 30px; margin-bottom: 10px; text-align: center; font-size: 21px; font-weight: 600;">Red Account</h3>
                <span style="display: flex; justify-content: center;">
                    <img src="../assets/redCondition.webp" height="25px" alt="">
                </span>
                
                <div class="contentBox" style="margin: 10px 10px 10px 20px; height: 100px;">
                    <p style="color: black;">Notify the CSM whenever an account turns RED based on the conditions set on Red Signals section</p>
                </div>
                <div style="display: flex; justify-content: end; margin-right: 15px;">
                    <label class="switch">
                    <input type="checkbox" v-model="redAccount" @change="redAccountChange">
                    <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div class="systemCard"  style="background: rgb(255 246 246); width: 27%; border-radius: 20px; height: 33vh;">
                <h3 style="margin-top: 30px; margin-bottom: 10px; text-align: center; font-size: 21px; font-weight: 600;">Account Pulse</h3>
                <span style="display: flex; justify-content: center;">
                    <img src="../assets/accountPulse.webp" height="25px" alt="">
                </span>
                <div class="contentBox"  style="margin: 10px 10px 10px 20px; height: 100px;">
                    <p style="color: black;">Notify CSM to set account pulse periodically to ensure they are on top of their portfolio accounts</p>
                </div>
                <span style="display: flex;" :style="{'justify-content': (accountPulse) ? 'space-between' : 'end'}">
                    <span v-if="accountPulse" style="display: flex; margin-left: 10px; font-size: 12px; align-items: baseline;">Every <input type="number" name="" placeholder='14' @input="accountPulseTasksChange()" v-model="accountPulseTasks" class="input_box" width="80px" value="14" id=""> days</span>
                    <span style="display: flex; justify-content: end; margin-right: 15px;">
                        <label class="switch">
                        <input type="checkbox" v-model="accountPulse" @change="accountPulseChange">
                        <span class="slider round"></span>
                        </label>
                    </span>
                </span>
            </div>
            <div class="systemCard"  style="background: rgb(255 246 246); width: 27%; border-radius: 20px; height: 33vh;">
                <h3 style="margin-top: 30px; margin-bottom: 10px; text-align: center; font-size: 21px; font-weight: 600;">G2 Nudges</h3>
                <span style="display: flex; justify-content: center;">
                    <img src="../assets/G2Nudges.webp" height="25px" alt="">
                </span>
                <div class="contentBox"  style="margin: 10px 10px 10px 20px; height: 100px;">
                    <p style="color: black;">Notify CSM to get G2 review whenever a new NPS of 10 is received from an account</p>
                </div>
                <span style="display: flex;" :style="{'justify-content': (G2Nudges) ? 'space-between' : 'end'}">
                    <span v-if="G2Nudges" style="font-size: 12px; margin-left: 10px; display: flex; align-items: center;">G2 Link <input  class="G2Nudges input_box"  @input="g2NudgesTasksChange()" v-model="g2Link" type="text" name="" placeholder='https://google.com/...' value="https://google.com" id=""></span>
                    <span style="display: flex; justify-content: end; margin-right: 15px;">
                    <label class="switch">
                    <input type="checkbox" v-model="G2Nudges" @change="G2NudgesChange">
                    <span class="slider round"></span>
                    </label>
                </span>
                </span>
            </div>
            <div class="systemCard"  style="background: rgb(255 246 246); width: 43%; border-radius: 20px; height: 33vh;">
                <h3 style="margin-top: 30px; margin-bottom: 10px; text-align: center; font-size: 19px; font-weight: 600;">Key Contact Changes</h3>
                <span style="display: flex; justify-content: center;"><img src="../assets/KeyContactChanges.png" height="25px" alt=""></span>
                <div class="contentBox"  style="margin: 10px 10px 10px 20px; height: 100px;">
                    <p style="color: black;">Notify CSM to check whether a contact has left the organisation</p>
                </div>
                <div style="display: flex; justify-content: end; margin-right: 15px;">
                    <label class="switch" v-tooltip="'This feature is not implemented'">
                    <input type="checkbox" v-model="keyContact" @change="keyContactChange" disabled>
                    <span class="slider round disabled_slider"></span>
                    </label>
                </div>
            </div>
        </div>
        <div style="display: flex;  justify-content: space-between; margin-top: 2rem;">
            
            <div class="systemCard"  style="background: rgb(255 246 246); width: 43%; border-radius: 20px; height: 33vh;">
                <h3 style="margin-top: 30px; margin-bottom: 10px; text-align: center; font-size: 21px; font-weight: 600;">Account Planning</h3>
                <span style="display: flex; justify-content: center;"><img src="../assets/AccountPlanning.png" height="25px" alt=""></span>
                <div class="contentBox"  style="margin: 10px 10px 10px 20px; height: 100px;">
                    <p style="color: black;">Notify CSM to plan activities for an account whenever there are not many activities planned</p>
                </div>
                <span style="display: flex;" :style="{'justify-content': (accountPlanning) ? 'space-between' : 'end'}">
                    <span v-if="accountPlanning" style="margin-left: 20px; font-size: 12px; display: flex; align-items: center;">Task < <input class="input_box accountPlanning" v-model="accountPlanningTasks" @input="accountPlanningTasksChange()" placeholder='2' type="number" value="2"></span>
                    <span style="display: flex; justify-content: end; margin-right: 15px;">
                    <label class="switch">
                    <input type="checkbox" v-model="accountPlanning" @change="accountPlanningChange">
                    <span class="slider round"></span>
                    </label>
                </span>
                </span>
            </div>
        </div>
        <div style="width: 100%; display: flex; justify-content: end;" v-if="this.saveCondition==true">
            <button style="width: 13%; height: 45px; border-radius: 20px;" @click="saveNotificationData()">Save</button>
        </div>
    </div>
</template>
<script>
import Server from "../components/API/Server"
export default{
    data(){
        return{
            keyContact: false,
            accountPlanning: false,
            G2Nudges: false,
            accountPulse: false,
            redAccount: false,
            saveCondition: false,
            g2Link: 'https://www.google.com',
            accountPlanningTasks: '2',
            accountPulseTasks: '14',
        }
    },
    props: ["notificationSetting"],
    async mounted(){
        const data = (await Server.getNotificationSettings()).data;
        this.notificationSetting=data[0].notificationSettings
        console.log(this.notificationSetting);

        this.redAccount = this.notificationSetting.healthChange;
        this.accountPulse = this.notificationSetting.pulseSettingChange;
        this.keyContact = this.notificationSetting.keyContact;
        this.G2Nudges = this.notificationSetting.g2Nudges;
        this.accountPlanning = this.notificationSetting.accountPlanning;
        this.g2Link = this.notificationSetting.g2NudgesLink
        this.accountPlanningTasks = this.notificationSetting.accountPlanningTasks;
        this.accountPulseTasks = this.notificationSetting.pulseTime;
    },
    methods: {
        accountPlanningChange(){
            this.saveCondition = true;
        },
        accountPulseChange(){
            this.saveCondition = true;
        },
        G2NudgesChange(){
            this.saveCondition = true;
        },
        redAccountChange(){
            this.saveCondition = true;
        },
        keyContactChange(){
            this.saveCondition = true;
        },
        accountPlanningTasksChange(){
            console.log(this.accountPlanningTasks);
            this.saveCondition = true;
        },
        g2NudgesTasksChange(){
            console.log(this.g2Link);
            this.saveCondition = true;
        },
        accountPulseTasksChange(){
            console.log(this.accountPulseTasks);
            this.saveCondition = true;
        },
        async saveNotificationData(){
            console.log(this.notificationSetting);
            if(this.redAccount == '' || this.redAccount == undefined || this.redAccount == null){
                this.redAccount = false;
            }
            this.notificationSetting['healthChange'] = this.redAccount;
            this.notificationSetting['pulseSettingChange'] = this.accountPulse;
            if(this.G2Nudges == '' || this.G2Nudges == undefined || this.G2Nudges == null){
                this.G2Nudges = false;
            }
            this.notificationSetting['g2Nudges'] = this.G2Nudges;
            if(this.keyContact == '' || this.keyContact == undefined || this.keyContact == null){
                this.keyContact = false;
            }
            this.notificationSetting['keyContact'] = this.keyContact;
            if(this.accountPlanning == '' || this.accountPlanning == undefined || this.accountPlanning == null){
                this.accountPlanning = false;
            }
            this.notificationSetting['accountPlanning'] = this.accountPlanning;
            if(this.g2Link == '' || this.g2Link == undefined || this.g2Link == null){
                this.g2Link = 'https://google.com';
            }
            this.notificationSetting['g2NudgesLink'] = this.g2Link;
            if(this.accountPlanningTasks == '' || this.accountPlanningTasks == undefined || this.accountPlanningTasks == null){
                this.accountPlanningTasks = '2';
            }
            this.notificationSetting['accountPlanningTasks'] = this.accountPlanningTasks;
            if(this.accountPulseTasks == '' || this.accountPulseTasks == undefined || this.accountPulseTasks == null){
                this.accountPulseTasks = '14';
            }
            this.notificationSetting['pulseTime'] = this.accountPulseTasks;
            console.log(this.notificationSetting);
            await Server.saveNotificationSettings(this.notificationSetting);
            this.saveCondition = false;
        }
    }
}
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.input_box{
    width: 80px;
    margin-left: 5px;
    margin-bottom: 10px;
    margin-right: 5px;
    border: none;
}
.accountPlanning{
    margin-bottom: 0px;
}
.G2Nudges{
    margin-left: 5px;
    margin-bottom: 0px;
}
.disabled_slider{
    background-color: #dbd4d4;
}
.contentBox{
    margin: 10px 10px 10px 20px !important;
    height: 80px !important;
    font-size: 12px !important;
    color: #939191 !important;

}
.systemCard{
    background: #E9F1FB !important;
    width: 20% !important;
    border-radius: 20px !important;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.3 ) !important;

}
</style>