<template>
  <component :is="tag" class="nav-item" v-bind="$attrs" tag="li">
    <a style="display: flex;align-items: center;justify-content: space-between;width: 100%;"  @click="handleSelect($event)"  @mouseenter="handleSelectMouseEnter($event)" class="nav-link">
      <slot>
        <div class="" style="display: flex;align-items: center;">
          <img v-if="image"  style="height: 32px;" src="../../assets/redIcon.png"/>
          <img  style="height: 25px;margin-right: 14px;" v-else-if="imgSrc" :src="imgSrc" alt="">
          <i style="font-size: 25px;color: #4e4c4c !important;" v-else-if="icon" :class="icon">{{ iconname }}</i>
          <p>{{ name }}
          </p>
        </div>
        <i style="font-size: 10px;" v-if="accordian && !activeAccordian" class="tim-icons icon-minimal-down"></i>
        <i style="font-size: 10px;" v-if="accordian && activeAccordian" class="tim-icons icon-minimal-up"></i>
      </slot>
    </a>

  </component>
</template>
<script>
export default {
  name: "sidebar-link",
  inheritAttrs: false,
  data(){
    return {
      showsumm:false,
    }
  },
  inject: {
    autoClose: {
      default: true
    },
    addLink: {
      default: () => { }
    },
    removeLink: {
      default: () => { }
    }
  },
  props: {
    name: String,
    iconname: String,
    image:Boolean,
    imgSrc:String,
    icon: String,
    img: String,
    accordian:false,
    activeAccordian:false,
    tag: {
      type: String,
      default: "router-link"
    },
    expanded:Boolean,
  },
  methods: {
    handleSelectMouseEnter(event){
      if(this.accordian && !this.expanded){
        event.preventDefault();
        this.$emit("showaccordian")
      }
    },

    
    handleSelect(event){
    if(this.accordian){
      event.preventDefault();
      this.$emit("showaccordian")
    }
    },
    // hideSidebar() {
    
    //   if (this.autoClose) {
    //     this.$sidebar.displaySidebar(false);
    //   }
    // },
    isActive() {
      return this.$el.classList.contains("active");
    }
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  }
};
</script>
<style>

</style>
