<template>
    <div style="margin-top: 20px;">
            <div style="height: 70vh; overflow: scroll;">
                <div style="display: flex;align-items: center; justify-content: space-between; padding: 8px 10px;width: 95%;" class="" v-for="(log, idx) of logsNudges">
                <span style="flex: 0.35;" class="log-message">Data Sync for {{ log.appeq_supported_application }} <span style="font-weight: 600;" :style="{'color': (formatMessage(log.message)=='failed') ?'red':''}">{{ formatMessage(log.message) }}</span></span>
                <span style="flex: 0.3;" class="log-started-at">{{ formatDateTime(log.timestamp) }}</span>
                <span style="margin-left: 1rem; flex: 0.4;">  total: {{ log.data }}</span>

            </div>
            </div>
            <div>
                <ve-pagination @on-page-number-change="pageNumberChange" :total="totalDataCount" :page-size="allotedPageSize" />
            </div>
    </div>
</template>
<script>
import Server from './API/Server';
export default{
    data(){
        return {
            logsNudges: {},
            business_object_name: "",
            totalDataCount: 0,
            allotedPageSize: 50,
            pageNo: 0
        }
    },
    props: {
        bObject: ""
    },
    async mounted(){
        this.business_object_name = this.bObject;
        let data = (await Server.getDataObjectLogs(this.business_object_name, this.pageNo, this.allotedPageSize)).data;
        this.logsNudges = data.data;
        this.totalDataCount = data.count;
    },
    methods: {
        formatDateTime(dateTimeString) {
            var originalDate = new Date(dateTimeString);
            var day = originalDate.getDate();
            var monthIndex = originalDate.getMonth();
            var year = originalDate.getFullYear();

            // Define an array of month names
            var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            // Format the date in the desired format
            return day + "-" + monthNames[monthIndex] + "-" + year + ", " + originalDate.toLocaleTimeString();
        },
        async pageNumberChange(e){
            console.log(e);
            this.pageNo = e-1;
            let data = (await Server.getDataObjectLogs(this.business_object_name, this.pageNo, this.allotedPageSize)).data;
            this.logsNudges = data.data;
        },
        formatMessage(data){
            if(data.includes('Cron job')){
                console.log(data, '---- cron job')
                let temp = '';
                if(data.includes('completed')){
                    temp = 'completed';
                }else if(data.includes('started')){
                    temp = 'started';
                }else{
                    temp = 'failed';
                }
                return temp;
            }
        }
    }
}
</script>
<style>
.logs-container {
    max-width: 600px;
    margin: 20px auto;
}

.log-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.log-info {
    flex-grow: 1;
}

.log-message {
    font-weight: 400;
    margin-right: 10px;
}

.log-started-at {
    color: #666;
}

.log-status {
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
}

.running {
    color: blue;
}

.success {
    color: #4caf50;
    /* Green */
}

.error {
    color: #8b8686;
    /* Red */
}
</style>