<template>
    <div style="height: 100vh;margin-bottom: 0px;" class="card">
        <div class="">
            <div v-if="widget && widget.content && widget.content.title" class=""
                style="font-size: 20px;font-weight: 600;padding-left: 10px;padding-top: 10px;display: flex;align-items: center;justify-content: center;">
                {{ widget.content.title }}
            </div>
            <div v-if="widget && widget.content && widget.content.desc" class=""
                style="font-size: 12px;font-weight: 400;padding-left: 30px;padding-top: 10px;display: flex;align-items: center;justify-content: center;">
                {{ widget.content.desc }}
            </div>
            <img v-if="noShow" @click="handleClose()" src="../icons/feed_close.svg" style="position: absolute;top: 4px;right: 4px;" />
        </div>
        <div style="display: flex;align-items: center;justify-content: center;height: 100%;" class="" v-if="loading">
            <img src="../icons/loadingripple.svg" style="height: 120px;" />
        </div>
        <div v-if="widget.widget && widget.widget.type" style="height: 83%;" class="">
            <TaskPage v-if="widget.widget.type == 'tasklist'" :filterCond="widget.widget.filter" />
            <div style="height: 100%;" class="" v-else-if="widget.widget.type.toLowerCase().includes('dashboard')">
                <!-- <iframe id="appeqMetabase" style="height: 100%;margin-top: 10px;border: none;"
                    :src="metabaseDashboard" /> -->
                    <SideDash :url="metabaseDashboard" />
            </div>
            <div class="" v-else-if="widget.widget.type == 'data object list'">
                <ObjectList :widget="widget.widget" />
            </div>
            <div style="height: 100%;padding: 20px" class="" v-else-if="widget.widget.type=='multi tile'">
                <GridLayout
                style="height: 100%;"
        class="grid-container" 

                           :class="{ 'empty-grid': widget.widget.layout.length === 0 }"
      :layout.sync="widget.widget.layout"
      :col-num="12"
      :row-height="30"
      :is-draggable="true"
      :is-resizable="true"
      :is-mirrored="false"
      :vertical-compact="true"
      :margin="[10, 10]"
      ref="gridLayout"
      :use-css-transforms="true"
     

    >
      <GridItem 
      class="griditem"
      v-for="item in widget.widget.layout"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                :key="item.i">
        <!-- Your widget content -->
        <TaskPage v-if="item.type == 'tasklist'" :filterCond="item.filter" />
        <div style="height: 100%;" class="" v-else-if="item.type.toLowerCase().includes('dashboard')">
                <iframe id="appeqMetabase" style="height: 100%;margin-top: 10px;border: none;"
                    :src="metabaseDashboard" />
            </div>
            <div style="width: 100%;height: 100%;" class="" v-else-if="item.type == 'data object list'">
                <ObjectList :widget="item" />
            </div>
            <div style="height: 100%;" class="" v-else>
                <div v-html="item.content"
                    style="height: 100%;overflow-y: scroll;overflow-x: hidden;padding: 20px;" class="noscroll">
                </div>
                <div class="" style="display: flex;align-items: center;justify-content: flex-end;margin-top: 20px">
                    <button style="width: 10%;height: 30px;">{{ item.button1Text }}</button>
                    <button
                        style="width: 8%;margin-left: 10px;margin-right: 10px;height: 30px;">{{ item.button2Text }}</button>

                </div>
            </div>
      </GridItem>
      <div   class="" v-if="widget.widget.layout.length==0">
        <h2>Drag Widgets Here</h2>
    </div>
    </GridLayout>
            </div>
            <div style="height: 100%;" class="" v-else>
                <div v-html="widget.widget.content"
                    style="height: 100%;overflow-y: scroll;overflow-x: hidden;padding: 20px;" class="noscroll">
                </div>
                <div class="" style="display: flex;align-items: center;justify-content: flex-end;margin-top: 20px">
                    <button style="width: 10%;height: 30px;">{{ widget.widget.button1Text }}</button>
                    <button
                        style="width: 8%;margin-left: 10px;margin-right: 10px;height: 30px;">{{ widget.widget.button2Text }}</button>

                </div>
            </div>
        </div>
        <div v-if="kpiUrl!=''" style="height: 100%;">
            <iframe :src="kpiUrl" frameborder="0"></iframe>
        </div>

        <div v-if="noShow" style="height: 6%;background-color: #E9F1FB;" class="popup__footer">
            <div class=""></div>
            <div style="
            display: flex;align-items: center;font-size: 10px;" class="">
                <input style="margin-right: 8px;" type="checkbox" v-model="checkIfShowAgain" name="" id="">Do not show
                again
            </div>
            <div style="display: flex;align-items: center;" class="">
                <img src="../icons/share.svg"
                    style="height: 10px;margin-right: 4px;cursor: pointer;filter: invert(27%) sepia(90%) saturate(6734%) hue-rotate(355deg) brightness(93%) contrast(120%);" /><span
                    style="font-size: 8px;">Powered By <span
                        style="font-weight: 600;color: blue;">AppEQ.ai</span></span>

            </div>
        </div>
    </div>

</template>
<script>
import { GridLayout, GridItem } from 'vue-grid-layout';
import TaskPage from "../components/TaskPage.vue"
import { JWT } from "node-jsonwebtoken";
import Server from "../components/API/Server";
import ObjectList from "./ObjectList.vue";
import SideDash from './SideDash.vue';

export default {
    components: {
        TaskPage,
        ObjectList,
        GridItem,
        GridLayout,
        SideDash
    },
    data() {
        return {
            widget: {},
            loading: false,
            noShow:true,
            userEmail: '',
            checkIfShowAgain: false,
            kpiUrl: "",
            metabaseDashboard: ""
        }
    },
    methods: {
        async handleClose() {
            if (this.checkIfShowAgain == true) {
                console.log(this.widget, JSON.stringify(this.widget), 'widget daata ehere');

                let res = await Server.doNotShowAgainWidget(this.widget._id);
                console.log(res, 'response of do not show');
            }
            window.parent.postMessage({ action: "__appeq_close_popup", data: {} }, "*");
        },
        getParamsByKey() {
            const queryString = window.location.search.slice(1); // Remove the leading '?'
            const params = new URLSearchParams(queryString);

            const filteredParams = {};
            params.forEach((key, val) => {
                console.log(key, val)
                filteredParams[val] = key;
            });

            return filteredParams;
        },
        async openDash(number) {
            console.log("Dash opening", number)
            // const userInfo = (await ApiServer.getUsersData(localStorage.getItem("__email__id"))).data
            // this.org_id = userInfo.data.org_id
            var METABASE_SITE_URL = "https://app.appeq.ai/bi";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            const token = await jwt.sign({
                resource: { dashboard: number },
                params: {},
                exp: Math.round(Date.now() / 1000) + (1 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";
            this.metabaseDashboard = iframeUrl
            // feeds.sendActionToTop("__appeq_open_executive_dashboard", iframeUrl);

        },
    },
    watch: {
        widgetProp: {
            deep: true,
            handler: async function (newVal, oldVal) {
                this.widget = this.widgetProp
                console.log("this iwgdte")
                this.loading = false

                if (this.widget && this.widget.widget && this.widget.widget.number) {
                this.loading = true

                    await this.openDash(this.widget.widget.number)
                this.loading = false
                    
                }

            }

        }
    },
    async mounted() {
        Intercom('update', {
            "hide_default_launcher": true
        });
        console.log("Widget opening")
        const params = this.getParamsByKey()

        if(params['widget']){
            const params = this.getParamsByKey()

            localStorage.setItem("__email__id", params['email'])
            this.widget=JSON.parse(params['widget'])[0]
            console.log(this.widget)
            if (this.widget && this.widget.widget && this.widget.widget.number) {
                await this.openDash(this.widget.widget.number)
                const iframe = document.getElementById("appeqMetabase")
                if (iframe) {
                    this.loading = true;
                    iframe.addEventListener("message",(ev)=>{
                        console.log("message recd")
                        console.log(ev)
                    })
                    iframe.addEventListener("load", () => {
                        this.loading = false
                        setTimeout(() => {
                            console.log("checking started")
                            console.log(iframe.contentDocument)
                           const len= iframe.contentDocument.querySelectorAll(".link.link--wrappable").length
                           iframe.contentDocument.querySelectorAll(".link.link--wrappable").forEach(d=>{
                            d.addEventListener("click",(ev)=>{
                                this.metabaseDashboard="https://app.appeq.ai/bi/public/dashboard/41aafbe5-c0e9-497e-a08a-c831e7ac58bc?appeq_org_id=9e843969-521c-4700-b287-681c8e868f62&domain=mailshake.com"
                            })
                           })
                           console.log(len)
                        }, 10000);
                    })
                } else {
                    this.loading = false
                }
            }
        }
        if(params['show'] && params['show']=='no'){
            this.noShow=false;
        }
        if (this.widgetProp) {
            this.widget = this.widgetProp
            this.loading = false
            if (this.widget && this.widget.widget && this.widget.widget.number) {
                await this.openDash(this.widget.widget.number)
                const iframe = document.getElementById("appeqMetabase")
                if (iframe) {
                    this.loading = true;

                    iframe.addEventListener("load", () => {
                        this.loading = false
                    })
                } else {
                    this.loading = false
                }
            }
        }
    },
    props: {
        widgetProp: {}
    },
    created() {
        window.addEventListener("message", async (ev) => {
            console.log("Got message")
            console.log(ev)
            console.log(JSON.stringify(ev), 'ev daata');
            if (ev.data.widget && ev.data.widget.length > 0) {
                this.loading = false
                console.log('code here widget ev data')
                this.widget = ev.data.widget[0]
                this.userEmail = ev.data.email;
                const params = this.getParamsByKey()

                localStorage.setItem("__email__id", params['email'])
                if(this.widget && this.widget.widget && this.widget.widget.type == "multi tile"){
                    const number=this.widget.widget.layout.filter(d=>d.type=='dashboard')
                    console.log(number,"numberfor dashboard")
                    if(number && number.length!=0)
                    await this.openDash(number[0]['number'])
                    const iframe = document.getElementById("appeqMetabase")
                    if (iframe) {
                        this.loading = true;

                        iframe.addEventListener("load", () => {
                            this.loading = false
                        })
                    } else {
                        this.loading = false
                    }

                }
                if (this.widget && this.widget.widget && this.widget.widget.type == "dashboard") {
                    console.log(this.widget, JSON.stringify(this.widget), 'widget check here')
                    this.loading=true
                    await this.openDash(this.widget.widget.number)
                    this.loading=false;
                }
                if(this.widget.url){
                    this.kpiUrl = this.widget.url;
                }
            }

        })
    }
}
</script>
<style lang="scss" scoped>
.popup__footer {
    margin-top: 20px;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
}

.noscroll::-webkit-scrollbar {
    display: none;
}

iframe {
    &.ql-video {
        height: 100% !important;

    }
}

.ql-editor .ql-video {
    height: 100% !important;
}
.ql-video{
    height: 100%;
}
p{
    color: black
}
::-webkit-scrollbar {
  width: 0px;
  display: none;
}

iframe{
    height: 100%
}

</style>
<style>
.noscroll .ql-video{
    height: 100% !important;
}
</style>