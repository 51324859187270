<template >
    <div v-if="!mappNot">
        <div style="display: flex;justify-content: flex-end;margin-top: 15px;margin-bottom: 20px;">
            <div class="">
             <span style="font-weight: 600;color: grey;">Standard Account Fields</span>   - Map the standard fields of AppEQ with Salesforce fields for sync (bi-directional)
            </div>
            <!-- <button @click="handleClear()" style="width: 20%;height: 40px;border: 1px solid #4e5fff;background-color: transparent;color: #4e5fff;">Clear</button> -->
            <button @click="handleSave()" style="width: 20%;margin-left: 10px;height: 35px;background-color: rgb(112, 112, 255);
    padding: 5px;
    width: 140px;
    height: 40px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    outline: none;
    margin-right: 1rem;">Save</button>

        </div>
        <div v-if="showForSpreadSheet" style="height: 60vh;overflow: scroll;">
            <table style="width: 100%;">
            <tr>
                <th>Field Name</th>
                <th>Header Name</th>
            </tr>
            <tbody style="height: 80%;overflow: auto;">
                <tr v-for="row of spreadSheetData">
                    <td>{{ row.label }}</td>
                    <td >
                        <Multiselect v-model="row['selectedKey']" :options="datatype" style="width: 100%;margin-top: 0px !important;">
                        </Multiselect>
                    </td>
            </tr>
            </tbody>
           
           
        </table>
        </div>
        <div v-if="!showForSpreadSheet" style="height: 60vh;overflow: scroll;">
            <table style="width: 100%;">
            <tr>
                <th>Field Name</th>
                <th>Header Name</th>
                <th>Remarks</th>
            </tr>
            <tbody style="height: 80%;overflow: auto;">
                <tr v-for="row of rows">
                    <td>{{ row.label }}</td>
                    <td >
                        <Multiselect v-model="row['selectedKey']" :options="keys" style="width: 100%;margin-top: 0px !important;">
                        </Multiselect>
                    </td>
                    <td>{{ row.remarks }}</td>
            </tr>
            </tbody>
           
           
        </table>
        </div>
      
      
    </div>
    <div style="display: flex;flex-direction: column;align-items: center;" class="" v-else>
        <img src="../assets/nodata.svg" style="height: 300px;"/>
        Data Mapping Not Required
    </div>
</template>
<script>
import Server from "./API/Server";
import Multiselect from "vue-multiselect";
import {fields, fields2,Pulse, Contacts} from "./Utils/Constants.js"
import LocalUtils from "./Utils/LocalUtils";
export default {
    props:{
        mappedFields:{type:Array},
        source: {type:String},
        bObject:{type:String}
    },
    data() {
        return {
            rows: fields,
            spreadSheetData: [],
            showForSpreadSheet: false,
            keys: [],
            allUsers:[],
            datatype: ['text','number','date'],
            mappNot:false,
        };
    },
    async mounted() {
        console.log(this.source);
        if(this.source=='spreadsheet'){
            let data = await Server.getFieldsApi(this.bObject);
            console.log(data)
            if(data.data && data.data.data){
                let vol = data.data.data;
                for(let i=0;i<vol.length;i++){
                    this.spreadSheetData.push({label: vol[i].label, key: vol[i].key, selectedKey: vol[i].datatype});
                }
            }
            console.log(this.spreadSheetData)
            this.showForSpreadSheet = true;
            this.mappNot = false;
            return;
        }
        if(this.bObject!="accounts" && this.bObject!="Contacts" &&this.bObject!="Account_Pulse__c" &&  this.bObject!="Pulse__c" && this.bObject!="Net Promoter Score"){
            this.mappNot=true;
            return;
        }
       if(this.bObject!="accounts"){
        this.rows=fields2
       }
       if(this.bObject=="Account_Pulse__c"){
        this.rows=Pulse
       }
       if(this.bObject=="Contacts"){
        this.rows = Contacts;
       }
       if(this.bObject=="Customer DB"){
        this.rows=fields
       }
       if(this.bObject=="Pulse__c"){
        this.rows=Pulse
       }
      
        const data = (await Server.getConfiguratorKeys(this.bObject)).data;
      
        if (data && data.code == 200) {
            this.keys = data.keys;
        }
      const creds=(await Server.googleSheetCredentials(LocalUtils.getEmail())).data
      console.log(creds)
      const selectedCred=creds.filter(d=>d.business_object_name==this.bObject)
      if(selectedCred.length!=0)
      this.mappedFields=selectedCred[0].mappedFields
      this.rows = this.rows.map(data => {
          
          if(this.mappedFields && Array.isArray(this.mappedFields)){
              const idx=this.mappedFields.findIndex(d=>d.key==data.key)
              return {...data,selectedKey:this.mappedFields[idx]['selectedKey']}
          }
          return { ...data, selectedKey: "" };
      });
      console.log(this.mappedFields,"gaurav12")
    },
    methods:{
        handleClear(){
            this.rows=this.rows.map(d=>{return {...d,selectedKey:''}})
        },
        async handleSave(){
            try {
                let finalObj = [];
                let data;
                if(this.source == 'spreadsheet'){
                    finalObj = this.spreadSheetData.map(data=>{
                        return {label: data.label, key: data.key, datatype: data.selectedKey}
                    })
                    data=await Server.saveFieldsApi({mappedFields:finalObj,business_object_name:this.bObject});
                }else{
                    finalObj=this.rows.map(data=>{
                return {key:data.key,selectedKey:data.selectedKey}
            })
            data=await Server.saveMapping({mappedFields:finalObj,business_object_name:this.bObject});
                }
            if(data.code==200){
                this.$toasted.success("Saved mapped fields")
                // window.location.reload();
            }
        } catch (error) {
                this.$toasted.error("Something went wrong")
            }
        }
    },
    components: { Multiselect }
}
</script>
<style lang="scss" scoped>
    td{
        border: 1px solid #e8e8e8;
        padding: 5px;
        width: 33%;
    }
    th{
        border: 1px solid #e8e8e8;
        padding: 5px;
        width: 33%;


    }
    select:focus{
        border: none;
        outline: none;
    }
</style>