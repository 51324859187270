<template>
    <div>
        <div style="display: flex; justify-content: space-between; align-items: center; margin: 15px 0px 15px 20px;">
            <h2 style="margin: 0px;">Add Custom {{ business_object_name }} Field</h2>
            <img @click="handleClose()" src="../assets/img/feed_close.svg" style="cursor: pointer; font-size: 25px;" alt="">
        </div>
        <div style="height: 58vh; background-color: rgb(247 247 247); margin: 20px 0px; padding: 1px;">
            <div class="form" @click="showTimeSeriesOptions=false;">
                <div class="from-label">
                    Category 
                </div>
                <div style="display: flex;align-items: center;justify-content: space-around;" class="form-input">
                   
                    <div class="" style="display: flex;align-items: center;">
                        <input style="margin-left: 10px;margin-right: 5px;" name="category"
                            v-model="customFields['category']" type="radio" value="aggregate" />Aggregate
                    </div>
                    <div class="" style="display: flex;align-items: center;justify-content: center;">
                        <input style="margin-left: 10px;margin-right: 5px;" name="category"
                            v-model="customFields['category']" type="radio" value="formula" />Formula
                    </div>
                    <div class="" style="display: flex;align-items: center;">
                        <input style="margin-right: 10px;" name="category" v-model="customFields['category']" type="radio"
                            value="timeseries" />Time Series

                    </div>
                </div>
                <div style="margin-top: 10px;" v-if="customFields['category'] == 'timeseries'">
                    <div style="margin-left: 0px;" class="row">
                        <div style="flex: 1;" class="">

                            <div class="from-label">
                                Name <span style="color:red !important"> *</span>
                            </div>
                            <div class="form-input">
                                <input v-model="customFields['name']" placeholder="Enter Field Name" type="text" />
                            </div>
                        </div>
                        <div  style="flex: 1;margin-left: 20px;" class="">

                            <div class="from-label">
                                Type
                            </div>
                            <div class="form-input">
                                Numeric
                            </div>
                        </div>

                    </div>
                    <div class="from-label">
                        Additional Attribute
                    </div>
                    <div class="form-input">
                        <Multiselect placeholder="Add additional derived fields" :multiple="true" style="max-width: 100%;" :options="['last7DaysAvg','last15DaysAvg','last30DaysAvg']" v-model="customFields['additionalAttributes']"/>
                        <!-- <input placeholder="Enter Field Name" type="text" /> -->
                    </div>
                    <div class="from-label">
                        Map to Field <span style="color:red !important"> *</span>
                    </div>
                    <div class="form-input">
                        <Multiselect track-by="key"  label="key" placeholder="Select field for the enabling time series" style="max-width: 100%;" :options="timeSeriesKeys" v-model="customFields['performOperationOnKey']" >
                            <template slot="singleLabel" slot-scope="{ option }">
                    <div class="" style="font-size: 10px !important;display: flex;align-items: center;">
                        <i class="material-symbols-outlined" style="font-size: 12px;margin: 0px;margin-right: 5px;">{{
                            giveIcon(option.datatype) }}</i>
                        {{ option.key }}
                        - {{ option.label }}
                    </div>
                </template>
                <template slot="option" style="font-size: 10px !important;" slot-scope="{ option }">
                    <div class="" style="font-size: 10px !important;display: flex;align-items: center;">
                        <i class="material-symbols-outlined" style="font-size: 12px;margin: 0px;margin-right: 5px;">{{
                            giveIcon(option.datatype) }}</i>
                        {{ option.key }}
                        - {{ option.label }}
                    </div>

                </template>   
                        
                        </Multiselect>
                        
                        <!-- <input placeholder="Enter Field Name" type="text" /> -->
                    </div>
                </div>
                <div class="" v-if="customFields['category'] == 'aggregate'">
                    <div style="margin-left: 0px;" class="row">
                        <div style="flex: 1;" class="">

                            <div class="from-label">
                                Name <span style="color:red !important"> *</span>
                            </div>
                            <div class="form-input">
                                <input v-model="customFields['name']" placeholder="Enter Field Name" type="text" />
                            </div>
                        </div>
                        <div  style="flex: 1;margin-left: 20px;" class="">

                            <div class="from-label">
                                Type
                            </div>
                            <div class="form-input">
                                Numeric
                            </div>
                        </div>

                    </div>
                    <div class="from-label">
                        Aggregate Function <span style="color:red !important"> *</span>
                    </div>
                    <div class="form-input">
                        <Multiselect sty placeholder="Select function AVG, SUM, COUNT" style="max-width: 100%;margin-top: 0px !important;" :options="aggregatesOptions"
                            v-model="customFields['aggregateOptions']" />
                        <!-- <input placeholder="Enter Field Name" type="text" /> -->
                    </div>
                    <div v-if="customFields['aggregateOptions']" class="from-label">
                        Choose Business Object Key <span style="color:red !important"> *</span>
                    </div>
                    <div v-if="customFields['aggregateOptions']" class="form-input">
                        <Multiselect placeholder="Select Business Object" v-if="customFields['aggregateOptions']" @select="handleBObjectSelect"
                            style="max-width: 30%;margin-right: 10px;" :options="bObjectList"
                            v-model="customFields['bObject']" />
                        <!-- <Multiselect v-if="customFields['bObject'] && customFields['aggregateOptions'] != 'COUNT()'"
                            style="max-width: 100%;" :options="keys" v-model="customFields['performOperationOnKey']" /> -->
                        <!-- <input placeholder="Enter Field Name" type="text" /> -->
                        <Multiselect placeholder="Select field which is numeric" v-if="customFields['bObject'] && customFields['aggregateOptions'] != 'COUNT()'" track-by="key" label="key" 
                        :searchable="true" @select="selectFilterKey" :show-labels="false" v-model="customFields['performOperationOnKey']"
                            style="margin-left:10px;min-width: 65%;width: 65% !important;margin-top: 0px !important;" :options="keys">
                            <template slot="singleLabel" slot-scope="{ option }">
                                <div class="" style="font-size: 10px !important;display: flex;align-items: center;">
                                    <i class="material-symbols-outlined" style="font-size: 12px;margin: 0px;margin-right: 5px;">{{
                                        giveIcon(option.datatype) }}</i>
                                    {{ option.key }}
                                    - {{ option.label }}
                                </div>
                            </template>
                            <template slot="option" style="font-size: 10px !important;" slot-scope="{ option }">
                                <div class="" style="font-size: 10px !important;display: flex;align-items: center;">
                                    <i class="material-symbols-outlined" style="font-size: 12px;margin: 0px;margin-right: 5px;">{{
                                        giveIcon(option.datatype) }}</i>
                                    {{ option.key }}
                                    - {{ option.label }}
                                </div>

                            </template>
                        </Multiselect>
                    </div>
                    <div v-if="customFields['aggregateOptions'] && customFields['bObject']" style=";" class="filterCond">
        <div v-if="addNewFilter" style="height: 100%; width: 100%; position: absolute; top: 0px; left: 0px; z-index: 9999999999999; background-color: rgba(0, 0, 0, 0.2); border-radius: 9px;">

                        <div style="" class="" v-if="addNewFilter">
                            <FilterModal @save="handleSaveFilter" @close="addNewFilter = false"
                                :business_object_name="customFields['bObject']" />
                        </div>
                        </div>
                        <span v-tooltip="'If no filter is selected we will scan all fields of the business object'" @click="addFilter" style="color: blue;cursor: pointer;font-weight:600;">+ Add Filter
                            Condition</span>
                        <div class="row" style="margin-left: 10px;">
                            <div  style="margin:20px 8px;display: flex;" v-for="(fil,idx) of filter">
                                <div style="position: relative;"  class="filerBox">
                                    {{ fil.str }}
                                    <img @click="deleteFilterCond(idx)" class="delete" src="../assets/img/feed_close.svg" style="height: 20px;position: absolute;right: 0px;top: 0px;" />
                                </div>
                                <div class="" v-if="idx<filter.length-1">
                                    and
                                </div> 
                            </div>

                        </div>
                    </div>
                </div>
                <div v-if="customFields['category'] == 'formula'">

                     <div style="margin-left: 0px;" class="row">
                        <div style="flex: 1;" class="">

                            <div class="from-label">
                                Name <span style="color:red !important"> *</span>
                            </div>
                            <div class="form-input">
                                <input v-model="customFields['name']" placeholder="Enter Field Name" type="text" />
                            </div>
                        </div>
                        <div  style="flex: 1;margin-left: 20px;" class="">

                            <div class="from-label">
                                Type
                            </div>
                            <div class="form-input">
                                Numeric
                            </div>
                        </div>

                    </div>
                   
                    <div class="from-label">
                        Formula <span style="color:red !important"> *</span>
                    </div>
                    <div class="form-input">
                        <textarea  style="outline: none; border: 1px solid #e8e8e8; padding: 20px; border-radius: 10px;" name="" placeholder="EG: FOR 20% OF A GIVEN VALUE, =20*DAILY_USAGE*100"  autocomplete="true" v-model="customFields['filter']" id="" cols="120" rows="6"></textarea>
                    </div>
                </div>

            </div>
        </div>
        <div style="display: flex; align-items: center; justify-content: end;">
            <span style="margin-right: 1rem;font-size: 10px;color: #979797;font-style: italic;"> This field cannot be modified once saved. It can be deleted and
                created.</span>
            <button @click="handleSave()"
                style="background-color: #7070ff; padding: 5px; width: 110px; height: 40px; font-size: 18px; font-weight: 600; border: none; border-radius: 7px; cursor: pointer; outline: none; margin-right: 1rem;">Save</button>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Server from './API/Server';
import { operetors } from '../pages/Functions';
import FilterModal from './FilterModal.vue';
export default {
    methods: {
        deleteFilterCond(idx){
            this.filter.splice(idx,1)
        },
        handleSaveFilter(event) {
            this.filter.push(event)
            this.addNewFilter = false
        },
        async handleValueSelect(key) {
            const res = await Server.getValue(key, this.customFields['bObject']);
            this.values = [...res.data]
        },
        addFilter() {
            if (!this.customFields['bObject']) {
                this.$toasted.error("Please select a business object")
                return
            }
            this.addNewFilter = true
            console.log(this.customFields)

        },
       
        async handleBObjectSelect() {
            this.keys=[]
            const data1 = await Server.getFieldsApi(this.customFields['bObject']);
            console.log(data1)
            let flag = false;
            if(data1.data && data1.data.data){
                for(let i=0;i<data1.data.data.length;i++){
                    if(data1.data.data[i].datatype == 'currency' || data1.data.data[i].datatype == 'double'){
                        flag = true;
                        this.keys.push(data1.data.data[i]);
                    }
                }
            }
            if(flag == false){
                const data = await Server.getConfiguratorKeys(this.customFields['bObject'])
                console.log(data)
                this.keys = (data.data.keys.map((d) => {return{key:d,label:d}}));
            }
        },
        async selectFilterKey() {
            console.log(this.filter)
            this.currentOperators = this.operators[this.filter.key.datatype] || this.operators['text'];
            if (this.filter.key.datatype == 'date') {
                this.values = this.relativeDates;
            } else
                this.values = (await Server.getValue(this.filter.key.key, this.customFields['bObject'])).data
                console.log(this.values)
        },
        giveIcon(icon) {
            if (!this.icon[icon]) {
                return "format_size"
            }
            else {
                return this.icon[icon]
            }
        },
        handleTextForTimeSeries(e){
            console.log('code')
            let customfieldLen = this.customFields['filter'];
            // if(!customfieldLen || customfieldLen==''){
            //     console.log('code 1')
            //     return;
            // }
            // if(e == ' '){
            //     return;
            // }
            console.log('code 2')
            this.showTimeSeriesOptions = true;
            console.log(customfieldLen.split(' ')[customfieldLen.split(' ').length-1]);
            this.timeSeriesKeysOptions = this.timeSeriesKeys.filter((data)=>{
                return data.toLowerCase().includes(customfieldLen.split(' ')[customfieldLen.split(' ').length-1].toLowerCase())
            })
        },
        handleTimeSeriesOoption(data){
            console.log(this.customFields['filter'])
            if(this.customFields['filter'] && this.customFields['filter']!=''){
                console.log(this.customFields['filter'])
                let arr = this.customFields['filter'].split(' ');
                arr.splice(arr.length - 1, 1); // Remove the last element
                arr.push(data);
                arr = arr.join(' '); // Join the modified array back into a string
                console.log(arr);

                this.customFields['filter'] = arr;
            }else{
                this.customFields['filter'] += data;
            }
            this.showTimeSeriesOptions = false;
        },
        handleClose() {
            this.$emit('close');
        },
        async handleSave() {
            console.log(this.customFields)
            if(this.customFields['category']!='formula'){
            
            this.customFields['filter'] = this.filter
            }
            this.customFields = { ...this.customFields, business_object_name: this.business_object_name }
            console.log(this.customFields)
            if(this.customFields['performOperationOnKey'] && this.customFields['performOperationOnKey'].key)
            this.customFields['performOperationOnKey'] = this.customFields['performOperationOnKey'].key;
            this.customFields = { ...this.customFields, business_object_name: this.business_object_name }
            if(this.customFields['category']!='formula'){

            for(let i=0;i<this.customFields['filter'].length;i++){
                if(this.customFields['filter'][i].str.includes('within')){
                    this.customFields['filter'][i].filter['operator'] = 'within';
                }
            }
        }
            await Server.saveCustomFieldsInfo(this.customFields)
            this.$toasted.success("Fields saved successfully")
            this.$emit('save');
        }
    },
    data() {
        return {
            customFields: {
                name: '',
                filter: [],
                category: 'aggregate'
            },
            filter:[],
            addNewFilter:false,
            keys:[],
            timeSeriesKeys:[],
            values:[],
            operetors:operetors,
            business_object_name: "",
            icon: {
                date: 'calendar_month',
                number: 'calculate',
                text: 'text_format',
                double:"looks_one",
                currency:"payments"
            },
            bObjectList:[],
            aggregatesOptions:['SUM()','AVG()','MIN()','MAX()','COUNT()'],
            attributeOptions:['last7DaysAvg','last15DaysAvg','last30DaysAvg']
        }
    },
    props: {
        bObj: ""
    },
    components: { Multiselect, FilterModal },
    async mounted() {
        // if(!this.customFields['category']){
        //     this.customFields['category']='timeseries'
        // }
        this.business_object_name = this.bObj;
        var creds = JSON.parse(localStorage.getItem("ALL_CREDS"))
        this.bObjectList = creds.map(data => data.business_object_name)
        const data = await Server.getFieldsApi(this.business_object_name)
        this.timeSeriesKeys = (data.data.data.map((d) => d));
        this.timeSeriesKeysOptions = this.timeSeriesKeys;

    }
}
</script>

<style>
.from-label {
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
}

.form-input {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;

    &:nth-child(2n) {
        margin-left: 10px;
    }

    &:nth-child(3n) {
        margin-left: 10px;
    }

    input[type='text'] {
        width: 100%;
        border: 1px solid #e8e8e8;
        outline: none;
        padding: 8px;
        border-radius: 10px;

    }
}

.form {
    padding: 20px;
}

Multiselect {
    width: 100%;
}

.multiselect__placeholder{
    margin-bottom: 8px;
}
Multiselect{
    margin-top: 0px !important;
}
.filerBox{
    color: black;
    background-color: white;
    border: 0.6px solid #0000ff85;
    padding: 4px 20px 4px 8px;
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    height: 22px;
    white-space: nowrap;
    text-transform: capitalize;
}

.filterCond {
    margin-top: 20px;
    border: 1px dotted grey;
    padding: 10px;

    span {
        margin-left: 10px;
    }
}
.delete{
    filter: invert(17%) sepia(99%) saturate(7275%) hue-rotate(1deg) brightness(102%) contrast(114%);
    height: 20px;
    margin-right: -4px;
    width: 21px;
    cursor: pointer;
}

</style>

<style lang="scss">
.multiselect{
    margin-top: 0px !important;
}
</style>
