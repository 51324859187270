<template>
    <div>
        <div class="internal_panel">
                <div class="login-panel">
                    <div class="panel__heading">
                        Demo
                    </div>
                    <div class="grid">
                        <label class="label">Email</label>
                        <input v-model="email" placeholder="johndoe@example.in" v-on:keyup.enter="handleOnclickPassCode()"
                            class="email" />
                    </div>
                    <div class="grid">
                        <label class="label">Passcode</label>
                        <input v-model="passcode" placeholder="Enter PassCode" v-on:keyup.enter="handleOnComplete"
                            class="email" />
                    </div>
                    <div v-if="isLoading" class="loadingLogin">
                        <img src="../assets/infinity_blue.svg" />
                    </div>
                    <small class="error">{{ error }}</small>

                    <button @click="handleOnclickPassCode()" class="panel__login">
                        {{
        showPasscode? 'Sign In': 'Send
                        passcode'}}
                    </button>
                </div>       
        </div>
        <div v-if = "isFirstConfigure" class="firstCinfigurationDiv">
                    <img src="../assets/firstConfiguration.svg"></img>
                    <h3 class="left_heading">Setting up Your Dashboard Please Wait .....</h3>
                    <img class="loadingGif" src="../assets/loading.gif"></img>
        </div>
    </div>
</template>

<script>
import OTPInput from "vue-otp-input";
import Server from "@/components/API/Server.js";
import mixpanel from "../analytics/mixpanel";

export default {
    components: {
        OTPInput
    },
    data() {
        return {
            email: "",
            passcode: "",
            error: "",
            showPasscode: true,
            isLoading:false,
            isFirstConfigure:false,
        };
    },
    methods: {
        logoClicked() {
            window.open("https://www.appeq.ai", '_blank');

        },
        async handleOnComplete(value) {
            this.isLoading = true,
            this.passcode = value;
            this.handleOnclickPassCode();
        },
        async handleOnclickPassCode() {
            this.isLoading = true;
            try {
                if (this.email == "demo@appeq.ai" && !this.showPasscode) {
                    this.showPasscode = true;
                    return;
                }
                
                // if (this.email == "demo@appeq.ai" && this.passcode == "4321") {
                //     localStorage.setItem("ADMIN_CREDS", JSON.stringify({ "__email__id": this.email }))
                //     this.$router.push("/integrations")
                //     this.isLoading = false;
                //     this.isFirstConfigure = false;
                //     return;
                // }
                if (!this.showPasscode) {
                    this.showPasscode = true
                    // await Server.sendPasscode(this.email);
                }
                else {
                    try{
                        this.isFirstConfigure = true;
                        const data = (await Server.internalLogin(this.email, this.passcode)).data;
                        localStorage.setItem("IS_ADMIN", 'true');
                        // const isVisised = await this.$getItem('isVisited');
                        localStorage.setItem("ADMIN_CREDS", JSON.stringify(data))
                        // this.$setItem('isVisited', 2);
                        localStorage.setItem("__email__id", this.email)
                        
                        // if(data){
                        //     try{
                        //         const res = (await Server.checkAdmin(data.__nakama__username));
                                
                        //         if (res) {
                        //             console.log(res.code);
                        //             if(res.code==200 && res.data.isAdmin)
                        //                 localStorage.setItem("IS_ADMIN", 'true');
                        //         }
                        //     }catch(e){
                        //         console.log(e);
                        //     }

                        // }

                        //adding user in mixpanel mixpanel 
                        if (data) {
                            this.$router.push("/integrations")
                        }
                        try{
                            mixpanel.init(data.__nakama__username,this.email);
                            mixpanel.dashboardLogin(data.__nakama__username,this.email,'passcode');
                        }catch(e){
                            console.log(e);
                        }
                    }catch(e){
                        console.log(e);
                    }
                    
                }
                this.error = ""
                this.showPasscode = true
                // Server.callSceduler('spreadsheet')
            } catch (e) {
                this.error = e;
                console.log(e);
            }
            this.isLoading = false;
            this.isFirstConfigure = false;
        },
        // async handleGoogleLogin() {
        //     try {
        //         localStorage.clear();
                
        //         localStorage.setItem("IS_ADMIN", 'true');
        //         var client = window.google.accounts.oauth2.initTokenClient({
        //             scope: 'profile email',
        //             client_id: '180578786917-pkbmnkbqd229un30jc1kstkkp0sbn9te.apps.googleusercontent.com',
        //             callback: async (user) => {
        //                 try {
        //                     this.isFirstConfigure = true;
        //                     const userProfile = await Server.getUserProfile(user['access_token']);
        //                     if (userProfile && userProfile.data && userProfile.data.email) {
        //                         const res = await Server.checkLoginGoogle(userProfile.data.email);
        //                         console.log(res.username);
        //                         if (res) {
        //                             // try{
        //                             //     const resData = (await Server.checkAdmin(res.username));
        //                             //     console.log(resData.code);
        //                             //     if(resData.code==200 && resData.data.isAdmin)
        //                             //     localStorage.setItem("IS_ADMIN", 'true');
                                        
        //                             // }catch(e){
        //                             //     console.log(e);
        //                             // }

        //                             //adding user in mixpanel mixpanel 
        //                             try{
        //                                 mixpanel.init(res.username,userProfile.data.email);
        //                                 mixpanel.dashboardLogin(res.username,userProfile.data.email,'google');
        //                             }catch(e){
        //                                 console.log(e);
        //                             }


                                    
        //                         }
        //                         if (res.email_id) {
        //                             localStorage.setItem("ADMIN_CREDS", JSON.stringify({ "__email__id": res.email_id, "picture": userProfile.data.picture }));
        //                             this.isFirstConfigure = false;
        //                             this.$router.push("/integrations")
        //                         } else {
        //                             this.isFirstConfigure = false;
        //                             this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")

        //                         }
        //                     } else {
        //                         this.isFirstConfigure = false;
        //                         this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")
        //                     }
        //                 } catch (error) {
        //                     this.isFirstConfigure = false;
        //                     console.log(error)
        //                     this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")
        //                 }
        //             }
        //         })
        //         client.requestAccessToken((not) => {
        //             console.log(not)
        //         });

        //     } catch (e) {
        //         this.isFirstConfigure = false;
        //         console.log(e);
        //         this.$toasted.error(e);
        //     }

        // }
    },
    mounted() {
        // if (localStorage.getItem('ADMIN_CREDS')) {
        //     this.$router.push("/integrations")
        // }
        // console.log("here in internal login");
    }
}
</script>

<style  lang="scss">
.login {
    height: 100vh;
    display: flex;
}

.internal_panel{
    margin-left: auto; 
    width: 30vw;
    position: absolute;
    display: block;
    top: 15vh;
    left: 35vw;
}


.login-panel-for-internal-login{
    height: 600px;
    width: 410px;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}



.loadingLogin{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -50px;
    img{
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

.grid{
    display: grid;
}

.label {
    width: 100%;
    margin-top: 20px;
}

.My-card {
    // position: absolute !important;
    // width: 80% !important;
    // bottom: 10px;
    // left: 10%;
    height: 50%;
    z-index: 2;
    opacity: 0.9;

    img {
        margin-left: 15px;
        // height: 90%;
        height: auto;
        max-height: 360px;
    }
}

.carousel {
    height: 100%;
    width: 40vw;
    min-width: 600px;
    background: linear-gradient(180deg, #f6f8fd, #f2faff);

    &:after {
        content: "";
        position: absolute;
        background-position: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        z-index: 1;
        background: 50% auto no-repeat url('../assets/loginBackImage/linear-gradient-backgroud.png');
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        background: 50%/382px auto no-repeat url('../assets/loginBackImage/background-retina.png');
    }

    .left_heading {
        margin: 60px;
        margin-top: 110px;
        text-align: center;
        position: absolute;
        z-index: 2;

        .blue-text {
            color: #0061fe;
        }
    }

    .left_heading_text {
        text-align: center;
        margin: auto;
        margin-top: 55px;
        position: absolute;
        top: 140px;
        left: 100px;
        z-index: 2;
        color: #0061fe !important;
    }

    &_img-bottom {
        position: absolute;
        bottom: 30px;
        left: 7.5vw;
        height: 45vh;
        width: 35vw;
    }
}


.email {
    width: 370px;
    border-radius: 5px;
    height: 40px;
    border: 1px solid #dcddde;
    box-shadow: none;
    padding: 7px 14px;
    font-size: 13px;
    line-height: 1.42857143;
    background-color: #fff;
    box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.08);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline: 0;
}

.panel {
    display: flex;
    padding-right: 15vw;
    flex: 1%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__login {
        background-color: #0061fe;
        color: white;
        border-radius: 10px;
        width: 370px;
        margin-top: 50px;
        height: 40px;
        border: none;
        text-align: center;
        cursor: pointer;
    }

    .or_span {
        margin-top: 38px;
        display: block;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -10px;
    }

    &__login:hover {
        opacity: 0.6;
    }

    .otp_block_2 {
        div {
            div {

                .otp-input {
                    height: 35px;
                    width: 85px;
                    background: #FFFFFF;
                    border: 1px solid #ECEBEB;
                    border-radius: 6px;
                    text-align: center;
                    box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.08);

                }
            }
        }
    }

    &__heading {
        font-weight: 500;
        text-align: center;
        font-size: 30px;
        margin-bottom: 300px;
        color: #212121;
        margin-bottom: 60px;
    }

    &__googleBtnNew{
        margin-top: 10px;
        color: black;
        cursor: pointer;
        background: #858484;
        border: 1px solid #d1d2d3;
        display: flex;
        width: 370px;
        font-size: 13px;
        align-items: center;
        height: 40px;
        border-radius: 5px;
        justify-content: center;
        box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.08);

        &:hover {
            background: #858484;
        }

        img {
            margin-right: 10px;
        }
    }

    .forget {
        color: #212121;
        text-align: center;
        font-size: 13px;
        margin-top: 20px;
        cursor: pointer;
        transition: color .2s ease;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
    }

    .error {
        color: red;
        margin-top: 10px;
    }

    a {
        color: #0061fe;
    }

}

.firstCinfigurationDiv{
    position: absolute;
    height: 100vh;
    width:100vw;
    top: 0;
    left: 0;
    background: white;
    z-index: 999999;
    transition: 0.5s ease-in-out;

    img{
        height: 500px;
        position: absolute;
        left: 33vw;
        top: 25vh;
    }
    h3{
        height: 500px;
        position: absolute;
        left: 34vw;
        top: 25vh;
    }
    .loadingGif{
        height: 70px;
        position: absolute;
        left: 48vw;
        top: 10vh;
    }

}
</style>