<template>
    <div>
        <div class="card" style="margin-left: 3px;">
            <div style="justify-content: space-between;padding:20px;margin: 0px;align-items: center;" class="row">
                <div>
                    <span style="font-size: 26px;font-weight: 700;">Good {{ timeofday }}, <span
                            style="text-transform: capitalize;">{{ email.split("@")[0] }} </span> 👋</span>
                    <br />
                    <span style="font-size: 15px;font-weight: 500;">
                        🚀 Checkout the help centre guide you on how to best use APPEQ
                    </span>
                </div>
            </div>
        </div>
        <div style="padding: 10px; background: white; border-radius: 4px; height: 74vh;">
            <h2 style="text-align: center;">Help Centre</h2>
            <div style="display: flex; justify-content: space-evenly; margin: 10px; margin-top: 3rem;">
                <div style="flex: 0.05;"></div>
                <div style="flex: 0.3; display: flex; align-items: normal;">
                    <img src="../assets/help.webp" style="height: 50px; margin-right: 12px; margin-top: -7px;" alt="">
                    <h3>Chat</h3>
                </div>
                <div style="flex: 0.4;">
                    <span>Our team will respond to your query. Please use the chat icon on the right bottom corner</span>
                </div>
                <div style="flex: 0.1;"></div>
            </div>
            <div style="display: flex; justify-content: space-evenly; margin: 10px;">
                <div style="flex: 0.05;"></div>
                <div style="flex: 0.3; display: flex;">
                    <img @click="openKnowledgeBaseLink()" src="../assets/knowledgeBase.webp" style="height: 27px; margin-left: 15px; margin-right: 23px; cursor: pointer;" alt="">
                    <h3 style="cursor: pointer;" @click="openKnowledgeBaseLink()">Knowledge Base</h3>
                </div>
                <div style="flex: 0.4;">
                    <span>Articles that will provide more information on product features</span>
                </div>
                <div style="flex: 0.1;"></div>
            </div>
            <div style="display: flex; justify-content: space-evenly; margin: 10px;">
                <div style="flex: 0.05;"></div>
                <div style="flex: 0.3; display: flex;">
                    <img @click="openVideoLink()" src="../assets/video.svg" style="height: 19px; margin-right: 18px; margin-left: 17px; margin-top: 6px; cursor: pointer;" alt="">
                    <h3 style="cursor: pointer;" @click="openVideoLink()">Videos</h3>
                </div>
                <div style="flex: 0.4;">
                    <span>Short videos explanation</span>
                </div>
                <div style="flex: 0.1;"></div>
            </div>
        </div>
    </div>
</template>
<script>
import LocalUtils from '../components/Utils/LocalUtils'

export default {
    data() {
        return {
            timeofday: "Morning",
            email: ""
        }
    },
    async mounted(){
        this.email = LocalUtils.getEmail()
        var today = new Date()
        var curHr = today.getHours()

        if (curHr < 12) {
            this.timeofday = 'morning'
        } else if (curHr < 18) {
            this.timeofday = 'afternoon'
        } else {
            this.timeofday = 'evening'
        }
        Intercom("show");
    },
    methods: {
        openKnowledgeBaseLink(){
            window.open("https://help.appeq.ai/en/","_blank");
        },
        openVideoLink(){
            window.open("https://www.youtube.com/playlist?list=PLPihtLn_-rqHhPaNEVfcERrXubQO1Xib6","_blank");
        }
    }
}
</script>