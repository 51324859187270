<template>
  <div class="events-container">
    <h1>Today's Events ({{ today }})</h1>
    <div class="event-timeline">
      <div v-for="(event,idx) in events" :key="event.id"
           class="event-box"
           :style="getEventStyle(event,idx)">
        <strong>{{ event.time }}</strong>: {{ event.description }}
      </div>
    </div>
    <ul class="time-bar">
      <li v-for="hour in hours" :key="hour">{{ formatHour(hour) }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      today: new Date().toLocaleDateString('en-US', {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
      }),
      events: [
        { id: 1, time: '08:00 AM', duration: 10, description: 'CS Survey on Klient' },
        { id: 2, time: '08:00 AM', duration: 10, description: 'Product Usage' },
        { id: 3, time: '08:00 AM', duration: 10, description: 'Dasboard' },
        // { id: 4, time: '04:00 PM', duration: 2, description: 'Webinar' },
        // { id: 5, time: '07:00 PM', duration: 1, description: 'Performance Review' }
      ],
      hours: Array.from({length: 24}, (_, i) => i)  // Generates an array with 24 hours
    };
  },
  methods: {
    formatHour(hour) {
      const amPm = hour < 12 ? 'AM' : 'PM';
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
      return `${formattedHour} ${amPm}`;
    },
    getEventStyle(event,idx) {
      const startHour = this.convertTimeToDecimal(event.time);
      console.log(startHour,"120")
      const top = startHour*65 ; // Calculate top position
      const atop=idx*50
      const height = 50; // Calculate height based on duration
      const width=event.duration*65
      return {
        left: `${top}px`,
        height: `${height}px`,
        top: `${atop}px`,
        width: `${width}px`,
        position: 'absolute',
        padding: '10px',
        border: '1px solid #ccc',
        backgroundColor: '#f9f9f9'
      };
    },
    convertTimeToDecimal(time) {
      const [hour, minutes] = time.split(' ')[0].split(':');
      const amPm = time.split(' ')[1];
      const hoursInDecimal = parseInt(hour) % 12 + (amPm === 'PM' ? 12 : 0) + parseInt(minutes) / 60;
      return hoursInDecimal;
    }
  }
}
</script>

<style scoped>
.events-container {
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
}

.event-timeline {
  position: relative;
  /* height: 1200px; 24 hours * 50px */
  height: 400px;
  border-left: 4px solid #333;
  margin-bottom: 20px;
}

.time-bar {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.time-bar li {
  flex: 1;
  width: 5%;
  text-align: center;
  border-left: 1px solid #ccc;
}

.time-bar li:first-child {
  border-left: none;
}
</style>
