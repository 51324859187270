<template>
    <div>
      <div v-if="!loading && current==0" style="display: flex; flex-direction: column;">
        <h6 style="margin-top: 1.5rem !important;">Enter your Freshsales API Key</h6>
        <div style="border: 1px solid #dce2ea; display: flex; height: 40px;align-items: center;">
          <input type="text" style="border: none;font-size: 12px;font-weight: 300; width: 100%;" placeholder="Enter API Key" v-model="apiKey"/>
        </div>
        <h6 style="margin-top: 1.5rem !important;">Enter the base url</h6>
        <div style="border: 1px solid #dce2ea; display: flex; height: 40px;align-items: center;">
          <input type="text" style="border: none;font-size: 12px;font-weight: 300; width: 100%;" placeholder="Enter url" v-model="baseUrl"/>
        </div>
        <button class="connect_button" @click="connectFreshsales">Connect</button>
      </div>
      <div v-else-if="!loading && current==1">
        <h5 class="successText">
          You have successfully configured Freshsales
        </h5>
        <img src="../assets/success.svg" />
    </div>
      <div v-else style="display: flex; align-items: center; justify-content: center;">
        <img src="../icons/loadingripple.svg" style="height: 50px;" alt="">
      </div>
    </div>
  </template>
  
  <script>
  import Multiselect from "vue-multiselect";
  import Server from "./API/Server.js";
  
  export default {
    data(){
      return {
        apiKey: '',
        baseUrl: '',
        current: 0,
        isSuccess: false,
        loading: false,
        bObj:null
      }
    },
    props: {
      selectedBobj: []
    },
    components: {
      Multiselect
    },
    methods: {
      async connectFreshsales() {
        try {
          this.loading = true;
          let resp = await Server.connectFreshsales(this.apiKey, this.bObj,this.baseUrl);
          console.log(resp);
          this.current = 1;
          this.$toasted.success("Freshsales credentials saved");
          this.loading = false;
        //   setTimeout(() => {
        //   window.location.reload()
        // }, 2000);
        } catch (error) {
          this.$toasted.error("Access not given by Freshsales")
          this.loading = false;
        }
      },
    },
    async mounted() {
      console.log(this.selectedBobj, 'business object');
      this.bObj = this.selectedBobj;
    }
  }
  </script>
<style scoped>
.connect_button{
    border: 1px solid blue; border-radius: 6px; background: white; color: black; height: 40px; margin: 10px 0px;
}
.connect_button:hover{
    background: rgb(88, 88, 255); color: white;
}
</style>