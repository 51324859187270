<template>
    <div class="available-source">
        <div v-for="item in this.availableBuisnessObjects">
          {{ item }}
        </div>
    </div>
</template>

<script>

import Server from "../../components/API/Server";
import LocalUtils from "../../components/Utils/LocalUtils";

export default {
  name: "Available-sources",

  data() {
    return {
        availableBuisnessObjects:[],
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {

  },
  
  async mounted() {
    if (LocalUtils.getEmail()) {
      const data = await Server.getBusinessDataObs();
      const OccBuisObj =  localStorage.getItem('occupiedBuisnnessObjects').split(",");

      if (data.data) {
        localStorage.setItem("ALL_Buisness", JSON.stringify(data.data));
        data.data.map((d, index) => {
          if(!Object.values(OccBuisObj).includes(d["business_object_name"])){
            this.availableBuisnessObjects.push(d);
          }
        })
      }
    }
  },
};

</script>

<style lang="scss" scoped>
.available-source{
  padding: 10px 30px 30px 30px;
}

</style>