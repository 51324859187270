<template>
  <div class="salesforce">
    <div v-if="showCsmAccountPage">
      <!-- <Keys application="salesforce"></Keys> -->
      <div style="display: flex;align-items: center;">
        <input v-model="csmAcc" type="checkbox" />
        <div style="margin-left: 10px;">
          Is this a csm account
        </div>
      </div>
      <button @click="showCsmAccountPage=false">Next</button>
    </div>
    <div v-else-if="!success &&!showKeys">
      <p style="text-align: center">
        <b>How do I find my subdomain?</b><br />
        If you sign in at “appeq.my.salesforce.com”, add <b>appeq</b>.<br />
        Do not enter the full appeq.my.salesforce.com
      </p>
      <div class="zendeskInputBox">
        <div class="domainbox">https://</div>
        <input v-model="salesforceDomain" />
        <div class="domainbox">.my.salesforce.com</div>
      </div>
      <div v-if="accountPulse" style="display: flex;align-items: center;justify-content: flex-start;margin-top: 10px;">
        <input v-model="mappingBObject" type="checkbox" />
        <span style="margin-left: 10px;">
          Is this your account pulse data?
        </span>
      </div>
      <button
        :style="{
          background: salesforceDomain ? '' : 'grey',
          cursor: salesforceDomain ? 'pointer' : '',
        }"
        v-if="!isLoading && !success"
        @click="onSubmitData()"
      >
        Connect your data
      </button>
      <div v-if="isLoading && !success" class="loading">
        <img src="../assets/infinity_blue.svg" />
      </div>
      <small class="error">{{ error }}</small>
    </div>
    <h5 v-if="success  &&!showKeys" class="successText">
      You have successfully configured salesforce
    </h5>
    <img v-if="success  && !showKeys" src="../assets/success.svg" />
  </div>
</template>

<script>
import Server from "./API/Server";
import LocalUtils from "./Utils/LocalUtils";
import mixpanel from "../analytics/mixpanel";
import Keys from "../pages/Keys.vue";
import Multiselect from "vue-multiselect";
export default {
  components:{
    Keys,
    Multiselect
},
  data() {
    return {
      username: "",
      salesforceDomain: "",
      password: "",
      csmAcc:false,
      mappingBObject:false,
      token: "",
      success: false,
      isLoading: false,
      accountPulse:false,
      error: "",
      showCsmAccountPage:true,
      showKeys:false,
      isActive: [false, false, false],
    };
  },
  async mounted() {
    console.log(localStorage.getItem("item_creds"));
    if (localStorage.getItem("item_creds")) {
      const json = JSON.parse(localStorage.getItem("item_creds"));
      if (json["username"]) {
        this.username = json["username"];
      }
      if (json["password"]) {
        this.password = json["password"];
      }
      if (json["token"]) {
        this.token = json["token"];
      }
      var creds = JSON.parse(localStorage.getItem("ALL_CREDS"))
      const idx=creds.findIndex(d=>d['type']=='csm_account')
      if(idx!=-1){
        this.showCsmAccountPage=false
      }
      const bObj = localStorage.getItem("Business_obj_name");
      if(bObj && bObj.toLowerCase().includes("pulse")){
        this.accountPulse=true
      }
      if(bObj && bObj.toLowerCase().includes("__c")){
      }else{

        this.showCsmAccountPage=false

      }
    }
  },
  methods: {
    handleIClick(key) {
      console.log(key);
      this.$set(this.isActive, key, !this.isActive[key]);
      console.log(this.isActive);
    },
    async onSubmitData() {

      if(this.salesforceDomain == ''){
        this.$toasted.error("Please Enter Domain First");
        return ;
      }
      try {
        let domain = this.salesforceDomain;
        let client_id =
          "3MVG9wt4IL4O5wvLgb9I5m8DvdLV3B_WpFrNAM6bHFe9w6BqDaJTyoQ2pI4JsP8LqwJ98EyTODeYtVFvLX91S";
        let client_secret =
          "148F2E93D23C83B291CDB7197CC31EDED6E11B5FED7D55F04603957A7937045B";
        let url = `https://${domain}.my.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${client_id}&redirect_uri=${process.env.VUE_APP_REDIRECTION_ROUTE}/integrations`;
        let win = window.open(url, "_blank", "height=570,width=520");
        let pollTimer = window.setInterval(async () => {
          try {
            var url = new URL(win.location.href);
            if (url.searchParams.get("code")) {
              console.log(url.searchParams.get("code"));
              win.close();
              window.clearInterval(pollTimer);
              this.isLoading = true;
              let code = url.searchParams.get("code");
              const data = await Server.getSalesforceaccess(
                code,
                client_id,
                client_secret,
                `https://${domain}.my.salesforce.com`
              );
              const bObj = localStorage.getItem("Business_obj_name");
              console.log(bObj.split(","))
              await Promise.all(bObj.split(",").map(async d=>{
                const res = await Server.saveSalesforceData({
                appeq_supported_application: "salesforce",
                business_object_name: d,
                username: LocalUtils.getEmail(),
                type:this.csmAcc?"csm_account":"",
                instance_url: `https://${domain}.my.salesforce.com`,
                access_token: data.data["access_token"],
                refresh_token: data.data["refresh_token"],
                mappingBObject:this.mappingBObject?"pulse":"",
                "admin-email": LocalUtils.getEmail(),
              });
            if(res.data.code!=200){
                this.$toasted.error("Error in your credentials. Please try again after some time or contact us at support@appeq.ai");
                return;
              }
           return res 
            }));
             
              // bObj.split(",").map(async data=>{
              //   await Server.onResync(LocalUtils.getEmail(), data);

              // })
              this.$toasted.success(
                "Please wait we will notify you once the data has been resynced via mail ."
              );
              this.isLoading = false;
              this.success = true;
              this.$toasted.success(
                "You have successfully configured salesforce . Please wait for some time you will get notified when we pull all your data"
              );
              this.success=true;
              window.location.reload()
            }
          } catch (error) {}
        }, 1000);
      } catch (error) {
        this.error = error;
        console.log(error)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  flex-shrink: 0;
  background: #6f55ff;
  color: white;
  border: 0px;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 56px;
  border-radius: 6px;
  width: 100%;
  margin-top: 20px;
}

.zendeskInputBox {
  margin-top: 25px;
  border: 1px solid rgb(220, 226, 234);
  display: flex;
  height: 40px;
  align-items: center;


  input {
    width: 100%;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
}

.error {
  text-align: center;
  color: red;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

i:hover {
  scale: 1.2;
  transition: 0.2s ease-in-out;
}

.iconIsActive {
  scale: 1.5;
  transition: 0.2s ease-in-out;
  border-radius: 50%;
}

.icon-alert-circle-exc {
  position: absolute;
  margin-top: 5px;
  right: 30px;
  transition: 0.2s ease-in-out;
  color: blue;
}

.support {
  left: 350px;
  height: 100px;
  width: 320px;
  background: whitesmoke;
  position: absolute;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
}

.support2 {
  left: 350px;
  height: 100px;
  width: 320px;
  background: whitesmoke;
  position: absolute;
  margin-left: -25px;
  margin-top: -20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.zendeskInputBox {
  border: 1px solid rgb(220, 226, 234);
  display: flex;
  height: 40px;
  align-items: center;

  input {
    width: 100%;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
}

button {
  button {
    flex-shrink: 0;
    background: #6f55ff;
    color: white;
    border: 0px;
    padding: 0px 16px;
    font-size: 14px;
    font-weight: 500;
    height: 56px;
    cursor: pointer;
    border-radius: 6px;
    width: 100%;
    margin-top: 5px;
  }
}
.domainbox {
  background: #ebebeb;
}
</style>
