<template>
    <div class="filterModal" :style="{'width': (!nudgeFilter)?'90%':'70%', 'top': (!nudgeFilter)?'25%':'30%', 'left': (!nudgeFilter)?'5%':'15%'}">
        <div style="display: flex; align-items: center; justify-content: space-between; margin-left: 7px;">
            <h3 style="margin-bottom: 10px;"> Add Filter</h3>
            <img src="../assets/img/feed_close.svg" style=" cursor: pointer;" @click="cancel" alt="">
        </div>
        <div class="row">
            where
            <Multiselect placeholder="Select Field" track-by="key" label="key" :searchable="true" @select="selectFilterKey" :show-labels="false" v-model="filter['key']"
                style="margin-left:10px;min-width: 35%;width: 35% !important;margin-top: 0px !important;" :options="keys">
                <template slot="singleLabel" slot-scope="{ option }">
                    <div class="" style="font-size: 10px !important;display: flex;align-items: center;">
                        <i class="material-symbols-outlined" style="font-size: 12px;margin: 0px;margin-right: 5px;">{{
                            giveIcon(option.datatype) }}</i>
                        {{ option.key }}
                        - {{ option.label }}
                    </div>
                </template>
                <template slot="option" style="font-size: 10px !important;" slot-scope="{ option }">
                    <div class="" style="font-size: 10px !important;display: flex;align-items: center;">
                        <i class="material-symbols-outlined" style="font-size: 12px;margin: 0px;margin-right: 5px;">{{
                            giveIcon(option.datatype) }}</i>
                        {{ option.key }}
                        - {{ option.label }}
                    </div>

                </template>
            </Multiselect>
            <!-- <div class="" v-if="filter['key']  && filter.key.datatype == 'date'" style="margin-left: 10px;"> within</div> -->
            <Multiselect placeholder="Select Operator"  v-model="filter['operator']" label="label"
                style="margin-left: 10px;min-width: 25%;width: 25% !important;margin-top: 0px !important;"
                :options="currentOperators" />
            <!-- <Multiselect v-if="filter.key && flag && filter.key.datatype !== 'date' && values && filter['operetor']"
            <Multiselect placeholder="Select value" v-if="filter.key && filter.key.datatype !== 'date' && values && filter['operetor']"
                style="margin-left: 10px; min-width: 30%; width: 30% !important; margin-top: 0px !important;"
                :options="values" v-model="filter.value" /> -->
                <div v-if="filter && filter['operator'] && filter['operator']['query']!='$type'" class="">

            <vue-autosuggest v-if="filter.key && filter.key.datatype !== 'date'  && filter['operator'] && filter['operator']['query']!='$in' && filter['operator']['query']!='$nin'" v-model="filter.value" @selected="onSelect" :input-props="{id:'autosuggest__input', placeholder:'Enter Value?',onInputChange: onInputChange(filter.value) }" :suggestions="filteredOptions" >
            </vue-autosuggest>
            <Multiselect   style="margin-left: 10px;min-width: 100%;width: 100% !important;margin-top: 0px !important;" v-model="filter.value" :options="values" multiple="true" v-else-if="filter['operator'] && (filter['operator']['query']=='$in' || filter['operator']['query']=='$nin')" />
        </div>

            <!-- For date datatype, show relative date options -->
            <div v-if="filter.key && filter.key.datatype === 'date'"
                style="margin-left: 10px; display: flex; align-items: center; flex-wrap: wrap;flex-direction: column;margin-top: -30px;">
                <div class="" style="display: flex;align-items: center;">
                    <div v-for="chip in dateChips" :key="chip.value" @click="onChipClick(chip.value)" class="date-chip"
                        style="margin: 5px; padding: 5px 8px; border-radius: 15px; background-color: #e0e0e0; cursor: pointer;">
                        {{ chip.label }}
                    </div>
                </div>
                <!-- Input for custom number of days, shown only when 'Custom' is selected -->
                <input type="number" v-model="filter.value" placeholder="Enter days"
                    style="margin-left: 10px; min-width: 100%; width: 100% !important; border:1px solid #e8e8e8;border-radius: 4px;height:38px;margin-top: 0px !important;" />
            </div>
            <div class="" v-if="filter['key']  && filter.key.datatype == 'date'" style="margin-left: 20px;"> days</div>



        </div>
        <div style="display: flex;align-items: center;justify-content: flex-end;margin-top: 45px;" class="">
            <button @click="cancel()" style="width: 120px;height: 35px;border-radius: 8px;border: 1px solid #4e5fff;color: #4e5fff;background-color: white;">Cancel</button>
            <button @click="save()" style="width: 120px;height: 35px;margin-left: 10px;border-radius: 8px;">+ADD</button>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Server from './API/Server';
import {VueAutosuggest} from "vue-autosuggest";
export default {
    props: [
        "business_object_name",
        "nudgeFilter"
    ],
    data() {
        return {
            keys: [],
            currentOperators: [],
            filter: {
                key: '',
                value:'',
                operator:''
            },
            dateChips: [
                { label: 'Today', value: 1 },
                { label: 'Last 7 Days', value: 7 },
                { label: 'Last 30 Days', value: 30 },
            ],
            values: [],
            name: '',
            flag: false,
            filteredOptions: [],
            operators: {
                double:[{ label: "Equal to", value: "==", query: "$eq" },
                { label: "Greater Than", value: ">", query: "$gt" },
                { label: "Not Equal to", value: "!=", query: "$ne" },
                { label: "In", value: "In", query: "$in" },
                { label: "Not In", value: "Not In", query: "$nin" },


                { label: "Less Than", value: "<", query: "$lt" },
                { label: "Greater Than Equal to", value: ">=", query: "$gte" },
                { label: "Less Than Equal to", value: "<=", query: "$lte" },],
                currency:[{ label: "Equal to", value: "==", query: "$eq" },
                { label: "Greater Than", value: ">", query: "$gt" },
                { label: "Not Equal to", value: "!=", query: "$ne" },
                { label: "In", value: "In", query: "$in" },
                { label: "Not In", value: "Not In", query: "$nin" },

                { label: "Less Than", value: "<", query: "$lt" },
                { label: "Greater Than Equal to", value: ">=", query: "$gte" },
                { label: "Less Than Equal to", value: "<=", query: "$lte" },],

                date: [{ label: "Within", value: "==", query: "$eq" },
                { label: "Less Than", value: "==", query: "$lte" }
            ],
                number: [{ label: "Equal to", value: "==", query: "$eq" },
                { label: "Greater Than", value: ">", query: "$gt" },
                { label: "Not Equal to", value: "!=", query: "$ne" },
                { label: "In", value: "In", query: "$in" },

                { label: "Less Than", value: "<", query: "$lt" },
                { label: "Greater Than Equal to", value: ">=", query: "$gte" },
                { label: "Less Than Equal to", value: "<=", query: "$lte" },
                { label: "Not In", value: "Not In", query: "$nin" }],
                text: [{ label: "Equal to", value: "==", query: "$eq" },
                { label: "Not In", value: "Not In", query: "$nin" },
                { label: "In", value: "In", query: "$in" },
                { label: "Is Null", value: "Is Null", query: "$type" }],
            },
            icon: {
                date: 'calendar_month',
                number: 'calculate',
                text: 'text_format',
                double:"looks_one",
                currency:"payments"
            },
        }
    },
    watch: {
        'filter.key': function (newVal, oldVal) {
            // console.log("asdnskj")
            // if (newVal && newVal.datatype) {
            //     this.currentOperators = this.operators[newVal.datatype] || [];
            // } else {
            //     this.currentOperators = [];
            // }
        }
    },

    methods: {
        save(){
            if(this.filter.value==''){
                this.$toasted.error("Value can't be empty");
                return;
            }
            // this.filter.value = Number(this.filter.value);
            if(this.filter.key.datatype=='number' || this.filter.key.datatype=='currency' || this.filter.key.datatype=='double'){
                this.filter.value = this.filter.value*1;
                if(isNaN(this.filter.value)){
                    this.$toasted.error('Please enter a valid value');
                    return;
                }
            }   
            let str=""
            if(this.filter.key.datatype=="date")
            this.filter.value+="days"
            if(this.filter.operator && this.filter.operator.label){
                str="If " + this.filter.key.key + " "+ this.filter.operator.label + " " +this.filter.value

            }else{
                str=this.filter.key.key + " "+ "within" + " " +this.filter.value

            }
            this.$emit("save",{filter:this.filter,str:str})
        },
        cancel(){
            this.$emit("close")
        },
        onChipClick(value) {
            this.filter.value = value; // Assign the selected chip's value to filter.value
            // Handle 'Custom' differently if needed
        },
        // Additional
        nameWithLang({ name, sku }) {
            this.name = `${name} — ${sku}`
        },
        async selectFilterKey() {
            this.currentOperators = this.operators[this.filter.key.datatype] || this.operators['text'];
            if (this.filter.key.datatype == 'date') {
                this.values = this.relativeDates;
            } else
                this.values = (await Server.getValue(this.filter.key.key, this.business_object_name)).data
        },
        onSelect(e){
            if(e){
                if(e.item){
                    this.filter.value = e.item;
                }
            }
        },
        async onInputChange(text) {
        if (text === '' || text === undefined) {
          return;
        }
        this.values = (await Server.getValue(this.filter.key.key, this.business_object_name)).data
        // const filteredData = this.values.filter(item => {
        //     console.log(item);
        //   return item!=null && (item.toUpperCase().indexOf(text.toUpperCase()) > -1);
        // });
        const filteredData = [];
        for(let i=0;i<this.values.length;i++){
            if(this.values[i]!=null && Number.isNaN(text)){
                if(this.values[i].toUpperCase().indexOf(text.toUpperCase()) > -1){
                    filteredData.push(this.values[i]);
                }
            }else if(this.values[i]!=null){
                if (this.values[i].toString().toUpperCase().indexOf(text.toString().toUpperCase()) > -1) {
                    filteredData.push(this.values[i]);
                }
            }
        }
        const tempFilter=[]
        this.filteredOptions = [];
        for(let i=0;i<filteredData.length;i++){
            tempFilter.push(filteredData[i]);
        }
        this.filteredOptions=[{
          data: tempFilter
        }]
      },
        giveIcon(icon) {
            if (!this.icon[icon]) {
                return "format_size"
            }
            else {
                return this.icon[icon]
            }
        }
    },
    components: { Multiselect, VueAutosuggest },
    async mounted() {
        const data1 = await Server.getFieldsApi(this.business_object_name);
            if(data1.data && data1.data.data){
                for(let i=0;i<data1.data.data.length;i++){
                        this.flag = true;
                        this.keys.push(data1.data.data[i]);
            }
            if(this.flag == false){
                const data = await Server.getConfiguratorKeys(this.business_object_name)
                this.keys = (data.data.keys.map((d) => {return{key:d,label:d}}));
                for(let i=0;i<this.keys.length;i++){
                    this.filteredOptions.push(this.keys[i].key);
                }
            }
    }
}}
</script>
<style scoped lang="scss">
.row {
    margin-left: 0px !important;

    Multiselect {
        margin-left: 10px;
    }

    display: flex;
    align-items: center;
}

.filterModal {
    background-color: white;
    box-shadow: 0 0 1px #101010;
    width: 100%;
    padding: 12px;
    position: absolute;
    width: 90%;
    top: 25%;
    left: 5%;
    border-radius: 10px;
    background-color: white;
    z-index: 99999;
    min-height: 200px;
}

.date-chip {
    margin: 5px;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 8px;
    background-color: #e0e0e0;
    cursor: pointer;

    &:hover {
        background-color: #bdbdbd;
    }
}
</style>
<style>
#autosuggest__input{
    border-radius: 5px;
    padding: 10px;
    height: 41px;
    margin-top: 0px;
}
</style>