import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    intersect: false,
  },
};

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["options"],

  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, { ...chartOptions, ...this.options });
  },
};
