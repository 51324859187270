<template>
    <div class="config">
        <div class="config__header">
            <h3> {{ preview ? 'Preview' : 'Configure Feeds' }} </h3>
        </div>
        <div  class=" feeds__tile">
            <div class="feeds__tile--logo">
                <img
                    v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + appeq_supported_application + '.com&sz=48')" />
            </div>
            <div class="feeds__tile--title">
                <div class="feeds__tile--heading">
                    {{ appeq_supported_application}}
                </div>
                <div class="feeds__tile--subheading">
                    {{ businness_object_name }}
                </div>
            </div>
            <span class="material-symbols-outlined settings"
            @click="close">
                close
            </span>

        </div>

        <div v-if="!preview" class="config__body">
            <multiselect 
            style="width: 100%;"
            :hide-selected="true"
            class="CongStyle" @select="handleSelectKey" @remove="handleRemove" :multiple="true" placeholder="Please select business keys" v-model="selectedKeys" mode="tags" :options="keys">
            </multiselect>
        </div>
        <div class="feedsDiv">
            <div class ="logoImageDiv">
                <img  height="30px" src="https://s2.googleusercontent.com/s2/favicons?domain_url=https://tesla.com&sz=128"/>
            </div>
            
            <div  class="config__body"> 
                <grid-layout  ref="gridlayout" :layout.sync="layout"
                            :col-num="8"
                            :row-height="6"
                            :row-num="6"
                            :margin="[20,20]"
                            :is-draggable="true"
                            :is-resizable="true"
                            :vertical-compact="true"
                            :use-css-transforms="true"
                >
                    <grid-item 
                    style="display: flex;text-overflow: ellipsis;align-items: center;"
                    :key="item.i.value" v-for="item,i in layout"
                            :x="item.x"
                            :y="item.y"
                            :w="item.w"
                            :h="item.h"
                            :i="item.i"
                    >
                    <input placeholder="Enter label" v-model="item.i.label"/>  
                        <input  style="margin-left: 10px;" v-model="item.i.value" class="text">
                        <!-- <img @click="deleteLay(i)" style="height: 10px;position: absolute;right: 10px;top: 0px;cursor: pointer;" src="../assets/delete-recycle-bin-trash-can-svgrepo-com.svg"/> -->
                    
                    </grid-item>
                </grid-layout>
             </div>
             <div class="sourceLogo">
                <img height="30px"
                    v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + appeq_supported_application+ '.com&sz=48')" />                {{ businness_object_name }}
             </div>
        </div>
        <span style="margin-top: 20px;margin-left: 20px;">NOTE : You can also drag and drop fields and resize them according to your wish</span>
        <div class="config__footer">
            <button @click="resetToDefault" class="config__footer--button">Reset</button>
            <button @click="close" class="config__footer--button">Cancel</button>
            <button @click="saveKey()" class="config__footer--button">Save</button>
        </div>
    </div>
</template>

<script>
import Server from './API/Server';
import LocalUtils from './Utils/LocalUtils';
import Multiselect from 'vue-multiselect'
import {GridLayout, GridItem} from "vue-grid-layout";
import {keyStore} from '../pages/Configuration/defaultKeyStore';
import { identify } from 'mixpanel-browser';

export default {
    name: "Config",
    props: {
        appeq_supported_application:{
            type: String,   
            default: ""
        },
        businness_object_name: {
            type: String,   
            default: ""
        },
        close:{
            type: Function,
            required: true
        }
    },
    data() {
        return {
            layout: [
           
            ],
            keyStore,
        
            keys: [],
            preview: false,
            inputVal: '',
            feedsConfig: [],
            filterKeys: [],
            selectedKeys: [],
            colNum: 6,
            values: [],
            showVals: []
        }
    },
    setup() {
        return {}
    },
    async mounted() {
        this.getInitData()
    },
    methods: {
        deleteLay(ind){
            const sp=this.selectedKeys.findIndex(data=>data==this.layout[ind].i.value);
            this.selectedKeys.splice(sp,1)
            this.layout.splice(ind,1)
        },
        handleRemove(val){
            console.log(val)
            const ind=this.layout.findIndex(data=>data.i.value==val)
            this.layout.splice(ind,1)
        },
        async handleSelectKey(val) {
            console.log(val)
        
            this.layout.push({
                x:(this.layout.length * 2) % (this.colNum || 6),y:this.layout.length + (this.colNum || 6),w:2,h:3,i:{label:'',value:val}
            })
        },
        async getInitData() {
            try {

                const res = await Server.getAdminConfigs()
                let selKeys = [];

                if(res && res.data[0] && res.data[0].feeds_configuration){
                    this.feedsConfig = res.data[0]['feeds_configuration'];
                    res.data[0].feeds_configuration.map(d=>{

                        // console.log(d.appeq_supported_application,d.business_object_name);
                        // console.log( this.appeq_supported_application,this.businness_object_name  );
                        // console.log(this.appeq_supported_application == d.appeq_supported_application);
                        // console.log(this.businness_object_name == d.business_object_name);

                        if(d.appeq_supported_application == this.appeq_supported_application && d.business_object_name == this.businness_object_name){ 
                            console.log('sdfasdf');                   
                            this.selectedKeys = d['keys'];
                            this.layout = d['layout'];
                        }
                    })

                    console.log(this.layout,this.selectedKeys);
                    if(this.layout.length==0 || this.selectedKeys.length==0){

                        console.log("kjhkj");

                        this.keyStore.defaultKeys.map(row=>{

                            console.log("jhgjhg",row.business_object_name , this.businness_object_name , row.appeq_supported_application , this.appeq_supported_application);

                        if(row.business_object_name == this.businness_object_name && row.appeq_supported_application == this.appeq_supported_application){
                            
                            


                            console.log("herere");

                            row.data.map(key=>{
                                this.layout.push(key);
                                this.selectedKeys.push(key.i.value)
                            });
                        }
                    })

                    }
                }

                // this.feedsConfig = res.data.length>0?res.data[0]['feeds_configuration']:[];
                // this.feedsConfig.map(d => { d["business_object_name"] == this.businness_object_name ? selKeys.push(...d['keys']) : [] })
                // this.feedsConfig.map(d => { d["business_object_name"] == this.businness_object_name ? this.layout.push(...d['layout']) : [] })
                // this.selectedKeys = selKeys;

                console.log(this.businness_object_name);

                console.log( this.businness_object_name , this.appeq_supported_application);

                if(res && res.data && res.data.length==0){

                    this.keyStore.defaultKeys.map(row=>{
                        console.log(row.business_object_name , row.appeq_supported_application );
                        if(row.business_object_name == this.businness_object_name && row.appeq_supported_application == this.appeq_supported_application){
                            row.data.map(key=>{
                                console.log(key);
                                this.layout.push(key);
                                this.selectedKeys.push(key.i.value)
                            });
                        }
                    })
                }
                // else if(res && res.data && res.data.length>0){

                //     res.data.map(d=>{
                //         if(d.feeds_configuration )
                //     })

                // }

                const data = (await Server.getConfiguratorKeys(this.businness_object_name)).data
                this.keys = data.keys;
                this.values = data.data;
                this.filterKeys = this.keys;
            } catch (error) {
                console.log(error)
            }
        },
        changeInpVal(val) {
            this.filterKeys = this.keys.filter(key => key.includes(this.inputVal))
        },
        handleSelctBox(key) {
            this.selectedKeys.push(key)
        },
        async saveKey() {
            try {
               
                const idx= this.feedsConfig.findIndex(d=>d["business_object_name"]==this.business_object_name)
                
                   if(idx!=-1){
                    finalFeeds[idx]={ 'business_object_name': this.businness_object_name, keys: this.selectedKeys,layout:this.layout,appeq_supported_application:this.appeq_supported_application }
                   }else{
                    finalFeeds.push({ 'business_object_name': this.businness_object_name, keys: this.selectedKeys,layout:this.layout,appeq_supported_application:this.appeq_supported_application })
                   }
                await Server.saveConfigurator({
                    email: LocalUtils.getEmail(),
                    feeds_configuration: finalFeeds
                });
                this.$emit('close')
            } catch (error) {
                console.log(error)
            }
        },
        resetToDefault(){
            this.layout = [];
            this.selectedKeys = [];

            this.keyStore.defaultKeys.map(row=>{
                if(row.business_object_name == this.businness_object_name && row.appeq_supported_application == this.appeq_supported_application){
                    row.data.map(key=>{
                        this.layout.push(key);
                        this.selectedKeys.push(key.i.value)
                    });
                }
            });

            console.log(this.layout,this.selectedKeys);
        }
    },
    components: {
        Multiselect,
        GridLayout,
        GridItem
    }
}
</script>

<style lang="scss" scoped>
.multiselect-tag {
    font-size: 0.8rem !important;
    color: white !important;
}

.multiselect {
    margin-top: 12px !important;
    width: 33% !important;
}

.multiselect-option {
    span {
        color: black !important;
        font-size: 1rem !important;
    }
}

.config {
    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__body {
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        &--box {
            height: 150px;
            width: 100%;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 0px;
            }
        }

        &--row {
            display: flex;
            align-items: center;

            input {
                margin-right: 10px;
            }
        }

        &--desc {
            font-size: 12px !important;
        }
    }

    &__footer {
        right: 120px;
        display: flex;
        bottom: 30px;
        justify-content: flex-end;
        align-items: flex-end;

        &--button {
            
            border-radius: 8px;
            margin: 5px;
            background-color: #3358f4;
            padding: 2px 25px;
            color: white;
            border: none;
            height: 30px;
            width: 150px;

        }
    }


    .searchBox {
        border: 0.5px solid black;
        border-radius: 6px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .myrow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}
multiselect{
    width: 100% !important;
    max-width: 100% !important;
}
.CongStyle.multiselect{
    width: 100%;
    max-width: 100vw;
}

input{
    outline: none;
    width: 48%;
    border: none;
    border-bottom: 1px solid #cfcfcf;
    background: transparent;
}

.feedsDiv{
    background: white;
    position: relative;
    box-shadow: rgba(46, 80, 255, 0.19) 0px 8px 24px;
    border-radius: 25px;
    margin-top: 20px;
    min-height: 250px;
    .config__body{
        margin-left: 70px;
        margin-right: 120px;
    }
    .logoImageDiv{
        position: absolute;
        top:40%;
        left:30px;
    }
}
.config__footer{
    margin-top: 30px;
}
.vue-grid-item.vue-resizable.cssTransforms {
    border: 1px solid #e8e8e8;
    background: white;
    border-radius: 10px;
    padding: 5px;
}
.sourceLogo {
    position: absolute;
    top: 15px;
    right: 40px;
}
</style>
