<template >
    <div>
        <div id="newSummaryDiv" class="summaryData" :class="this.health ? this.health : ''">
            <div class="ticket_info_headers">
                <div style="display: flex;align-items: center;">
                    <img class="logoSummaryShow" v-bind:src="getIcon(account_domain ? account_domain : '')" />
                    <span v-if="account_domain" class="ticket_header_name">{{ getFeedName(account_domain) }}</span>
                    <span v-if="account_domain && this.health == 'red'" class="healthSpan"
                        :class="this.health == 'red' ? this.health : ''">
                        {{ 'RED' }}
                    </span>
                </div>
                <span class="rec"></span>
                <!-- <div class="conv_blk">
                    <img class="conv_icon" src="../icons/conv_list_icon.svg" alt="">
                </div> -->
                <i @click="closeSummary()"  class="tim-icons close-icon icon-simple-remove"></i>
            </div>
            <div class="ticket_info_body">
                <div v-if="!loadingSum" style="display: flex;align-items: flex-start;font-size: 12px;"
                    v-for="summ of summaryData">
                    <div class="summary__data_obj" style="display:flex" v-for="row of summ.appeq_summary_row">
                        <template
                            v-if="row.appeq_summary_column.appeq_summary_label == 'health' || row.appeq_summary_column.appeq_summary_label == 'Health' || row.appeq_summary_column.appeq_summary_label == 'HEALTH'">

                            <span style="font-style:italic;font-size:12px;color: #817f7f;"> {{ loadingSum ? '' :
                                row.appeq_summary_column.appeq_summary_label + ' : ' }}</span>

                            <span class="healthSpan" :class="this.health ? this.health : ''" style="font-weight: 700;">
                                {{ loadingSum ? '' : row.appeq_summary_column.appeq_summary_value }}
                            </span>

                        </template>
                        <template v-else>
                            <span v-if="row.appeq_summary_column.appeq_summary_type != 'graph'"
                                style="font-style:italic;font-size:12px;color: #817f7f;"> {{ loadingSum ? '' :
                                    row.appeq_summary_column.appeq_summary_label + ' : ' }}&nbsp;</span>
                            <span
                            style="margin-left: 4px;font-weight: 600;"
                                v-if="row.appeq_summary_column.appeq_summary_type != 'graph' && row.appeq_summary_column.appeq_summary_type != 'requesters' && row.appeq_summary_column.appeq_summary_type != 'lastTicket'"
                                v-bind:innerHTML="loadingSum ? '' : row.appeq_summary_column.appeq_summary_value"> 
                                <!-- inner html is now working here -->
                                {{ loadingSum ? '' : row.appeq_summary_column.appeq_summary_value }}
                            </span>
                            <span :aria-label="row.appeq_summary_column.appeq_emails[index]" data-microtip-position="right"
                                role="tooltip" class="requesterEmail" :style="{ 'background': getRandomColor() }"
                                @click="openCompose(index, row.appeq_summary_column)"
                                v-if="row.appeq_summary_column.appeq_summary_type == 'requesters' && Array.isArray(row.appeq_summary_column.appeq_summary_value)"
                                v-for="data, index of row.appeq_summary_column.appeq_summary_value ">
                                {{ data }}
                            </span>
                            <div v-if="row.appeq_summary_column.appeq_summary_type == 'graph'" id="chartDiv"
                                style="width:150px; height:120px;margin-left: 5px;"></div>
                            <div style="margin-left: -10px;" v-if="row.appeq_summary_column.appeq_summary_type == 'graph'">
                                New Tickets (Past 90 days)</div>
                            <div style="display:flex;align-items: center;color:black"
                                v-if="row.appeq_summary_column.appeq_summary_type == 'lastTicket'">
                                <span style="font-weight: 600;">
                                    {{ row.appeq_summary_column.appeq_summary_prevalue?row.appeq_summary_column.appeq_summary_prevalue:'NA' }}
                                </span>
                                <template v-if="row.appeq_summary_column.appeq_emails">
                                    <span :aria-label="row.appeq_summary_column.appeq_emails" data-microtip-position="right"
                                        role="tooltip" class="requesterEmail"
                                        :style="{ 'background': getRandomColor() }"
                                        @click="openCompose(index, row.appeq_summary_column)">
                                        {{ row.appeq_summary_column.appeq_summary_value }}
                                    </span>
                                </template>

                                <!-- <img @click="handleRedirect(row.appeq_summary_column.query, row.appeq_summary_column.appeq_summary_bObj)"
                                    src="../icons/redirect.png" /> -->
                            </div>
                            <!-- <img @click="handleRedirect(row.appeq_summary_column.query, row.appeq_summary_column.appeq_summary_bObj)"
                                src="../icons/redirect.png" -->
                                <!-- v-if="row.appeq_summary_column.appeq_summary_type == 'feedsLink'" /> -->

                        </template>
                    </div>
                </div>
                <div style="display:flex;align-items:center;justify-content: center;" v-if="this.loadingSum">
                    <img style="height: 100px;width: 100px;" src="../assets/loadingRipple.svg" />
                </div>
            </div>
            <div v-if="showHighBtn" class="ticket_info_footer">
                <span class="knowMoreHighLights" @[!disableHightLighBtn&&`click`]="handleClick()">
                    <img src="../assets/eqai.svg" />
                    Ask EQ.AI</span>
                <div class="loadingHigh" v-if="loadingHighLights">
                    <img class="loadingHighLights" src="../assets/highLights.svg" />
                    <span>Our Genie is generating highlights</span>
                </div>
                <!-- <span v-if="showHighLights">HighLights</span> -->
                <span id="highLights"></span>
                <br />
                <span v-if="lastUpdatedAtHighLights" style="font-size: 9px;color: #919191;">Generated On :
                    {{ lastUpdatedAtHighLights }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import Server from "../components/API/Server";
import moment from "moment";
import LocalUtils from "./Utils/LocalUtils";
import { createChart } from 'lightweight-charts';

export default {

    props: {
        account_domain: {
            type: String,
        },
        health: {
            type: String,
            default:"RED"
        },
        closeSummary:{
            type: Function ,
        }

    },
    name: "SummaryView",
    data() {
        return {
            summaryData: [],
            i: 0,
            chartDi: null,
            lineData: {},
            lastUpdatedAtHighLights: '',
            showHighBtn: false,
            showHighLights: false,
            disableHightLighBtn: false,
            highLights: "",
            loadingSum: false,
            loadingHighLights: false
        }
    },
    components: {
        
    },

    created() {
                   
    },
    mounted() {

        this.openSummary();

    },
    methods: {
        async handleClick() {

            try {
                let box = document.querySelector('#newSummaryDiv');
                let widthh = box.offsetWidth - 10;
                console.log(widthh);

                var element = document.getElementById("highLights");
                console.log(element);

                element.setAttribute("style", `display:block;width:${widthh}px`);
            } catch (e) {
                console.log(e);
            }

            this.disableHightLighBtn = true;
            this.loadingHighLights = true;
            this.showHighLights = true;
            document.getElementById("highLights").innerHTML = '';
            this.i = 0;
            this.highLights = (await Server.fetchTicketHighLights(this.account_domain)).data
            this.loadingHighLights = false;
            await this.typeWriter(this.highLights)
            this.lastUpdatedAtHighLights = moment(Date.now()).format("DD MMM,YYYY")
            this.disableHightLighBtn = false;


        },
        async typeWriter() {
            if (this.i < this.highLights.length) {
                document.getElementById("highLights").innerHTML += this.highLights.charAt(this.i);
                this.i++;
                const wait = (ms) => new Promise((res) => setTimeout(res, ms))
                await wait(20);
                return await this.typeWriter()
            }

        },
        getRandomColor() {
            let maxVal = 0xFFFFFF; // 16777215
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            randomNumber = randomNumber.toString(16);
            let randColor = randomNumber.padStart(6, 0);
            return `#${randColor.toUpperCase()}`
        },
        openCompose(index, data) {
            window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${data.appeq_emails}&tf=1`)
        },
        async openSummary() {
            if (this.account_domain) {
                try {
                    this.loadingSum = true;
                    let data = (await Server.fetchSummaryData(this.account_domain));
                    console.log("here",data);
                    const email=LocalUtils.getEmail()
                    console.log(data)
                    if (data.data && data.data[0].ai) {
                        this.showHighBtn = true;
                    }
                    // check for members for which summary to be shown
                    if(data && data.data && data.data[0].data)
                    this.summaryData=data.data[0].data
                    // this.summaryData = data.data.data[0].data
                    await Promise.all(this.summaryData.map(async res => {

                        if (res.appeq_summary_row) {
                            await Promise.all(res.appeq_summary_row.map(async d => {
                                if (d.appeq_summary_column.appeq_summary_value) {
                                    let val = d.appeq_summary_column.appeq_summary_value
                                    let funcName = val.toString().substr(0, val.toString().indexOf("("));
                                    val = val.toString().substr(val.toString().indexOf("("));

                                    d.appeq_summary_column.appeq_summary_bObj = val.split(",")[0].replace("(", "")

                                    d.appeq_summary_column.appeq_summary_value = (await Server.fetchSummaryDataObj(this.account_domain, val.split(",")[0].replace("(", ""), funcName, val.split(",")[1].replace(")", ""))).data
                                    if (d.appeq_summary_column.appeq_summary_value.error) {
                                        d.appeq_summary_column.appeq_summary_value = 'No Data'
                                        d.appeq_summary_column.appeq_summary_type = ''
                                        return
                                    }
                                    d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.data
                                    if (d.appeq_summary_column.appeq_summary_type == 'graph' && !d.appeq_summary_column.appeq_summary_value.error && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                        this.lineData['labels'] = d.appeq_summary_column.appeq_summary_value.map(data => data.date)
                                        this.lineData['datasets'] = [{ 'data': d.appeq_summary_column.appeq_summary_value.map(data => data.tickets) }]
                                        const ticketsData = d.appeq_summary_column.appeq_summary_value.map(data => {
                                            return { time: data.date.toString(), value: data.tickets }
                                        })
                                        this.lineData['data'] = ticketsData
                                        return;
                                    }
                                    if (d.appeq_summary_column.appeq_summary_type == 'lastTicket' && d.appeq_summary_column.appeq_summary_value && d.appeq_summary_column.appeq_summary_value['created_at']) {
                                        let date1 = new Date()
                                        var x = new moment(d.appeq_summary_column.appeq_summary_value&&d.appeq_summary_column.appeq_summary_value['created_at']?d.appeq_summary_column.appeq_summary_value['created_at']:null)
                                        var y = new moment(date1)
                                        var duration = moment.duration(x.diff(y))
                                        let duration_days = Math.abs(Math.floor(duration.asDays()))
                                        d.appeq_summary_column.appeq_summary_prevalue = duration_days + ' days ago by '
                                        d.appeq_summary_column.query = { 'id': d.appeq_summary_column.appeq_summary_value['id'] }
                                        d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value['requester_email']
                                        d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value['requester_email'].toString().split("@")[0][0]
                                    }
                                    if (d.appeq_summary_column.appeq_summary_type == 'requesters' && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                        d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value
                                        d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.map(data => data.toString().split("@")[0].split(".").map(d => d[0]).join(""))
                                    }
                                    d.appeq_summary_column.appeq_summary_value = typeof d.appeq_summary_column.appeq_summary_value == "object" && d.appeq_summary_column.appeq_summary_type != "requesters" ? "_" : d.appeq_summary_column.appeq_summary_value;
                                    if (d.appeq_summary_column.appeq_summary_label == 'Health') {
                                        d.appeq_summary_column.appeq_summary_value = this.health;
                                        console.log("health of it is", this.health);
                                    }
                                }

                            })
                            )
                        }
                    }))
                    this.loadingSum = false;

                    await new Promise((res) => setTimeout(res, 200))
                    if (this.lineData['data']) {
                        const chart = createChart(document.getElementById("chartDiv"), {
                            width: 150, height: 120, grid: {
                                vertLines: {
                                    color: "transparent",
                                    visible: false
                                },
                                horzLines: {
                                    color: "transparent"
                                },

                            },
                            leftPriceScale: {
                                visible: true,
                            },

                            timeScale: {
                                barSpacing: 9,
                            },

                        });
                        chart.priceScale('left').applyOptions({
                            autoScale: true,
                            visible: true,
                        });
                        chart.priceScale('right').applyOptions({
                            autoScale: true,
                            visible: false
                        });
                        const lineSeries = chart.addLineSeries();
                        lineSeries.applyOptions({

                            lineType: 2, lineStyle: 0,
                            priceScale: {
                                position: 'left',

                            },


                        })
                        lineSeries.setData(this.lineData['data']);
                        this.chartDi = chart
                        if (this.highLights && this.showHighBtn) {
                            this.disableHightLighBtn = true;
                            try {
                                let box = document.querySelector('#newSummaryDiv');
                                let widthh = box.offsetWidth - 10;
                                console.log(widthh);

                                var element = document.getElementById("highLights");
                                console.log(element);

                                element.setAttribute("style", `display:block;width:${widthh}px`);
                            } catch (e) {
                                console.log(e);
                            }
                            await this.typeWriter(this.highLights)
                            this.disableHightLighBtn = false;
                        }
                    }
                }
                catch (err) {
                    this.loadingSum = false;
                    console.log(err);

                }
            }
        },
        getIcon(context_link) {
            try {
                if (context_link == null)
                    return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + 'salesforce.com' + '&sz=128';
                else
                    return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + context_link + '&sz=128';
            } catch (e) {
                console.log(e);
            }
        },
        closeSumm() {
            try{
                 if(this.chartDi)
                    this.chartDi.remove();
            }catch(e){
                console.log(e);
            }
           
            if(document.getElementById("chartDiv"))
                document.getElementById("chartDiv").remove();
            feeds.sendActionToTop("__appeq_close_summary", "");

        },
        handleRedirect(query, val) {
            if (typeof query != 'object') {
                query = JSON.parse(query)
            }
            let final = { ...query, account_domain: this.account_domain, business_object_name: val }
            console.log(final)
            feeds.sendActionToTop("__appeq_open_ticket_iframe_summary", final);

        },
        openHealthRed() {
            try {
            let final = { ...{ health: "red" }, account_domain: this.account_domain }
            // feeds.sendActionToTop("__appeq_open_ticket_iframe_summary", final);
            document.getElementById("__appeq_frame").contentWindow.postMessage("__appeq_open_ticket_iframe_summary", final)
            } catch (error) {
             console.log(error)   
            }
        },
        getFeedName(name) {
            if (name) {
                let companyName = name;

                try {
                    if (companyName.toString().split(".").length > 1) {
                        companyName = companyName.toString().split(".")[0];
                    } else if (companyName.toString().split("@").length > 1) {
                        companyName = companyName.toString().split("@")[0];
                    }
                } catch (e) {
                    console.log(e);
                }

                name = companyName;
            }

            return name;
        },
    },
}
</script>
<style scoped lang="scss">

@import 'summary.scss';


img {
    cursor: pointer;
    height: 9px;
    margin-left: 8px;
    margin-top: 2px;

}

.requesterEmail {
    background-color: green;
    color: #fff;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    /* padding: 4px; */
    margin-left: 3px;
    width: 20px;
    display: block;
    text-align: center;
}

.close {
    object-fit: cover;
}

.knowMoreHighLights {
    font-size: 16px;
    font-weight: 600;
    margin-left: -4px;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.knowMoreHighLights img {
    height: 25px;
    width: 25px;
    margin-top: 0px;
    margin-left: 0px;
}

#highLights {
    font-size: 12px;
    color: blue;
    font-weight: 500;
}

.loadingHighLights {
    height: 40px;
    width: 40px;
}

.loadingHigh {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
</style>