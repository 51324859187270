<template>
    <div style="height: 90vh;">
        <div v-if="loading" class=""
        style="height: 100%;width: 100%;display: flex;align-items: center;justify-content: center;">
        <img src="../assets/loadingRipple.svg" />
    </div>
    <img v-if="breadCruumbs.length>0" src="../icons/leftArrow.svg" style="position: fixed;left: 20px;top: 10px;cursor: pointer;height: 20px;" @click="handleAccBack"/>
        <iframe id="appeq_metabase" :src="metabaseUrl" style="border: none;height: 100%;width: 100%;" frameborder="0"></iframe>
    </div>
</template>
<script>
export default{
    data(){
        return{
            metabaseUrl:"",
            loading:false,
            breadCruumbs:[],
        }
    },
    props:{
        url:{}
    },
    watch:{
        url:{
            deep:true,
            handler:function(){
                console.log("IN WATCH")
                this.initData(this.url)
            }
        }
    },
    mounted(){
        Intercom('update', {
            "hide_default_launcher": true
        });
        console.log("Widget opening")
        const params = this.getParamsByKey()
        if(params['url']){
            const modifiedUrl = params['url'].replace("titled=true", "titled=false");
            params['url']=modifiedUrl
           this.initData(params['url'])

        }
        console.log("this.url")
        console.log(this.url)
        if(this.url){
           this.initData(this.url)
        }
    },
    methods:{
        handleAccBack(){
            this.breadCruumbs.pop()
            this.initData(this.breadCruumbs[0])
        },
        initData(urltoshow){
            this.loading=true
            const iframe=document.getElementById("appeq_metabase")
            this.metabaseUrl=urltoshow
            iframe.addEventListener("load",(ev)=>{
                this.loading=false
                setTimeout(() => {
                    const dc=iframe.contentDocument
                    if(dc){
                        dc.querySelector(".EmbedFrame-footer").remove()
                        dc.querySelectorAll(".link.link--wrappable").forEach(d=>{
                        d.addEventListener("click",(ev)=>{
                            this.breadCruumbs.push(this.metabaseUrl)
                            this.initData("https://app.appeq.ai/bi/public/dashboard/cba672e1-b9b0-4309-93a7-3799daf01920?team_name="+d.textContent)
                        })
                    })
                    }
                   
                }, 4000);
            })
        },
        getParamsByKey() {
            const queryString = window.location.search.slice(1); // Remove the leading '?'
            const params = new URLSearchParams(queryString);

            const filteredParams = {};
            params.forEach((key, val) => {
                console.log(key, val)
                filteredParams[val] = key;
            });

            return filteredParams;
        },
    }
}
</script>