<!-- IntercomWidget.vue -->
<template>
  <div :class="['forIntercom', { minimized: minimized }]" id="intercom-container"></div>
</template>

<script>
export default {
  data() {
    return {
      minimized: false,
    };
  },
  mounted() {
    Intercom("show");
    this.setupIntercomEventListeners();
  },
  methods: {
    setupIntercomEventListeners() {
      window.Intercom('onHide', this.onIntercomHide);
    },
    onIntercomHide() {
      this.minimized = true;
      // Send a message to the parent window
      window.parent.postMessage({ action: 'minimizeIntercom' }, '*');
    }
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.forIntercom {
  background: white;
  width: 100%;
  height: 100%;
  transition: height 0.3s ease;
}
.minimized {
  height: 50px; /* Adjust this height as needed */
}
</style>
