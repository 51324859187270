<template>
    <div class="myFooter">
        <button v-if="currentStep!=1" @click="changeStep(-1)" class="prev"><i class="tim-icons icon-minimal-left"></i> Previous step</button>
        <button v-if="currentStep==4" @click="changeStep(1)" class="next">{{currentStep==4?'Complete':'Next step'}}<i class="tim-icons icon-minimal-right"></i></button>
        <button v-if="currentStep==3" @click="redirect()" class="next">Finish<i class="tim-icons icon-minimal-right"></i></button>
        <button v-if="currentStep==1" @click="changeStep(1)" class="nextFirst">Next step<i class="tim-icons icon-minimal-right"></i></button>
        <button v-if="currentStep==2 && downloaded" @click="changeStep(1)" class="nextFirst">Next step<i class="tim-icons icon-minimal-right"></i></button>
        <button v-if="currentStep==2 && !downloaded" @click="onClickStart()" class="nextFirst">Next step<i class="tim-icons icon-minimal-right"></i></button>
    </div>
</template>

<script>
export default {
    name: "Footer",
    props: {
        currentStep: {
            type: Number,
            default: 1
        },
        downloaded:{
            type:Boolean,
            default: true
        }
    },
    methods: {
        redirect(){
            this.$emit("changeStep", +2);
            var win = window.open("https://mail.google.com/mail/u/0?first=True", "_blank");
        },
        changeStep(index) {
            // currentStep = currentStep + 1;
            console.log(this.downloaded)
            this.$emit("changeStep", index)
        },
        onClickStart() {
            // this.showIframe = true
            console.log(this.downloaded)
            this.downloaded = true;
            this.$emit("changeStep", 1)
            window.open('https://chrome.google.com/webstore/detail/appeq-customer-360-platfo/likcmamamahkjeneloakgacokbgdkfea?embedded=true', 'winname');

        }
    },
    
}
</script>

<style lang="scss" scoped>
.myFooter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 20px;
    margin-top: 15px;

    
    button:hover {
        scale:1.1;
        transition: 0.2s ease-in-out;
    }
    button {
        background-color: #4e60ff;
        color: white;
        border-radius: 10px;
        border: none;
        width: 125px;
        height: 40px;
        margin: 20px;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
    .prev {
        background-color: white;
        border: 1px solid #4e60ff;
        color: #4e60ff;
    }
}
.nextFirst{
    margin: 20px;
    bottom: 20px;
    right:20px;
}
</style>