<template>
    <div class="configure">
        <h2>Choose</h2>
        <span v-if="!forTrends">You can configure your keys to display the fields in summary view</span>
        <span v-if="forTrends">You can configure your keys to save trending report</span>

        <!-- <br /> -->
        <!-- <span>You can choose from a variety of Functions. getKeys function displays all the value as it
            is</span> -->
        <div  v-if="!forTrends">
            <span>Label</span>
            <input class="labelInput" v-model="enteredLabel" placeholder="Enter Label"/>
        </div>
        <span v-if="!forTrends">Select Business Object</span>
        
        
        <Multiselect v-if="!forTrends" @select="handleKeySelected()" select-label="" :taggable="true" :hide-selected="true" v-model="selectedObject" class="mySelect" :options="this.keys" />
        <!-- <span class="s">Configure Functions</span>
        <Multiselect v-model="selectedFunc" class="mySelect" :options="['getKeys']" /> -->
        <span class="s">Select Business Keys</span>
        <Multiselect v-if="!isField" v-model="selectedKey" select-label="" :taggable="true" :searchable="true" :hide-selected="true" class="mySelect" :options="bkeys"  >
            <template slot="option" slot-scope="{ option }">
        <div :style="{ color: (selectedObject == 'ticket' || selectedObject=='Customer Invoice' || selectedObject=='Opportunities'|| selectedObject=='Net Promoter Score')?'blue':'' }">{{(selectedObject == 'ticket' || selectedObject=='Customer Invoice' || selectedObject=='Opportunities'|| selectedObject=='Net Promoter Score')?'Fx ':''}}{{ option }}</div>
        
      </template>
        </Multiselect>
        <Multiselect v-else v-model="selectedKey" select-label="" label="label" :taggable="true" :searchable="true" :hide-selected="true" class="mySelect" :options="bkeys" />
        <button v-if="(this.selectedObject == 'ticket')" @click="onSave( enteredLabel,selectedObject, selectedKey, selectedFunc)" class=" saveBtn">Save</button>
        <button v-else @click="onSave(enteredLabel,selectedObject, selectedKey, selectedFunc)" class=" saveBtn">Save</button>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Server from './API/Server';
import { store } from "../pages/Configuration/Store"
export default {
    props:{
        forTrends:Boolean,
        enteredLabel:'',
        selectedObject: '',
        summary:{},
        selectedKey: '',
    },  
    data() {
        return {
            keys: [],
            bkeys: [],
            occpBusiness:[],
            store,
            selectedFunc: 'getKeys',
            isField: false,
            resData:'',
            type:'',
            query:''
        }
    },

    methods: {
        onSave(enteredLabel,selectedObject, selectedKey, selectedFunc){
            if(this.isField){
                this.$emit("onsave",{
                enteredLabel:enteredLabel,selectedObject:selectedObject, selectedKey:selectedKey.key, selectedFunc:selectedFunc,type:this.getType(selectedKey.key)
            })
            return;
            }
            this.$emit("onsave",{
                enteredLabel:enteredLabel,selectedObject:selectedObject, selectedKey:selectedKey, selectedFunc:selectedFunc,type:this.getType(selectedKey)
            })

        },
        async handleKeySelected() {
            console.log(this.selectedObject)
            if (this.selectedObject == "ticket" || this.selectedObject=="Customer Invoice" || this.selectedObject=="Opportunities" || this.selectedObject=="Net Promoter Score" || this.selectedObject=="tasks") {
                this.bkeys = [];
                try{
                    this.resData = await Server.getFunction(this.selectedObject);
                    if(this.resData){
                        this.resData.data.map(d=>{
                            this.bkeys.push(d.function_name);
                        })
                    }
                }catch(e){
                    console.log(e);
                }
                return;
            }
            if(this.selectedObject=='Crunchbase Data'){
                this.bkeys=[  "linkedin",
          "website_url",
          "short_description",
          "location_identifiers",]
            }else{
                let allData = (await Server.getConfiguratorKeys(this.selectedObject));
                // if(allData.data && allData.data.fields && allData.data.fields.length>0){
                //     this.bkeys = allData.data.fields;
                //     if(this.selectedKey){
                //         this.selectedKey=this.bkeys.filter(d=>d.key==this.selectedKey)[0]
                //     }
                //     this.isField = true;
                //     return;
                // }
                this.isField=false;
                this.bkeys = allData.data.keys;
                console.log(this.bkeys)
                console.log(allData);
            }
        },
        getType(selectedKey){
            try{     
                this.resData.data.map(d=>{
                    if(d.function_name == selectedKey){
                        this.type =  d.type;
                        this.query = d.query;
                        console.log(selectedKey);
                    }
                });
                let result = {
                    "type" : this.type,
                    "query" : this.query
                }
                return result;
            }catch(e){
                console.log(e);
            }
        }

    }, async mounted() {
        console.log("for treninf",this.forTrends)
        if(this.forTrends){
            await this.handleKeySelected()
            return
        }
        const data = await Server.getBusinessDataObs()
        console.log(data);
        this.occpBusiness = JSON.parse(localStorage.getItem("occupiedBuisnessObj"));
        if(this.selectedKey){
            await this.handleKeySelected()
        }
        this.occpBusiness.map(d =>{
            this.keys.push(d);
        });
        this.keys.push("Crunchbase Data");


    },
    components: {
        Multiselect
    }
}
</script>

<style scoped lang="scss" >
h2 {
    text-align: center;
}

.configure {
    padding: 12px;
    span{
        display: flex;
        justify-content: center;
    }
}

.mySelect {
    width: 100% !important;
    margin: auto;
}

.saveBtn {
    background-color: blue;
    width: 100%;
    color: white;
    margin-top: 20px;
    padding: 4px 0px;
    cursor: pointer;
    border: none;
    border-radius: 20px;
}

span {
    margin-top: 10px !important;
}

.labelInput{
    min-height: 50px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    width: 305px;
}
</style>