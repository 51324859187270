<template>
    <div>
        <div class="body">
         
            <div class="row">
                <div style="flex: 0.6;">
                    <span style="font-weight: 600;font-size: 14px;">Feed List View</span>
                    <p style="color: black;">This view will be used to show records of the object when listed in Feed as a list record.
                This is the default columns that will be shown when it’s listed in a tabular form. Make sure you select the most frequently referenced columns.
                    </p>
                </div>
                <div>
                    <button @click="openFeedConfigure" style="width: 120px;height: 30px;border-radius: 20px;">Configure</button>
                </div>
            </div>
            <div class="tableForFeedListColumn">
                <div v-if="keys.length!=0" class="row">
                    <div class="item" v-for="key of keys">
                        {{ key }}
                    </div>
                </div>
                <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;" class="" v-else>
                    <img src="../assets/nodata.svg" style="height: 90px;flex-direction: column;"/>
                    <div class="">
                        Not Configured. System selected fields will be displayed.
                    </div>
                </div>
            </div>
            <div class="row">
                <div style="flex: 0.6;">
                    <span style="font-weight: 600;font-size: 14px;">Edit Form View</span>
                    <p style="color: black;word-break: break-all;">Account Fields can be updated through AppEQ interface and bi-directional synced to underlying CRM in real time. We support upto 5 fields for updation through AppEQ.</p>
                </div>
                <div>
                    <button disabled style="width: 120px;height: 30px;border-radius: 20px" v-tooltip="'Disabled'">Configure</button>
                </div>
            </div>
          
        </div>
        <Modal class="feeds_config"  v-if="feedConfigure" :show="feedConfigure">
            <i @click="handleCloseConfigure()" class="tim-icons close-icon icon-simple-remove"></i>

            <Configure @close="handleCloseConfigure( )" :feedsData="feedData"/>
        </Modal>
    </div>
</template>
<script>
import Server from './API/Server';
import Modal from './Modal.vue';
import Configure from '../pages/Configure.vue';

export default {
    props: {
        bObject: {}
    },
    data() {
        return {
            keys: [],
            feedConfigure: false,
            feedData: {}
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        handleCloseConfigure(){
            this.feedData={}
            this.feedConfigure=false
            this.initData()
        },
        openFeedConfigure() {
            this.feedData = {
                business_object_name: this.bObject
            };
            this.feedConfigure = true;
        },
        async initData() {
            const adminConfs = (await Server.getAdminConfigs()).data[0];
            if (adminConfs && adminConfs.feeds_configuration && adminConfs.feeds_configuration.length > 0) {
                const configs = adminConfs.feeds_configuration.filter(d => d.business_object_name == this.bObject);
                console.log(configs);
                if (configs.length != 0) {
                    this.keys = configs[0].keys;
                }
            }
            console.log(adminConfs, "gaurav");
        }
    },
    components: { Modal,Configure }
}
</script>
<style lang="scss" scoped>
.body{
    padding: 10px;
}
    .row{
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        width: 100%;
        justify-content: space-between;
    }
    p{
        margin-top: 10px;
    }
    .item{
        font-size: 10px;
    }
</style>