<template>
    <div class="step4">
        <button @mouseenter="skipTool=true" @mouseleave="skipTool=false" @click="changeStep(+2)" class="prev">Finish <i class="tim-icons icon-double-right"></i></button>
        <div class="skipTour" v-if="skipTool">
            <div class="arrow-up"></div>
            <p class="skipTourText">Click to End the Tour</p>
        </div>
        
        <span class="span-text">Visualise Account Insights in your Inbox</span>
        <div class="desc">
            You will be redirected to your Inbox to demonstrate how you can get Account Insights
        </div>
        <div v-if="countDown!=0" class="timer">
            
            Redirecting After<i class="tim-icons icon-time-alarm"></i> {{countDown}}  &nbsp; Seconds
        </div>
        <button v-if="countDown==0" @click="redirectClick()">Try Now</button>
        <div v-if="countDown==0" class="desc">
           Click if not automatically redirected.
        </div>
    </div>
</template>

<script>
import mixpanel from '../../../analytics/mixpanel';
export default {
    props: {
        currentStep: {
            type: Number,
            default: 1,
        }
    },
    data() {
        return {
            countDown: 5,
            skipTool:false,
        }
    },
    methods: {
        changeStep(index) {
            // currentStep = currentStep + 1;
            if(index==+2){
                try{
                    mixpanel.tourSkipped("try_now");
                }catch(e){
                    console.log(e);
                }
            }

            this.$emit("changeStep", index)
        },
        redirect(){
            let newTab = window.open("about:blank");
            newTab.location.href = "https://mail.google.com/mail/u/0?first=True";
        },
        redirectClick(){
            this.$emit("changeStep", +2);
            let newTab = window.open("about:blank");
            newTab.location.href = "https://mail.google.com/mail/u/0?first=True";
        },
        countDownTimer () {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
            else{
                this.redirect();
            }
        }
    },
    mounted(){
            this.countDownTimer()
        }
}
</script>

<style src="./Step4.scss" lang="scss" scoped>

</style>