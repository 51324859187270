<template>
    <div class="leftSide">
        <div class="header">
            <img width="100px" src="../../../assets/appeq.png" />
        </div>
        <!-- <div class="imageList">
            <div v-for="(app) of appsList" v-if="app.logoURL" class="image">
                <img :src="app.logoURL" />
            </div>
        </div> -->
        <div class="leftImage">
            <img v-if="this.stepsJson && this.stepsJson[currentStep - 1]" width=100% height=338px
                :src="(this.stepsJson[currentStep - 1]['img'])" />
        </div>
        <div class="subtitle">
            <span v-if="this.stepsJson && this.stepsJson[currentStep - 1]" class="step">{{ this.stepsJson[currentStep -
                    1]["title"]
            }}</span>
            <span v-if="this.stepsJson && this.stepsJson[currentStep - 1]" class="step__sub">{{
                    this.stepsJson[currentStep - 1]["subtitle"]
            }}</span>
            <span v-if="this.stepsJson && this.stepsJson[currentStep - 1]" class="step__desc">{{
                    this.stepsJson[currentStep - 1]["desc"]
            }}</span>
            <div class="bars">
                <div class="bar unselected"><span :class="currentStep >= 1 ? 'selected' : ''" class="barText">Choose Apps</span></div>
                <div :class="currentStep >= 2 ? 'unselected' : ''" class="bar"><span :class="currentStep >= 2 ? 'selected' : ''" class="barText">Download Extension</span></div>
                <div :class="currentStep >= 3 ? 'unselected' : ''" class="bar"><span :class="currentStep >= 3 ? 'selected' : ''" class="barText">Experience</span></div>
                <!-- <div :class="currentStep >= 4 ? 'unselected' : ''" class="bar"><span :class="currentStep >= 4 ? 'selected' : ''" class="barText">Invite</span></div> -->

            </div>
        </div>
    </div>
</template>

<script>
import Server from '../../../components/API/Server';
import json from "../OnboadingConstants.js"
export default {
    props: {
        appsList: [],
        currentStep: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            stepsJson: [],
        }
    },
    async mounted() {
        this.stepsJson = json;
        this.appsList = (await Server.getAllowedApps()).data["selected_apps"];
    }
}
</script>

<style src="./LeftSide.scss" lang="scss" scoped>

</style>