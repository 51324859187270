<template>
    <div>
        <div style="height: 100%; padding: 20px;">
            <div style=" display: flex; align-items: center;  width: 65%;
    justify-content: space-around;"> 
                <span style="font-size: 16px;">Choose Playbook</span> 
                <div style="margin-bottom: 10px;width: 70%;" class="triggerConfiguration">
                    <Multiselect v-model="value" :options="options" placeholder="Select one trigger configuration" :searchable="false" :show-labels="false" :inputSelected="staffSkills" @select="checkSelection()"></Multiselect>
                </div>
            </div>
            <span satyle=" position: static;">
                <div v-if="this.error!=''" style="font-size: 13px; margin-top: 5px; color: red;">
                {{ error }}
            </div>
            </span>
            <hr>
            <div v-if="this.value==''" style="display: flex; justify-content: center;">
                <img  style="width:400px;display: flex;align-items: center;justify-content: center;" src="../assets/nodata.svg"/>
            </div>
            <div style="height: 300px; overflow: scroll;" v-if="this.value!=''">
                <ve-table :columns="columns" :table-data="tableData" />
            </div>
            <div style="position: absolute; right: 30px; bottom: 30px;">
                <button @click="trigger()" style="border-radius: 7px; background: rgb(78, 95, 255); color: white; font-size: 17px; padding: 3px 37px; height: 50px;">Trigger Now</button>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import Server from "../components/API/Server"
    export default{
        methods: {
            checkSelection(){
                
                console.log("is it working",this.value, "table: ",this.tableData);
                if(this.value == ''){
                    this.tableData.length= 0;
                }
                if(this.staffSkills.length==0){
                    this.tableData.length = 0;
                }
            }
        },
        async mounted(){
                const data = await Server.getNotificationSettings();
                console.log(data);
                for(let i=0;i<data.data.length;i++){
                    this.tableData.length= 0;
                    if(data.data[i].notificationSettings){
                        for(let j=0;j<data.data[i].notificationSettings.customNudges.length;j++){
                            if(data.data[i].notificationSettings.customNudges[j].trigger == "manual"){
                                this.options.push(data.data[i].notificationSettings.customNudges[j].name);
                                this.messages[data.data[i].notificationSettings.customNudges[j].name]=data.data[i].notificationSettings.customNudges[j].customMsg;
                                this.tasks[data.data[i].notificationSettings.customNudges[j].name] = data.data[i].notificationSettings.customNudges[j].tasks;
                            }
                    }
                    }
                }
                console.log(this.value);
                console.log(this.tasks);
            },
        components: {
            Multiselect
        },
        props: {
            domain: {
                type: String,
                default: '',
            },
            account_id: {
                type: String,
                default: '',
            }
        },
        methods: {
            async trigger(){
                if(!this.value){
                    this.error = 'Playbook field is empty!';
                    setTimeout(()=>{
                        this.error = '';
                    },4000);
                    return false;
                }
                const data = await Server.triggerNotification(this.domain, this.value, this.messages[this.value], this.tableData,this.account_id);
                this.$toasted.success("Horray! Notification sent to the users successfully!")
                this.$emit("close")
            }
        },
        watch: {
            value(name){
                this.tableData.length = 0;
                if(name){
                    console.log(name);
                    this.tableData.length= 0;
                    for(let i in this.tasks){
                        for(let j in this.tasks[i])
                        if(i == name){
                            this.tableData.push(this.tasks[i][j]);
                        }
                    }
                }else{
                    this.tableData.length= 0;
                }
                console.log(this.tableData);
                this.tableData.map(d=>{
                    d.due_date="T+"+d.due_date.toString()
                })
            }
            
        },
        data () {
    return {
      value: '',
      options: [],
      messages: {},
      selctedEmail: null,
      tempName: '',
      tasks: {},
      staffSkills: '',
      columns: [
      { field: "due_date", key: "a", title: "Due", align: "left" },
          { field: "task_name", key: "b", title: "Task", align: "left" },
          { field: "task_desc", key: "c", title: "Task Description", align: "left" },
          { field: "user", key: "d", title: "Assignee", align: "left" }
        ],
        tableData: [],
        error: ''
    }
  },
    }
</script>
<style>
    .ve-table .ve-table-border-around{
        height: 300px;
    overflow: scroll;
    }
    .triggerConfiguration{
        .multiselect__tags{
        width: 20rem;
    }
    }
</style>