<template>
  <div>
    <div class="loading" v-if="loading"><img src="../assets/Dual Ring-1s-200px (1).svg"/></div>
    <p v-if="!loading"> Note that more number of keys will take more time to fetch the data. Please select only those keys which are required!</p>
    <Multiselect :close-on-select="false" v-if="!loading" :loading="loading" @deselect="unselectOption" searchable="true" mode="tags" v-model="selectedKeys"   :options="keys" ></Multiselect>
    <button v-if="!loading" @click="saveKeys">Save</button>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Server from '../components/API/Server';
export default {
    components:{
        Multiselect
    },
    data(){return{
        keys:[],
        selectedKeys:["Website"],
        loading:false
    }},
    props:["application"],
    methods:{
        async saveKeys(){
        const bObj = localStorage.getItem("Business_obj_name");
        await Server.saveKeys(bObj,this.selectedKeys)
        window.location.reload()
        this.$toasted.success("Successfully saved keys for your salesforce");
        },
        unselectOption(value){
            if(value=='Website'){
                this.selectedKeys.push("Website")
        }
            if(this.application=='hubspot'){
            this.selectedKeys=this.keys
            }
        }
    },
    async mounted(){
        this.loading=true
        var creds=localStorage.getItem("item_creds")
        if(creds && creds.keys){
            this.selectedKeys=creds.keys
        }
        const bObj = localStorage.getItem("Business_obj_name");
        if(bObj=="Opportunities"){
            this.selectedKeys=["AccountId","StageName","Name","Amount","Type","LastModifiedDate"]
        }else if(bObj=="accounts"){
            this.selectedKeys=["Website","NumberOfEmployees","BillingCity","BillingState","LastModifiedDate","Name"]
        }
        const data=await Server.fetchKeys(this.application, bObj);
      
        var tempKeys=[]
        data.data.records.map(d=>{
            tempKeys=Object.keys(d)
        })
        this.keys=tempKeys.map(data=>{
            return {
                name:data,
                label:data,
                value:data,
                disabled:data=='Website'?true:false
            }
        })
        if(this.application=='hubspot'){
            this.selectedKeys=this.keys
        }
        this.loading=false;
    }
};
</script>
<style  lang="scss">

.multiselect-tag {
    font-size: 0.8rem !important;
    color: white !important;
}

.multiselect {
    margin-top: 12px !important;
    width: 100% !important;
}

.multiselect-option {
    span {
        color: black !important;
        font-size: 1rem !important;
    }
}

button {
  flex-shrink: 0;
  background: #4e5fff;
  color: white;
  border: 0px;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 56px;
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  margin-top: 20px;
}

.loading{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
