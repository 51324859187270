<template>

  <div>
    <div v-if="current==1" style="display: flex;flex-direction: column;" class="">
    <h6 class="mt-4" v-if="!isNextPage">Enter your Mixpanel Username</h6>
    <div class="zendeskInputBox">
      <input placeholder="Enter Username" v-model="productUsage.username" />
    </div>
    <h6 class="mt-4" v-if="!isNextPage">Enter your Mixpanel Secret Token</h6>

    <div class="zendeskInputBox">
      <input placeholder="Enter Secret Token" v-model="productUsage.secret" />
    </div>
    <h6 class="mt-4" v-if="!isNextPage">Enter your Mixpanel Project Id</h6>

    <div class="zendeskInputBox">
      <input placeholder="Enter Secret Token" v-model="productUsage.project_id" />
    </div>
    <button @click="handleSaveCreds" style="margin-top: 20px;">Save</button>
  </div>
  <div v-else>
        <h5 class="successText">
          You have successfully configured Mixpanel
        </h5>
        <img src="../assets/success.svg" />
    </div>
  </div>
</template>

<script>
import Server from "./API/Server"
export default {
  data() {
    return {
      productUsage: {
        username: "",
        project_id: "",
        secret: ""
      },
      current: 1
    }
  },
  methods: {
    async handleSaveCreds() {
      const data = await Server.mixpanelSaveData(this.productUsage)
      if (data && data.code == 200) {
        this.current = 2;
        this.$toasted.success(
          "Please wait we will notify you once the data has been resynced via mail ."
        );
        setTimeout(() => {
          window.location.reload()
        }, 2000);
        mixpanel.businessObjectAdded(localStorage.getItem("Business_obj_name"));
      } else {
        this.current = 1;
        this.$toasted.error("Something Went Wrong !")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zendeskInputBox {
  border: 1px solid rgb(220, 226, 234);
  display: flex;
  height: 40px;
  align-items: center;

  input {
    width: 100%;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
}
</style>