import VueRouter from "vue-router";
import routes from "./routes";
import CryptoJS from 'crypto-js';

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  mode: "history",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  console.log('working')
  const current_url = window.location.href;
  console.log(current_url)
  const arr_of_values = current_url.split('?value=');
  let getValue = '';
  if (arr_of_values.length != 1) {
    getValue = arr_of_values[1];
    if (getValue) {
      const recievedValue = decodeURIComponent(getValue);
      const decryptedValue = CryptoJS.AES.decrypt(recievedValue, "DYyAKzBo70ca9aCSLNgwzLutBhkw0EXs6Iqj7jI8").toString(CryptoJS.enc.Utf8)
      if (decryptedValue || JSON.parse(decryptedValue)) {
        let response = JSON.parse(decryptedValue);
        window.location.href = arr_of_values[0];
        response = JSON.stringify(response)
        localStorage.setItem('ADMIN_CREDS', response);
      }
    }
  }
  const arr_of_splits = current_url.split('?token=');
  let getToken = '';
  if (arr_of_splits.length != 1) {

    getToken = arr_of_splits[1];
    if (getToken) {
      console.log("getToken (receiver):", getToken);

      const receivedToken = decodeURIComponent(getToken);
      console.log("receivedToken (receiver):", receivedToken);
      const decryptedText = CryptoJS.AES.decrypt(receivedToken, "DYyAKzBo70ca9aCSLNgwzLutBhkw0EXs6Iqj7jI7").toString(CryptoJS.enc.Utf8)
      console.log("hash (receiver):", decryptedText);
      if (decryptedText || JSON.parse(decryptedText)) {
        let response = JSON.parse(decryptedText);
        const currentTimestamp = Math.round(Date.now() / 1000);
        if (response['exp'] != 0 && response['exp'] > currentTimestamp) {
          window.location.href = arr_of_splits[0];
          let emailID = response['__email__id'];
          console.log(emailID);
          let arrOfEmailComp = emailID.split('@');
          response['__email__id'] = 'onboarding@' + arrOfEmailComp[arrOfEmailComp.length - 1];
          response = JSON.stringify(response)
          console.log(response);
          localStorage.setItem('ADMIN_CREDS', response);
        }
      }
    }
  }
  const adminCred = localStorage.getItem("ADMIN_CREDS");
  const googleAuth = localStorage.getItem("google_auth");
  try {
    if (to.fullPath == "/internal-login" && adminCred) {
      const data = JSON.parse(adminCred);
      if (data && data.__email__id);
      const domain = data.__email__id.split("@")[1];
      if (domain && domain == "appeq.ai") {
        console.log("logged in");
        next();
        return;
      }
    }
  } catch (e) {
    console.log(e);
  }
  if (adminCred || to.fullPath == "/" || to.fullPath.includes("dashboard") || to.fullPath.includes("intercom-feeds") || to.fullPath.includes("popup") || to.fullPath.includes("onboarding") || to.fullPath.includes("widget-settings") || to.fullPath.includes("sidedash")) {
    next({});
  } else {
    next({ path: "/" });
  }
});
export default router;
