<template>
  <div class="content">
    <fade-transition :duration="100" mode="out-in">
      <router-view></router-view>
    </fade-transition>
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions';
export default {
  components: {
    FadeTransition
  }
};
</script>
<style lang="scss" scoped>
.content{
  height:94vh ;
  overflow-x: hidden;
  left: 10%;
  margin-top: 1.5%;
  position: absolute;
  right: 10%;
  background-color: linear-gradient( 90deg,#5170FF,#FF66C4) !important;
}
.content ::-webkit-scrollbar{
  width: 0px;
}

.content ::-webkit-scrollbar-thumb{
  width: 0px;
}
.content{
  &::-webkit-scrollbar{
    width: 0px;
  }
  &::-webkit-scrollbar-thumb{
    width: 0px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-button{
    width: 0px !important;
  }
}
</style>
