<template>
    <div>
      <div v-if="!isSuccess">
        <p style="text-align: center; margin-top: 30px">
          Click on this button to get started
        </p>
        <button v-if="!isLoading" @click="getAccessToken()">
          Connect your access token
        </button>
        <div v-if="isLoading" class="loading">
          <img src="../assets/infinity_blue.svg" />
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        "
      >
        <h5 v-if="isSuccess" class="successText">
          You have successfully configured Zoho
        </h5>
        <img v-if="isSuccess" src="../assets/success.svg" />
      </div>
    </div>
  </template>
  
  <script>
  import Server from "../components/API/Server";
  import LocalUtils from "./Utils/LocalUtils";
  export default {
    data: () => {
      return {
        isLoading: false,
        isSuccess: false,
      };
    },
    methods: {
      async getAccessToken() {
        let url = `https://accounts.zoho.in/oauth/v2/auth?response_type=code&client_id=1000.88DLPIA37UZWXIYLBBKAWHAS3JBQ4K&scope=ZohoCRM.modules.ALL,ZohoCRM.settings.ALL&redirect_uri=${process.env.VUE_APP_REDIRECTION_ROUTE}&prompt=consent&access_type=offline`;
        var win = window.open(url, "_blank");
        let pollTimer = window.setInterval(async () => {
          try {
            var url = new URL(win.location.href);
            if (url.searchParams.get("code")) {
              console.log(url.searchParams.get("code"));
              win.close();
              window.clearInterval(pollTimer);
              this.isLoading = true;
              let code = url.searchParams.get("code");
              const data = await Server.getZohoAccessToken(code);
              console.log(data, 'ye reesposne aayaaaa')
              const bObj = localStorage.getItem("Business_obj_name");
             await Promise.all(bObj.split(",").map(async d=>{
              console.log('business ovject', d);
                const saveHub = await Server.saveZohoData({
                appeq_supported_application: "zoho-crm",
                business_object_name: d,
                access_token: data.data["access_token"],
                refresh_token:data.data["refresh_token"],
                email: LocalUtils.getEmail()
              });
              }));
              // bObj.split(",").map(async data=>{
              //     await Server.onResync(LocalUtils.getEmail(), data);
  
              //   })
              console.log(data);
              this.$toasted.success(
                "Please wait we will notify you once the data has been resynced via mail ."
              );
  
              this.isLoading = false;
              this.isSuccess = true;
              //Server.callSceduler("spreadsheet",data)
              // window.location.reload();
            }
          } catch (error) {
            console.log(error);
          }
        }, 1000);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  button {
    flex-shrink: 0;
    background: #6f55ff;
    color: white;
    border: 0px;
    padding: 0px 16px;
    font-size: 14px;
    font-weight: 500;
    height: 56px;
    cursor: pointer;
    border-radius: 6px;
    width: 100%;
    margin-top: 5px;
  }
  
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  </style>
  